import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router";
import { openSnackbar } from "../redux/actions";
import { useSelector } from "react-redux";
import Layout from "../components/common/layout";

const GuestRoutes = () => {
  const userInfo = useSelector((state) => state.userInfo) || {};
  let loggedIn = Boolean(localStorage.getItem("auth_token"));

  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn && userInfo.Id === undefined) {
      window.localStorage.clear();
    }

    console.log(loggedIn, userInfo);

    // if (loggedIn && userInfo.Id !== undefined) {
    //   dispatch(
    //     openSnackbar({
    //       type: "success",
    //       title: "",
    //       body: "A user is logged in",
    //     })
    //   );
    // }
  }, [loggedIn, userInfo, dispatch]);

  return !loggedIn ? (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default GuestRoutes;
