import { Box, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { apiUrl } from "../../constants";
import { getAuthorizedFetchOptions } from "../../helpers/getAuthorizedFetchOptions";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";
import LoadingButton from "../common/button/loadingButton";

const ResendUserInvite = ({ id }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendInvite = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await fetch(
      apiUrl + "locker/resendinvite",
      getAuthorizedFetchOptions("POST", {
        id,
        senderFirstName: userInfo.firstName,
        senderLastName: userInfo.lastName,
      })
    );
    const data = await response.json();

    if (data.statusCode !== 10) {
      return console.log(data.error);
    }

    setIsLoading(false);
    dispatch(
      openSnackbar({ title: "Success", body: "Invitation successfully sent" })
    );
  };

  return (
    <LoadingButton isLoading={isLoading} onClick={handleSendInvite}>
      Resend Invite
    </LoadingButton>
  );
};

export default ResendUserInvite;
