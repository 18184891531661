import React from "react";
import { Navigate, Outlet } from "react-router";
import Layout from "../components/common/layout";

const LoggedInRoutes = () => {
  const loggedIn = Boolean(localStorage.getItem("auth_token"));

  return loggedIn ? (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default LoggedInRoutes;
