import {
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import DragAndDropImage from "../../images/drag-and-drop.svg";
import { useDropzone } from "react-dropzone";
import Check from "@mui/icons-material/Check";
import LargeButton from "../../components/common/button/largeButton";
import UploadFileDialog from "../common/uploadFileDialog";
import useUpload from "../../hooks/useUpload";
import PatientService from "../../services/patient.service";
import usePatientFiles from "../../hooks/usePatientFiles";
import CaseService from "../../services/case.service";
import CloseIcon from "@mui/icons-material/Close";

const scanSetTypes = [
  { value: 0, name: "Pre Scan" },
  { value: 1, name: "Mock Up Scan" },
  { value: 2, name: "Post Scan" },
  { value: 3, name: "Other" },
];

const ResubmitDialog = ({
  open,
  onClose,
  onSubmit,
  patientId,
  caseId,
  uploadType = "rescan",
  caseNumber,
  message,
  backButtonMessage = "Back",
  fileUploadType = "intraoralScans",
  type = "intraoralScans",
}) => {
  const [scanType, setScanType] = useState("");
  const [content, setContent] = useState();

  const [scanSetType, setScanSetType] = useState(0);
  const [scanSetName, setScanSetName] = useState("");
  const [isDialogOpenIOS, setIsDialogOpenIOS] = useState(false);
  const [isDialogOpenOther, setIsDialogOpenOther] = useState(false);
  const { uploading, upload } = useUpload({ patientId, type });
  const [uploadingSet, setUploadingSet] = useState(false);

  const [photoSetName, setPhotoSetName] = useState("");
  const [error, setError] = useState("");
  const {
    files,
    loading,
    pages,
    currentPage,
    handleChangePage,
    handleAddFiles,
  } = usePatientFiles({
    patientId,
    type: type,
    shouldGetExtensions: true,
    take: 10,
  });

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    if (uploadType === "rescan") {
      const resubmitResponse = CaseService.submitrescan(caseId);
      onSubmit();
    } else {
      setScanType("");
      setContent();
      onClose();
    }
  };

  const handleSelectChange = (event) => {
    setScanSetType(event.target.value);
    if (event.target.value !== 3 && scanSetName !== "") setScanSetName("");
  };

  React.useEffect(() => {
    if (scanType === "intraoralScans") {
      setIsDialogOpenIOS(true);
    } else if (scanType === "otherScans") {
      setIsDialogOpenOther(true);
    } else if (scanType === "selectExisting") {
      setContent(<div>Select Existing</div>);
    } else {
      setContent();
    }
  }, [scanType]);

  const handleUpload = async (files) => {
    if (scanType === "intraoralScans") {
      const data = await upload(files, {
        FileSetType: scanSetType,
      });

      setUploadingSet(true);

      const setData = await PatientService.uploadFileSet(
        {
          Description: scanSetName,
          FileName: scanSetName,
          FileSetType: scanSetType,
          ExtensionIds: data.map((d) => d.id),
          PatientId: patientId,
        },
        scanType
      );

      addPatientFile(setData);
      // method expects an array but setData is an object
      handleAddFiles([setData]);
    } else if (scanType === "otherScans") {
      if (files.length === 0) {
        const message = "No files selected";
        setError(message);
        throw new Error(message);
      }

      console.log(files);
      const data = await upload(files);
      console.log(data);

      addPatientFile(data);
      // method expects an array but setData is an object
      handleAddFiles([data]);
      setIsDialogOpenOther(false);
    }
  };

  const addPatientFile = async (setData) => {
    console.log(setData);
    var addCasePatientFiles = [];

    let files = [];

    if (uploadType === "rescan") {
      console.log(setData.fileExtensions);
      if (setData.fileExtensions !== undefined) {
        setData.fileExtensions.forEach((value) => {
          addCasePatientFiles.push({
            patientFileId: setData.id,
            patientFileExtensionId: value.id,
          });
        });
      } else if (setData.length > 0) {
        setData.forEach((value) => {
          addCasePatientFiles.push({
            patientFileId: value.id,
            patientFileExtensionId: "00000000-0000-0000-0000-000000000000",
          });
        });
      }

      console.log("rescan!");
      let addCasePatientFilesRequest = {
        caseId: caseId,
        caseCaseServiceProviderId: null,
        casePatientFiles: addCasePatientFiles,
      };

      const addPatientFileResponse = await CaseService.addpatientfiles(
        addCasePatientFilesRequest
      );
      await addPatientFileResponse.json().then((response) => {
        const resubmitResponse = CaseService.submitrescan(caseId);
      });
    } else if (uploadType === "approve") {
      setData.fileExtensions.forEach((value) => {
        let f = { fileName: value.fileName, path: value.path };
        files.push(f);
      });

      let model = {
        caseNumber: caseNumber,
        files: files,
        isSingleFileOnly: false,
      };
      CaseService.sendfiletodesign(model);
    }

    setIsDialogOpenIOS(false);
    setUploadingSet(false);
    onSubmit();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          paddingY: 4,
          paddingX: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <Box sx={{ textAlign: "right", marginRight: "0px", marginTop: "0px" }}>
        <IconButton
          onClick={() => {
            setScanType("");
            setContent();
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        {content}
        <Stack alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ marginTop: 2, fontWeight: "bold" }}
          >
            {message}
          </Typography>
          <Stack spacing={2} sx={{ paddingTop: 2 }}>
            {fileUploadType === "intraoralScans" && (
              <LargeButton
                variant="secondary"
                fullWidth
                onClick={() => {
                  setScanType(fileUploadType);
                }}
              >
                Intraoral Scan Files
              </LargeButton>
            )}
            {fileUploadType === "otherScans" && (
              <LargeButton
                variant="secondary"
                fullWidth
                onClick={() => {
                  setScanType(fileUploadType);
                }}
              >
                Yes
              </LargeButton>
            )}
            {/* <LargeButton
              variant="primary"
              fullWidth
              onClick={() => {
                setScanType("selectExisting");
              }}
            >
              Select Latest Case
            </LargeButton> */}
            <LargeButton variant="gray" fullWidth onClick={handleClose}>
              {backButtonMessage}
            </LargeButton>
          </Stack>

          {/* <LargeButton variant="primary" fullWidth onClick={handleSubmit}>
              Confirm
            </LargeButton> */}
        </Stack>
      </DialogContent>

      <UploadFileDialog
        additionalFields={
          <Box>
            <FormControl fullWidth>
              <InputLabel id="scan-set-select-label">Scan Set Type</InputLabel>
              <Select
                labelId="scan-set-select-label"
                id="simple-select"
                value={scanSetType}
                label="Scan Set Type"
                onChange={handleSelectChange}
              >
                {scanSetTypes.map((v) => (
                  <MenuItem key={v.value} name={v.name} value={v.value}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {scanSetType === 3 && (
              <TextField
                label="Name of scan set"
                fullWidth
                sx={{ marginTop: 2 }}
                value={scanSetName}
                onChange={(e) => setScanSetName(e.target.value)}
              />
            )}
          </Box>
        }
        title="Upload Intraoral Scans"
        open={isDialogOpenIOS}
        uploading={uploading || uploadingSet}
        onClose={() => {
          setIsDialogOpenIOS(false);
        }}
        onUpload={handleUpload}
      />

      <UploadFileDialog
        title="Upload Files"
        open={isDialogOpenOther}
        uploading={uploading}
        onClose={() => {
          setIsDialogOpenOther(false);
          setError("");
        }}
        onUpload={handleUpload}
        error={error}
        resetError={() => setError("")}
      />
    </Dialog>
  );
};

export default ResubmitDialog;
