import React from "react";
import Dropzone from 'react-dropzone';
import { Box, Grid, Divider, IconButton, TextField, InputAdornment } from "@mui/material"
import MenuItem from '@mui/material/MenuItem';
import ToothChart from "../../../common/toothChart/toothChart";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import moment from "moment";
import LinearProgress from '@mui/material/LinearProgress';

import setFilePhoto from "../../../../images/set_file_photo.svg";
import DragAndDropImage from "../../../../images/drag-and-drop.svg";
import caseCreatedIcon from "../../../../images/case_created_icon.svg";
import AdditionalFiles from "../../../../images/additionalFiles.svg";

import ToothService from "../../../../services/toothChart.service";
import FileUploaderService from "../../../../services/fileUploader.service";
import PatientService from "../../../../services/patient.service";

import SelectToothRestorationModal from "../common/selectToothRestorationModal/selectToothRestorationModal";

function CrownAndBridge({treatment, patientId, handleTreatmentUpdate, patientFiles, notion}) {

    const materialOptions = [
        "Final",
        "PMMA"
    ]

    const pfzDetails = [
        "Facial Cutback",
        "Lingual Collar",
        "Simple Coping"
    ];

    const pmmaPreparationTypes = [        
        "Un-prepped shell temps (no virtual prep)",
        "Prepped shell temps (0.5 mm all around)",
        "Prepped shell temps (1 mm all around)",
        "Prepped shell temps (prepped scan attached)"
    ];
        
    const [treatmentDetails, setTreatmentDetails] = React.useState(treatment);
    const [selectToothRestorationOpen, setSelectToothRestorationOpen] = React.useState(false);
    const [selectedTooth, setSelectedTooth] = React.useState(0);
    const [typesOfRestoration, setTypesOfRestoration] = React.useState([
        "Veneers",
        "Crowns",
        "Pontic"
    ]);
    const [requiredFiles, setRequiredFiles] = React.useState([
        {
            name: "IOS Scan (STL Files) (Upper, Lower and Bite)",
            image: setFilePhoto,
            files: [],
            numberOfFilesUploading: 0
        },
        {
            name: "Additional Files",
            image: AdditionalFiles,
            files: [],
            numberOfFilesUploading: 0
        }
    ])

   
    React.useEffect(() => {

        let requirements = [
            {
                description: "Tooth Chart",
                value: null
            },
            {
                description: "Type of Restoration (Multiple)",
                value: null
            },
            {
                description: "Misaligned Teeth Correction",
                value: null
            },
            {
                description: "Gingival Contouring",
                value: null,
                needGingivalGuide: null
            },
            {
                description: "Vertical Dimension for Occlusion",
                value: null
            },
            {
                description: "Restoration Type",
                value: null,
                type: ""
            },
            {
                description: "Max Preferred Length of Centrals",
                value: null
            },
            {
                description: "Are you fabricating?",
                value: null,
                printer: "",
                material: "",
                millInfo: "",
                block:""
            },
        ]

        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        treatmentDetailsCopy.requirements = requirements;

        setTreatmentDetails(treatmentDetailsCopy);

        handleTreatmentUpdate(treatmentDetailsCopy);

        if (treatment.files.length > 0) {
            let requiredFilesCopy = [
                {
                    name: "IOS Scan (STL Files) (Upper, Lower and Bite)",
                    image: setFilePhoto,
                    files: []
                },
                {
                    name: "Additional Files",
                    image: AdditionalFiles,
                    files: []
                }
            ];

            treatment.files.forEach(x => {
                requiredFilesCopy[x.idx].files.push(x);
            })

            setRequiredFiles(requiredFilesCopy);
        }

        if (treatment.toothNumbers.length > 0) {
            let numbers = [];

            treatment.toothNumbers.forEach(x => {
                numbers.push(x.tooth);
            });

            ToothService.loadToothNumbers(numbers, treatment.id);
        }
    }, [])

    React.useEffect(() => {
        
        let requiredFilesCopy = Object.assign([], requiredFiles);
                                    
        requiredFilesCopy[0].files = requiredFilesCopy[0].files.splice(); 

        setRequiredFiles(requiredFilesCopy);

        if(patientFiles)
        {
            
            let ios = patientFiles.find(x => x.fileType === "Intraoral Scan")
            
            if(ios)
            {          
                                  
                ios.files.forEach(y=>{
                    PatientService.getPatientFileExtension(y.patientFileId, y.patientId, y.fileType)
                    .then(response1=> response1.json())
                    .then(json1=>{
                        json1.forEach(pFile => {

                            let linkToPatient = {
                                PatientId: patientId,
                                FileId: pFile.fileId,
                                Filename: pFile.fileName
                            };
                
                            PatientService.uploadPatientFile(linkToPatient)
                                .then(response => response.json())
                                .then(json => {

                                    let newFile = {
                                        idx: 0,
                                        fileName: pFile.fileName,
                                        fileId: pFile.fileId,
                                        patientFileId: json.id,
                                        uploadedDate: pFile.uploadedDate
                                    };
                                    
                                    treatmentDetails.files = treatmentDetails.files.concat(newFile);                         

                                    setTreatmentDetails(treatmentDetails);
                                    if (handleTreatmentUpdate) {
                                        handleTreatmentUpdate(treatmentDetails);
                                    }
                                    
                                    let requiredFilesCopy = Object.assign([], requiredFiles);
                                    
                                    requiredFilesCopy[0].files.push(newFile);
                    
                                    setRequiredFiles(requiredFilesCopy);
                                    
                            
                            });
                        });
                    });
                })
            }
        }
    }, [patientFiles])

    const handleRequirementSelectNeedGingival = (reqDesc, value, measurementToZero = false) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {
            getRequirement.needGingivalGuide = value;

            if (measurementToZero) {
                if (typeof(getRequirement.measurement) !== "undefined") {
                    getRequirement.measurement = -1;
                }
            }          
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }
    
    const handleRequirementSelectMeasurement = (reqDesc, value) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {
            getRequirement.measurement = value;
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleSelectRestorationClose = (selected) => {
        setSelectToothRestorationOpen(false);        
        if (selected !== null) {
            treatmentDetails.toothNumbers.forEach(tooth => {
                
                if(tooth.restorationType == "" )
                {                
                    tooth.restorationType=selected;
                }
                
                setTreatmentDetails(treatmentDetails);

            if (handleTreatmentUpdate) {
                handleTreatmentUpdate(treatmentDetails);
            }
            });
        }
    }

    const handleSelectTooth = (number) => {
        console.log("number in handleSelectTooth",number);
        setSelectedTooth(number);
        let newTooth = {
                   tooth: number,
                   restorationType: ""
               };               
        treatmentDetails.toothNumbers = treatmentDetails.toothNumbers.concat(newTooth);
        setTreatmentDetails(treatmentDetails);
        
    }
    
    const removeTooth = (number) => {

        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
        treatmentDetailsCopy.toothNumbers = treatmentDetailsCopy.toothNumbers.filter(x => x.tooth !== number);

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }

        ToothService.removeSelectedTooth(number, treatment.id);

    }

    const handleRemoveTeeth = (number) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
        treatmentDetailsCopy.toothNumbers = treatmentDetailsCopy.toothNumbers.filter(x => x.tooth !== number);

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleRequirementSelectValue = (reqDesc, value, measurementToZero = false) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {
            getRequirement.value = value;

            if (measurementToZero) {
                if (typeof(getRequirement.measurement) !== "undefined") {
                    getRequirement.measurement = -1;
                }
            }
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleChangeAdditionalNotes = (value) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        treatmentDetailsCopy.additionalNotes = value;

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const onFileChange = (files, idx) => {
        
        if (files.length > 0) {            
                        
            let requiredFilesCopy = Object.assign([], requiredFiles);        
            requiredFilesCopy[idx].numberOfFilesUploading =requiredFilesCopy[idx].numberOfFilesUploading + files.length; 
            setRequiredFiles(requiredFilesCopy);
            
            for(var i=0;i<files.length;i++) {
                let x = files[i];

                let generateFileId = FileUploaderService.generateGuid();

                let blobName = "patient/" + "00000000-0000-0000-0000-000000000000" + "/" + generateFileId;

                if (patientId !== "") {
                    blobName = "patient/" + patientId + "/" + generateFileId;
                }   
              
    
                //console.log("fileId", blobName);
    
                let fileObj = {
                    idx: idx,
                    treatmentId: treatment.id,
                    fileId: generateFileId,
                    fileName: x.name,
                    patientFileId: "",
                    patientExtensionId: ""
                }
    
                FileUploaderService.uploadFile(x, blobName, fileUploadCallback, fileObj);

              

              }
        }

        // let documentId = "file-upload-" + treatment.id;

        // document.getElementById(documentId).value = "";
    }

    const fileUploadCallback = (progress, fileObj) => {

        if (progress === 100) {                                       

            if (patientId !== "") {
                let linkToPatient = {
                    PatientId: patientId,
                    FileId: fileObj.fileId,
                    Filename: fileObj.fileName
                };
    
                PatientService.uploadPatientFile(linkToPatient)
                    .then(response => response.json())
                    .then(json => {
                        let newFile = {
                            idx: fileObj.idx,
                            fileName: fileObj.fileName,
                            fileId: fileObj.fileId,
                            patientFileId: json.id
                        };
    
                        treatmentDetails.files = treatmentDetails.files.concat(newFile);                         

                        setTreatmentDetails(treatmentDetails);

                        if (handleTreatmentUpdate) {
                            handleTreatmentUpdate(treatmentDetails);
                        }
        
                        let requiredFilesCopy = Object.assign([], requiredFiles);
        
                        requiredFilesCopy[fileObj.idx].files.push(newFile);
                        requiredFilesCopy[fileObj.idx].numberOfFilesUploading =requiredFilesCopy[fileObj.idx].numberOfFilesUploading - 1; 
                        setRequiredFiles(requiredFilesCopy);
                    })
            }
            else {
                let newFile = {
                    idx: fileObj.idx,
                    fileName: fileObj.fileName,
                    fileId: fileObj.fileId,
                    patientFileId: "00000000-0000-0000-0000-000000000000"
                };

                let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

                treatmentDetailsCopy.files = treatmentDetailsCopy.files.concat(newFile);
                
                setTreatmentDetails(treatmentDetailsCopy);

                if (handleTreatmentUpdate) {
                    handleTreatmentUpdate(treatmentDetailsCopy);
                }

                let requiredFilesCopy = Object.assign([], requiredFiles);
        
                requiredFilesCopy[fileObj.idx].files.push(newFile);
                requiredFilesCopy[fileObj.idx].numberOfFilesUploading =requiredFilesCopy[fileObj.idx].numberOfFilesUploading - 1; 
                setRequiredFiles(requiredFilesCopy);
            }
                        
        }
    }

    const removeFile = (requireIdx, fileIdx) => {
        let requiredFilesCopy = Object.assign([], requiredFiles);

        let fileCopy = Object.assign({},  requiredFilesCopy[requireIdx].files[fileIdx]);

        requiredFilesCopy[requireIdx].files.splice(fileIdx, 1);

        setRequiredFiles(requiredFilesCopy);

        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        treatmentDetailsCopy.files =treatmentDetailsCopy.files.filter(x => x.fileId !== fileCopy.fileId);

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleRequirementsSelectList = (reqDesc, value) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {
            if (typeof(getRequirement.value.find(x => x === value)) === "undefined") {
                getRequirement.value.push(value);

                if (value === "PFZ") {
                    let pfzRequirement =   {
                        description: "PFZ Additional Details",
                        value: null
                    }

                    treatmentDetailsCopy.requirements.push(pfzRequirement);
                }
                else if (value === "PMMA") {
                    let pmmaRequirement =   {
                        description: "Preparation Type",
                        value: []
                    }

                    treatmentDetailsCopy.requirements.push(pmmaRequirement);
                }
            }        
            else {
                getRequirement.value =  getRequirement.value.filter(x => x !== value);

                if (value === "PFZ") {
                    treatmentDetailsCopy.requirements = treatmentDetailsCopy.requirements.filter(x => x.description !== "PFZ Additional Details");
                }
                else if (value === "PMMA") {
                    treatmentDetailsCopy.requirements = treatmentDetailsCopy.requirements.filter(x => x.description !== "Preparation Type");
                }
            }
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleSelectCustomRestorationType = (reqDesc, value) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {
            
                getRequirement.type = value;            
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleSelectCustomPrinterMaterial = (reqDesc, identifier, value) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {

            if (identifier === "Printer") {
                getRequirement.printer = value;
            }
            else {
                getRequirement.material = value;
            }
            
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    const handleSelectCustomMillingInfo = (reqDesc, identifier, value) => {
        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        let getRequirement = treatmentDetailsCopy.requirements.find(x => x.description === reqDesc);

        if (typeof(getRequirement) !== "undefined") {

            if (identifier === "Mill Info") {
                getRequirement.millInfo = value;
            }
            else {
                getRequirement.block = value;
            }
        }

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
        }
    }

    return (
        <>
            <SelectToothRestorationModal isOpen={selectToothRestorationOpen} withGingivalContouring={false} typesOfRestoration={typesOfRestoration} handleClose={handleSelectRestorationClose} />
            {treatmentDetails.requirements.filter(x => x.description !== "Type of Restoration (Multiple)").map((req, idx) => (
                <>
                    <Box>
                        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <Box sx={{display: 'flex', backgroundColor: '#5B5B5B', color:'#fff', width: '40px', height: '40px', borderRadius: '50%', alignItems: 'center', justifyContent: 'center', marginRight: '15px'}}>
                                {idx + 1}
                            </Box>
                            <Box sx={{fontSize: '21px', fontWeight: '500', lineHeight: '31px', color: '#27374F'}}>
                                {req.description}
                            </Box>
                        </Box>

                        {req.description === "Tooth Chart" ? (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Box sx={{background: 'rgba(0,0,0,0.08)', borderRadius: '21px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Box sx={{width:'75%'}}>
                                            <ToothChart toothChartId={treatment.id} selectedChartType={notion} handleSelectTooth={handleSelectTooth} handleRemoveTeeth={handleRemoveTeeth}/>
                                        </Box>
                                    </Box>
                                
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{background: '#fff', minHeight:'100%', borderRadius: '16px 19px 19px 19px', overflowY: 'auto'}}>
                                        
                                        <Box sx={{color: '#000', fontWeight: '500', fontSize: '18px', lineHeigth: '21px', paddingTop: '40px', textAlign:'center'}}>
                                            Your selected tooth will be displayed here:
                                        </Box>
                                        <Box sx={{ lineHeigth: '21px', paddingTop: '10px', textAlign:'center'}}>
                                        <Button sx={{textTransform:'none', background: 'linear-gradient(270deg, #11C984 0%, #32BE60 100%)', boxShadow: "0px 7px 7px rgba(0, 0, 0, 0.05)", borderRadius: '5px', width:'250px', height: '60px', color: '#fff'}} onClick={()=>setSelectToothRestorationOpen(true)} >Choose a treatment plan for the selected tooth.</Button>  
                                        </Box>

                                        {treatmentDetails.toothNumbers.length === 0 ? (
                                            <Box sx={{display:'flex', background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FDFDFD', borderRadius: '6px', width:'75%', margin:'0 auto', paddingTop:'22px', paddingBottom: '22px', paddingLeft: '20px', marginTop:'30px'}}>
                                                Select a tooth first.
                                            </Box>
                                        ) : (
                                            <>
                                                {treatmentDetails.toothNumbers.map((tooth, idx) => (
                                                    <Box sx={{display:'flex', width:'75%', margin:'0 auto', borderRadius: '6px', marginTop: '30px'}}>
                                                        <Box sx={{display:'flex', width:'65%', alignItems:'center', paddingTop:'22px', paddingBottom: '22px', paddingLeft: '20px', background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FDFDFD', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}}>
                                                            <Box sx={{flexGrow: 1}}>
                                                                #{tooth.tooth} Tooth
                                                            </Box>
                                                            <Box sx={{marginRight: '15px'}}>
                                                                <IconButton onClick={() =>  removeTooth(tooth.tooth)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{display: 'flex', alignItems:'center', width:'35%', paddingTop:'22px', paddingBottom: '22px', paddingLeft: '20px', background: '#0587E4', borderTopRightRadius: '6px', borderBottomRightRadius: '6px', color: '#fff'}}>
                                                        {tooth.restorationType.indexOf("-") >= 0 ? tooth.restorationType.split("-")[0] : tooth.restorationType}
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </>
                                        )}                                        
                                    </Box>
                                </Grid>
                            </Grid>
                        ) : ("")}

                    </Box>
                    {req.description === "Misaligned Teeth Correction" ? (
                            <Box sx={{display: 'flex', alignItems:'center'}}>
                                <Box sx={{width:'230px', cursor:'pointer', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '15px', height: '55px', background: req.value === "As Much as Possible" ? '#0587E4' :'#FBFBFB', color: req.value === "As Much as Possible" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Misaligned Teeth Correction", "As Much as Possible")}>
                                    As Much as Possible
                                </Box>
                                <Box sx={{width:'230px', height: '55px', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '60px', cursor:'pointer', background: req.value !== null && req.value !== "As Much as Possible" ? '#0587E4' :'#FBFBFB', color: req.value !== null &&  req.value !== "As Much as Possible" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Misaligned Teeth Correction", "")}>
                                    Others
                                </Box>

                                {req.value !== null && req.value !== "As Much as Possible" ? (
                                    <Box sx={{display:'flex', flexDirection: 'column'}}>                                        
                                        <Box>
                                            <TextField 
                                                size={"medium"}                                                
                                                value={req.value}
                                                onChange={(e) => { handleRequirementSelectValue("Misaligned Teeth Correction", e.target.value)}}
                                                sx={{backgroundColor: '#fff', width: '250px'}}
                                                placeholder="Please specify"
                                                />
                                        </Box>
                                    </Box>
                                ) : ("")}
                            </Box>
                        ) : ("")}

                        {req.description === "Gingival Contouring" ? (
                            <Box sx={{display: 'flex', alignItems:'center'}}>
                                <Box sx={{width:'230px', cursor:'pointer', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '15px', height: '55px', background: req.value === "Yes" ? '#0587E4' :'#FBFBFB', color: req.value === "Yes" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Gingival Contouring", "Yes")}>
                                    Yes
                                </Box>
                                <Box sx={{width:'230px', height: '55px', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '60px', cursor:'pointer', background: req.value !== null && req.value !== "Yes" ? '#0587E4' :'#FBFBFB', color: req.value !== null &&  req.value !== "Yes" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Gingival Contouring", "No")}>
                                    No
                                </Box>

                                {req.value !== null && req.value !== "No" ? (
                                    <Box sx={{display:'flex', flexDirection: 'column'}}>
                                        <Box sx={{marginBottom: '10px'}}>
                                             Do you need a Gingival Reduction Guide? (Additional $150.00)
                                        </Box>
                                        <Box sx={{display: 'flex'}}>
                                            <Box sx={{width:'100px', cursor:'pointer', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '15px', height: '55px', background: req.needGingivalGuide === true ? '#0587E4' :'#FBFBFB', color: req.needGingivalGuide === true ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectNeedGingival("Gingival Contouring", true)}>
                                                Yes
                                            </Box>
                                            <Box sx={{width:'100px', height: '55px', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '60px', cursor:'pointer', background: req.needGingivalGuide !== null && req.needGingivalGuide !== true ? '#0587E4' :'#FBFBFB', color: req.needGingivalGuide !== null &&  req.needGingivalGuide !== true ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectNeedGingival("Gingival Contouring", false)}>
                                                No
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : ("")}
                            </Box>
                        ) : ("")}

                        {req.description === "Vertical Dimension for Occlusion" ? (
                            <>
                                <Box sx={{display: 'flex', flexWrap:'wrap'}}>
                                    <Box sx={{width:'194px', height:'65px', display:'flex', border: req.value === "Keep Existing" ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center', background:'rgba(196, 196, 196, 0.5)'}} onClick={() => handleRequirementSelectValue("Vertical Dimension for Occlusion", "Keep Existing", true)}>
                                           Keep Existing
                                    </Box>                                   
                                    <Box sx={{width:'194px', height:'65px', display:'flex', border: req.value === "Open Bite" ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center',  background:'rgba(196, 196, 196, 0.5)'}} onClick={() => handleRequirementSelectValue("Vertical Dimension for Occlusion", "Open Bite")}>
                                            Open Bite
                                    </Box>
                                    <Box sx={{width:'194px', height:'65px   ', display:'flex', border: req.value === "Make Ideal" ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center',  background:'rgba(196, 196, 196, 0.5)'}} onClick={() => handleRequirementSelectValue("Vertical Dimension for Occlusion", "Make Ideal", true)}>
                                        Make Ideal
                                    </Box>
                                </Box>

                                {req.value === "Open Bite" ? (
                                        <Box sx={{display:'flex', flexDirection: 'column', marginTop: '10px'}}>
                                            <Box sx={{marginBottom: '10px'}}>
                                            Desired Length in mm
                                            </Box>
                                            <Box>
                                                <TextField 
                                                    size={"medium"}
                                                    value={req.measurement}
                                                    onChange={(e) => { if(Number(e.target.value) < 0) { return; } handleRequirementSelectMeasurement("Vertical Dimension for Occlusion", e.target.value)}}
                                                    sx={{backgroundColor: '#fff', width: '250px'}}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                                    }}
                                                    />
                                            </Box>
                                        </Box>
                                ) : ("")}
                            </>
                        ) : ("")}


                    
                    {req.description === "Restoration Type" ? (
                        <Box sx={{display: 'flex', flexWrap:'wrap'}}>
                            

                                <Box sx={{width:'250px', height:'100px', marginBottom: '10px', display:'flex', border: req.value=="Final" ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center', background:'rgba(196, 196, 196, 0.5)'}} 
                                onClick={() => handleRequirementSelectValue("Restoration Type", "Final")}>
                                    Final
                                </Box>

                                <Box sx={{width:'250px', height:'100px', marginBottom: '10px', display:'flex', border: req.value=="PMMA" ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center', background:'rgba(196, 196, 196, 0.5)'}} 
                                onClick={() => handleRequirementSelectValue("Restoration Type", "PMMA")}>
                                    PMMA
                                </Box>

                            {req.value !== null && req.value ==="Final" ? (
                                    <Box sx={{display:'flex', flexDirection: 'column'}}>
                                        <Box sx={{marginBottom: '10px'}}>
                                            Select Type
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                            <TextField 
                                                size={"medium"}
                                                select
                                                value={req.type}
                                                variant="outlined"
                                                onChange={(e) => handleSelectCustomRestorationType(req.description,  e.target.value)}                                                
                                                sx={{backgroundColor: '#fff', width: '250px', marginRight: '15px'}}
                                            >
                                                <MenuItem key="EMAX" value="EMAX">
                                                EMAX
                                                </MenuItem>
                                                <MenuItem key="FCZ" value="FCZ">
                                                FCZ
                                                </MenuItem>
                                                <MenuItem key="PFZ" value="PFZ">
                                                PFZ
                                                </MenuItem>
                                                <MenuItem key="FCC-FGC" value="FCC-FGC">
                                                FCC-FGC
                                                </MenuItem>
                                                
                                            </TextField>    
                                           
                                        </Box>
                                    </Box>
                                ) : ("")}
                        </Box>
                    ) : ("")}

                    {req.description === "PFZ Additional Details" ? (
                        <Box sx={{display: 'flex', flexWrap:'wrap'}}>
                            {pfzDetails.map((mat, idx) => (
                                <Box sx={{width:'250px', height:'100px', marginBottom: '10px', display:'flex', border: req.value === mat ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center', background:'rgba(196, 196, 196, 0.5)'}} onClick={() => handleRequirementSelectValue("PFZ Additional Details", mat)}>
                                    {mat}
                                </Box>
                            ))}
                        </Box>
                    ) : ("")}

                    {req.description === "Preparation Type" ? (
                        <Box sx={{display: 'flex', flexWrap:'wrap'}}>
                            {pmmaPreparationTypes.map((mat, idx) => (
                                <Box sx={{width:'250px', height:'100px', padding:'10px', marginBottom: '10px', display:'flex', border: req.value === mat ? '2px solid #32BE60' : '2px solid #000000', cursor:'pointer', marginRight:'10px', borderRadius:'16px', justifyContent:'center', alignItems:'center', background:'rgba(196, 196, 196, 0.5)'}} onClick={() => handleRequirementSelectValue("Preparation Type", mat)}>
                                    {mat}
                                </Box>
                            ))}
                        </Box>
                    ) : ("")}

                    {req.description === "Max Preferred Length of Centrals" ? (
                            <Box sx={{display: 'flex', alignItems:'center'}}>
                                <Box sx={{width:'230px', cursor:'pointer', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '15px', height: '60px', background: req.value === "Ideal" ? '#0587E4' :'#FBFBFB', color: req.value === "Ideal" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Max Preferred Length of Centrals", "Ideal")}>
                                    Ideal
                                </Box>
                                <Box sx={{width:'230px', height: '60px', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '60px', cursor:'pointer', background: req.value !== null && req.value !== "Ideal" ? '#0587E4' :'#FBFBFB', color: req.value !== null &&  req.value !== "Ideal" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Max Preferred Length of Centrals", "")}>
                                    Desired Length
                                </Box>

                                {req.value !== null && req.value !== "Ideal" ? (
                                    <Box sx={{display:'flex', flexDirection: 'column'}}>
                                        <Box sx={{marginBottom: '10px'}}>
                                            Indicate Length in mm
                                        </Box>
                                        <Box>
                                            <TextField 
                                                size={"medium"}
                                                type="number"
                                                value={req.value}
                                                onChange={(e) => { if(Number(e.target.value) < 0) { return; } handleRequirementSelectValue("Max Preferred Length of Centrals", e.target.value)}}
                                                sx={{backgroundColor: '#fff', width: '250px'}}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                                  }}
                                                />
                                        </Box>
                                    </Box>
                                ) : ("")}
                            </Box>
                        ) : ("")}

                        {req.description === "Are you fabricating?" ? (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box sx={{width:'230px', cursor:'pointer', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', marginRight: '15px', height: '60px', background: req.value === "Printing" ? '#0587E4' :'#FBFBFB', color: req.value === "Printing" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Are you fabricating?", "Printing")}>
                                    Printing
                                </Box>
                                <Box sx={{width:'230px', height: '60px', marginRight: '60px', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', cursor:'pointer', background: req.value === "Milling" ? '#0587E4' :'#FBFBFB', color:  req.value === "Milling" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Are you fabricating?", "Milling")}>
                                    Milling
                                </Box>
                                <Box sx={{width:'230px', height: '60px', marginRight: '60px', boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)', cursor:'pointer', background: req.value === "No" ? '#0587E4' :'#FBFBFB', color:  req.value === "No" ? '#fff' : '#000', borderRadius: '18px', display:'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRequirementSelectValue("Are you fabricating?", "No")}>
                                    No
                                </Box>

                                {req.value !== null && req.value ==="Printing" ? (
                                    <Box sx={{display:'flex', flexDirection: 'column'}}>
                                        <Box sx={{marginBottom: '10px'}}>
                                            Indicate Printer/Resin
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                            <TextField 
                                                size={"medium"}
                                                select
                                                value={req.printer}
                                                variant="outlined"
                                                onChange={(e) => handleSelectCustomPrinterMaterial(req.description, 'Printer', e.target.value)}                                                
                                                sx={{backgroundColor: '#fff', width: '250px', marginRight: '15px'}}
                                            >
                                                <MenuItem key="Ackuretta" value="Ackuretta">
                                                    Ackuretta
                                                </MenuItem>
                                                <MenuItem key="Asiga" value="Asiga">
                                                    Asiga
                                                </MenuItem>
                                                <MenuItem key="Formlabs" value="Formlabs">
                                                Formlabs
                                                </MenuItem>
                                                <MenuItem key="Phrozen" value="Phrozen">
                                                    Phrozen
                                                </MenuItem>
                                                <MenuItem key="Uniz NBEE" value="Uniz NBEE">
                                                    Uniz NBEE
                                                </MenuItem>
                                                <MenuItem key="Asiga" value="Other">
                                                    Other
                                                </MenuItem>
                                                
                                            </TextField>    
                                            <TextField 
                                                size={"medium"}
                                                value={req.material}
                                                onChange={(e) => handleSelectCustomPrinterMaterial(req.description, 'Material', e.target.value)}
                                                placeholder="Material"
                                                sx={{backgroundColor: '#fff', width: '250px'}}
                                                />
                                        </Box>
                                    </Box>
                                ) : ("")}

                                {req.value !== null && req.value ==="Milling" ? (
                                    <Box sx={{display:'flex', flexDirection: 'column'}}>
                                        <Box sx={{marginBottom: '10px'}}>
                                            Indicate Mill Info/Block 
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                            <TextField 
                                                size={"medium"}                                                
                                                value={req.millInfo}
                                                placeholder="Mill Info"                                                
                                                onChange={(e) => handleSelectCustomMillingInfo(req.description, 'Mill Info', e.target.value)}                                                
                                                sx={{backgroundColor: '#fff', width: '250px', marginRight: '15px'}}
                                            >
                                                
                                            </TextField>    
                                            <TextField 
                                                size={"medium"}
                                                value={req.block}
                                                onChange={(e) => handleSelectCustomMillingInfo(req.description, 'Block', e.target.value)}
                                                placeholder="Block"
                                                sx={{backgroundColor: '#fff', width: '250px'}}
                                                />
                                        </Box>
                                    </Box>
                                ) : ("")}
                            </Box>
                        ) : ("")}



                    <Divider sx={{marginTop:'60px', marginBottom: '60px'}}/>
                </>
            ))}

            <Box sx={{display:'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                    <Box sx={{display: 'flex', backgroundColor: '#5B5B5B', color:'#fff', width: '40px', height: '40px', borderRadius: '50%', alignItems: 'center', justifyContent: 'center', marginRight: '15px'}}>
                        {treatmentDetails.requirements.length }
                    </Box>
                    <Box sx={{fontSize: '21px', fontWeight: '500', lineHeight: '31px', color: '#27374F', flexGrow: 1}}>
                        Required Files
                    </Box>   
               
                </Box>

                {requiredFiles.map((file, idx) => (
                    <Box sx={{display: 'flex', alignItems: 'center', padding:'42px', marginBottom:'10px', background:'rgba(234, 234, 234, 1)', borderRadius:'12px'}}>    
                        <Box sx={{marginRight: '18px'}}>
                            <img src={file.image} />
                        </Box>

                        <Box sx={{display:'flex', alignItems:'center', flexGrow: 1}}>
                            <Box sx={{color: '#322F2F', fontWEight:'500', fontSize: '21px', lineHeight: '21px'}}>
                                {file.name}
                            </Box>
                        </Box>

                        <Box sx={{fontSize: '21px', fontWeight: '500', lineHeight: '31px', color: '#27374F'}}>
                            <Dropzone onDropAccepted={(files) => onFileChange(files, idx)}>
                                    {({getRootProps, getInputProps}) => (
                                          <Box sx={{marginTop:'10px', background:'#ECF2F9', borderRadius:'12px', width:'600px', height: '175px', textAlign:'center', overflowY:'auto'}} {...getRootProps()}>                                    
                                            <input {...getInputProps()} />
                                            {file.files.length === 0 ? (                                                
                                                <img src={DragAndDropImage} style={{marginTop: '20px'}}/>
                                            ) : (
                                                <>
                                                    {file.files.map((file, fileIdx) => (
                                                    <Box sx={{display:'flex', marginTop: '20px'}}>
                                                        <Box sx={{xs:1,marginRight:'10px', marginLeft:'25px'}}>
                                                            <img src={caseCreatedIcon} style={{width:'45px', height:'45px'}}/>
                                                        </Box>
                                                        <Box sx={{xs:3, flex:'wrap',fontSize: '16px', lineHeight:'21px', fontWeight:'400', flexGrow: 1, textAlign: 'left'}}>
                                                            {file.fileName}
                                                        </Box>
                                                        <Box sx={{xs:3,fontSize: '16px', lineHeight:'21px', fontWeight:'400', flexGrow: 1, textAlign: 'left'}}>
                                                            {moment(file.uploadedDate).format('lll')}
                                                        </Box>
                                                        <Box sx={{xs:3,flexGrow: 1,fontSize: '16px', lineHeight:'21px', fontWeight:'400', color: '#4A90E2', textDecoration: 'underline', cursor: 'pointer', marginRight: '15px'}} onClick={(e) => {e.preventDefault(); e.stopPropagation(); removeFile(idx, fileIdx)}}>
                                                            Remove File
                                                        </Box>
                                                    </Box>
                                                    ))}                                            
                                                </>
                                            )}
                                        </Box>
                                        
                                        )}
                            </Dropzone>   

                            {file.numberOfFilesUploading > 0 ? (
                            <Box sx={{ width:'100%', margin:'10px', textAlign:'center'}}>
                                        <LinearProgress color="success" />
                                        Uploading File
                            </Box>       
                            ) : ("")}
                        </Box>          
                    </Box>
                ))}

            </Box>

            <Divider sx={{marginTop:'60px', marginBottom: '60px'}}/>

            <Box sx={{display:'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                    <Box sx={{display: 'flex', backgroundColor: '#5B5B5B', color:'#fff', width: '40px', height: '40px', borderRadius: '50%', alignItems: 'center', justifyContent: 'center', marginRight: '15px'}}>
                        {treatmentDetails.requirements.length + 1}
                    </Box>
                    <Box sx={{fontSize: '21px', fontWeight: '500', lineHeight: '31px', color: '#27374F'}}>
                        Additional Notes
                    </Box>
                </Box>

                <Box>
                    <TextField 
                        id="additional-notes"
                        multiline
                        InputProps={{
                            style:{height: '220px', alignItems:'start'}
                        }}
                        sx={{width:'100%', background: '#fff', marginTop: '5px'}}
                        value={treatmentDetails.additionalNotes}
                        onChange={(e) => handleChangeAdditionalNotes(e.target.value)}
                        />
                </Box>

            </Box>

        </>
    )
}

export default CrownAndBridge;