import {
  INIT_USERINFO,
  UPDATE_DISPLAYTHEME,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  OPEN_LOADING,
  CLOSE_LOADING,
  UPDATE_PATIENT,
  UPDATE_PATIENTID,
  UPDATE_USE_CURRENT_SCAN,
  UPDATE_PRODUCT_TO_ADD,
  OPEN_PREBOOK_DIALOG,
  
} from "../constants/index";

const initialState = {
  userInfo: null,
  displayTheme: {
    headerColor: "#fff",
  },
  snackbarOpen: false,
  snackbarMessage: {
    title: "",
    body: "",
    type: "success",
  },
  loadingOpen: false,
  patientId: null,
  productToAdd: null,
  useCurrentScan: true,
  patient: null,
  openPrebookDialog: false
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_USERINFO:
      return Object.assign({}, state, {
        userInfo: action.payload,
      });
    case UPDATE_DISPLAYTHEME:
      return Object.assign({}, state, {
        displayTheme: action.payload,
      });
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackbarOpen: true,
        snackbarMessage: action.payload,
      });
    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbarOpen: false,
        snackbarMessage: "",
      });
    case OPEN_LOADING:
      return Object.assign({}, state, {
        loadingOpen: true,
      });
    case CLOSE_LOADING:
      return Object.assign({}, state, {
        loadingOpen: false,
      });
    case UPDATE_PATIENT:
      return Object.assign({}, state, {
        patient: action.payload
      });
    case UPDATE_PATIENTID:
      return Object.assign({}, state, {
        patientId: action.payload
      });
    case UPDATE_PRODUCT_TO_ADD:
      return Object.assign({}, state, {
        productToAdd: action.payload
      });
      case UPDATE_USE_CURRENT_SCAN:
        return Object.assign({}, state, {
          useCurrentScan: action.payload
        });
      case OPEN_PREBOOK_DIALOG:
        return Object.assign({}, state, {
          openPrebookDialog: action.payload,
        });
    default:
      return state;
  }

  return state;
}

export default rootReducer;
