import { useState } from "react";
import PatientService from "../services/patient.service";

const useUpload = ({ patientId, type }) => {
  const [uploading, setUploading] = useState(false);

  const upload = async (files, extraOptions) => {
    setUploading(true);
    const data = await PatientService.uploadFilesByType({
      files,
      type,
      patientId,
      extraOptions
    });
    setUploading(false);

    return data;
  };

  return {
    uploading,
    upload,
  };
};

export default useUpload;
