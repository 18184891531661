const _apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];

const AccountService = {
  login: function (email, password) {
    let data = {
      method: "POST",
      body: JSON.stringify({
        Email: email,
        Password: password,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "account/login", data);
  },
  logOut: function (toLoginPage, email, password) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "account/ssologout", data);
  },
  forgotPassword: function (email) {
    let data = {
      method: "POST",
      body: JSON.stringify({
        Email: email,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "account/forgotpassword?email=" + email, data);
  },
  resetPassword: function (body) {
    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "account/resetpassword", data);
  },
  validateEmail: function (email) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "account/validateEmail?email=" + email, data);
  },
  register: function (model) {
    console.log("model", model);
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "account/create", data);
    //return fetch('http://localhost:52249/api/account/create', data);
  },
  ssoUpdate: function (model, token) {
    console.log("model", model);
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    return fetch(_apiUrl + "account/ssoupdate", data);
    //return fetch('http://localhost:52249/api/account/create', data);
  },
  ssologin: function (otlid, ssologin) {
    let data = {
      method: "POST",
      body: JSON.stringify({
        otlid: otlid,
        sessionid: ssologin,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "account/ssologin", data);
  },
    setPasswordNoAuth: function (email,password) {
        let data = {
            method: "POST",
            body: JSON.stringify(
                {Email: email,
                NewPassword: password}
            ),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        };

        return fetch(_apiUrl + "account/changepasswordnoauth", data);
    },
};

export default AccountService;
