
import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";

const _apiUrl = process.env.REACT_APP_API_END_POINT.split('=')[1];

const _blobConnectionString = process.env.REACT_APP_BLOB_URL;
const _blobContainer = process.env.REACT_APP_CONTAINER;

const FileUploaderService = {
    uploadFile: function (file, blobName, callback, fileObj, onError) {        
        let data = {
            method: 'GET',
            body: null,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
            }
        };

        fetch(_apiUrl + 'blob/getcontainersas?containerRef=' + _blobContainer, data)
            .then(response => response.json())
            .then(json => {
                let sas = json;

                let blobServiceClient = new BlobServiceClient(`${_blobConnectionString}${sas}`);
                
                let containerClient = blobServiceClient.getContainerClient(_blobContainer);

                let blockBlobClient = containerClient.getBlockBlobClient(blobName);

                let uploadSummary = blockBlobClient.uploadData(file, {
                    blockSize: 4 * 1024 * 1024,
                    onProgress: (ev) => {
                        if (callback) {
                            callback((ev.loadedBytes / file.size) * 100, fileObj)
                        }                       
                    },
                    blobHTTPHeaders: null           
                }).catch(err => onError(err));
            }).catch(err => onError(err))

    },
    uploadAttachmentAsync: async function (file, patientId, caseId) {
      let data = {
        method: "GET",
        body: null,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      };
  
      const response = await fetch(
        _apiUrl +
          `blob/getsas?DomainName=attachments&DomainId=${caseId}&FileType=0&Filename=${file.path}`,
        data
      );
  
      const responseData = await response.json();
  
      console.log(responseData);
      const sas = responseData.sasToken;
      console.log(sas);
      const blockBlobClient = new BlockBlobClient(sas);
      const uploadSummary = await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024,
        blobHTTPHeaders: null,
      });
  
      console.log(uploadSummary);
  
      return { fileId: responseData.fileId };
    },
    uploadFileAsync: async function (file, blobName) {
        let data = {
            method: 'GET',
            body: null,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
            }
        };

        const response = await fetch(_apiUrl + 'blob/getcontainersas?containerRef=' + _blobContainer, data)
        const json = await response.json();
        let sas = json;

        let blobServiceClient = new BlobServiceClient(`${_blobConnectionString}${sas}`);
        
        let containerClient = blobServiceClient.getContainerClient(_blobContainer);

        let blockBlobClient = containerClient.getBlockBlobClient(blobName);

        let uploadSummary = await blockBlobClient.uploadData(file, {
            blockSize: 4 * 1024 * 1024,
            blobHTTPHeaders: null           
        });
        return uploadSummary;
    },
    uploadPatientImage: async function ({ file, patientId, fileExtension}) {
      let data = {
        method: 'GET',
        body: null,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
        }
      };
      const response = await fetch(_apiUrl + `patient/getprofileimagesas?patientId=${patientId}&imageFileExtension=${fileExtension}`, data);
      const responseData = await response.json();
      const sas = responseData.data.sasToken;

      const blockBlobClient = new BlockBlobClient(sas);
      const uploadSummary = await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024,
        blobHTTPHeaders: null
      });

      console.log(uploadSummary);
      console.log(responseData.data);

      return {
        profilePictureUrl: sas,
        profilePictureFileName: responseData.data.fileName
      };
    },
    generateGuid: function() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
           var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
           return v.toString(16);
        });
     }
};

export default FileUploaderService;