import React from 'react'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { brandLight } from '../../../constants'
import { CardContent } from '@mui/material'
import ProductImage from './productImage'
import ProductDescription from './productDescription'
import CtaButtons from './ctaButtons'
import ProductBadge from './productBadge'
const ProductCard = ({product,selectedTreatment, badge = true, onClickAboutTreatment, onClickOrderNow, patient}) => {
    let productName = product.description;
    if(product.id === process.env.REACT_APP_ALL_ON_X_IMPLANTS_ID){
        productName = productName.replace("Implants","Package");
    }
    return (
        <Card sx={{boxShadow: 'none'}}>
            <CardContent>
                <Stack
                    justifyContent="space-between"
                    sx={{
                    height: "100%",
                    paddingY: 3,
                    paddingX: 5.25,
                    backgroundColor: brandLight,
                    borderRadius: "24px",
                    boxSizing: "border-box",
                    boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
                    backdropFilter: "blur(10px)",
                    }}
                >
                    {badge.visible && <ProductBadge badge={badge} />}
                    <Stack
                    sx={{
                        width: "100%",
                        height: "100%",
                    }}
                    >
                        <ProductImage imageLink={product.imageLink} />
                        <ProductDescription description={productName} productDescription={product.productDescription} />
                    </Stack>
                    <CtaButtons patient={patient} onClickOrderNow={onClickOrderNow} onClickAboutTreatment={onClickAboutTreatment} product={product} selectedTreatment={selectedTreatment} />
                </Stack>
            </CardContent>
        </Card>
    )
}

export default ProductCard