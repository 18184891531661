export const groupTeeth = (teeth) => {
  let ans = teeth.reduce((prev, curr) => {
    let found = prev.find(
      (x) =>
        x.notes === curr.notes &&
        x.restorationType === curr.restorationType &&
        x.widthAndLength === curr.widthAndLength &&
        x.gingivalContouring === curr.gingivalContouring &&
        x.designMode === curr.designMode &&
        x.device === curr.device &&
        x.material === curr.material &&
        x.materialClass === curr.materialClass 
    );
    if (found) {
      found.teeth.push(curr.tooth);
    } else {
      prev.push({
        teeth: [curr.tooth],
        notes: curr.notes,
        restorationType: curr.restorationType,
        widthAndLength: curr.widthAndLength,
        gingivalContouring: curr.gingivalContouring,
        designMode: curr.designMode,
        device: curr.device,
        material: curr.material,
        materialClass: curr.materialClass,
      });
    }
    return prev;
  }, []);

  return ans;
}