import moment from "moment";
import PatientService from "../services/patient.service";

export const fileSetUpload = (patientId, setName, fileIds) => {
  const dateToday = moment().format("LLL");
  let type;
  let data;

  switch (setName) {
    case "Clinical Photography":
      const photoSetTypeOther = 4;
      type = "clinicalPhotos";
      data = {
        Description: dateToday,
        FileName: "",
        FileSetType: photoSetTypeOther,
        ExtensionIds: fileIds,
        PatientId: patientId,
        Template: "Generic Photo Series",
      };
      break;
    case "IOS Scan (STL Files) (Upper, Lower and Bite)":
      const fileSetTypeOther = 3;
      type = "intraoralScans";
      data = {
        Description: dateToday,
        FileName: dateToday,
        FileSetType: fileSetTypeOther,
        ExtensionIds: fileIds,
        PatientId: patientId,
      };
      break;
    default:
      return;
  }

  return PatientService.uploadFileSet(data, type);
};
