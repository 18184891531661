import React, { useState } from "react";
import {
  Divider,
  Pagination,
  Box,
  CircularProgress,
  ButtonBase,
  TextField,
} from "@mui/material";

import DataTable from "../../components/common/table/dataTable";
import TableRow from "../../components/common/table/tableRow";
import usePaginationValues from "../../hooks/usePaginationValues";
import AdminService from "../../services/admin.service";
import AdminHubService from "../../services/adminHub.service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { brandColor } from "../../constants";

const invoicePerPage = 20;

function Invoice() {
  const [total, setTotal] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [endDate, setEndDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const { currentPage, pagesCount, skip, handleChangePage } =
    usePaginationValues(invoicePerPage, total);

  const headers = [
    " ",
    "Invoice Number",
    "Invoice Type",
    "Invoice Date",
    "Amount",
  ];

  const handlePaginationClick = (event, value) => {
    handleChangePage(value);
  };

  const getPath = async (path) => {
    const blobPath = AdminService.getInvoicePath(path).result;
    return blobPath;
  };

  const handleRowClicks = invoices.map((invoice) => {
    return async function () {
      const response = await AdminService.getInvoicePath(invoice.id);
      const data = await response.json();
      console.log("data", data);
      window.open(
        data,
        "_blank",
        "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,noopener,noreferrer"
      );
    };
  });

  const rows = invoices.map((invoice, i) => [
    <RequestQuoteIcon />,
    invoice.invoiceNumber,
    invoice.isPostPaid ? "Postpaid" : "Prepaid",
    moment(invoice.createdDate).format("ll"),
    `${invoice.currencySymbol}${invoice.totalGross}`,
  ]);

  useEffect(() => {
    if (!userInfo.Id) {
      navigate("/");
    }
  }, [userInfo.Id]);

  useEffect(() => {
    setInvoices([]);
    getInvoices();
  }, [skip]);

  const getInvoices = () => {
    let app = ["KOIS", "EvidentDesign"];
    setLoading(true);

    setFromDate(fromDate ? fromDate.setHours(0, 0, 0, 0) : null);
    setEndDate(endDate ? endDate.setHours(23, 59, 59, 0) : null);

    let model = {
      userId: userInfo.Id,
      skip: skip,
      take: invoicePerPage,
      invoiceNumber: invoiceNumber,
      fromDate: fromDate
        ? moment.utc(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : "",
      endDate: endDate ? moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss") : "",
      consumerApps: app,
    };

    AdminHubService.getInvoices(model)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        if (json.error === null) {
          console.log("json in get", json);
          setInvoices(json.data.invoices);
        }
      })
      .catch((err) => {
        //set error message
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <>
      {/* <Searchbar onSearch={handleSearch} /> */}

      <Box
        display="flex"
        alignItems="center"
        paddingX={2}
        paddingY={2}
        marginY={3}
        sx={{
          backgroundColor: "white",
          borderRadius: "2rem",
          boxShadow:
            "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        }}
      >
        <SearchIcon sx={{ paddingLeft: 1, marginRight: 2, color: "#8b8b8b" }} />
        <TextField
          placeholder="Invoice Number"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          sx={{
            border: "0.5px solid #DFDFDF !important",
            background: "#fff",
            borderRadius: "15px",
          }}
        />
        &nbsp;&nbsp;&nbsp;
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            maxDate={new Date()}
            label="Start date"
            value={fromDate}
            onChange={(newValue) => {
              setFromDate(newValue);
            }}
            sx={{ border: "0.5px solid #DFDFDF !important" }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: "0.5px solid #DFDFDF !important",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              />
            )}
          />
          &nbsp;to&nbsp;
          <DatePicker
            maxDate={new Date()}
            label="End Date"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            sx={{ border: "0.5px solid #DFDFDF !important" }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: "0.5px solid #DFDFDF !important",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              />
            )}
          />
        </LocalizationProvider>
        &nbsp;&nbsp;&nbsp;
        <ButtonBase
          sx={{
            width: "10%",
            marginTop: 1,
            paddingY: 2,
            fontSize: "16px",
            fontWeight: "bold",
            color: "white",
            background: brandColor,
            borderRadius: "30px",
          }}
          onClick={() => getInvoices()}
        >
          Search
        </ButtonBase>
      </Box>

      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
        }}
      />
      {loading ? (
        <CircularProgress color="success" />
      ) : (
        <Box
          sx={{
            paddingY: 2,
            paddingX: 4,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            boxShadow:
              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);",
            borderRadius: "0 1rem 1rem 1rem",
          }}
        >
          <DataTable headers={headers}>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                row={row}
                onClick={handleRowClicks[i]}
                disableLastCell
              />
            ))}
          </DataTable>
          <Pagination
            count={pagesCount}
            page={currentPage}
            onChange={handlePaginationClick}
          />
        </Box>
      )}
    </>
  );
}

export default Invoice;
