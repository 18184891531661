import {
  CircularProgress,
  Divider,
  Link,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import LargeButton from "../common/button/largeButton";
import ContentContainer from "../common/contentContainer";
import usePatientFiles from "../../hooks/usePatientFiles";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import ExpandingTableRow from "../common/table/expandingTableRow";

import DownloadFileButton from "../common/button/downloadFileButton";
import NoFilesAlert from "./noFilesAlert";
import useUpload from "../../hooks/useUpload";
import UploadFileDialog from "../common/uploadFileDialog";
import PatientService from "../../services/patient.service";
import CaseNumberListDialog from "./casenumberListDialog";
import { grey } from "@mui/material/colors";
import PhotographyThumbnail from "./photographyThumbnail";

const type = "clinicalPhotos";

const ClinicialPhotos = ({ patientId }) => {
  const [photoSetName, setPhotoSetName] = useState("");
  const [uploadingSet, setUploadingSet] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { uploading, upload } = useUpload({ patientId, type });
  const {
    files,
    loading,
    pages,
    currentPage,
    handleChangePage,
    handleAddFiles,
  } = usePatientFiles({
    patientId,
    type: type,
    shouldGetExtensions: true,
    take: 10,
  });
  const [isCaseNumberDialogOpen, setIsCaseNumberDialogOpen] = useState(false);
  const [filesToSend, setFilesToSend] = useState();
  const [error, setError] = useState("");

  const headers = ["Photo Set", "Date Created", " "];
  const rows = files.map((file) => [
    file.fileName,
    moment(file.uploadedDate).format("LLL"),
    <LargeButton
      variant="primary"
      onClick={() => {
        setIsCaseNumberDialogOpen(true);
        setFilesToSend(file);
      }}
    >
      Send to Evident Design
    </LargeButton>,
  ]);

  const expandContents = useMemo(
    () =>
      files.map((file) => {
        const fileExtensions = file.fileExtensions;

        const data = {
          headers: ["File", ""],
        };

        data.rows = fileExtensions.map((file) => {
          return [
            <PhotographyThumbnail fileName={file.fileName} path={file.path} />,
            <DownloadFileButton path={file.path} fileName={file.fileName} />,
          ];
        });

        return (
          <DataTable headers={data.headers} sx={{ paddingX: 2 }}>
            {data.rows.map((row, i) => (
              <TableRow key={i} row={row} shouldUseDivider />
            ))}
          </DataTable>
        );
      }),
    [files]
  );

  const handleClickPagination = (event, value) => {
    handleChangePage(value);
  };

  const handleUpload = async (files) => {
    if (photoSetName === "") {
      const message = "Photo set name is required";
      setError(message);
      throw new Error(message);
    } else if (files.length === 0) {
      const message = "No files selected";
      setError(message);
      throw new Error(message);
    }

    const data = await upload(files, {
      FileSetSubType: 29, // generic set photos
      FileSetType: 4, // photo set type other
    });

    setUploadingSet(true);

    const setData = await PatientService.uploadFileSet(
      {
        Description: photoSetName,
        FileName: "",
        FileSetType: 4,
        ExtensionIds: data.map((d) => d.id),
        PatientId: patientId,
        Template: "Generic Photo Series",
      },
      type
    );

    console.log(setData);

    // method expects an array but setData is an object
    handleAddFiles([setData]);
    setIsDialogOpen(false);
    setPhotoSetName("");
    setUploadingSet(false);
  };

  const handleSendToEvidentDesign = (caseNumber) => {
    setIsCaseNumberDialogOpen(false);
  };

  return (
    <ContentContainer>
      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: 1.25 }}
        >
          Clinical Photos
        </Typography>
        <Typography
          variant="body2"
          sx={{
            width: "90%",
            marginBottom: 3,
          }}
        >
          Taking photos of the patient can be an important step for planning and
          communication. To upload, select Upload Scans and drag and drop the
          file/s into one of the predefined area.
        </Typography>
        <LargeButton variant="primary" onClick={() => setIsDialogOpen(true)}>
          Upload Photos
        </LargeButton>
      </Box>
      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
          marginBottom: 5,
        }}
      />

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          <React.Fragment>
            <DataTable headers={headers}>
              {rows.map((row, i) => (
                <ExpandingTableRow
                  key={row}
                  row={row}
                  expandContent={expandContents[i]}
                />
              ))}
            </DataTable>
            <Pagination
              count={pages}
              page={currentPage}
              onChange={handleClickPagination}
              sx={{ marginY: 2 }}
            />
          </React.Fragment>
        ) : (
          <NoFilesAlert />
        ))}
      <UploadFileDialog
        additionalFields={
          <Box>
            <TextField
              label="Photo set name"
              fullWidth
              sx={{ marginTop: 2 }}
              value={photoSetName}
              onChange={(e) => {
                setError("");
                setPhotoSetName(e.target.value);
              }}
            />
            <Typography
              sx={{ fontWeight: 700, marginTop: 2, color: "#2E3246" }}
            >
              Guides:
            </Typography>
            <Stack sx={{ marginBottom: 1 }}>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://evidentdigital.com/dentofacial-display-analysis"
                underline="hover"
              >
                Dentofacial Display Analysis Photos
              </Link>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://evidentdigital.com/digital-treatment-simulation-image"
                underline="hover"
              >
                Smile Mockup Photos
              </Link>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://evidentdigital.blob.core.windows.net/public/Aligner_Photos.pdf"
                underline="hover"
              >
                Aligner Photos
              </Link>
            </Stack>
          </Box>
        }
        title="Upload Clinical Photos"
        open={isDialogOpen}
        uploading={uploading || uploadingSet}
        onClose={() => {
          setIsDialogOpen(false);
          setPhotoSetName("");
          setError("");
        }}
        onUpload={handleUpload}
        error={error}
        resetError={() => setError("")}
      />

      <CaseNumberListDialog
        open={isCaseNumberDialogOpen}
        onClose={() => {
          setIsCaseNumberDialogOpen(false);
        }}
        onSubmit={handleSendToEvidentDesign}
        patientId={patientId}
        filesToSend={filesToSend}
      />
    </ContentContainer>
  );
};

export default ClinicialPhotos;
