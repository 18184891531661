import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { TextField, InputAdornment, MenuItem, Checkbox } from "@mui/material";

export default function DesiredBaseThickness(props) {
  const {
    req,
    handleRequirementChildSelectValue,
    handleRequirementsSelectList,
    isTick = false,
  } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        type="number"
        size={"medium"}
        value={req["upper"]}
        onChange={(e) => {
          handleRequirementChildSelectValue(
            req.description,
            "upper",
            e.target.value
          );
        }}
        sx={{ backgroundColor: "#fff", width: "250px" }}
        placeholder={"Upper Denture"}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
      />
      <TextField
        type="number"
        size={"medium"}
        value={req["lower"]}
        onChange={(e) => {
          handleRequirementChildSelectValue(
            req.description,
            "lower",
            e.target.value
          );
        }}
        sx={{ backgroundColor: "#fff", width: "250px", margin: "10px" }}
        placeholder={"Lower Denture"}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
      />
      {isTick && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginRight: "15px" }}>
            <Checkbox
              checked={req["tick"]}
              onChange={(e) => {
                handleRequirementChildSelectValue(
                  req.description,
                  "tick",
                  e.target.checked
                );
              }}
            />
          </Box>
          <Box>
            Tick if Overjet and Overbite would be idealized per situation
          </Box>
        </Box>
      )}
    </Box>
  );
}
