import FileUploaderService from "./fileUploader.service";

const _apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];

const CaseService = {
  submitCase: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/submitcase", data);
  },

  getFabPartners: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "fabricationpartner/getsuggestedfabpartners", data);
  },

  uploadOtherDocFile: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patientfile/uploadotherdoc", data);
  },
  getRequiredFiles: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/getrequiredfiles", data);
  },
  getCaseById: function (caseId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "patient/gettreatmentcasesbyid?caseId=" + caseId,
      data
    );
  },
  getCaseFileDownloads: function (caseId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/getcasefiledownloads?caseId=" + caseId, data);
  },
  getFilesByCase: function (caseId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/getfilesbycase?caseId=" + caseId, data);
  },
  getChats: function (caseId, userId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl +
        `patient/gettreatmentcasesbyid?caseId=${caseId}&userId=${userId}`,
      data
    );
  },
  submitCaseComment: async (model, files, patientId, caseId) => {
    let commentAttachments = [];

    let uploadFilePromises = await files.map(async (file) => {
      const { fileId } = await FileUploaderService.uploadAttachmentAsync(
        file,
        patientId,
        caseId
      );

      console.log(fileId);
      var newCommentAttachment = {
        fileName: file.path,
        path: "attachments/" + caseId + "/" + fileId,
        serverFileName: fileId,
        progress: 0,
      };
      commentAttachments.push(newCommentAttachment);

      return newCommentAttachment;
    });

    const filesData = await Promise.all(uploadFilePromises);

    console.log(uploadFilePromises);
    console.log(commentAttachments);

    model.CommentAttachments = commentAttachments;
    console.log(model);
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/submitcasecomment", data);
  },

  updateWorkflowStage: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/updateworkflowstage", data);
  },

  approveWithChangeProduct: function (model) {
      let data = {
          method: "POST",
          body: JSON.stringify(model),
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
      };

      return fetch(_apiUrl + "case/approvewithchangeproduct", data);
  },

  approveDesign: function (caseId, comment) {
    let model = {
      caseId: caseId,
      comment: comment,
    };
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/rejectdesign", data);
  },
  rejectDesign: function (caseId, comment) {
    let model = {
      caseId: caseId,
      comment: comment,
    };
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/rejectdesign", data);
  },
  approveCasePackage: function (refNo, packageProductId, productId) {
    let model = {
      refNo: refNo,
      packageProductId: packageProductId,
      productId: productId,
    };
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/approveCasePackage", data);
  },
  rejectCasePackage: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/rejectCasePackage", data);
  },
  getallcasefiledownloads: function (caseId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + `case/getallcasefiledownloads?caseId=${caseId}`,
      data
    );
  },
  addpatientfiles: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/addpatientfiles", data);
  },
  submitrescan: function (caseId) {
    let data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `case/submitrescan?caseId=${caseId}`, data);
  },
  updatenotifications: function (personnelId, refIds) {
    let data = {
      method: "POST",
      body: JSON.stringify(refIds),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + `case/updatenotifications?personnelid=${personnelId}`,
      data
    );
  },
  requestredesign: function (caseModel, comment) {
    let model = {
      case: caseModel,
      comment: comment,
    };

    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `case/requestredesign`, data);
  },
  sendfiletodesign: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `case/sendfiletodesign`, data);
  },
  getserviceproviders: function (dentistId, model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + `case/getserviceproviders?dentistId=${dentistId}`,
      data
    );
  },
  sendtolabs: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `case/sendtolabs`, data);
  },
  getorderpdf: function (caseId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + `Blob/getfilesasforpdf?url=ordersummarypdf/${caseId}`,
      data
    );
  },
  getCaseNumbersByProduct: function (model, personnelId, patientId) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl +
        `case/getCaseNumbersByProduct?personnelId=${personnelId}&patientId=${patientId}`,
      data
    );
  },
  createPrebook: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `case/CreatePrebookSchedule`, data);
  },
  getPrebookCount: function ({ personnelIds, practiceId }) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    let params = ""
    personnelIds.forEach(id => {
      params = params + "personnelIds=" + id + "&"
    });
    params = params + "practiceId=" + practiceId;

    return fetch(
      _apiUrl + `case/GetPrebookCount?` + params,
      data
    );
  },
  getPrebooks: function ({personnelIds, ...model }) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    let params = new URLSearchParams(model).toString()

    params = params + `&personnelIds=${personnelIds.join("&personnelIds=")}`

    return fetch(
      _apiUrl + `case/GetPrebookSchedules?` + params,
      data
    );
  },
  getPrebookSlots: function (model) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    const params = new URLSearchParams(model).toString()

    return fetch(
      _apiUrl + `case/getavailableprebooktimes?` + params,
      data
    );
  },
  getPrebookDetails: function (model) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    const params = new URLSearchParams(model).toString()

    return fetch(
      _apiUrl + `case/getprebook?` + params,
      data
    );
  },
  getserviceprovidersTruedent: function () {
    let data = {
      method: "GET", 
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + `case/getserviceprovidersTruedent`,
      data
    );
  }
};

export default CaseService;
