import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { brandLight, brandOrange } from "../../constants";

const NoFilesAlert = () => {
  const [params] = useSearchParams();
  const page = params.get("page");

  return (
    <Alert
      severity="warning"
      sx={{
        borderRadius: "15px",
        background: brandLight,
        backgroundColor: brandLight,
        color: "#000",
        [`& .MuiAlert-icon`]: {
          color: "#000",
        },
      }}
    >
      <AlertTitle>
        <strong>No files</strong>
      </AlertTitle>
      {page ? "No files available for the given page" : "No files available"}
    </Alert>
  );
};

export default NoFilesAlert;
