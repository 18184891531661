import { CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiUrl, brandColor } from "../../constants";
import { getFetchOptions } from "../../helpers/getFetchOptions";
import defaultLogo from "../../images/evident_logo2.svg";
import { openSnackbar } from "../../redux/actions";

const InvitedLab = () => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get("param");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const validateAndAdd = async () => {
      try {
        const str = window.atob(param);
        let [email, ...token] = str.split("&");
        token = token.join("&");

        await fetch(
          apiUrl + "lab/validateandaddlab",
          getFetchOptions("POST", { email, token })
        );

        window.location.href = process.env.REACT_APP_EVIDENT_LABS_URL;
      } catch (error) {
        navigate("/");
        dispatch(
          openSnackbar({
            type: "error",
            title: "Something went wrong",
            body: "Please try again later",
          })
        );
      }
    };

    validateAndAdd();
  }, [dispatch, navigate, param]);

  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "#101010", height: 46 }} />
      <Stack alignItems="center" my={6} spacing={4}>
        <img
          src={defaultLogo}
          sx={{ width: "195px", height: "51px" }}
          alt="Evident Logo"
        />
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h4" component="h1">
            Processing request
          </Typography>
          <CircularProgress />
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default InvitedLab;
