import React from "react";
import Box from "@mui/material/Box";
import "./mainWebsiteMenu.css";
import ocd from "../../images/outsource-cad-designs.png";
import lms from "../../images/lab-management-software.png";
import marketplace from "../../images/marketplace.png";
import sfs from "../../images/secured-file-storage.png";
import wa from "../../images/workflow-automation.png";
import ed from "../../images/education.png";
import menu from "../../images/menu-evident.png";

const MainWebsiteMenu = ({ loggedIn }) => {
  const labDesignURL = process.env.REACT_APP_EVIDENT_LAB_DESIGN_URL;
  const dentistDesignURL = process.env.REACT_APP_EVIDENT_DENTIST_DESIGN_URL;
  const lmsURL = process.env.REACT_APP_EVIDENT_LMS_URL;
  const connectURL = process.env.REACT_APP_EVIDENT_CONNECT_URL;
  const storageURL = process.env.REACT_APP_STORAGE_URL;
  const conciergeURL = process.env.REACT_APP_CONCIERGE_URL;
  const marketplaceURL = process.env.REACT_APP_MARKETPLACE_URL;
  const educationURL = process.env.REACT_APP_EDUCATION_URL;
  const lmsUnauthorized = process.env.REACT_APP_EVIDENT_LMS_NOTLOGGEDIN_URL;
  const educationUnauthorized = process.env.REACT_APP_EVIDENT_MAIN_URL + '/education';

  return (
    <>
      <label class="sub-menu-icon" for="sub-menu-check">
        <img src={menu} />
      </label>
      <input type="checkbox" id="sub-menu-check" class="sub-menu-input" />
      <div class="sub_menu">
        <ul>
          <li>
            <a href="" class="disabled-item">
              <figure>
                <img src={ocd} />
              </figure>
              Outsource CAD Design
            </a>
          </li>
          <li>
            <a href={loggedIn ? lmsURL : lmsUnauthorized} class="">
              <figure>
                <img src={lms} />
              </figure>
              Lab Management Software
            </a>
          </li>
          <li>
            <a href={storageURL}>
              <figure>
                <img src={sfs} />
              </figure>
              Secure File
              <br />
              Storage
            </a>
          </li>
          <li>
            <a href={marketplaceURL}>
              <figure>
                <img src={marketplace} />
              </figure>
              Marketplace
            </a>
          </li>
          <li>
            <a href={connectURL}>
              <figure>
                <img src={wa} />
              </figure>
              Workflow Automation
            </a>
          </li>
          <li>
            <a href={loggedIn ? educationURL : educationUnauthorized}>
              <figure>
                <img src={ed} />
              </figure>
              Education
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MainWebsiteMenu;
