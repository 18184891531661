import {
  Alert,
  Box,
  Container,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import  { useRef } from "react";
import StyledButton from "../../components/common/button/largeButton";
import ContentContainer from "../../components/common/contentContainer";
import { brandBlack, brandColor, brandLight } from "../../constants";
import logo from "../../images/evident_logo2.svg";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { loginSchema } from "../../types/loginSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "../../hooks/useLogin";
import { grey } from "@mui/material/colors";
import CustomModal from "../../components/common/modal/customModal";
import LoginBranding from "../../components/coBranding/loginBranding";
import AccountService from "../../services/account.service";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/actions";

const Partnership = () => {
    const {

        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
    });
    const [passwordError, setPasswordError] = React.useState(true);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { loggingIn, login } = useLogin();
    const passwordRef = useRef("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
    const [passwordText, setPasswordText] = React.useState("");
  const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    
    const [passwordErrors, setPasswordErrors] = useState([]);
    const validatePassword = (password) => {
        console.log(password);
        const validations = [];
        const err = [];
      
        if (password.length < 8) {
            validations.push({ key: 1, value: "be at least 8 characters long.", color: "red" });
            err.push("be at least 8 characters long");
        }
        else {
            validations.push({ key: 1, value: "be at least 8 characters long.", color: "green" });

        }

        if (!/[a-z]/.test(password)) {
            validations.push({ key: 2, value: "contain at least one lowercase letter.", color: "red" });
            err.push("contain at least one lowercase letter.");
        }
        else {
            validations.push({ key: 2, value: "contain at least one lowercase letter.", color: "green" });
        }

        if (!/[A-Z]/.test(password)) {
            validations.push({ key: 3, value: "contain at least one uppercase letter.", color: "red" });
            err.push("contain at least one uppercase letter.");
        }
        else {
            validations.push({ key: 3, value: "contain at least one uppercase letter.", color: "green" });
        }

        if (!/\d/.test(password)) {
            validations.push({ key: 4, value: "contain at least one digit.", color: "red" });
            err.push("contain at least one digit.");
        }
        else {
            validations.push({ key: 4, value: "contain at least one digit.", color: "green" });
        }

        if (!/[@$!%*?&]/.test(password)) {
            validations.push({ key: 5, value: "contain at least one special character.", color: "red" });
            err.push("contain at least one special character.")
        }
        else {
            validations.push({ key: 5, value: "contain at least one special character.", color: "green" });
        }

        console.log(err);
        if (err.length > 0) {
            setPasswordError(true);
        }
        else {
            setPasswordError(false);
        }
        return validations;


    };


    const handlePasswordChange = (event) => {
        console.log("test");
        const newPassword = event.target.value;
        console.log("test");
        setPassword(newPassword);
        setPasswordErrors(validatePassword(newPassword));
    }
  const handleCloseAdvisory = async (isOpen) => {
    //try {
    //  setOpen(isOpen);
    //  await login(email, password);
    //  // navigate("/");
    //} catch (err) {
    //  console.log(err);
    //  setError("Password Setup failed. Please try again.");
    //}
  };

   
  const handleSetPassword = async (values) => {
      setError("");
      setUploading(true);
      console.log(passwordRef.current + values.password)
      try {

          if (password == values.password) {
             // setEmail(values.email)
              //setPassword(values.password)
              setUploading(true);
              const response = await AccountService.setPasswordNoAuth(values.email, password);

              const data = await response.json();
              if (data.statusCode !== 10) {
                  setUploading(false);
                  throw data.error;
                 
              }
              dispatch(
                  openSnackbar({
                      title: "Success",
                      type: "success",
                      body: "Password set successfully",
                  })
              );
              navigate("/");

          }
          else {
              setError("Passwords do not match");
              setUploading(false);
          }
      //    if (values.password == values.c)
      //setEmail(values.email);
      //setPassword(values.password);
      
      /*setOpen(true);*/

      
      // navigate("/");
    } catch (err) {
      console.log(err);

      setError(err);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#101010", height: 46 }} />
      {loggingIn && (
        <LinearProgress
          sx={{
            width: "100%",
            top: "0",
            left: "0",
            borderRadius: "100px",
          }}
        />
      )}
      <Container maxWidth="md">
        <Box sx={{ width: "full", paddingY: 10, paddingX: 4 }}>
          <LoginBranding />
          <ContentContainer small sx={{ marginTop: 3 }}>
            <Stack
              component="form"
              alignItems="center"
              spacing={3}
              onSubmit={handleSubmit(handleSetPassword)}
              sx={{ paddingTop: 2, paddingBottom: 3 }}
            >
              <Typography
                variant="h5"
                component="h1"
                sx={{ color: brandBlack }}
              >
                Activate your Evident Hub Account
              </Typography>
              <Stack spacing={2} width="100%">
                <TextField
                  sx={{backgroundColor:"white"}}
                  placeholder="Email you used to sign up"
                  {...register("email")}

                  fullWidth
                />
                
                <TextField
                  type="password"
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  ref={passwordRef}
                  sx={{backgroundColor:"white"}}
                  fullWidth
                />
                
                {passwordErrors.length > 0 && (

                     <Box>        
                        <p>Password must:</p>
                        {passwordErrors.map((validations, index) => (
                        <li style={{ color: validations.color }} key={index}>{validations.value}</li>
                        ))}
                   </Box>        
                        
                    
                       
                )}
                
               
                 <TextField
                  sx={{backgroundColor:"white"}}
                  type="password"
                  placeholder="Confirm Password"
                  {...register("password")}
                  fullWidth
                />
                {error && (
                  <Alert severity="error" sx={{ background: brandLight }}>
                    {error}
                  </Alert>
                )}
                <StyledButton
                  variant="primary"
                  type="submit"
                  disabled={passwordError}
                  loading={uploading}
            
                >
                  Set Password
                </StyledButton>
              </Stack>
              <Stack spacing={2} alignItems="center">
                

                <Typography
                  sx={{
                    fontSize: "0.8em",
                    color: grey[700],
                    textAlign: "center",
                    paddingTop: 4,
                  }}
                >
                  By signing up with evident, you agree to our{" "}
                  <Link
                    href="/docs/Evident_-_HIPAA_BAA.pdf"
                    target="blank"
                    underline="hover"
                  >
                    Business Associate Agreement
                  </Link>{" "}
                  and our{" "}
                  <Link
                    href="/docs/Evident_-_HUB_T&Cs.pdf"
                    target="blank"
                    underline="hover"
                  >
                    Terms of Use.
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </ContentContainer>
        </Box>
        
      </Container>
    </>
  );
};

export default Partnership;
