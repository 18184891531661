import { Box } from "@mui/system";
import React from "react";
import { Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

const Searchbar = ({ onSearch, placeHolder = "Search Name" }) => {
  const [name, setName] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearch(name);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingX={2}
      paddingY={2}
      marginY={3}
      sx={{
        backgroundColor: "white",
        borderRadius: "2rem",
        boxShadow:
          "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
      }}
    >
      <SearchIcon sx={{ paddingLeft: 1, marginRight: 2, color: "#8b8b8b" }} />
      <Input
        placeholder={placeHolder}
        fullWidth
        disableUnderline
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        inputProps={{
          sx: {
            padding: 0,
            fontSize: "16px",
            "&::placeholder": {
              fontStyle: "italic",
            },
          },
        }}
      />
    </Box>
  );
};

export default Searchbar;
