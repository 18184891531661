import React from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { Button, ButtonBase } from "@mui/material";
import { brandBlue, brandColor, brandGray } from "../../../constants";

function ProceedToOrderModal({
  isOpen,
  handleClose,
  selectedTreatment,
  handleCancel,
  initUser,
}) {
  const closeDialog = (confirm) => {
    handleClose(confirm);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeDialog(false)}
      maxWidth="sm"
      fullWidth
      sx={{ textAlign: "center" }}
    >
      <Box
        sx={{
          fontWeight: "700",
          fontSize: "21px",
          lineHeight: "21px",
          color: "#2E3246",
          marginTop: "20px",
        }}
      >
        You have selected the following treatment
      </Box>
      <Box
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "21px",
          color: "#2E3246",
          marginTop: "10px",
        }}
      >
        &nbsp;
      </Box>
      {selectedTreatment.map((treat) => (
        <Box
          sx={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#2E3246",
            marginTop: "10px",
          }}
        >
          {treat.description}
        </Box>
      ))}
      <Box
        sx={{
          marginTop: "67px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ButtonBase
          sx={{
            width: "35%",
            marginTop: 1,
            paddingY: 2,
            fontSize: "15px",
            fontWeight: 600,
            color: "white",
            background: brandColor,
            borderRadius: "30px",
            boxShadow: "0px 2px 17px rgba(42, 193, 106, 0.37)",
          }}
          onClick={() => closeDialog(true)}
        >
          Proceed to Order
        </ButtonBase>
        <ButtonBase
          sx={{
            width: "35%",
            marginTop: 1,
            paddingY: 2,
            fontSize: "15px",
            fontWeight: 600,
            marginBottom: 2,
            color: "white",
            background: brandGray,
            borderRadius: "30px",
            boxShadow: "0px 2px 17px rgba(42, 193, 106, 0.37)",
          }}
          onClick={() => closeDialog(false)}
        >
          Add More Treatments
        </ButtonBase>
        <Button
          onClick={handleCancel}
          size="large"
          sx={{ marginBottom: 2, color: brandBlue }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}

export default connect(null, null)(ProceedToOrderModal);
