import { Skeleton, Stack } from "@mui/material";
import React, { useMemo } from "react";
import RoundedSkeleton from "./roundedSkeleton";

const TableSkeleton = ({ count, height = "72px" }) => {
  const skeletons = new Array(count).fill("");

  return (
    <>
      <RoundedSkeleton height="24px" sx={{ marginY: 2 }} />
      <Stack spacing={1} paddingBottom={1}>
        {skeletons.map(() => (
          <Skeleton
            variant="rounded"
            height={height}
            sx={{ borderRadius: "1em" }}
          />
        ))}
      </Stack>
    </>
  );
};

export default TableSkeleton;
