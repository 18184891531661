import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import StyledButton from "../common/button/largeButton";
import DialogContainer from "../common/dialogContainer";

const ProceedToFinalPrompt = ({ open, onClick }) => {
  return (
    <DialogContainer open={open} maxWidth="sm">
      <Box sx={{ textAlign: "center" }}>
        <h1>Scan Uploaded</h1>
        <Typography sx={{marginBottom: 2}}>
          The designer has recieved your file. Please wait for the designer to
          update the case.
        </Typography>

        <StyledButton variant="secondary" onClick={onClick}>Continue</StyledButton>
      </Box>
    </DialogContainer>
  );
};

export default ProceedToFinalPrompt;
