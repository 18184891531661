import { Box } from "@mui/material";
import React from "react";
import { brandColor } from "../../constants";
import { grey } from "@mui/material/colors";

const MenuItem = ({ active, children, ...props }) => {
  console.log(active);
  return (
    <Box
      sx={{
        display: "flex",
        cursor: "pointer",
        borderRadius: 2,
        marginBottom: 0.5,
        paddingLeft: 1,
        paddingRight: 2,
        paddingY: 1,
        textDecoration: "none",
        color: active ? "white" : "#3f3f3f",
        bgcolor: active ? brandColor : "inherit",
        ":hover": {
          bgcolor: !active && grey[100],
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MenuItem;
