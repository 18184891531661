
const _apiUrl = process.env.REACT_APP_API_END_POINT.split('=')[1];

const LockerService = {
    getlockers: function (email) {
        let data = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        };
    
        return fetch(
          _apiUrl + "locker/getlockers?email=" + email,
          data
        );
      }
};
export default LockerService;