import {
  CircularProgress,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import LargeButton from "../common/button/largeButton";
import ContentContainer from "../common/contentContainer";
import usePatientFiles from "../../hooks/usePatientFiles";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import DownloadFileButton from "../common/button/downloadFileButton";
import NoFilesAlert from "./noFilesAlert";
import UploadFileDialog from "../common/uploadFileDialog";
import PatientService from "../../services/patient.service";
import useUpload from "../../hooks/useUpload";
import CaseNumberListDialog from "./casenumberListDialog";

const type = "completedSurgicalPlan";

const CompletedSurgicalPlan = ({ patientId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { uploading, upload } = useUpload({ patientId, type });
  const {
    files,
    loading,
    pages,
    currentPage,
    handleChangePage,
    handleAddFiles,
  } = usePatientFiles({
    patientId,
    type: type,
    take: 20,
  });
  const [isCaseNumberDialogOpen, setIsCaseNumberDialogOpen] = useState(false);
  const [filesToSend, setFilesToSend] = useState();

  const headers = ["Photo Set", "Date Created", " "];
  const rows = files.map((file) => [
    file.fileName,
    moment(file.uploadedDate).format("LLL"),
    <DownloadFileButton path={file.path} fileName={file.fileName} />,
    <LargeButton
      variant="primary"
      onClick={() => {
        setIsCaseNumberDialogOpen(true);
        setFilesToSend(file);
      }}
    >
      Send to Evident Design
    </LargeButton>,
  ]);

  const handleClickPagination = (event, value) => {
    handleChangePage(value);
  };

  const handleUpload = async (files) => {
    const data = await upload(files);
    handleAddFiles(data);
    setIsDialogOpen(false);
  };
  const handleSendToEvidentDesign = (caseNumber) => {
    setIsCaseNumberDialogOpen(false);
  };

  return (
    <ContentContainer>
      <Box sx={{ width: "100%", marginBottom: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: 1.25 }}
        >
          Completed Surgical Plan STL
        </Typography>
        <Typography
          variant="body2"
          sx={{
            width: "90%",
            marginBottom: 3,
          }}
        >
          {/* No content */}
        </Typography>
        <LargeButton variant="primary" onClick={() => setIsDialogOpen(true)}>
          Upload Files
        </LargeButton>
      </Box>
      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
          marginBottom: 5,
        }}
      />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          <React.Fragment>
            <DataTable headers={headers}>
              {rows.map((row, i) => (
                <TableRow key={i} row={row} />
              ))}
            </DataTable>
            <Pagination
              count={pages}
              page={currentPage}
              onChange={handleClickPagination}
              sx={{ marginY: 2 }}
            />
          </React.Fragment>
        ) : (
          <NoFilesAlert />
        ))}
      <UploadFileDialog
        title="Upload Completed Surgical Plan"
        open={isDialogOpen}
        uploading={uploading}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onUpload={handleUpload}
      />
      <CaseNumberListDialog
        open={isCaseNumberDialogOpen}
        onClose={() => {
          setIsCaseNumberDialogOpen(false);
        }}
        onSubmit={handleSendToEvidentDesign}
        patientId={patientId}
        filesToSend={filesToSend}
        isZip={true}
      />
    </ContentContainer>
  );
};

export default CompletedSurgicalPlan;
