import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import LargeButton from "../common/button/largeButton";
import { useNavigate, Link } from "react-router-dom";

import { useSelector } from "react-redux";
import CaseService from "../../services/case.service";
import RejectDialog from "./rejectDialog";
import ApproveDialog from "./approveDialog";
import ApproveWithFabricateDialog from "./approveWithFabricateDialog";

import DownloadFileLargeButton from "../common/button/downloadFileLargeButton";
import ResubmitDialog from "./resubmitDialog";
import LabsDialog from "./labsDialog";
import {
  brandBlue,
  brandGray,
  brandLight,
  brandLightGreen,
  brandOrange,
} from "../../constants";
import { grey } from "@mui/material/colors";
import PreviewFileButton from "./previewFileButton";
import FilePreviewDownload from "./filePreviewDownload";

const CaseResult = ({ caseId, onStatusChange }) => {
  const [forApprovals, setForApprovals] = useState([]);
  const [selectedCase, setSelectedCase] = useState({});

  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isApproveWithFabricateDialogOpen, setIsApproveWithFabricateDialogOpen] = useState(false);    
  const [isRequestRedesignDialogOpen, setIsRequestRedesignDialogOpen] = useState(false);

  const [approveTreatmentModel, setApproveTreatmentModel] = useState({});

  const [rejectPackageType, setRejectPackageType] = useState("");
  const [rejectCasePackageModel, setRejectCasePackageModel] = useState({});

  const [isResubmitDialogOpen, setIsResubmitDialogOpen] = useState(false);

  const [caseFiles, setCaseFiles] = useState([]);

  const [isLoadingBackdrop, setisLoadingBackdrop] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);

  const [type, setType] = useState("rescan");
  const [packageApproveModel, setPackageApproveModel] = useState({});

  const [isLabDialogOpen, setIsLabDialogOpen] = useState(false);
  const [selectedCaseType, setSelectedCaseType] = useState(""); 
  const [autoSendToLab, setAutoSendToLab] = useState(false);
  const [labs, setLabs] = useState([]); 
  
  const navigate = useNavigate();
  useEffect(() => {
    getCase();
    getFiles();
    
     
  }, []);

  const getCase = async () => {
    // copied to caseFiles
    setisLoadingBackdrop(true);
    const response = await CaseService.getCaseById(caseId);
    const data = await response.json();

     
    
     if(data.treatment.includes("Truedent Denture") && (data.comment != null || data.comment != undefined ))
     {  
      const commentVal= data.comment; 
      const comment = JSON.parse(commentVal);
          if(comment.products[0] &&
            comment.products[0].productDetails &&
            comment.products[0].productDetails.some(detail =>
              detail.reqName === "Are you fabricating In-house ?" &&
              detail.reqValue === "No"))
          { 
            setAutoSendToLab(true);  
            const responseTruedentLabs = await CaseService.getserviceprovidersTruedent();
            await responseTruedentLabs.json().then((res) => { 
              setLabs(res[0].providers);
              const labIDForSendToLab =  res[0].providers.find((x) => x.labCode==process.env.REACT_APP_EVIDENT_ARTISAN_ID);
               
            });  
          }
          else
          { 
            setAutoSendToLab(false);  
          }
     }
     else
     {
      setAutoSendToLab(false);  
     }
    
    let task = [];
    data.caseTreatments.forEach((ct) => {
      ct?.serviceProviders.forEach((sp) => {
        if (sp.lastModified) {
          sp.treatmentType = ct.treatmentType;
          task.push(sp);
        }
      });
    });

    task.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.lastModified) - new Date(a.lastModified);
    });

    if (task.length < 1) {
      var tempLatestTask =
        data.caseTreatments && data.caseTreatments.length > 0
          ? data.caseTreatments[0].serviceProviders[0]
          : null;

      if (tempLatestTask) {
        tempLatestTask.status = "Sent";
        data.latestTask = tempLatestTask;
      }
    } else {
      data.latestTask = task[0];
    }
    setSelectedCase(data);

    setisLoadingBackdrop(false);
  };

  const getFiles = async () => {
    const response = await CaseService.getallcasefiledownloads(caseId);
    const data = await response.json();

    setCaseFiles(data.files);
    console.log(data);
  };

  useEffect(() => {
    console.log(selectedCase);
    if (Object.keys(selectedCase).length !== 0) {
      let statuses = ["In Progress", "For Approval", "Received", "Completed"];

      let approvals = [];

      // FOR MAIN PACKAGE APPROVE BUTTON
      if (statuses.includes(selectedCase.latestTask.status)) {
        let result = selectedCase.latestTask;

        result.caseId = selectedCase.id;
        result.flags = selectedCase.flags;
        result.treatment = selectedCase.treatment.map((tt, ttidx) => (
          <Typography
            key={ttidx}
            color="#fff"
            variant="h6"
            component="h6"
            marginY={0}
          >
            {tt}
          </Typography>
        ));

        if (
          selectedCase.latestTask.status === "For Approval" &&
          !selectedCase?.treatment?.find((role) =>
            ["Evident Smile Package", "Aligners", "All on X Implants"].includes(
              role
            )
          ) &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
          result.approveButton = (
            <LargeButton
              variant="primary"
              onClick={() =>{ 
                
                
                setSelectedCaseType(selectedCase.treatment);
                
                handleApprove(
                  {
                    caseId: selectedCase.id,
                    isApproved: true,
                    userId: userInfo.Id,
                    status: "Approved",
                    serviceProviderType:
                      selectedCase.latestTask.serviceProvider,
                  },
                  selectedCase.treatment,
                  selectedCase.latestTask.status
                )  

                if(selectedCase?.treatment?.includes("Truedent Denture") &&  autoSendToLab == true  && process.env.REACT_APP_EVIDENT_ENABLETRUEDENT_NEWFORM === "TRUE")
                { 
                  setisLoadingBackdrop(true);

                  const labIDForSendToLab =  labs.find((x) => x.labCode==process.env.REACT_APP_EVIDENT_ARTISAN_ID);
 
                  
                  if(labIDForSendToLab != undefined || labIDForSendToLab !=null)
                  {
                    //auto send to lab to artisan
                  let model = {
                    caseId: caseId,
                    labId: labIDForSendToLab.id,
                  };
                  
                   
                  CaseService.sendtolabs(model).then((response) => { 
                     
                  });

                  }

                  //setIsLabDialogOpen(true);
                  
                  setisLoadingBackdrop(false);
                }
              }}
            >
              Approve
            </LargeButton>
          );
        } else if (
          selectedCase.latestTask.status === "For Approval" &&
          selectedCase.treatment.includes("All on X Implants") &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
          result.approveButton = (
            <LargeButton
              variant="primary"
              onClick={() => {
                
                setSelectedCaseType(selectedCase.treatment);
                setIsApproveDialogOpen(true);
                setApproveTreatmentModel({
                  caseId: selectedCase.id,
                  isApproved: true,
                  userId: userInfo.Id,
                  status: "Approved",
                  serviceProviderType: selectedCase.latestTask.serviceProvider,
                });
              }}
            >
              Approve
            </LargeButton>
          );
        } else if (
          selectedCase.latestTask.status === "For Approval" &&
          selectedCase.treatment.includes("Evident Smile Package") &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
          result.approveButton = (
            <LargeButton
              variant="primary"
              onClick={() =>{ 
                
                setSelectedCaseType(selectedCase.treatment);
                handleApprove(
                  {
                    caseId: selectedCase.id,
                    isApproved: true,
                    userId: userInfo.Id,
                    status: "Approved",
                    serviceProviderType:
                      selectedCase.latestTask.serviceProvider,
                  },
                  selectedCase.treatment,
                  selectedCase.latestTask.status
                )
              }
            }
            >
              Approve
            </LargeButton>
          );
        } else if (
          selectedCase.latestTask.status === "For Approval" &&
          selectedCase.treatment.includes("Aligners") &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
            result.approveButton = (
                <LargeButton
                    variant="primary"
                    onClick={() => {
                        setIsApproveWithFabricateDialogOpen(true);
                        setApproveTreatmentModel({
                            caseId: selectedCase.id,
                            isApproved: true,
                            userId: userInfo.Id,
                            status: "Approved",
                            serviceProviderType: selectedCase.latestTask.serviceProvider,
                        });
                    }}
                >
                    Approve
                </LargeButton>
            //<LargeButton
            //  variant="primary"
            //  onClick={() =>
            //    handleApprove(
            //      {
            //        caseId: selectedCase.id,
            //        isApproved: true,
            //        userId: userInfo.Id,
            //        status: "Approved",
            //        serviceProviderType:
            //          selectedCase.latestTask.serviceProvider,
            //      },
            //      selectedCase.treatment,
            //      selectedCase.latestTask.status
            //    )
            //  }
            //>
            //  Approve
            //</LargeButton>
          );
        }
        // END APPROVE BUTTON

        // FOR REQUEST REDESIGN BUTTON

        console.log(
          selectedCase.treatment.find(
            (x) =>
              x === "Evident Smile Package" ||
              x === "All on X Implants" ||
              x === "All on Four Standard"
          )
        );
        // copied to caseFiles
        if (
          (selectedCase.latestTask.status === "Completed" ||
            selectedCase.latestTask.status === "Received") &&
          selectedCase.latestTask.serviceProviderId ===
            "00000000-0000-0000-0000-000000000000" &&
          selectedCase.treatment.find(
            (x) =>
              x === "Evident Smile Package" ||
              x === "All on X Implants" ||
              x === "All on Four Standard"
          ) === undefined
        ) {
          result.requestRedesign = (
            <LargeButton
              variant="orange"
              onClick={() => {
                setIsRequestRedesignDialogOpen(true);
              }}
            >
              Request Redesign
            </LargeButton>
          );
        }

        // END FOR REQUEST REDESIGN BUTTON

        //FOR REJECT BUTTON
        if (
          selectedCase.latestTask.status === "For Approval" &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
          result.rejectButton = (
            <LargeButton
              variant="red"
              onClick={() => {
                setIsRejectDialogOpen(true);
                setRejectPackageType("MainPackage");
              }}
            >
              Request Redesign
            </LargeButton>
          );
        }
        //END REJECT BUTTON

        //FOR DOWNLOAD/PREVIEW BUTTON
        if (
          selectedCase.designLink &&
          selectedCase.latestTask.status === "For Approval" &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
          result.previewButton = (
            <LargeButton
              variant="secondary"
              onClick={() => {
                var win = window.open(selectedCase.designLink, "_blank");
                win.focus();
              }}
            >
              View Design
            </LargeButton>
          );
        }

        if (
          !selectedCase.designLink &&
          selectedCase.latestTask.status === "For Approval" &&
          selectedCase?.caseTreatments[0]?.casePackages.length === 0
        ) {
          let files = caseFiles.filter((x) => x.casePackageId === null);
          result.files = files.map((f) => {
            const file = {
              fileName: f.fileName,
              path: f.filePath,
              type: f.fileName.split(".").pop(),
            };

            return (
              <FilePreviewDownload isLoading={isLoadingBackdrop} file={file} />
            );
          });
        }
        //END DOWNLOAD/PREVIEW BUTTON

        // RESUBMIT BUTTON
        if (
          selectedCase.latestTask.status === "In Progress" &&
          selectedCase.flags.rescan &&
          selectedCase.latestTask.serviceProviderId ===
            "00000000-0000-0000-0000-000000000000" &&
          (selectedCase.flags.rescanUploaded === false ||
            selectedCase.flags.rescanUploaded === null) &&
          !selectedCase.isUploadingRescan
        ) {
          result.resubmitButton = (
            <LargeButton
              variant="secondary"
              onClick={() => {
                setIsResubmitDialogOpen(true);
                setType("rescan");
              }}
            >
              Resubmit
            </LargeButton>
          );
        }
        // RESUBMIT BUTTON

        // SEND TO LABS BUTTON
        if (
          selectedCase.latestTask.status === "Completed" &&
          // selectedCase.partner &&
          selectedCase.latestTask.serviceProvider === "Design"
        ) {
          result.sendToLabsButton = (
            <LargeButton
              variant="primary"
              onClick={() => {
                setIsLabDialogOpen(true);
              }}
            >
              Send to Lab
            </LargeButton>
          );
        }
        // END SENT TO LABS BUTTONV

        if (
          result.rejectButton !== undefined ||
          result.approveButton !== undefined ||
          result.resubmitButton !== undefined ||
          result.requestRedesign !== undefined ||
          result.sendToLabsButton !== undefined
        ) {
          approvals.push(result);
        }
      }

      selectedCase?.caseTreatments.forEach((ct) => {
        ct?.casePackages.forEach((cp) => {
          console.log(cp);
          let result = {};
          result.status = cp.status;
          result.packageProductId = cp.packageProductId;
          result.caseId = cp.caseId;
          result.flags = "";
          result.treatment = cp.name;

          if (result.status === "For Approval") {
            if (ct.treatmentType === "All on X Implants") {
              result.approveButton = (
                <LargeButton
                  variant="primary"
                  onClick={() => {
                    handleApproveCasePackage(
                      cp.refId,
                      cp.packageProductId,
                      cp.productId
                    );
                    const standardPackageId = 1044;
                    if (cp.productId === standardPackageId) {
                      navigate("/cases");
                    }
                  }}
                >
                  Approve
                </LargeButton>
              );
            } else {
              result.approveButton = (
                <LargeButton
                  variant="primary"
                  onClick={() =>{ 
                    handleApproveCasePackage(
                      cp.refId,
                      cp.packageProductId,
                      cp.productId
                    )
                    
 
                    
                  }
                }
                >
                  Approve
                </LargeButton>
              );
            }

            result.rejectButton = (
              <LargeButton
                variant="red"
                onClick={() => {
                  setIsRejectDialogOpen(true);
                  setRejectPackageType("CasePackage");
                  setRejectCasePackageModel({
                    refNo: cp.refId,
                    packageProductId: cp.packageProductId,
                    productId: cp.productId,
                  });
                }}
              >
                Request Redesign
              </LargeButton>
            );

            approvals.push(result);
          }

          //FOR DOWNLOAD/PREVIEW BUTTON
          if (
            result.status === "For Approval" ||
            (!selectedCase.designLink &&
              selectedCase.latestTask.status === "For Approval" &&
              selectedCase?.caseTreatments[0]?.casePackages.length === 0)
          ) {
            let files = caseFiles.filter(
              (file) => file.casePackageId === cp.casePackageId
            );
            const fileTypesForPreview = ["stl", "html", "pdf", "jpg", "png"];

            result.files = files.map((f) => {
              const file = {
                fileName: f.fileName,
                path: f.filePath,
                type: f.fileName.split(".").pop(),
              };
              console.log(file);

              return (
                <FilePreviewDownload
                  isLoading={isLoadingBackdrop}
                  file={file}
                />
              );
            });
          }
          //END DOWNLOAD/PREVIEW BUTTON
        });
      });

      setForApprovals(approvals);
    }
  }, [selectedCase]);

  const handleRequestRedesign = async (notes) => {
    setisLoadingBackdrop(true);
    setIsRequestRedesignDialogOpen(false);

    let commentModel = {
      CaseId: caseId,
      CommentAttachments: [],
      CommentedBy: "",
      Message: notes,
      PersonnelId: userInfo.Id,
      SendFromOutsideHub: false,
      SendMessageNotification: false,
      SendToHubOnly: false,
    };

    await CaseService.requestredesign(
      convertNightguardProducts(),
      commentModel
    ).then((res) => {
      getCase();
      onStatusChange();
    });

    setisLoadingBackdrop(false);
  };

  const convertNightguardProducts = () => {
    let caseModel = selectedCase;

    var treatmentList = [];

    caseModel.treatment.forEach((x) => {
      if (
        x == "Flat Plane Occlusal Nightguard with Platform" ||
        x == "Standard Nightguard (no platform)"
      ) {
        treatmentList.push("Nightguard");
      } else {
        treatmentList.push(x);
      }
    });

    caseModel.treatment = treatmentList;

    caseModel.caseTreatments.forEach((x) => {
      if (
        x.treatmentType == "Flat Plane Occlusal Nightguard with Platform" ||
        x.treatmentType == "Standard Nightguard (no platform)"
      ) {
        x.treatmentType = "Nightguard";
      }
    });

    return caseModel;
  };

  const handleReject = async (notes) => {
    setIsRejectDialogOpen(false);

    if (rejectPackageType === "MainPackage") {
      rejectDesign(selectedCase.id, notes);
    } else if (rejectPackageType === "CasePackage") {
      handleRejectCasePackage(notes);
    }
  };

  const rejectDesign = async (caseId, comment) => {
    setisLoadingBackdrop(true);
    await CaseService.rejectDesign(caseId, comment).then((res) => {
      getCase();
      onStatusChange();
    });
    setisLoadingBackdrop(false);
  };

  const approveDesign = async (model) => {
     
    setisLoadingBackdrop(true);
    await CaseService.updateWorkflowStage(model).then((res) => {
      getCase();
      onStatusChange();
    });

    setisLoadingBackdrop(false);
  };

  const approveWithChangeProduct = async (model) => {
      setisLoadingBackdrop(true);
      await CaseService.approveWithChangeProduct(model).then((res) => {
          getCase();
          onStatusChange();
      });
      setisLoadingBackdrop(false);
  }
  const handleApproveCasePackage = async (
    refId,
    packageProductId,
    productId
  ) => {
    console.log(refId, packageProductId, productId);
    setisLoadingBackdrop(true);
    CaseService.approveCasePackage(refId, packageProductId, productId).then(
      (res) => {
        getCase();
        onStatusChange();
      }
    );

    setisLoadingBackdrop(false);
  };

  const handleRejectCasePackage = async (comment) => {
    setisLoadingBackdrop(true);
    let model = rejectCasePackageModel;
    model.comment = comment;
    CaseService.rejectCasePackage(model).then((res) => {
      getCase();
      onStatusChange();
    });

    setisLoadingBackdrop(false);
  };

  const handleApprove = (model, treatmentList, currentStatus) => {
    // vm.isLoadingCases = true;

    // if (command === "Approved" && serviceProvider === "Design") {
    //   if (
    //     treatmentList.includes("Aligners") &&
    //     currentStatus === "For Approval"
    //   ) {
    //     fabricationNoticeModalService
    //       .showNoticeModal()
    //       .then(function (response) {});
    //   }
    // }

     
    approveDesign(model);
  };

  const handleApproveDialog = (notes) => {
    setIsApproveDialogOpen(false);

    let model = approveTreatmentModel;
    model.appointmentDate = notes;

    approveDesign(model);
   };

   const handleApproveWithFabricateDialog = (result) => {
    setIsApproveWithFabricateDialogOpen(false);
    
    let model = approveTreatmentModel;    

    if (result) {
        approveDesign(model);
    } else {
        model.continueWithFabrication = result;
        approveWithChangeProduct(model);
    }     
    
   };

  const handleAfterUpload = async () => {
    if (type === "approve") {
      await handleApproveCasePackage(
        packageApproveModel.refNo,
        packageApproveModel.packageProductId,
        packageApproveModel.productId
      );
      getCase();
    }
    setForApprovals([]);
    // getCase();
    setIsResubmitDialogOpen(false);
    onStatusChange();
  };

  const handleAfterSendToLab = () => {
    setIsLabDialogOpen(false);
    getCase();
    onStatusChange();
  };
  return (
    <React.Fragment>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingBackdrop}
      >
        <CircularProgress color="success" />
      </Backdrop> */}

      {isLoadingBackdrop && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress color="success" />
        </Box>
      )}

      {!isLoadingBackdrop && (
        <Box paddingX={5} borderRadius="20px">
          <Grid container marginY={5}>
            {forApprovals.length > 0 ? (
              <React.Fragment>
                {/* <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold", marginBottom: 1.25 }}
                >
                  Products for Dentist Approval
                </Typography> */}
                {forApprovals.map((row, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={12}>
                      <Box
                        key={i}
                        paddingX={5}
                        paddingY={3.5}
                        marginTop={2}
                        marginBottom={1.5}
                        borderRadius="20px"
                        sx={{ bgcolor: brandGray }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" spacing={3} alignItems="start">
                            <React.Fragment>
                              <Avatar
                                sx={{ height: "120px", width: "120px" }}
                              />
                              <Box sx={{ fontSize: "1.3em" }}>
                                <Box
                                  component="p"
                                  color="#fff"
                                  mt={0}
                                  mb={0.75}
                                >
                                  <Box
                                    component="p"
                                    fontWeight="500"
                                    color="#fff"
                                    marginRight={1}
                                    sx={{
                                      textAlign: "left",
                                      marginTop: 0,
                                      marginBottom: 2,
                                    }}
                                  >
                                    {row.treatment}
                                    {/* Submitted Treatment: */}
                                  </Box>
                                  {/* {row.treatment} */}
                                </Box>

                                {/* {row.downloadButton && row.file.fileName && (
                                  <Box>
                                    <Typography
                                      sx={{
                                        textAlign: "left",
                                        marginBottom: 1,
                                        fontSize: "0.875rem",
                                        color: grey[200],
                                      }}
                                    >
                                      {row.file.fileName}
                                    </Typography>
                                  </Box>
                                )} */}
                                <Box my={0}>
                                  <Typography
                                    color="#fff"
                                    variant="body2"
                                    marginY={0}
                                  >
                                    {/* No content */}
                                  </Typography>
                                </Box>

                                <Stack spacing={2}>
                                  {console.log(row.files)}
                                  {row.files}
                                </Stack>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  marginTop={1}
                                >
                                  {row?.downloadButton}
                                  {row?.previewButton}
                                </Stack>
                              </Box>
                            </React.Fragment>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        {row?.rejectButton}
                        {row?.approveButton}
                        {row?.resubmitButton}
                        {row?.requestRedesign}
                        {row?.sendToLabsButton}
                      </Stack>
                    </Grid>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <Typography>No Products for Approval</Typography>
            )}
          </Grid>
        </Box>
      )}

      <RejectDialog
        title={"Redesign Request"}
        placeholder={"Provide notes for the Redesign Request (REQUIRED)"}
        open={isRequestRedesignDialogOpen}
        onClose={() => {
          setIsRequestRedesignDialogOpen(false);
        }}
        onSubmit={handleRequestRedesign}
      />

      <RejectDialog
        open={isRejectDialogOpen}
        onClose={() => {
          setIsRejectDialogOpen(false);
        }}
        onSubmit={handleReject}
      />

      <ApproveDialog
        open={isApproveDialogOpen}
        onClose={() => {
          setIsApproveDialogOpen(false);
        }}
        onSubmit={handleApproveDialog}
      />
      <ApproveWithFabricateDialog
        open={isApproveWithFabricateDialogOpen}
        onClose={() => {
            setIsApproveWithFabricateDialogOpen(false);
        }}
        onSubmit={handleApproveWithFabricateDialog}
       />

      <ResubmitDialog
        open={isResubmitDialogOpen}
        onClose={() => {
          setIsResubmitDialogOpen(false);
        }}
        patientId={selectedCase.patientId}
        caseId={caseId}
        onSubmit={handleAfterUpload}
        uploadType={type}
        caseNumber={selectedCase.caseRefNo}
        backButtonMessage={"No"}
        message={"Do you want to upload more photos?"}
        fileUploadType={"otherScans"}
        type={"otherDocs"}
      />

      <LabsDialog
        open={isLabDialogOpen}
        onClose={() => {
          setIsLabDialogOpen(false);
        }}
        dentistId={selectedCase.personnelId}
        caseId={caseId}
        onSubmit={handleAfterSendToLab}
        caseType={selectedCaseType} 
      />
    </React.Fragment>
  );
};

export default CaseResult;
