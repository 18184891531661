import React, { useState, useRef, useMemo } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Button,
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
  Typography,
  LinearProgress,
  Link,
  Stack,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../components/header/header";
import AccountService from "../../services/account.service";
import { initUser } from "../../redux/actions/index";
import { countryList } from "./countryList";
import { learnAboutUsOptions } from "./learnAboutUsOptions";
import AdminService from "../../services/admin.service";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { apiUrl, brandColor, brandGray, brandLight } from "../../constants";
import { getFetchOptions } from "../../helpers/getFetchOptions";
import ToothChartService from "../../services/toothChart.service";
import LockerService from "../../services/locker.service";
import { Controller, useForm } from "react-hook-form";
import InputText from "../common/inputText";
import { yupResolver } from "@hookform/resolvers/yup";
import { practiceSchema } from "../../types/practiceSchema";
import SelectInput from "../common/selectInput";
import { useLogin } from "../../hooks/useLogin";
import StyledFormLabel from "../common/styledFormLabel";
import provinces from "./provinces.json";
import AddPracticeDialog from "./addPracticeDialog";
import { openLoading, closeLoading } from "../../redux/actions";

const initialRoles = [
  "Dentist",
  "Dental Hygienist",
  "Dental Assistant",
  "Practice Administrator",
];

function SignUp2() {
  const location = useLocation();
  console.log(location.state?.user, location.state?.userBasicData);
  const [user, setUser] = useState(
    location.state?.userBasicData
      ? {
          email: location.state.userBasicData.email,
          password: "",
          firstName: location.state.userBasicData.firstName,
          lastName: location.state.userBasicData.lastName,
        }
      : null
  );
  const [isFromSSO, setIsFromSSO] = useState(
    location.state?.userBasicData ? true : false
  );

  const [roles, setRoles] = useState(initialRoles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [position, setPosition] = React.useState([]);
  const [practices, setPractices] = useState([]);

  console.log(window.location.href, window.location.href.split("/"));
  const href = window.location.href.split("/");
  let url = "";
  if (href.length >= 3) {
    for (let i = 0; i < 3; i++) {
      url = url + href[i] + "/";
    }

    // href.forEach((x) => (url = url + x + "/"));
  }
  console.log(url);

  const _loginUrl = process.env.REACT_APP_ACCOUNT_LOGIN + url;
  console.log(_loginUrl);
  const [accountModel, setAccountModel] = useState({
    password: "",
    learnAboutUs: "",
    learnAboutUsOthers: "",
    personnel: {
      FirstName: "",
      Email: "",
      LastName: "",
      PositionId: "",
      Role: "",
      UtmSource: "",
      UtmMedium: "",
      UtmCampaign: "",
    },
    practices: [],
    PersonnelInviteId: "00000000-0000-0000-0000-000000000000",
  });

  const ref = useRef(null);

  useEffect(() => {
    // dispatch(closeLoading());
    let isLoggedIn = localStorage.getItem("auth_token");
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (location.state !== null && location.state.userBasicData !== null) {
      console.log(location.state.user, user);
    }
    const getPractices = async () => {
      const options = getFetchOptions("GET");
      const response = await fetch(
        "https://preprodkoisapi.azurewebsites.net/api/account/getallpractices",
        options
      );
      const data = await response.json();
      setPractices(data);
    };

    getPractices();
  }, []);

  useEffect(() => {
    console.log(location);
    if (location.state?.user) {
      const { user } = location.state;

      const practice = {
        Id: user.practiceId,
        email: user.practiceEmail,
        name: user.practiceName,
        phone: user.phone,
        specialty: JSON.parse(user.specialty),
        address: {
          Line1: user.line1,
          Line2: user.line2,
          City: user.city,
          State: user.state,
          Country: user.country,
          Postal: user.postal,
        },
        equipment: JSON.parse(user.equipment),
      };
      console.log(practice);

      setRoles([...roles, user.position]);
      setAccountModel((prev) => ({
        ...prev,
        PersonnelInviteId: user.personnelId,
        practices: [practice],
      }));
      setUser(user);
    } else {
      setUser({});
    }
  }, [location]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [isLogin, setIsLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useLogin();

  const handleClickOpen = () => {
    if (isLogin) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddPractice = (practice) => {
    setAccountModel((prevState) => ({
      ...prevState,
      practices: [...prevState.practices, practice],
    }));
    ref.current.values.practices = [...ref.current.values.practices, practice];
    setErrorMessage("");
    setOpen(false);
  };

  const removePractice = (i) => {
    if (isLogin) return;
    let data = [...accountModel.practices];
    data.splice(i, 1);
    setAccountModel((prev) => ({
      ...prev,
      practices: data,
    }));
    ref.current.values.practices = data;
  };

  React.useEffect(() => {
    AdminService.getPosition()
      .then((response) => response.json())
      .then((json) => {
        console.log("jsonPosition", json);
        setPosition(json);
        console.log("position", position);
      });
  }, []);

  console.log(accountModel);

  const setUserInfo = (loginResult, practiceAndPersonnel) => {
    // from login
    let userInfo = {
      Id: loginResult.personnel.id,
      LoginId: loginResult.jwt.loginId,
      FirstName: loginResult.personnel.firstName,
      LastName: loginResult.personnel.lastName,
      Roles: loginResult.jwt.roles,
      Email: loginResult.personnel.email,
      PersonnelAttributes: loginResult.personnel.attributes,
      PracticeWithPersonnel: practiceAndPersonnel,
    };
    var myObject = JSON.parse(loginResult.personnel.attributes);
    if (myObject.DentalNotationUserSetting != null) {
      ToothChartService.setDentalNotationSystem(
        myObject.DentalNotationUserSetting
      );
    }
    dispatch(initUser(userInfo));
    setIsLogin(false);
    handleClose(true);
  };

  const registerSSOUser = (values) => {
    var positionId = position.find((x) => x.description === values.role);
    if (positionId) {
      accountModel.personnel.PositionId = positionId.id;
    } else {
      accountModel.personnel.PositionId = position.find(
        (x) => x.description === "Dentist"
      ).id;
    }

    accountModel.password = values.password;
    accountModel.learnAboutUs = values.learnAboutUs;
    if (values.learnAboutUs === "Other")
      accountModel.learnAboutUs =
        values.learnAboutUs + " - " + values.learnAboutUsOthers;
    accountModel.learnAboutUsOthers = values.learnAboutUsOthers;
    accountModel.personnel.Email = values.email;
    accountModel.personnel.FirstName = values.firstName;
    accountModel.personnel.LastName = values.lastName;
    accountModel.personnel.Role = values.role;

    console.log(accountModel.practices);
    accountModel.practices = values.practices.map((practice) => ({
      ...practice,
      email: !practice.Email ? values.email : practice.Email,
      specialty: JSON.stringify(practice.specialty),
      equipment: JSON.stringify(practice.equipment),
    }));

    AccountService.ssoUpdate(accountModel, location.state.token)
      .then((response) => response.json())
      .then(async (json) => {
        // set to onboarded
        console.log(user);
        if (user && Object.keys(user).length !== 0) {
          fetch(
            apiUrl + "locker/settoonboarded",
            getFetchOptions("POST", {
              email: user.email,
              personnelId: user.personnelId,
              position: accountModel.personnel.Role,
              practiceId: user.practiceId,
            })
          ).then(async () => {
            try {
              // await login(values.email, values.password);
              // navigate("/", {
              //   state: {
              //     selectedTreatment:
              //       location.state?.selectedTreatment || undefined,
              //   },
              // });

              await AccountService.logOut();
              window.localStorage.clear();
              window.location.href = _loginUrl;
            } catch (err) {
              setErrorMessage("Login failed. Please try again.");
              setIsLogin(false);
            }
          });
        } else {
          try {
            // await login(values.email, values.password);
            // navigate("/", {
            //   state: {
            //     selectedTreatment:
            //       location.state?.selectedTreatment || undefined,
            //   },
            // });

            await AccountService.logOut();
            window.localStorage.clear();
            window.location.href = _loginUrl;
          } catch (err) {
            setErrorMessage("Login failed. Please try again.");
            setIsLogin(false);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLogin(false);
      });
  };

  const registerNewUser = (values) => {
    AccountService.validateEmail(values.email)
      .then((response) => response.json())
      .then((json) => {
        if (json.error === null) {
          var positionId = position.find((x) => x.description === values.role);
          if (positionId) {
            accountModel.personnel.PositionId = positionId.id;
          } else {
            accountModel.personnel.PositionId = position.find(
              (x) => x.description === "Dentist"
            ).id;
          }

          accountModel.password = values.password;
          accountModel.learnAboutUs = values.learnAboutUs;
          if (values.learnAboutUs === "Other")
            accountModel.learnAboutUs =
              values.learnAboutUs + " - " + values.learnAboutUsOthers;
          accountModel.learnAboutUsOthers = values.learnAboutUsOthers;
          accountModel.personnel.Email = values.email;
          accountModel.personnel.FirstName = values.firstName;
          accountModel.personnel.LastName = values.lastName;
          accountModel.personnel.Role = values.role;

          accountModel.practices = accountModel.practices.map((practice) => ({
            ...practice,
            email: !practice.Email ? values.email : practice.Email,
            specialty: JSON.stringify(practice.specialty),
            equipment: JSON.stringify(practice.equipment),
          }));

          AccountService.register(accountModel)
            .then((response) => response.json())
            .then(async (json) => {
              // set to onboarded
              console.log(user);
              if (user && Object.keys(user).length !== 0) {
                fetch(
                  apiUrl + "locker/settoonboarded",
                  getFetchOptions("POST", {
                    email: user.email,
                    personnelId: user.personnelId,
                    position: accountModel.personnel.Role,
                    practiceId: user.practiceId,
                  })
                ).then(async () => {
                  try {
                    await login(values.email, values.password);
                    navigate("/", {
                      state: {
                        selectedTreatment:
                          location.state?.selectedTreatment || undefined,
                      },
                    });
                  } catch (err) {
                    setErrorMessage("Login failed. Please try again.");
                    setIsLogin(false);
                  }
                });
              } else {
                try {
                  await login(values.email, values.password);
                  navigate("/", {
                    state: {
                      selectedTreatment:
                        location.state?.selectedTreatment || undefined,
                    },
                  });
                } catch (err) {
                  setErrorMessage("Login failed. Please try again.");
                  setIsLogin(false);
                }
              }
            })
            .catch((err) => {
              console.log("err", err);
              setIsLogin(false);
            });
        } else {
          setErrorMessage(
            "There is already an account associated with the email address"
          );
          setIsLogin(false);
          console.log("errorMessage");
        }
      })
      .catch((err) => {
        setErrorMessage("E-mail already exists.");
        console.log("err", err);
        setIsLogin(false);
      });
  };

  return (
    <>
      <div>
        <Header
          doctorName={
            isFromSSO
              ? `${location.state.userBasicData.firstName} ${location.state.userBasicData.lastName}`
              : null
          }
        />
        <Grid
          container
          xs={12}
          md={8}
          sx={{ mx: "auto" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box
              sx={{
                width: "100%",
                paddingTop: "20px",
              }}
            ></Box>
          </Grid>
        </Grid>
        {errorMessage !== "" ? (
          <Box
            sx={{
              textAlign: "center",
              fontSize: "20px",
              color: "red",
              marginBottom: "20px",
            }}
          >
            {errorMessage}
          </Box>
        ) : (
          ""
        )}
        {user && (
          <Formik
            innerRef={ref}
            initialValues={{
              email: user.email,
              password: "",
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.position,
              learnAboutUs: null,
              learnAboutUsOthers: "",
              IntraOralScanner: false,
              CBCTScanner: false,
              "3DPrinter": false,
              ChairsideMill: false,
              FamilyDentistry: false,
              PediatricDentistry: false,
              Orthodontics: false,
              Aligners: false,
              Implants: false,
              Prostodontics: false,
              practices: accountModel.practices,
              acceptTos: false,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.firstName) {
                errors.firstName = "First name is required.";
              }

              if (!values.lastName) {
                errors.lastName = "Last name is required.";
              }

              if (!values.role) {
                errors.role = "Role is required.";
              }

              if (!values.email) {
                errors.email = "Email address is required.";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password && !isFromSSO) {
                errors.password = "Password is required.";
              }

              if (values.password.length < 8 && !isFromSSO) {
                errors.password = "Password must be at least 8 characters long";
              }

              const reg = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
              if (!reg.test(values.password) && !isFromSSO) {
                errors.password =
                  "Password must have 1 number, 1 uppercase letter, and 1 lowercase letter";
              }

              if (values.password !== values.confirmPassword && !isFromSSO) {
                errors.confirmPassword = "Password must match.";
              }

              if (!values.learnAboutUs) {
                errors.learnAboutUs = "How did you hear about us is required.";
              }

              if (
                values.learnAboutUs === "Other" &&
                !values.learnAboutUsOthers
              ) {
                errors.learnAboutUsOthers = "Specify Other.";
              }

              if (values.acceptTos !== true) {
                errors.acceptTos = "Accept privacy policy and terms of use";
              }

              if (!values.practices.length > 0) {
                errors.practices = "Please add a Practice";
              }

              console.log("errors", errors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setIsLogin(true);
              setErrorMessage("");

              console.log(values);

              if (isFromSSO) {
                registerSSOUser(values);
              } else if (!isFromSSO) {
                if (isLogin) return;

                if (!accountModel.practices.length > 0) {
                  setErrorMessage("Please add a practice");
                  setSubmitting(false);
                  return;
                }

                registerNewUser(values);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <AddPracticeDialog
                  practices={practices}
                  open={open}
                  onClose={handleClose}
                  onAddPractice={handleAddPractice}
                />
                <Grid
                  container
                  xs={12}
                  md={10}
                  mx="auto"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        backgroundColor: brandLight,
                        width: "70vw",
                      }}
                    >
                      {isLogin && <LinearProgress />}
                      <form onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            width: "85%",
                            margin: "0 auto",
                            paddingTop: "50px",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "28px",
                              lineHeight: "41px",
                              color: "#000",
                            }}
                          >
                            <b>Sign-up</b> to start sending today.
                          </Box>
                          <Box
                            sx={{
                              fontWeight: "300",
                              fontSize: "18px",
                              lineHeight: "26px",
                              marginBottom: "48px",
                              color: "#585656",
                            }}
                          >
                            We make digital dentistry easy
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ marginBottom: "15px" }}>
                                <TextField
                                  sx={{ width: "100%", marginBottom: "15px" }}
                                  id="firstName"
                                  name="firstName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstName}
                                  label="First name"
                                  variant="outlined"
                                  disabled={isLogin || isFromSSO}
                                  Autocomplete="off"
                                />
                                <Box sx={{ color: "red" }}>
                                  {errors.firstName &&
                                    touched.firstName &&
                                    errors.firstName}
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box sx={{ marginBottom: "15px" }}>
                                <TextField
                                  sx={{ width: "100%", marginBottom: "15px" }}
                                  id="lastName"
                                  name="lastName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                  label="Last name"
                                  variant="outlined"
                                  disabled={isLogin || isFromSSO}
                                  Autocomplete="off"
                                />

                                <Box sx={{ color: "red" }}>
                                  {errors.lastName &&
                                    touched.lastName &&
                                    errors.lastName}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                              <Box sx={{ marginBottom: "15px" }}>
                                <TextField
                                  sx={{ width: "100%", marginBottom: "15px" }}
                                  id="email"
                                  type="email"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  label="Email Address"
                                  variant="outlined"
                                  disabled={isLogin || user.email || isFromSSO}
                                  Autocomplete="off"
                                />

                                <Box sx={{ color: "red" }}>
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box sx={{ marginBottom: "15px" }}>
                                <TextField
                                  sx={{ width: "100%", marginBottom: "15px" }}
                                  select
                                  id="role"
                                  name="role"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.role}
                                  label="Role"
                                  variant="outlined"
                                  disabled={
                                    isLogin ||
                                    initialRoles.length < roles.length
                                  }
                                >
                                  {roles.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                <Box sx={{ color: "red" }}>
                                  {errors.role && touched.role && errors.role}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          {!isFromSSO && (
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <Box sx={{ marginBottom: "15px" }}>
                                  <TextField
                                    sx={{ width: "100%", marginBottom: "15px" }}
                                    id="password"
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    label="Password"
                                    variant="outlined"
                                    disabled={isLogin}
                                    Autocomplete="off"
                                  />

                                  <Box sx={{ color: "red" }}>
                                    {errors.password &&
                                      touched.password &&
                                      errors.password}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Box sx={{ marginBottom: "15px" }}>
                                  <TextField
                                    sx={{ width: "100%", marginBottom: "15px" }}
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.confirmPassword}
                                    label="Confirm Password"
                                    variant="outlined"
                                    disabled={isLogin}
                                    Autocomplete="off"
                                  />

                                  <Box sx={{ color: "red" }}>
                                    {errors.confirmPassword &&
                                      touched.confirmPassword &&
                                      errors.confirmPassword}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          <Box sx={{ marginBottom: "15px" }}>
                            <TextField
                              sx={{ width: "100%", marginBottom: "15px" }}
                              select
                              id="learnAboutUs"
                              name="learnAboutUs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.learnAboutUs}
                              label="How did you hear about us?"
                              variant="outlined"
                              disabled={isLogin}
                            >
                              {learnAboutUsOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Box sx={{ color: "red" }}>
                              {errors.learnAboutUs &&
                                touched.learnAboutUs &&
                                errors.learnAboutUs}
                            </Box>
                          </Box>

                          {values.learnAboutUs === "Other" && (
                            <Grid container>
                              <Grid item xs={12} md={12}>
                                <Box sx={{ marginBottom: "15px" }}>
                                  <TextField
                                    sx={{ width: "100%", marginBottom: "15px" }}
                                    id="learnAboutUsOthers"
                                    name="learnAboutUsOthers"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.learnAboutUsOthers}
                                    label="Please specify"
                                    variant="outlined"
                                    disabled={isLogin}
                                  />

                                  <Box sx={{ color: "red" }}>
                                    {errors.learnAboutUsOthers &&
                                      touched.learnAboutUsOthers &&
                                      errors.learnAboutUsOthers}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}

                          {!Object.keys(user).length > 0 && (
                            <Grid container>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpen}
                                >
                                  Add Practice Details
                                </Button>
                                <Box sx={{ color: "red" }}>
                                  {errors.practices &&
                                    touched.practices &&
                                    errors.practices}
                                </Box>
                              </Grid>
                            </Grid>
                          )}

                          {/* TODO: Multiple Practice// Add Practice */}

                          <Grid container xs={12} m={3} spacing={4}>
                            {accountModel.practices !== null &&
                              accountModel.practices.map((prac, index) => {
                                return (
                                  <>
                                    <Card
                                      sx={{ minWidth: 275, margin: "10px" }}
                                      key={index}
                                      onChange={handleChange}
                                      value={values.practices}
                                    >
                                      <CardContent>
                                        <Typography
                                          variant="h5"
                                          component="div"
                                        >
                                          {prac.name}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Address Line 1: {prac.address.Line1}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Address Line 2: {prac.address.Line2}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Country: {prac.address.Country}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          State/Province: {prac.address.State}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Zip/Postal: {prac.address.Postal}
                                        </Typography>
                                      </CardContent>
                                      {!user.practiceId && (
                                        <CardActions>
                                          <Button
                                            size="small"
                                            onClick={() =>
                                              removePractice(index)
                                            }
                                          >
                                            Remove
                                          </Button>
                                        </CardActions>
                                      )}
                                    </Card>
                                  </>
                                );
                              })}
                          </Grid>
                          <Grid item xs={12} sx={{ marginY: 4 }}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                value={values.acceptTos}
                                onChange={handleChange}
                                name="acceptTos"
                                label={
                                  <Box>
                                    By proceeding, I agree to evident's{" "}
                                    <Link
                                      href="/docs/Evident_-_HIPAA_BAA.pdf"
                                      target="blank"
                                      underline="hover"
                                    >
                                      Business Associate Agreement
                                    </Link>{" "}
                                    and our{" "}
                                    <Link
                                      href="/docs/Evident_-_HUB_T&Cs.pdf"
                                      target="blank"
                                      underline="hover"
                                    >
                                      Terms of Use.
                                    </Link>
                                  </Box>
                                }
                              />
                              <Box sx={{ color: "red" }}>
                                {errors.acceptTos &&
                                  touched.acceptTos &&
                                  errors.acceptTos}
                              </Box>
                            </FormGroup>
                          </Grid>

                          <Button
                            disabled={isLogin}
                            sx={{
                              width: "100%",
                              height: "57px",
                              color: "#fff",
                              marginBottom: "15px",
                              background: values.acceptTos
                                ? brandColor
                                : brandGray,
                              borderRadius: "29px",
                              "&:hover": {
                                background: values.acceptTos
                                  ? brandColor
                                  : brandGray,
                              },
                            }}
                            type="submit"
                          >
                            Sign up
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}

export default SignUp2;
