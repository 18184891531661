import React, { useState,useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";

import Main from "../views/main/main";
import Patient from "../views/patient/patient";
import Patients from "../views/patients/patients";

import Cases from "../views/caseTracker/cases";
import Case from "../views/caseTracker/case";
import ResetPassword from "../views/resetPassword/resetPassword";
import Partnership from "../views/partnership/partnership";
import Labs from "../views/labs/labs";
import Users from "../views/users/users";
import InvitedLab from "../views/invitedLab/invitedLab";
import ConfirmUser from "../views/confirmUser/confirmUser";
import SignUp2 from "../components/account/SignUp2";
import PaymentDetails from "../views/paymentDetails/paymentDetails";

import DesignPreferences from "../views/designPreferences/designPreferences";
import Profile from "../views/profile/profile";
import LoggedInRoutes from "./loggedInRoutes";
import GuestRoutes from "./guestRoutes";
import Login from "../views/login/login";
import ForgotPassword from "../views/forgotPassword/forgotPassword";
import Videos from "../views/videos/videos";
import SignUpSSO from "../components/account/signupsso";
import ChangePasswordModal from "../components/account/changePasswordModal";
import useSegment from "../hooks/useSegment";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientId } from "../redux/actions";
import SessionExpire from "../views/logout/logout";

export default function AppRoutes() {
  const [currentLocation,setCurrentLocation] = useState('');
  const location = useLocation(), dispatch = useDispatch(), segment = useSegment();

  useEffect(()=>{
    if(location.pathname !== currentLocation){
      setCurrentLocation(location.pathname);
      segment.trigger({event:'page'});
    }
  },[location]);

  useEffect(()=>{
    if(currentLocation !== '/'){
      dispatch(updatePatientId({patientId: null}))
    }
  },[currentLocation]);
  return (
    <Routes>
      <Route key={8} exact path="/" element={<Main />} />
      <Route path="/sessionexpire" element={<SessionExpire />} />
      <Route path="/" element={<LoggedInRoutes />}>
        <Route key={1} path="/prebook/:prebookId" element={<Main noLayout />} />
        <Route key={3} path="/patients" element={<Patients />} />
        {["/patients/:patientId", "/patients/:patientId/:fileType"].map(
          (path) => (
            <Route key={path} path={path} element={<Patient />} />
          )
        )}
        <Route key={4} path="/cases" element={<Cases />} />
        {["/cases/:caseId", "/cases/:caseId/:activityNav"].map((path) => (
          <Route key={2} path={path} element={<Case />} />
        ))}
        <Route path="/labs" element={<Labs />} />
        <Route path="/users" element={<Users />} />
        <Route path="/paymentDetails" element={<PaymentDetails />} />
        <Route path="/designpreferences" element={<DesignPreferences />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/videos" element={<Videos />} />
        
      </Route>
      
      <Route path="/" element={<GuestRoutes />}>
        <Route path="signup" element={<SignUp2 />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="account/confirmuser" element={<ConfirmUser />} />
        <Route path="signupsso" element={<SignUpSSO />} />
          </Route>
          <Route path="account/setpassword" element={<Partnership />} />
      <Route path="account/resetpassword" element={<ResetPassword />} />
      <Route path="account/invitedlab" element={<InvitedLab />} />
      <Route key={5} path="/:section" element={<Main />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}


