import React from "react";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { IconButton, Checkbox, Button } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";


function AdditionalFilesModal({requiredFiles, isOpen, handleClose, mappedFiles,}) {

  const additionalFiles = requiredFiles.find(section => section.name === "Additional Files");
  const additionalFilesId = additionalFiles?.files.map(file => file.patientFileId);

    const closeDialog = (confirm) => {        
        handleClose(confirm, selectedFiles);
        setSelectedFiles([]);
    }
    const [selectedFiles,setSelectedFiles] = React.useState([]);
    
    const handleChange = (file,isChecked) => {
        console.log("selectedFiles",selectedFiles);        
        let copySF = Object.assign([], selectedFiles);
        if(isChecked){
            copySF = copySF.concat(file);
        }
        else{
            copySF = copySF.filter(x=> x.id!==file.id);
        }
        
        setSelectedFiles(copySF);
    };

    const filteredMappedFiles = [];
    mappedFiles.forEach(section => {
      const newSection = {
        ...section,
        files: section.files.filter(f => !additionalFilesId.includes(f.id))
      }
      if (newSection.files.length > 0) filteredMappedFiles.push(newSection);
    })


    return (
        <Dialog open={isOpen} maxWidth="sm" fullWidth sx={{marginLeft: '25px', marginTop: '35px'}}>
        <Box sx={{textAlign:'right', marginLeft: '25px'}}>
            <IconButton onClick={() => closeDialog(false)}>
                <CloseIcon />
            </IconButton>
        </Box>
        <FormGroup>
        {filteredMappedFiles         
        .sort((a, b) =>
        a.fileType < b.fileType ? -1 : 1)
         .map((mf) =>
            
       {
        return (
(
                <Box sx={{marginLeft:"25px"}}>
                <Box><h2>{mf.fileType === "Cbct Scan" ? "CBCT Scan" : mf.fileType }</h2></Box>
                {mf.files
                .sort((a, b) =>
                a.uploadedDate < b.uploadedDate ? 1 : -1)
                .map((file)=> 
                    (
                        <Box sx={{marginLeft:"15px"}}>
                            
                        <Checkbox key={file.id} onChange={(e) => {
                            handleChange(
                                file,
                                e.target.checked,
                                
                            );
                            }}/>{file.fileName} - Uploaded: {moment(file.uploadedDate).format('lll')}
  
                            
                        </Box>
                    )
                
                )
                }
                </Box>
                
            )
        )
       } 
         )
        }
        <Box sx={{textAlign:"center", marginTop:"20px"}}>
            <Button onClick={() => closeDialog(true)}  sx={{color: '#fff', marginBottom: '15px', background: ' linear-gradient(90.3deg, #11C984 0.26%, #32BE60 100%, #21713B 100%)', boxShadow: '0px 2px 17px rgba(42, 193, 106, 0.37)', borderRadius: '29px', width: '481px', height: '57px' }}>
                    Add to Case
            </Button>
        </Box>

        </FormGroup>
        </Dialog>
    )
}

export default AdditionalFilesModal;