import { Box, Button, CircularProgress } from "@mui/material"
import React from 'react'

const LoadingButton = ({ isLoading, onClick, children }) => {
  return (
    <Button size="small" variant="contained" onClick={onClick}>
      {isLoading && (
        <CircularProgress
          size={19}
          sx={{ position: "absolute", color: "white" }}
        />
      )}
      <Box as="span" sx={{ visibility: isLoading ? "hidden" : "visible" }}>
        { children }
      </Box>
    </Button>
  )
}

export default LoadingButton