const getAuthorizedFetchOptions = (method, body = null) => {
  const options = {
    method: method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
    },
  };

  return options;
};

export { getAuthorizedFetchOptions };