import { FILE_TYPES } from "../constants/fileTypes";
import { BlobServiceClient } from "@azure/storage-blob";
import FileUploaderService from "./fileUploader.service";
import { getInputAdornmentUtilityClass } from "@mui/material";
import DigitalPrescription from "../components/patient/digitalPrescription";
const _apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];

const PatientService = {
    getPatientList: function (userId) {
    const body = {
        PersonnelId: userId,        
    };
    console.log("userId1",userId);
    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "patient/patientslistbypersonnel",
      data
        );

       
  },
  getAllMappedFiles: function (patiendId) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "case/getallmappedfiles?patientId=" + patiendId,
      data
    );
  },
  getPatientFileExtension: function (patientFileId, patientId, fileType) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl +
        "patientfile/getpatientfileextension?patientFileId=" +
        patientFileId +
        "&patientId=" +
        patientId +
        "&fileType=" +
        fileType,
      data
    );
  },
  addPatient: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patient/new", data);
  },
  uploadPatientFile: function (model, type) {
    let path;
    let extra = {};
    switch (type) {
      case "Clinical Photography":
        path = "uploadclinicalext";
        extra = {
          FileSetSubType: 29, // generic set photos
          FileSetType: 4, // photo set type other
        };
        break;
      case "IOS Scan (STL Files) (Upper, Lower and Bite)":
        path = "uploadiosext";
        extra = {
          FileSetType: 3, // ios type other
        };
        break;
      case "Cbct Scan":
        path = "uploadcbct";
        break;
      default:
        path = "uploadotherdoc";
    }

    let data = {
      method: "POST",
      body: JSON.stringify({
        ...model,
        ...extra,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patientfile/" + path, data);
  },
  uploadPatientFileV3: function (model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patientfile/uploadotherdocv3", data);
  },
  getOldPatientPageLink: function (id, loginId) {
    return (
      process.env.REACT_APP_WEB_REDIRECT.split("=")[1] +
      "DentistDashboard/myPatientDetail/" +
      id +
      "?ver=3&lgid=" +
      loginId
    );
  },
  getPatientAndCases: function ({
    personnelId,
    practiceId,
    name = "",
    skip = 0,
    take = 20,
  }) {
    const body = {
      PersonnelId: personnelId,
      PracticeId: practiceId,
      Skip: skip,
      Take: take,
      PatientNameFilter: name,
      FromDate: "",
      ToDate: "",
      StatusFilter: {
        All: true,
        Sent: false,
        InProgress: false,
        Shipped: false,
        Received: false,
        Completed: false,
        NoActiveCase: false,
        Consultation: false,
      },
      OrderBy: {
        PatientNameAscending: false,
        PatientNameDescending: false,
        AppointmentDateAscending: false,
        AppointmentDateDescending: false,
        ReturnDateAscending: false,
        ReturnDateDescending: false,
        UpdatedDateAscending: false,
        UpdatedDateDescending: true,
      },
    };

    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patient/patientandcaseslist", data);
  },

  getBasicPatientInfo: function (patientId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patient/getbasicpatientinfo?id=" + patientId, data);
  },
  getPatientFiles: function ({ patientId, type, skip, take }) {
    const fileTypeNumber = FILE_TYPES[type];
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl +
        "patientfile/getpatientfiles?" +
        `patientId=${patientId}` +
        `&fileType=${fileTypeNumber}` +
        `&skip=${skip}` +
        `&take=${take}`,
      data
    );
  },
  getPatientCompletedDesigns: function ({
    patientId,
    personnelId,
    skip = 0,
    take = 10,
  }) {
    const data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(
      _apiUrl +
        `patient/getcasesandcompletedesign?patientId=${patientId}&personnelId=${personnelId}&skip=${skip}&take=${take}`,
      data
    );
  },
  getPatientFilePath: async function (filePath, isExternal, fileName) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    const path = isExternal ? "getexternalfilesas" : "getfilesas";

    let url = _apiUrl + `Blob/${path}?url=` + filePath;
    if (fileName) {
      url = url + "&fileName=" + encodeURIComponent(fileName.trim());
    }

    try {
      const response = await fetch(
        url,
        data
      );
      const downloadPath = await response.json();
      return downloadPath;
    } catch (err) {
      console.log(err);
    }
  },
  getCasesByPersonnel: function ({
    personnelId,
    practiceId,
    search = "",
    statusFilter = "",
    skip = 0,
    take = 20,
  }) {
    const body = {
      PersonnelId: personnelId,
      PracticeId: practiceId,
      Search: search,
      statusFilter: statusFilter,
      Skip: skip,
      Take: take,
    };

    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patient/gettreatmentcasesbypersonnel", data);
  },
  uploadFilesByType: async ({ files, type, patientId, extraOptions }) => {
    const uploadFilePromises = files.map(async (file) => {
      const guid = FileUploaderService.generateGuid();
      const paths = {
        intraoralScans: "uploadiosext",
        clinicalPhotos: "uploadclinicalext",
        cbctImages: "uploadcbct",
        completedSurgicalPlan: "uploadcompletedsurgicalplan",
        digitalPrescription: "uploadlabcomm",
        otherDocs: "uploadotherdoc",
      };
      const fileName = file.path;
      const path = `patient/${patientId}/${guid}`;

      await FileUploaderService.uploadFileAsync(file, path);

      const data = {
        method: "POST",
        body: JSON.stringify({
          FileId: guid,
          FileName: fileName,
          PatientId: patientId,
          ...extraOptions,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      };
      const uploadResponse = await fetch(
        _apiUrl + `patientfile/${paths[type]}`,
        data
      );
      const uploadData = await uploadResponse.json();

      return uploadData;
    });

    const filesData = await Promise.all(uploadFilePromises);
    return filesData;
  },
  uploadFileSet: async (fileSetData, type) => {
    const fileSet = {
      ...fileSetData,
      FileType: FILE_TYPES[type],
    };

    const data = {
      method: "POST",
      body: JSON.stringify(fileSet),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    const paths = {
      intraoralScans: "uploadintraoralscan",
      clinicalPhotos: "uploadclinicalphotography",
    };

    const response = await fetch(_apiUrl + `patientfile/${paths[type]}`, data);
    const setData = response.json();
    return setData;
  },
  editPatientDetails: async function (body, profilePicture) {
    if (profilePicture !== null) {
      const { profilePictureFileName, profilePictureUrl } =
        await FileUploaderService.uploadPatientImage({
          patientId: body.patientId,
          file: profilePicture,
          fileExtension: profilePicture.name.split(".").pop(),
        });
      body = {
        ...body,
        profilePictureFileName,
        profilePictureUrl,
      };
    }

    const data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `patient/editpatient`, data);
  },
  getcasesofpatient: function (patientId) {
    const data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(
      _apiUrl + `patient/getcasesofpatient?patientId=${patientId}`,
      data
    );
  },
  getPatientAndCasesByLocker: function ({
    lockerIds,
    name = "",
    skip = 0,
    take = 20,
  }) {
    const body = {
      LockerIds: lockerIds,
      Skip: skip,
      Take: take,
      PatientNameFilter: name,
      FromDate: "",
      ToDate: "",
      StatusFilter: {
        All: true,
        Sent: false,
        InProgress: false,
        Shipped: false,
        Received: false,
        Completed: false,
        NoActiveCase: false,
        Consultation: false,
      },
      OrderBy: {
        PatientNameAscending: false,
        PatientNameDescending: false,
        AppointmentDateAscending: false,
        AppointmentDateDescending: false,
        ReturnDateAscending: false,
        ReturnDateDescending: false,
        UpdatedDateAscending: false,
        UpdatedDateDescending: true,
      },
    };

    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "patient/patientandcaseslistbylocker", data);
  },
};

export default PatientService;
