import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import {
  brandBlue,
  brandColor,
  brandGray,
  brandOrange,
  brandRed,
} from "../../../constants";

const StyledButton = ({
  children,
  variant = "outlined",
  fullWidth = false,
  loading = false,
  ...props
}) => {
  const variants = {
    outlined: {
      color: brandBlue,
      background: "none",
      border: "2px solid " + brandBlue,
    },
    secondary: {
      bgColor: brandBlue,
      color: "#F8F8F8",
    },
    primary: {
      background: brandColor,
      color: "#F8F8F8",
    },
    gray: {
      bgColor: brandGray,
      color: "#F8F8F8",
    },
    red: {
      bgColor: brandRed,
      color: "#fff",
    },
    orange: {
      bgColor: brandOrange,
      color: "#f8f8f8",
    },
  };

  const style = variants[variant];

  return (
    <Box
      component="button"
      disabled={loading}
      sx={{
        textTransform: "none",
        fontWeight: "600",
        color: style.color,
        paddingX: 6,
        paddingY: loading ? 1 : 2,
        opacity: loading ? 0.4 : 1,
        cursor: loading ? "not-allowed" : "pointer",
        background: style.background,
        backgroundColor: style.bgColor,
        border: style.border || "2px solid transparent",
        borderRadius: "30px",
        "&:hover": {
          background: style.background,
        },
        width: fullWidth ? "100%" : undefined,
        textDecoration: "none",
      }}
      {...props}
    >
      <Typography fontWeight="600" fontSize="15px">
        {loading ? (
          <CircularProgress size={30} sx={{ color: "white" }} />
        ) : (
          children
        )}
      </Typography>
    </Box>
  );
};

export default StyledButton;
