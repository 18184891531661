import React from "react";

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { IconButton } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import scanFileIcon from "../../../../images/scanfileicon.svg";
import infoIcon from "../../../../images/info_icon.svg";

function RequiredFilesModal({isOpen, handleClose}) {

    const closeDialog = () => {
        handleClose();
    }

    return (
        <Dialog open={isOpen} maxWidth="sm" fullWidth sx={{textAlign: 'center'}} PaperProps={{style:{padding:'15px'}}}>
            <Box sx={{textAlign:'right', marginRight: '25px', marginTop: '35px'}}>
                <IconButton onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box>
                <img src={infoIcon} style={{width:'115px'}}/>
            </Box>

            <Box sx={{textAlign:'center', fontWeight: '700', marginTop:'30px'}}>
                Requirement Scan Files for Diagnostic Wax-Up
            </Box>

            <Box sx={{textAlign:'center', fontWeight: '400'}}>
                If you're unsure of the details you wanted to include, here are some essential items that might help:
            </Box>

            <Box sx={{display:'flex', marginTop:'30px', padding:'15px', background: '#F7F7F7'}}>
                <img src={scanFileIcon} />

                <Box sx={{marginLeft:'10px'}}>
                    <Box sx={{fontSize:'21px', fontWeight: '500', color:'#0F0F0F'}}>Maxillary Scan</Box>
                    <Box sx={{fontSize:'15px', fontWeight: '400', color:'#0F0F0F', textAlign:'left'}}>A Clean STL File.</Box>
                </Box>             
            </Box>

            <Box sx={{display:'flex', marginTop:'30px', padding:'15px', background: '#F7F7F7'}}>
                <img src={scanFileIcon} />

                <Box sx={{marginLeft:'10px'}}>
                    <Box sx={{fontSize:'21px', fontWeight: '500', color:'#0F0F0F'}}>Mandibular Scan</Box>
                    <Box sx={{fontSize:'15px', fontWeight: '400', color:'#0F0F0F', textAlign:'left'}}>A Clean STL File.</Box>
                </Box>             
            </Box>

            <Box sx={{display:'flex', marginTop:'30px', padding:'15px', background: '#F7F7F7'}}>
                <img src={scanFileIcon} />

                <Box sx={{marginLeft:'10px'}}>
                    <Box sx={{fontSize:'21px', fontWeight: '500', color:'#0F0F0F'}}>Bite Scan</Box>
                    <Box sx={{fontSize:'15px', fontWeight: '400', color:'#0F0F0F', textAlign:'left'}}>A Clean STL File.</Box>
                </Box>             
            </Box>

        </Dialog>
    )
}

export default RequiredFilesModal;