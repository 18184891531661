import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

const DateInput = ({ label, name, value, onChange, helperText, error }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography
          component="label"
          htmlFor="bootstrap-input"
          sx={{ fontWeight: "bold", color: "#37465D" }}
        >
          {label}
        </Typography>
        <DatePicker
          disableFuture
          value={value}
          onChange={(newValue) => onChange(newValue)}
          renderInput={(params) => (
            <TextField
              fullWidth
              sx={{ marginTop: 1 }}
              name={name}
              error={error}
              helperText={helperText}
              FormHelperTextProps={{ variant: "standard" }}
              {...params}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateInput;
