import { Alert, AlertTitle, Button } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { brandLight } from "../../constants";

const EmptyAlert = ({ resource, backPath }) => {
  const [params] = useSearchParams();
  const page = params.get("page");
  const navigate = useNavigate();

  return (
    <Alert
      severity="warning"
      sx={{
        textAlign: "left",
        borderRadius: "15px",
        backgroundColor: brandLight,
        color: "#000",
        [`& .MuiAlert-icon`]: {
          color: "#000",
        },
      }}
    >
      <AlertTitle>
        <strong>No {resource}</strong>
      </AlertTitle>
      {page
        ? "No " + resource + " available for the given page"
        : "No " + resource + " available"}
      {page && (
        <Button onClick={() => navigate(-1)} sx={{ marginLeft: 1 }}>
          Go back
        </Button>
      )}
    </Alert>
  );
};

export default EmptyAlert;
