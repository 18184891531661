import { Check } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  LinearProgress,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import PatientService from "../../services/patient.service";
import DialogContainer from "../common/dialogContainer";
import { saveAs } from "file-saver";
import StyledButton from "../common/button/largeButton";

function startAndEnd(str) {
  if (str.length > 35) {
    return str.substr(0, 20) + "..." + str.substr(str.length - 10, str.length);
  }
  return str;
}

const DownloadAllFilesDialog = ({ files, caseRefNo, onClose }) => {
  const [periods, setPeriods] = useState("...");
  const [downloadedFiles, setDownloadedFiles] = useState([]);

  useEffect(() => {
    const periodInterval = setInterval(() => {
      setPeriods((prev) => (prev === "....." ? "." : prev + "."));
    }, 1415);

    return () => {
      clearInterval(periodInterval);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const zipFiles = async () => {
      const promises = files.map(async (file) => {
        const blobPath = await PatientService.getPatientFilePath(
          file.path,
          false,
          file.fileName
        );
        const blobResponse = await fetch(blobPath);
        const blob = await blobResponse.blob();

        if (isMounted) {
          setDownloadedFiles((prev) => [...prev, file.path]);
          saveAs(blob, file.fileName, { });
        }
      });

      const fileBlobs = await Promise.all(promises);

      if (isMounted) {
        onClose();
      }
      // const zipBlob = await zip.generateAsync({ type: "blob" });
      // if (isMounted) {
      //   saveAs(zipBlob, `${caseRefNo || "download"}.zip`);
      //   onClose();
      // }
    };

    zipFiles();
    return () => {
      isMounted = false;
    };
  }, [files, onClose, caseRefNo]);
  let progress = (downloadedFiles.length / files.length) * 100;
  if (progress === Infinity) {
    progress = 0;
  }

  console.log(progress);

  return (
    <DialogContainer open>
      <Typography component="h2" variant="h6" textAlign="center">
        Download All
      </Typography>

      <Stack spacing={1} marginTop={4}>
        {files.map((file) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color={grey[700]}>
              {startAndEnd(file.fileName)}
            </Typography>
            {downloadedFiles.includes(file.path) ? (
              <Check color="brand" />
            ) : (
              <CircularProgress size={20} />
            )}
          </Stack>
        ))}
      </Stack>

      <Stack
        direction="row"
        sx={{ marginTop: 3 }}
        alignItems="center"
        spacing={1}
      >
        <Box width="100%">
          <LinearProgress
            value={progress}
            variant="determinate"
            sx={{height: "6px"}}
            color="brand"
          />
        </Box>
        <Typography fontSize="14px">{Math.round(progress)}%</Typography>
      </Stack>

      <Box
        marginTop={2}
        marginBottom={2}
        bgcolor={grey[100]}
        padding={3}
        borderRadius="15px"
      >
        <Typography color={grey[700]} fontWeight="500">
          Downloading all your files please wait {periods}
        </Typography>
      </Box>
      <Button fullWidth size="large" color="brand" onClick={onClose}>
        Cancel
      </Button>
    </DialogContainer>
  );
};

export default DownloadAllFilesDialog;
