export const learnAboutUsOptions = [
  "Google",
  "Facebook",
  "Instagram",
  "Linkedin",
  "Webinar",
  "Youtube",
  "Newsletter",
  "Blog",
  "Email",
  "Referral",
  "Kois Center",
  "Other",
];
