import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import AccountService from "../../services/account.service";
import { brandColor } from "../../constants";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { forgotPassword } = AccountService;

  const onChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (email !== "") {
        forgotPassword(email);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Box sx={{ fontSize: "28px", lineHeight: "41px", color: "#000" }}>
        <b>Forgot Password</b>
      </Box>
      <Box
        sx={{
          fontWeight: "300",
          fontSize: "18px",
          lineHeight: "26px",
          marginTop: 2,
          marginBottom: "48px",
          color: "#585656",
        }}
      >
        No problem. Enter the email address associated with your account and
        we'll send you a link to reset your password.
      </Box>
      <form>
        <Box sx={{ width: "85%", margin: "0 auto" }}>
          <Box sx={{ marginBottom: "15px" }}>
            <TextField
              sx={{ width: "100%", marginBottom: "15px" }}
              id="email"
              type="email"
              name="email"
              onChange={onChange}
              value={email}
              label="Your Email"
              variant="outlined"
              required
            />
            <Box sx={{ color: "red" }}></Box>
          </Box>

          <Button
            sx={{
              color: "#fff",
              marginBottom: "15px",
              background: brandColor,
              "&:hover": {
                backgroundColor: brandColor,
              },
              borderRadius: "29px",
              width: "100%",
              height: "57px",
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Send Password Reset Link
          </Button>
          {showAlert && (
            <Box
              sx={{
                fontWeight: "300",
                fontSize: "18px",
                lineHeight: "26px",
                padding: "0 100px",
                marginBottom: "48px",
                color: "#585656",
              }}
            >
              <b>Password reset email sent.</b>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
}

export default ForgotPassword;
