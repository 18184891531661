import {
  Skeleton as MUISkeleton,
} from "@mui/material";

const RoundedSkeleton = ({sx, ...props}) => {
  return (
    <MUISkeleton variant="rectangular" sx={{borderRadius: 2, ...sx}} {...props}/>
  )
}

export default RoundedSkeleton;