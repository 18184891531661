import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import MenuItem from "./menuItem";

const MenuLink = ({ active, to, icon, title }) => {
  return (
    <MenuItem active={active} component={Link} to={to}>
      {icon}
      <Typography as="span" sx={{ marginLeft: 1, fontWeight: "400" }}>
        {title}
      </Typography>
    </MenuItem>
  );
};

export default MenuLink;
