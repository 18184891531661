import { useSearchParams } from "react-router-dom";

const usePaginationValues = (countPerPage, total) => {
  const [params, setParams] = useSearchParams();
  const page = parseInt(params.get("page")) || 1;
  const skip = page ? page * countPerPage - countPerPage : 0;
  const pagesCount = Math.ceil(total / countPerPage);

  const handleChangePage = (page) => {
    window.scrollTo(0, 0);
    setParams({
      page: page,
    });
  };

  return {
    currentPage: page,
    skip,
    pagesCount,
    handleChangePage,
  };
};

export default usePaginationValues;
