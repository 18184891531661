import React from "react";
import { Box } from "@mui/system";
import { Checkbox } from "@mui/material";

function FilesNeededForSmilePackageComponent(props) {
  const { req, handleRequirementsSelectList } = props;
  return (
    <Box sx={{}}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "15px" }}>
          <Checkbox
            checked={req.value.indexOf("Diagnostic Wax Up") >= 0}
            onChange={(e) => {
              handleRequirementsSelectList(
                req.description,
                "Diagnostic Wax Up",
                e.target.checked,
                1002
              );
            }}
          />
        </Box>
        <Box>Diagnostic Wax Up</Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "15px" }}>
          <Checkbox
            disabled={
              req.value.indexOf(
                "Virtually prepped shell temps (with a 0.6 mm virtual prep)"
              ) >= 0
            }
            checked={
              req.value.indexOf(
                "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)"
              ) >= 0
            }
            onChange={(e) => {
              handleRequirementsSelectList(
                req.description,
                "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)",
                e.target.checked,
                1054
              );
            }}
          />
        </Box>
        <Box>
          Trial Smile (One-piece veneer style to try in over the patient's
          existing teeth)
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "15px" }}>
          <Checkbox
            disabled={
              req.value.indexOf(
                "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)"
              ) >= 0
            }
            checked={
              req.value.indexOf(
                "Virtually prepped shell temps (with a 0.6 mm virtual prep)"
              ) >= 0
            }
            onChange={(e) => {
              handleRequirementsSelectList(
                req.description,
                "Virtually prepped shell temps (with a 0.6 mm virtual prep)",
                e.target.checked,
                1234
              );
            }}
          />
        </Box>
        <Box>Virtually prepped shell temps (with a 0.6 mm virtual prep)</Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "15px" }}>
          <Checkbox
            checked={
              req.value.indexOf(
                "Prepped shell temps (chairside prep and IOS scan)"
              ) >= 0
            }
            onChange={(e) => {
              handleRequirementsSelectList(
                req.description,
                "Prepped shell temps (chairside prep and IOS scan)",
                e.target.checked,
                1235
              );
            }}
          />
        </Box>
        <Box>Prepped shell temps (chairside prep and IOS scan)</Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "15px" }}>
          <Checkbox
            checked={req.value.indexOf("Shell temps (un-prepped model)") >= 0}
            onChange={(e) => {
              handleRequirementsSelectList(
                req.description,
                "Shell temps (un-prepped model)",
                e.target.checked,
                1012
              );
            }}
          />
        </Box>
        <Box>Shell temps (un-prepped model)</Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "15px" }}>
          <Checkbox
            checked={req.value.indexOf("Final restorations") >= 0}
            onChange={(e) => {
              handleRequirementsSelectList(
                req.description,
                "Final restorations",
                e.target.checked,
                1005
              );
            }}
          />
        </Box>
        <Box>Final restorations</Box>
      </Box>
    </Box>
  );
}

export default FilesNeededForSmilePackageComponent;
