import React from "react";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import HtmlContentChecker from "./htmlContentChecker";

function ProductDetailModal({
  isOpen,
  handleCloseDialog,
  treatmentDetailToDisplay,
  productDetail,
}) {
  const [treatmentToDisplay, setTreatmentToDisplay] = React.useState(
    treatmentDetailToDisplay
  );
  const [productDetailToDisplay, setProductDetailToDisplay] =
    React.useState(productDetail);

  React.useEffect(() => {
    setTreatmentToDisplay(treatmentDetailToDisplay);
    productDetail.forEach((x) => {
      if (
        x.treatmentCategoryId === treatmentDetailToDisplay.treatmentCategoryId
      ) {
        x.products.forEach((y) => {
          if (
            y.treatmentTypeId === treatmentDetailToDisplay.id &&
            y.description !== null &&
            y !== ""
          ) {
            setProductDetailToDisplay(y);
          }
        });
      }
    });
  }, [isOpen]);

  const closeDialog = () => {
    handleCloseDialog(false, treatmentDetailToDisplay);
  };

 
  const htmlContent =  treatmentDetailToDisplay.description + productDetailToDisplay.description;
  
  return (
    <>
      {treatmentDetailToDisplay != "" ? (
        <Dialog
          id="treatmentDetail"
          onClose={closeDialog}
          open={isOpen}
          maxWidth="md"
          fullWidth
       
        >
          <Box sx={{ textAlign: "right", marginLeft: "25px" }}>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ marginLeft: "25px !important" }}>
          <h2 style={{fontSize: '24px', fontWeight: 500, textAlign: 'center', marginTop: "0", marginBottom: '30px'}}>About Treatment</h2>
          </Box>
          <Box sx={{ marginX: "25px", marginBottom: "25px" }}>
         
            {/* <h3>What is it?</h3> */}
            {/* <div
              dangerouslySetInnerHTML={{
                __html: productDetailToDisplay.description,
              }}
            /> */}
            <HtmlContentChecker htmlContent={htmlContent} />
          </Box>
          
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}

export default ProductDetailModal;
