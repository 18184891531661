import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/material";

const HtmlPreview = ({ link }) => {
  return (
    <Box
      component="iframe"
      id="html-preview"
      src={link}
      sx={{ width: "100%", height: "480px", borderRadius: "20px" }}
    />
  );
};

export default HtmlPreview;
