import * as yup from "yup";

export const practiceSchema = yup.object({
  customerRefId: yup.string().nullable(),
  id: yup.string().nullable(),
  name: yup.string("Enter a valid name").required("Name is required"),
  phone: yup
    .string("Enter a valid phone number")
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "Please enter a valid number [0-9]"
    )
    .min(5, "Phone number must be 5 characters or longer")
    .required("Phone number is required"),
  specialty: yup.object({
    familyDentistry: yup.boolean(),
    pediatricDentistry: yup.boolean(),
    orthodonticsAligners: yup.boolean(),
    implants: yup.boolean(),
    prosthodontics: yup.boolean(),
  }),
  address: yup.object({
    Line1: yup.string().required("Address Line 1 is required"),
    Line2: yup.string().nullable(),
    City: yup.string().required("City is required"),
    State: yup.string().required("State is required"),
    Country: yup.string().required("Country is required"),
    Postal: yup.string().required("Postal is required"),
  }),
  equipment: yup.object({
    hasIos: yup.boolean(),
    hasCbct: yup.boolean(),
    hasPrinter: yup.boolean(),
    hasChairsideMill: yup.boolean(),
    Ios: yup
      .string()
      .when("hasIos", {
        is: true,
        then: yup.string().required("Required"),
      })
      .when("hasIos", {
        is: false,
        then: yup.string().nullable(),
      }),
    Cbct: yup
      .string()
      .when("hasCbct", {
        is: true,
        then: yup.string().required("Required"),
      })
      .when("hasCbct", {
        is: false,
        then: yup.string().nullable(),
      }),
    Printer: yup
      .string()
      .when("hasPrinter", {
        is: true,
        then: yup.string().required("Required"),
      })
      .when("hasPrinter", {
        is: false,
        then: yup.string().nullable(),
      }),
    ChairsideMill: yup
      .string()
      .when("hasChairsideMill", {
        is: true,
        then: yup.string().required("Required"),
      })
      .when("hasChairsideMill", {
        is: false,
        then: yup.string().nullable(),
      }),
  }),
});
