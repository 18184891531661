export const fileNestingProduct = {
    id: "f5b1e6ad-52c0-444b-9ea5-8228d778c8eb",
    description: "File Nesting",
    productId: 1049,
    imageLink: "https://evidentdigital.blob.core.windows.net/public/nesting.jpg",
    toothNumbers: [],
    files: [],
    caseNotes: "",
    additionalNotes: "",
    dueDate: "",
    productDescription:
      "Should we nest the design for you?",
    productSubDescription: "",
    serviceProviderSelected: "Design",
    IsRush: false
  };

  export const prebookingProduct = {
    id: "prebooking",
    description: "Prebook",
    productId: -999,
    imageLink: "https://evidentdigital.blob.core.windows.net/public/prebook.png",
    price: 0,
    toothNumbers: [],
    files: [],
    caseNotes: "",
    additionalNotes: "",
    dueDate: "",
    productDescription:
      "Pre-book a Same-Day Case.",
    productSubDescription: "",
    serviceProviderSelected: "Design",
  }