import React from "react";
import { connect } from "react-redux";

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IconButton, Button, Divider, Grid } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import toothRestorationIcon from "../../../../../images/tooth_restoration_icon.svg";

import crownPic from "../../../../../images/ModalToothSelection/crown.png";
import ponticPic from "../../../../../images/ModalToothSelection/pontic.png";
import veneerPic from "../../../../../images/ModalToothSelection/veneer.png";


function SelectToothRestorationModal({isOpen, typesOfRestoration, withGingivalContouring, handleClose}) {   

    const [selectedRestoration, setSelectedRestoration] = React.useState("");
    const [withGingival, setWithGingival] = React.useState(false);
    const [note, setNote] = React.useState('');

    const closeDialog = () => {
        setSelectedRestoration("");
        handleClose(null);
        
        setNote('');
    }

    const selectRestoration = (res) => {
        setSelectedRestoration(res);
    }

    const applyRestoration = () => {
        let selectedRes = selectedRestoration;
        
        setSelectedRestoration("");
        setWithGingival(false);
        
        if (withGingivalContouring && withGingival) {
            selectedRes += "- With Gingival Contouring"
        }

        handleClose(selectedRes, note.trim());
        
        setNote('');
    }

    return (
        <Dialog open={isOpen} maxWidth="md" fullWidth sx={{textAlign: 'center'}}>

            <Box>
                <img src={toothRestorationIcon}/>
            </Box>


            <Box sx={{fontSize: "21px", fontWeight:'700', lineHeight: '21px', color: '#2E3246'}}>Choose a treatment plan for the tooth selected.</Box>
            <Box sx={{fontWeight: '300', fontSize: '16px', lineHeight: '21px', marginBottom: '48px', color: '#2E3246', marginTop: '6px'}}>Click to select a treatment plan:</Box>
            
            <Box sx={{display:'flex', width:'100%', margin:'0 auto', justifyContent:'center', flexWrap: 'wrap'}}>
                {/* {typesOfRestoration.map((res, idx) => (
                    <Box sx={{backgroundSize:'cover', display: 'flex', justifyContent: 'center', alignItems: 'center', width:'40%', height: '113px', marginRight: '11px', marginBottom: '10px', borderRadius: '16px', cursor:'pointer', border: res === selectedRestoration ? '2px solid #32BE60' : '2px solid #000000',backgroundImage:`url(${crownPic})` }} onClick={() => selectRestoration("Crowns")}>
                        
                    </Box>
                ))} */}
                 <Box sx={{backgroundSize:'cover', backgroundRepeat: "no-repeat", display: 'flex', justifyContent: 'center', alignItems: 'stretch', width:'40%', height: '280px', marginRight: '11px', marginBottom: '10px', borderRadius: '16px', cursor:'pointer', border: "Crowns" === selectedRestoration ? '2px solid #32BE60' : '2px solid #000000',backgroundImage:`url(${crownPic})` }} onClick={() => selectRestoration("Crowns")}>
                    
                 </Box>
                 <Box sx={{backgroundSize:'cover', backgroundRepeat: "no-repeat", display: 'flex', justifyContent: 'center', alignItems: 'stretch', width:'40%', height: '280px', marginRight: '11px', marginBottom: '10px', borderRadius: '16px', cursor:'pointer', border: "Pontic" === selectedRestoration ? '2px solid #32BE60' : '2px solid #000000',backgroundImage:`url(${ponticPic})` }} onClick={() => selectRestoration("Pontic")}>
                    
                </Box>

                <Box sx={{backgroundSize:'cover', backgroundRepeat: "no-repeat", display: 'flex', justifyContent: 'center', alignItems: 'stretch', width:'40%', height: '300px', marginRight: '11px', marginBottom: '10px', borderRadius: '16px', cursor:'pointer', border: "Veneers" === selectedRestoration ? '2px solid #32BE60' : '2px solid #000000',backgroundImage:`url(${veneerPic})` }} onClick={() => selectRestoration("Veneers")}>
                
                </Box>
   
            </Box>

            
            <Box sx={{marginBottom: '48px', color: '#2E3246', marginTop: '15px'}}><TextField sx={{width:'90%'}} value={note} onChange={(e)=>setNote(e.target.value)} multiline minRows={3} maxRows={3} placeholder="Additional Note"/></Box>

            {withGingivalContouring ? (
                <>
                   <Box sx={{fontSize: "21px", fontWeight:'700', marginTop:'15px', lineHeight: '21px', color: '#2E3246'}}>Gingival Contouring</Box>
                   <Box sx={{fontWeight: '300', fontSize: '16px', lineHeight: '21px', marginBottom: '48px', color: '#2E3246', marginTop: '6px'}}>Click to select an option:</Box>

                   <Box sx={{display:'flex', width:'85%', margin:'0 auto', justifyContent:'center', flexWrap: 'wrap'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width:'40%', height: '113px', marginRight: '11px', marginBottom: '10px', background: 'rgba(196, 196, 196, 1)', borderRadius: '16px', cursor:'pointer', border: withGingival ? '2px solid #32BE60' : '2px solid #000000'}} onClick={() => setWithGingival(true)}>
                                Yes
                         </Box>
                         <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width:'40%', height: '113px', marginRight: '11px', marginBottom: '10px', background: 'rgba(196, 196, 196, 1)', borderRadius: '16px', cursor:'pointer', border: !withGingival ? '2px solid #32BE60' : '2px solid #000000'}} onClick={() => setWithGingival(false)}>
                                No
                         </Box>
                    </Box>
                </>
            ) : ("")}

            <Divider sx={{marginTop:'45px', marginBottom: '30px'}} />

            <Box sx={{fontWeight: '300', fontSize: '16px', lineHeight: '21px', marginBottom: '48px', color: '#2E3246', marginTop: '6px', fontStyle:'italic', marginTop:'15px', textAlign:'center'}}>Please confirm your selection.</Box>

            <Box sx={{marginTop: '10px', width:'85%', margin: '0 auto', marginBottom: '30px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button sx={{textTransform: 'none', height: '60px', fontSize: '18px', fontWeight: '700', color: '#fff', backgroundColor:'#5B5B5B', width:'100%', '&:hover': {color: '#fff', backgroundColor:'#5B5B5B'}}} onClick={closeDialog}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button sx={{textTransform: 'none', height: '60px', fontSize: '18px', fontWeight: '700', color: '#fff', background:'linear-gradient(270deg, #11C984 0%, #32BE60 100%)', width:'100%', '&:hover': { color: '#fff', background:'linear-gradient(270deg, #11C984 0%, #32BE60 100%)'}}} onClick={applyRestoration}>Apply</Button>
                    </Grid>
                </Grid>
            </Box>

        </Dialog>
    );
}

export default connect(null, null)(SelectToothRestorationModal);