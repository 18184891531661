import {
    Button,
    Chip,
    CircularProgress,
    Container,
    Divider,
    List,
    ListItem,
    Stack,
    Typography,
    Box
  } from "@mui/material";
  import React, { useState } from "react";
  import Header from "../../components/header/header";  
  import ProfileDetails from "../../components/profile/profile";  
  import { grey } from "@mui/material/colors";
  const Profile = () => {
  const [loading, setLoading] = useState(false);
  
  
    return (
      <React.Fragment>
        <Header  />
        <Container maxWidth="xl">
          
          <Box marginY={4}>
            {/* Header */}
            
           
             <ProfileDetails></ProfileDetails>
              
            
          </Box>
        </Container>
      </React.Fragment>
    );
  
}
  export default Profile;
  