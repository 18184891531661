import React from "react";
import { Box, button } from "@mui/system";
import Dropzone from "react-dropzone";

import DragAndDropImage from "../../images/drag-and-drop.svg";
import caseCreatedIcon from "../../images/case_created_icon.svg";
import BackupIcon from "@mui/icons-material/Backup";

import {
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

import moment from "moment";
import { brandBlue, brandLight } from "../../constants";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";

export default function FilesComponent(props) {
  const {
    requiredFiles,
    onFileChange,
    removeFile,
    uploading,
    openAdditionalFiles,
    showSelect,
  } = props;
  const dispatch = useDispatch(); 
  return (
    <Box
      sx={{
        width: "100%",
        background: brandLight,
        borderRadius: "16px",
        backdropFilter: " blur(20px)",
        display: "flex",
        flexDirection: "column",
        marginTop: "5px",
        paddingTop: "37px",
        paddingLeft: "57px",
        paddingRight: "57px",
        paddingBottom: "37px",
        boxSizing: "border-box",
        position: "relative",
        marginBottom: 4,
      }}
    >
      {uploading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "rgba(0,0,0,0.25)",
            borderRadius: "16px",
          }}
        >
          <Stack alignItems="center">
            <CircularProgress sx={{ color: "black" }} />
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              Uploading
            </Typography>
          </Stack>
        </Box>
      )}
      {requiredFiles.requiredFiles
        .sort((a, b) => (a.name < b.name ? 1 : -1))
        .map((file, idx) => (
          <Box
            sx={{
              backgroundColor: brandLight,
              paddingX: "37px",
              paddingY: "19px",
              marginBottom: 2,
              borderRadius: "12px",
            }}
          >
            <Stack direction="row" alignItems="center" marginBottom={2}>
              <Box
                component="span"
                sx={{
                  xs: 2,
                  marginRight: "12px",
                  width: "43px",
                  height: "43px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={file.image}
                  alt={file.name + "-image"}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </Box>
              <Box
                sx={{
                  width: "auto",
                  fontSize: "21px",
                  fontWeight: "500",
                  lineHeight: "31px",
                  color: "#27374F",
                }}
              >
                {file.name === "Cbct Scan" ? "CBCT Scan" : file.name}
              </Box>
            </Stack>

            {file.numberOfFilesUploading > 0 && (
              <Box sx={{ width: "100%", paddingBottom: 1 }}>
                <LinearProgress />
              </Box>
            )}

            <Box
              key={idx}
              sx={{
                display: "flex",
                alignItems: "stretch",
                borderRadius: "12px",
              }}
            >
              <Stack sx={{ width: "40%" }}>
                <Dropzone
                  minSize={1}
                  onDropRejected={(file) => {
                    dispatch(
                      openSnackbar({
                        title: "Upload failed",
                        type: "error",
                        body: file[0].file.path + " is empty",
                      })
                    );
                  }}
                  onDropAccepted={(files) => {
                    console.log(files);
                    onFileChange(files, idx, file.name);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                        background: "#ECF2F9",
                        border: "2px dashed " + brandBlue,
                        cursor: "pointer",
                        ":hover": {
                          borderColor: "#328fcf",
                          background: "#e3f1ff",
                        },
                      }}
                    >
                      <Box sx={{ marginBottom: "20px" }}>
                        <input {...getInputProps()} />
                        <img
                          src={DragAndDropImage}
                          alt="drag-and-drop"
                          style={{ width: "134px" }}
                        />
                      </Box>
                    </Box>
                  )}
                </Dropzone>

                {file.name === "Additional Files" && showSelect && (
                  <Button
                    variant="contained"
                    onClick={() => openAdditionalFiles(true)}
                    fullWidth
                    sx={{
                      marginTop: 1,
                      textAlign: "center",
                      paddingTop: "10px",
                    }}
                  >
                    Select From Patient's Files
                  </Button>
                )}
              </Stack>

              <Box
                sx={{
                  width: "100%",
                  height: file.name === "Additional Files" ? "240px" : "180px",
                  marginLeft: "30px",
                  padding: 2,
                  background: "#ECF2F9",
                  borderRadius: "12px",
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    height: "100%",
                    boxSizing: "border-box",
                    overflowY: "auto",
                  }}
                >
                  {file.files.map((file, fileIdx) => (
                    <Box
                      key={fileIdx}
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          xs: 1,
                          width: "45px",
                          height: "45px",
                        }}
                      >
                        <img
                          src={caseCreatedIcon}
                          style={{ width: "45px", height: "45px" }}
                        />
                      </Box>
                      <Stack
                        sx={{
                          xs: 3,
                          flexGrow: 1,
                          flex: "wrap",
                          alignItems: "start",
                          justifyContent: "flex-start",
                          px: 1.5,
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Box
                            sx={{
                              xs: 3,
                              flex: "wrap",
                              fontSize: "16px",
                              lineHeight: "21px",
                              fontWeight: "400",
                              flexGrow: 1,
                              maxWidth: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {file.fileName}
                          </Box>
                          <Box
                            sx={{
                              xs: 3,
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontWeight: "400",
                              flexGrow: 1,
                              textAlign: "left",
                              color: "#848484",
                            }}
                          >
                            {moment(file.uploadedDate).format("lll")}
                          </Box>
                        </Stack>
                        {file.set && (
                          <Box
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#848484",
                              textAlign: "justify",
                            }}
                          >
                            {file.set}
                          </Box>
                        )}
                      </Stack>
                      <Box
                        sx={{
                          xs: 3,
                          flexGrow: 1,
                          fontSize: "16px",
                          lineHeight: "21px",
                          fontWeight: "400",
                          color: "#4A90E2",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginRight: "15px",
                          textAlign: "end",
                        }}
                        onClick={() => {
                          removeFile(idx, fileIdx);
                        }}
                      >
                        Remove File
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
