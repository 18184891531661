import {
  Alert,
  Box,
  Container,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import StyledButton from "../../components/common/button/largeButton";
import ContentContainer from "../../components/common/contentContainer";
import { brandBlack, brandColor, brandLight } from "../../constants";
import logo from "../../images/evident_logo2.svg";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { loginSchema } from "../../types/loginSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "../../hooks/useLogin";
import { grey } from "@mui/material/colors";
import CustomModal from "../../components/common/modal/customModal";
import LoginBranding from "../../components/coBranding/loginBranding";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { loggingIn, login } = useLogin();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleCloseAdvisory = async (isOpen) => {
    try {
      setOpen(isOpen);
      await login(email, password);
      // navigate("/");
    } catch (err) {
      console.log(err);
      setError("Log in failed. Please try again.");
    }
  };

  const handleLogin = async (values) => {
    setError("");
    try {
      setEmail(values.email);
      setPassword(values.password);
      /*setOpen(true);*/

      await login(values.email, values.password);
       navigate("/");
    } catch (err) {
      console.log(err);
      setError("Log in failed. Please try again.");
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#101010", height: 46 }} />
      {loggingIn && (
        <LinearProgress
          sx={{
            width: "100%",
            top: "0",
            left: "0",
            borderRadius: "100px",
          }}
        />
      )}
      <Container maxWidth="md">
        <Box sx={{ width: "full", paddingY: 10, paddingX: 4 }}>
          <LoginBranding />
          <ContentContainer small sx={{ marginTop: 3 }}>
            <Stack
              component="form"
              alignItems="center"
              spacing={3}
              onSubmit={handleSubmit(handleLogin)}
              sx={{ paddingTop: 2, paddingBottom: 3 }}
            >
              <Typography
                variant="h5"
                component="h1"
                sx={{ color: brandBlack }}
              >
                Log in to Evident Hub
              </Typography>
              <Stack spacing={2} width="100%">
                <TextField
                  placeholder="Email"
                  error={Boolean(errors.email) || Boolean(error)}
                  helperText={errors.email && errors.email.message}
                  {...register("email")}
                  fullWidth
                />
                <TextField
                  type="password"
                  placeholder="Password"
                  error={Boolean(errors.password) || Boolean(error)}
                  helperText={errors.password && errors.password.message}
                  {...register("password")}
                  fullWidth
                />
                {error && (
                  <Alert severity="error" sx={{ background: brandLight }}>
                    {error}
                  </Alert>
                )}
                <StyledButton
                  variant="primary"
                  type="submit"
                  disabled={loggingIn}
                >
                  Log In
                </StyledButton>
              </Stack>
              <Stack spacing={2} alignItems="center">
                <Typography>
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    underline="hover"
                  >
                    Forgot Password
                  </Link>
                </Typography>
                <Typography sx={{ color: brandBlack }}>
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/signup" underline="hover">
                    Sign up
                  </Link>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "0.8em",
                    color: grey[700],
                    textAlign: "center",
                    paddingTop: 4,
                  }}
                >
                  By signing in with evident, you agree to our{" "}
                  <Link
                    href="/docs/Evident_-_HIPAA_BAA.pdf"
                    target="blank"
                    underline="hover"
                  >
                    Business Associate Agreement
                  </Link>{" "}
                  and our{" "}
                  <Link
                    href="/docs/Evident_-_HUB_T&Cs.pdf"
                    target="blank"
                    underline="hover"
                  >
                    Terms of Use.
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </ContentContainer>
        </Box>
        <CustomModal
          open={open}
          setOpen={handleCloseAdvisory}
          title={"Advisory"}
          content={`Starting Monday, February 13, 2023, we will be introducing charges for prebooked and rush design cases.

Prebooked case: You can now schedule your case in advance to align with your patient appointment. $50/case

Rush cases: Need your case back quickly? 2-5 hour turnaround time. $5/unit

Contact us for more information at 1 (877) 909 7770 or support@evidentlabs.com             
          `}
        />
      </Container>
    </>
  );
};

export default Login;
