import React from "react";
import { Box } from "@mui/system";
import { Checkbox } from "@mui/material";

export default function CheckboxListComponent(props) {
  const { req, handleRequirementsSelectList } = props;

  React.useEffect(() => {
    console.log(req);
  }, []);

  return (
    <Box sx={{}}>
      {req.itemList.map((item, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginRight: "15px" }}>
            <Checkbox
              checked={req?.value?.indexOf(item) >= 0}
              onChange={(e) => {
                handleRequirementsSelectList(
                  req.description,
                  item,
                  e.target.checked
                );
              }}
            />
          </Box>
          <Box>{item}</Box>
        </Box>
      ))}
    </Box>
  );
}
