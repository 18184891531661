import React from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import caseConfirmationIcon from "../../../images/case_confirmation_icon.svg";
import { brandColor, brandGray } from "../../../constants";
import { ThumbUp } from "@mui/icons-material";

function CreateCaseConfirmationModal({ isOpen, handleClose, initUser }) {
  const closeDialog = (confirm) => {
    handleClose(confirm);
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth sx={{ textAlign: "center" }}>
      <Box sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            width: "120px",
            height: "120px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            border: `5px solid ${brandColor}`,
            marginTop: "30px"
          }}
        >
          <ThumbUp sx={{ width: "85px", height: "85px", color: brandColor }} />
        </Box>
      </Box>

      <Box
        sx={{
          fontWeight: "700",
          fontSize: "21px",
          lineHeight: "21px",
          color: "#2E3246",
          marginTop: "30px",
        }}
      >
        Congratulations.
      </Box>

      <Box
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "21px",
          color: "#2E3246",
          marginTop: "30px",
        }}
      >
        You are just a click away from delivering a life-changing <br />{" "}
        treatment to your patient.
      </Box>

      <Box
        sx={{
          marginTop: "67px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            width: "300px",
            height: "60px",
            color: "#fff",
            background: brandColor,
            "&:hover": { background: brandColor },
          }}
          onClick={() => closeDialog(true)}
        >
          Submit Order
        </Button>
        <Button
          sx={[
            {
              textTransform: "none",
              width: "300px",
              height: "60px",
              background: brandGray,
              color: "#fff",
              marginTop: "10px",
              marginBottom: "49px",
            },
            { "&:hover": { background: brandGray } },
          ]}
          onClick={() => closeDialog(false)}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}

export default connect(null, null)(CreateCaseConfirmationModal);
