import logo from "./logo.svg";
import "./App.css";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { initUser, openLoading, closeLoading, updatePatientId } from "./redux/actions";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AppRoutes from "./routes/index";
import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "./redux/actions/index";
import { useHubspotChat } from "./hooks/useHubspotChat";
import { brandColor, brandLight } from "./constants";
import AdminService from "./services/admin.service";
import { PaymentDetailsDialogContextProvider } from "./providers/payment-dialog-provider";
// import { useSearchParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
function App() {
  // const [searchParams, setSearchParams] = useSearchParams();
  const snackbarOpen = useSelector((state) => state.snackbarOpen);
  const loadingOpen = useSelector((state) => state.loadingOpen);
  const snackbarMessage = useSelector((state) => state.snackbarMessage);
  const dispatch = useDispatch();
  const { hasLoaded, activeConversation } = useHubspotChat("6892986");
  const [isPromptModalOpen, setIsPromptModalOpen] = React.useState(false);
  const [promptModalTitle, setPromptModalTitle] = React.useState("");
  const [promptModalContent, setPromptModalContent] = React.useState("");

  // const otlid = searchParams.get("otlid");

  const _checkLoginUrl =
    process.env.REACT_APP_ACCOUNT_CHECK_LOGIN + window.location.href;

  const handleCloseSnackbar = () => dispatch(closeSnackbar());

  const getSiteSettings = () => {
    console.log(localStorage.getItem("siteSettings"));
    if (
      localStorage.getItem("siteSettings") !== null &&
      localStorage.getItem("siteSettings").length === 0
    ) {
    } else {
      //get site settings
      AdminService.getAppSettings()
        .then((response) => response.json())
        .then((json) => {
          localStorage.setItem("siteSettings", JSON.stringify(json));
        });
    }
  };

  const checkSSOSession = () => {
    console.log(window.location.pathname);
    const d = new Date();
    let time = d.getTime();
    console.log(parseInt(localStorage.getItem("checkSessionCount")), time);
    if (localStorage.getItem("checkSessionCount") !== null) {
      let timeDifferenceInMinutes =
        (time - parseInt(localStorage.getItem("checkSessionCount"))) / (1000 * 60);

      if (timeDifferenceInMinutes < 60) {
        console.log(
          "session checked already",
          localStorage.getItem("checkSessionCount")
        );
      } else {
        console.log(window.location.pathname);
        if (window.location.pathname === "/") {
          // dispatch(closeLoading());
          const dt = new Date();
          let cTime = dt.getTime();
          localStorage.setItem("checkSessionCount", cTime);
          console.log(localStorage.getItem("checkSessionCount"));
          window.location.href = _checkLoginUrl;
        }
      }
    } else {
      if (window.location.pathname === "/") {
        // dispatch(closeLoading());
        const dt = new Date();
        let cTime = dt.getTime();
        localStorage.setItem("checkSessionCount", cTime);
        console.log(localStorage.getItem("checkSessionCount"));
        window.location.href = _checkLoginUrl;
      }
    }

   
  };

  const getPromptModalSettings = async () => {
    const promptedWithModal = localStorage.getItem("promptedWithModal")
    if(promptedWithModal == null){
      try {
        AdminService.getPromptModalInfo()
        .then((response) => response.json())
        .then((json) => {
          if (json.shouldShow) {
            setPromptModalTitle(json.modalTitle);
            setPromptModalContent(json.modalContent);
            setIsPromptModalOpen(true);
          }
        });

        localStorage.setItem("promptedWithModal", true)

      } catch (error) {
        console.log(error);
      }
    }
    
  };
  

  // This function will called only once
  useEffect(() => {
    //commented out due to outdated method
    //checkSSOSession();
    getPromptModalSettings();
    getSiteSettings();
  }, []);

  return (
    <>
      <BrowserRouter>
        <PaymentDetailsDialogContextProvider>
          <AppRoutes />
        </PaymentDetailsDialogContextProvider>
        {snackbarOpen && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarMessage.type || "success"}
              variant="filled"
              sx={{
                width: "100%",
                background:
                  snackbarMessage.type === "success" || !snackbarMessage.type
                    ? brandColor
                    : undefined,
              }}
            >
              <AlertTitle>{snackbarMessage.title}</AlertTitle>
              {snackbarMessage.body}
            </Alert>
          </Snackbar>
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
          open={isPromptModalOpen}
          onClose={() => setIsPromptModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          maxWidth="md"
          aria-describedby="alert-dialog-description"
          sx={{
            padding: "20px 20px",
          }}
        >
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20 }}>
            {promptModalTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontSize: 14 }}
              id="alert-dialog-description"
            >
              {promptModalContent.split('\n').map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      <br />
                    </React.Fragment>
                ))}            
             </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsPromptModalOpen(false)} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </BrowserRouter>
    </>
  );
}

export default App;
