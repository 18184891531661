import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../../components/header/header";
import CardDetails from "../../components/paymentDetails/cardDetails";
import Invoice from "../../components/paymentDetails/invoice";
import { grey } from "@mui/material/colors";
import { brandGray, brandLight, brandLightGreen } from "../../constants";
const PaymentDetails = () => {
  const [loading, setLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(true);

  const handleUpdateCardDetails =(success)=>{
    //console.log("  ResultMessage(paymentError", success);
};


  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="xl">
        {/* <Box
            component="img"
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: "149px",
              marginTop: 4,
              marginBottom: -1,
              borderRadius: "1rem",
              cursor: "pointer",
            }}
            alt="Diagnostic WaxUps Banner"
            src={diagnosticWaxUpsBanner}
            onClick={() =>
              window.open(
                "https://evidentdigital.blob.core.windows.net/public/Brochure_DX.pdf",
                "_blank"
              )
            }
          /> */}
        <Box marginY={4}>
          {/* Header */}
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Stack direction="row" spacing={2}>
                <Box
                  key="paymentDetails"
                  sx={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    // backgroundImage: isPayment
                    //   ? "linear-gradient(270deg, #11C984 0%, #32BE60 100%)"
                    //   : null,
                    // backgroundColor: isPayment
                    //   ? null
                    //   : isPayment
                    //   ? "#f4f5f6"
                    //   : "#DEE1E2",
                  }}
                  onClick={() => setIsPayment(true)}
                >
                  <Button
                    disableRipple
                    sx={{
                      paddingX: 6,
                      paddingTop: 2,
                      paddingBottom: 1.7,
                      color: isPayment ? "#fff" : brandGray,
                      fontWeight: "bold",
                      fontSize: "16px",
                      textTransform: "none",
                      backgroundColor: isPayment ? brandLightGreen : brandLight,
                      "&:hover": {
                        backgroundColor: isPayment
                          ? brandLightGreen
                          : brandLight,
                      },
                      borderTopLeftRadius: "14px",
                      borderTopRightRadius: "14px",
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    Payment Details
                  </Button>
                </Box>

                <Box
                  key="invoice"
                  sx={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                  onClick={() => setIsPayment(false)}
                >
                  <Button
                    disableRipple
                    sx={{
                      paddingX: 6,
                      paddingTop: 2,
                      paddingBottom: 1.7,
                      color: !isPayment ? "#fff" : brandGray,
                      fontWeight: "bold",
                      fontSize: "16px",
                      textTransform: "none",
                      backgroundColor: !isPayment
                        ? brandLightGreen
                        : brandLight,
                      "&:hover": {
                        backgroundColor: !isPayment
                          ? brandLightGreen
                          : brandLight,
                      },
                      borderTopLeftRadius: "14px",
                      borderTopRightRadius: "14px",
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    Invoices
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Box
            sx={{
              paddingY: 2,
              paddingX: 4,
              borderRadius: "1rem",
              backgroundColor: brandLight,
              borderRadius: "0 1rem 1rem 1rem",
              textAlign: "center",
            }}
          >
            {isPayment ? (
              <>
                <CardDetails  isSuccessful={handleUpdateCardDetails} />
              </>
            ) : (
              <Invoice />
            )}
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
export default PaymentDetails;
