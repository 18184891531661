import BorderColorIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from "@mui/material/Typography";
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
const NotesSection = ({notes,onUpdateNotes,teeth,treatment}) => {
    const [editMode,setEditMode] = useState(false);
    const [currentNote,setCurrentNote] = useState(notes ?? "");
    const [touched,setTouched] = useState(false);

    const updateNoteHandler = () => {
        onUpdateNotes(teeth,currentNote);
        setEditMode(false);
        setTouched(false);
    }

    return (
        <>
            {!editMode && <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography display="flex" fontSize="1em" fontWeight="medium" alignItems="center">
                    <Typography variant="caption" component="div" paddingRight={2}>
                        &#x2022;  
                    </Typography>
                    <Typography variant="body1" component="div" minWidth={80}>
                        Note:
                    </Typography>
                    <Typography variant="body1" component="div">
                        {notes}
                    </Typography>
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={()=>{setEditMode(true)}}>
                        <BorderColorIcon sx={{color: "#AFAFB8"}} />
                    </IconButton>
                </Box>
            </Box>}
            {editMode && <Box>
                <Stack>
                    <OutlinedInput
                        sx={{backgroundColor: "#FFF"}}
                        placeholder="Please leave your note"
                        name="notes"
                        value={currentNote}
                        onChange={(e)=>{
                            setCurrentNote(e.target.value)
                            setTouched(true);
                        }}
                        minRows={2}
                        maxRows={2}
                        multiline
                        fullWidth
                    />
                </Stack>
                <Stack display="flex" flexDirection="row" justifyContent="end" marginY={1}>
                    <Button sx={{marginX: '5px'}} variant="outline" onClick={()=>{
                        setCurrentNote(notes);
                        setEditMode(false);
                        setTouched(false);
                    }}>Cancel</Button>
                    <Button 
                        sx={{
                            marginX: '5px',
                            backgroundColor: "#65B559",
                            '&:hover': {
                                backgroundColor: '#39A929', // Background color on hover
                            }, 
                            paddingX: '20px'
                        }} 
                        variant="contained" 
                        onClick={updateNoteHandler}
                        disabled={!touched}
                        >
                            Save
                    </Button>
                </Stack>
            </Box>
            }
        </>
        
    )
}

export default NotesSection