import {
  Autocomplete,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogContainer from "../common/dialogContainer";
import { inviteLabSchema } from "../../types/inviteLabSchema";
import InputText from "../common/inputText";
import { brandColor, brandGradient } from "../../constants";
import { useEffect } from "react";
import StyledFormLabel from "../common/styledFormLabel";

const InviteLabDialog = ({
  open,
  submitting,
  dentists,
  labs,
  practices,
  loading,
  onClose,
  onInvite,
  showDentists,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      practiceId: "",
      labCode: "",
      labName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      showDentists: showDentists,
    },
    resolver: yupResolver(inviteLabSchema),
  });

  const labCode = watch("labCode");
  const practiceId = watch("practiceId");

  useEffect(() => {
    reset({
      practiceId: "",
      labCode: "",
      labName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      showDentists: showDentists,
    });
  }, [reset, open]);

  const handleInvite = (values) => {
    onInvite(values);
  };

  const handleLabClick = (lab) => {
    if (!lab) return;
    reset({
      labCode: lab.id,
      labName: lab.labName,
      firstName: lab.firstName,
      lastName: lab.lastName,
      email: lab.email,
      phone: lab.phone,
      showDentists: showDentists,
      practiceId: practiceId,
    });
  };

  const handleLabFieldChange = (value) => {
    if (labCode) {
      reset({
        labCode: "",
        labName: value,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        showDentists: showDentists,
        practiceId: practiceId,
      });
    } else {
      setValue("labName", value);
    }
  };

  return (
    <DialogContainer open={open} onClose={onClose} loading={submitting}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
        {!loading && (
          <React.Fragment>
            <Box sx={{ textAlign: "center" }}>
              <Typography component="h2" variant="h6">
                Lab Information
              </Typography>
              <Typography variant="body2" mt={0.5}>
                Please fill out the lab's information below. They will be
                receiving an email inviting them to sign up.
              </Typography>
            </Box>

            <Stack
              component="form"
              onSubmit={handleSubmit(handleInvite)}
              spacing={1}
              sx={{ width: "100%", marginTop: 4 }}
            >
              <Box>
                <StyledFormLabel size="sm">Lab Name</StyledFormLabel>
                <Controller
                  control={control}
                  name="labCode"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Autocomplete
                      disablePortal
                      freeSolo
                      disabled={submitting}
                      options={labs}
                      onChange={(_, val) => {
                        handleLabClick(val);
                        onChange(val?.id || "");
                      }}
                      onInputChange={(_, val) => {
                        handleLabFieldChange(val);
                        onChange("");
                      }}
                      getOptionLabel={(option) => option.labName || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(error)}
                          FormHelperTextProps={{ variant: "standard" }}
                          helperText={error && error.message}
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.labName}
                          </li>
                        );
                      }}
                    />
                  )}
                />
              </Box>

              <InputText
                label="First Name (Optional)"
                register={register("firstName")}
                errors={errors.firstName}
                disabled={Boolean(labCode) || submitting}
              />

              <InputText
                label="Last Name (Optional)"
                register={register("lastName")}
                errors={errors.lastName}
                disabled={Boolean(labCode) || submitting}
              />

              <InputText
                label="Email"
                register={register("email")}
                errors={errors.email}
                disabled={Boolean(labCode) || submitting}
              />

              <InputText
                label="Phone Number"
                register={register("phone")}
                errors={errors.phone}
                disabled={Boolean(labCode) || submitting}
              />

              <Box>
                <StyledFormLabel size="sm">Practice</StyledFormLabel>
                <Controller
                  control={control}
                  name="practiceId"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Autocomplete
                      autoHighlight
                      disablePortal
                      disabled={submitting}
                      options={practices}
                      onChange={(_, val) => {
                        onChange(val?.id || "");
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(error)}
                          FormHelperTextProps={{ variant: "standard" }}
                          helperText={error && error.message}
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={JSON.stringify(option)}>
                            {option.name}
                          </li>
                        );
                      }}
                    />
                  )}
                />
              </Box>

              {dentists && dentists.length > 0 && (
                <Box>
                  <StyledFormLabel size="sm">Dentist</StyledFormLabel>
                  <Controller
                    control={control}
                    name="dentist"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        autoHighlight
                        disablePortal
                        disabled={submitting}
                        options={dentists}
                        onChange={(_, val) => {
                          onChange(val || "");
                        }}
                        getOptionLabel={(option) =>
                          `${option.dentistFirstName} ${option.dentistLastName}` ||
                          option
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={Boolean(error)}
                            FormHelperTextProps={{ variant: "standard" }}
                            helperText={error && error.message}
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.dentistId}>
                              {`${option.dentistFirstName} ${option.dentistLastName}`}
                            </li>
                          );
                        }}
                      />
                    )}
                  />
                </Box>
              )}

              <Stack direction="row" spacing={2} sx={{ paddingTop: 4 }}>
                <Button type="button" fullWidth onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: brandColor,
                    "&:hover": { background: brandColor },
                  }}
                >
                  Invite
                </Button>
              </Stack>
            </Stack>
          </React.Fragment>
        )}
      </Box>
    </DialogContainer>
  );
};

export default InviteLabDialog;
