import React, {  useEffect, useState } from "react";
import { Box } from "@mui/system";
import { TextField, InputAdornment, Typography } from "@mui/material";
import { brandBlue } from "../../constants";
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';



function ListWithOtherSpecifyComponent(props) {
  
  const { req, handleRequirementSelectValue, isCancelled } = props;
  const [indexHighlight, setIndexHighlight] = useState(-1);

  const [inputValue, setInputValue] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
  
    const handleBlur = () => {
      setIsEmpty(inputValue.trim() === '');
      //setIsEmpty(true);
    };
  
    const handleChange = (event) => {
      setInputValue(event.target.value);
      if (event.target.value.trim() !== '') {
        setIsEmpty(false);
      }
    };
    

    const location = useLocation();

    React.useEffect(() => {
      function handleRouteChange() {
        console.log('Route changed to:', location, location.pathname);
        // Trigger your function here
        const event = { target: { value: isCancelled ? 'Some initial value' : '' } };
        handleChange(event);
      
      }
  
      handleRouteChange();
    }, [location]);
  
   
  
  

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {req.itemList.items.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: "230px",
            cursor: "pointer",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
            marginRight: "15px",
            height: "55px",
            background: indexHighlight === index ? brandBlue : "#FBFBFB",
            color: indexHighlight === index ? "#fff" : "#000",
            borderRadius: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid",
            borderColor: req.value === index ? "transparent" : "#e0e0e0",
          }}
          onClick={() => {
            handleRequirementSelectValue(
              req.description,
              item === req.itemList.itemWithSpecify ? "" : item
            );
            setIndexHighlight(index);
            
           
          }}
        >
          {item}
        </Box>
      ))}
      {req.value !== null &&
      !req.itemList.items.find((it) => [it]?.includes(req.value)) ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{position: "relative"}}>          
              {/* Misaligned Teeth Correction */}
              <Typography
              color={isEmpty ? 'red' : 'textPrimary'}
              
              sx={{float:"right", fontSize:"13px",position:"absolute",top:"-50px"}}
            >
              {isEmpty ?  <span className="error-highlight">Please fill out the missing fields.</span> : ' '}
            </Typography>
            <TextField       
              autoFocus
              type={req.itemList.fieldTypeIsNumber ? "number" : ""}
              size={"medium"}
              value={req.value}
              onBlur={handleBlur}
              isEmpty={isEmpty}
              onChange={(e) => {
                if (req.itemList.fieldTypeIsNumber) {
                  if (Number(e.target.value) < 0) {
                    return;
                  }
                } 
                handleChange(e);
                handleRequirementSelectValue(req.description, e.target.value);
                
              }
            }
              sx={{  '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: isEmpty ? 'red' : '#48add9',
                },
                '&:hover fieldset': {
                  borderColor: isEmpty ? 'red' : '#48add9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: isEmpty ? 'red' : '#48add9',
                },
              },backgroundColor: "#fff", width: "250px",}}
              placeholder={
                req.itemList.placeholder !== null &&
                req.itemList.placeholder !== "" &&
                req.itemList.placeholder !== undefined
                  ? req.itemList.placeholder
                  : "Please specify"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {req.itemList.adornment}
                  </InputAdornment>
                ),
              }}
            />
           
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default ListWithOtherSpecifyComponent;
