import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ImagePreview = ({ filename, link }) => {
  return (
    <Stack width="100%" height="100%" maxHeight="480px" alignItems="center">
      <img
        src={link}
        alt={filename}
        style={{
          maxWidth: "100%",
          maxHeight: "480px",
          borderRadius: "20px",
        }}
      />
    </Stack>
  );
};

export default ImagePreview;
