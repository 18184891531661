const _apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];
const _designUrl = process.env.REACT_APP_DESIGN_API_END_POINT.split("=")[1];

const merchantTypeName = "Evident";

const AdminService = {
  getTreatments: function () {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "admin/treatmentproducts", data);
  },
  getProductProfiles: function () {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "admin/productprofiles", data);
  },
  getPaymentDetails: function (model) {
    var customerInfo = {
      merchantRef: model.domain,
      merchantTypeName: merchantTypeName,
      email: model.email,
      customerRef: model.id,
    };

    let data = {
      method: "POST",
      body: JSON.stringify(customerInfo),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "epay/GetCustomerCard?consumerApp=" + model.domain,
      data
    );
  },

  authorizationCharge: function (model) {
    var custInfo = {
      merchantRef: "EvidentDesign",
      merchantTypeName: merchantTypeName,
      email: model.email,
      customerRef: model.id,
      name: model.name,
    };

    var modelToSubmit = {
      amount: 1,
      description: "Authorization Charge",
      shouldTopUp: false,
      customerInfo: custInfo,
    };

    let data = {
      method: "POST",
      body: JSON.stringify(modelToSubmit),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "epay/authorizationcharge?consumerApp=EvidentDesign",
      data
    );
  },

  checkIfInactive: function (id) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "case/checkifinactive?userid=" + id, data);
  },

  testAuthorization: function () {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "admin/testauthorized", data);
  },
  getPractices: function (id) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "dentist/getpractices?id=" + id, data);
  },
  addPaymentDetails: function (model, domain) {
    let addCustomerEPAY = {
      id: model.dentistId,
      customerInfo: {
        consumerApp: domain,
        merchantRef: domain,
        merchantTypeName: merchantTypeName,
        name: model.name,
        email: model.email,
        customerRef: model.id,
        attributes: null,
      },
      consumerApp: domain,
      cardInfo: {
        id: "",
        name: model.cardHolderName,
        brand: "",
        expirationMonth: model.expirationMonth,
        expirationYear: model.expirationYear,
        number: model.creditCardNumber,
        cvv: model.cvvNumber,
      },
      setAsDefault: true,
      prepaidTopUp: model.prepaidTopup,
      isPrepaid: model.isPrepaid,
    };

    let data = {
      method: "POST",
      body: JSON.stringify(addCustomerEPAY),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "epay/AddCustomerToEPAY", data);
  },
  updatePaymentDetails: function (model, domain) {
    let addCustomerEPAY = {
      id: model.dentistId,
      customerInfo: {
        consumerApp: domain,
        merchantRef: domain,
        merchantTypeName: merchantTypeName,
        name: model.name,
        email: model.email,
        customerRef: model.id,
        attributes: null,
      },
      consumerApp: domain,
      cardInfo: {
        id: "",
        name: model.cardHolderName,
        brand: "",
        expirationMonth: model.expirationMonth,
        expirationYear: model.expirationYear,
        number: model.creditCardNumber,
        cvv: model.cvvNumber,
      },
      setAsDefault: true,
      prepaidTopUp: model.prepaidTopup,
      isPrepaid: model.isPrepaid,
      identifier: model.identifier
    };

    let data = {
      method: "POST",
      body: JSON.stringify(addCustomerEPAY),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "epay/UpdateCardInEPAY", data);
  },
  getPosition: function () {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return fetch(_apiUrl + "admin/getposition");
  },
  getDentistsByPracticeid: function (id) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "dentist/getdentistsbypracticeid?PracticeId=" + id,
      data
    );
  },
  getUserPositions: function () {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + "admin/getuserpositions", data);
  },
  getDentists: function (email) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `locker/getdentist?email=${email}`, data);
  },
  getdesignpreferencesbyid: function (id) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + `dentist/getdesignpreferencesbyid?personnelId=${id}`,
      data
    );
  },
  upsertdesignpreferences: function (email, model) {
    let data = {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl + "dentist/upsertdesignpreferences?email=" + email,
      data
    );
  },
  getInvoices: function ({
    userId,
    skip,
    take,
    invoiceNumber,
    fromDate,
    endDate,
  }) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(
      _apiUrl +
        "dentist/getinvoices?" +
        `id=${userId}` +
        `&skip=${skip}` +
        `&take=${take}` +
        `&invoiceNumber=${invoiceNumber}` +
        `&fromDate=${fromDate}` +
        `&endDate=${endDate}`,
      data
    );
  },
  getInvoicePath: function (filePath) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    return fetch(_apiUrl + `Blob/getadminfilesas?url=` + filePath, data);
  },
  getAllPractices: function () {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(_apiUrl + "account/getallpracticenames", data);
  },
  updateDentistDentalPractice: function (body) {
    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(_apiUrl + "dentist/updatedentistdentalpractice", data);
  },
  getEmailNotifications: function (personnelId) {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(
      _apiUrl + "dentist/getemailnotifications?personnelId=" + personnelId,
      data
    );
  },
  toggleEmailNotifications: function (body) {
    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(_apiUrl + "dentist/toggleemailnotifications", data);
  },
  getAppSettings: function () {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return fetch(_apiUrl + "admin/getappsettings", data);
  },

  getPromptModalInfo: function () {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return fetch(_designUrl + "Account/GetPromptModalInfo", data);
  },

  updateprimarypracticepersonnel: function (body) {
    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(_apiUrl + "dentist/updateprimarypracticepersonnel", data);
  },
  updatePersonnelBillingOption: function (body) {
    let data = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(_apiUrl + "dentist/updatepersonnelbillingoption", data);
  },
  checkPrepayStatus: function (personnelID) {
    let data = {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };
    return fetch(
      _apiUrl + `dentist/CheckPrepayStatus?personnelId=${personnelID}`,
      data
    );
  },
};

export default AdminService;
