import {
  Avatar,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Link,
  Alert,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import DateInput from "../common/dateInput";
import TextInput from "../common/textInput";
import LargeButton from "../common/button/largeButton";
import PatientService from "../../services/patient.service";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import defaultLogo from "../../images/evident_logo2.svg";
import { useFormik } from "formik";
import { patientDetailsSchema } from "../../types/patientDetailsSchema";
import { brandLight } from "../../constants";
import { red } from "@mui/material/colors";
import { openSnackbar } from "../../redux/actions";
import AdminService from "../../services/admin.service";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  patientCode: "",
  dateOfBirth: null,
  profilePictureFileName: null,
  profilePictureUrl: null,
  personnelId: "",
  isNewPatient: true
};

const getInitialValues = (patient, isDentist) => {
  if (!patient || !Object.keys(patient).length > 0) {
    return {
      ...defaultValues,
      isDentist
    };
  } else {
    return {
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      mobile: patient.phoneNumber,
      dateOfBirth: patient.dateOfBirth ? new Date(patient.dateOfBirth) : null,
      patientCode: patient.patientCode,
      profilePictureFileName: patient.profilePictureFileName,
      profilePictureUrl: patient.profilePictureUrl,
      personnelId: patient.personnelId,
      isNewPatient: false,
      isDentist
    };
  }
};

const PatientDetailsDialog = ({
  open,
  onClose,
  onSuccess,
  patient,
  patientId,
  isNewPatient = false,
  practiceId,
  practiceName,
  userId,
}) => {
  const [file, setFile] = useState(null);
  const [hovering, setHovering] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dentists, setDentists] = useState([]);
  const [error, setError] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
    onDropAccepted: (files) => {
      setFile(files[0]);
    },
  });
  const { setValues, ...formik } = useFormik({
    initialValues: defaultValues,
    validationSchema: patientDetailsSchema,
    onSubmit: (values) => {
      handleConfirm(values);
    },
  });

  useEffect(() => {
    if (!open) {
      setError("");
      setValues(getInitialValues(patient));
      formik.setTouched(false);
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;
    if (userInfo.Roles.includes("Dentist")) return;

    const getDentists = async () => {
      setLoading(true);
      const response = await AdminService.getDentistsByPracticeid(practiceId);
      const data = await response.json();
      setLoading(false);
      setDentists(data);
    };
    getDentists();
  }, [practiceId, isNewPatient, open, userInfo.Roles]);

  useEffect(() => {
    setValues(getInitialValues(patient, userInfo.Roles.includes("Dentist")));
  }, [patient, setValues, userInfo.Roles]);

  const handleClose = () => {
    onClose();
    setFile(null);
    setSubmitting(false);
    setValues(getInitialValues(patient));
  };

  const handleMouseOver = () => {
    setHovering(true);
  };

  const handleMouseOut = () => {
    setHovering(false);
  };

  const handleConfirm = async (form) => {
    setSubmitting(true);

    var pId = userInfo.PracticeWithPersonnel.find(
      (p) => p.practiceId === practiceId
    ).personnelId;

    const dateOfBirthExists = form.dateOfBirth !== null;
    const body = {
      ...form,
      dateOfBirth: dateOfBirthExists ? moment(form.dateOfBirth).format() : null,
      stringDateOfBirth: dateOfBirthExists
        ? moment(form.dateOfBirth).format("M/D/YYYY h:m:s")
        : null,
      patientId: patientId,
      practiceId: practiceId || patient.practiceId,
    };

    if (isNewPatient) {
      try {
        const newPatientData = {
          ...body,
          practiceId,
          createdBy: userInfo.id,
        };

        if (userInfo.Roles.includes("Dentist")) {
          newPatientData.personnelId = userInfo.Id;
        }

        delete newPatientData.profilePictureFileName;
        delete newPatientData.profilePictureUrl;

        const response = await PatientService.addPatient(newPatientData);
        const responseData = await response.json();
        if (responseData.statusCode !== 10) {
          throw new Error(responseData.error.message);
        }
        onSuccess(responseData.data,form);
      } catch (err) {
        setError(err.message);
      }
    } else {
      try {
        const response = await PatientService.editPatientDetails(body, file);
        const responseData = await response.json();
        if (responseData.statusCode !== 10) {
          throw new Error(responseData.error.message);
        }
        console.log(responseData);
        onSuccess({
          firstName: body.firstName,
          lastName: body.lastName,
          dateOfBirth: body.dateOfBirth,
          profilePictureUrl: body.profilePictureUrl,
        });
        handleClose();
      } catch (err) {
        console.log(err.message);
        setError(err.message);
      }
    }

    setSubmitting(false);
  };

  const handleDateOfBirthChange = (value) => {
    formik.setFieldValue("dateOfBirth", value);
  };

  const handlePictureRemove = () => {
    if (file) {
      setFile(null);
    }
    setHovering(false);
    setValues({
      ...formik.values,
      profilePictureFileName: null,
      profilePictureUrl: null,
    });
  };

  console.log(formik.errors, formik.values);
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      scroll="body"
      PaperProps={{
        sx: {
          padding: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack alignItems="center">
            {!isNewPatient ? (
              <Box sx={{ position: "relative" }}>
                <Avatar
                  sx={{ height: 120, width: 120 }}
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : formik.values.profilePictureUrl
                  }
                />
                <Box
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  sx={{
                    width: 120,
                    height: 120,
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    borderRadius: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    opacity: hovering ? 1 : 0,
                    cursor: "pointer",
                    transition: "all 0.3s",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <AddAPhotoIcon
                    sx={{
                      color: "white",
                      height: 50,
                      width: 50,
                      margin: "auto",
                    }}
                  />
                </Box>
                {(file || formik.values.profilePictureUrl) && (
                  <Link
                    component="button"
                    onClick={handlePictureRemove}
                    sx={{ paddingTop: 0.5, float: "right" }}
                  >
                    Remove
                  </Link>
                )}
              </Box>
            ) : (
              <Box sx={{ paddingY: 2 }}>
                <img
                  src={defaultLogo}
                  sx={{ width: "100%" }}
                  alt="evident-logo"
                />
              </Box>
            )}
            {practiceName && (
              <Typography
                component="h4"
                sx={{ fontSize: "20px", fontWeight: 500 }}
              >
                {practiceName}
              </Typography>
            )}
            <Typography component="h2" variant="h6" sx={{ marginTop: 2 }}>
              {isNewPatient ? "Add New Patient" : "Edit Patient Info"}
            </Typography>
            <Typography mt={0.5}>
              {isNewPatient
                ? "Let us know your patient by providing some info, add patient files later on."
                : "Let us know your patient by providing us info."}
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ marginTop: 4 }}>
            <TextInput
              id="firstName"
              name="firstName"
              label="First Name"
              placeholder="Type in Patient First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextInput
              id="lastName"
              name="lastName"
              label="Last Name"
              placeholder="Type in Patient Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextInput
              id="mobile"
              name="mobile"
              label="Mobile Number (Optional)"
              placeholder="Type in Patient Mobile Number"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            <TextInput
              id="patientCode"
              name="patientCode"
              label="Patient Id (Optional)"
              placeholder="Type in Patient Id"
              value={formik.values.patientCode}
              onChange={formik.handleChange}
              error={
                formik.touched.patientCode && Boolean(formik.errors.patientCode)
              }
              helperText={
                formik.touched.patientCode && formik.errors.patientCode
              }
            />
            <DateInput
              label="Date of Birth (Optional)"
              name="dateOfBirth"
              value={formik.values.dateOfBirth}
              onChange={handleDateOfBirthChange}
              error={
                formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
              }
              helperText={
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              }
            />
            <TextInput
              id="email"
              name="email"
              label="Email Address (Optional)"
              placeholder="Type in Patient Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            {isNewPatient && !userInfo.Roles.includes("Dentist") && (
              <>
                <FormControl
                  error={
                    formik.touched.personnelId &&
                    Boolean(formik.errors.personnelId)
                  }
                >
                  <Typography
                    component="label"
                    id="dentist-label"
                    sx={{ fontWeight: "bold", color: "#37465D" }}
                  >
                    Dentist
                  </Typography>
                  <Select
                    labelId="dentist-label"
                    value={formik.values.personnelId || ""}
                    name="personnelId"
                    onChange={formik.handleChange}
                    sx={{ marginTop: 1 }}
                  >
                    {dentists.map((dentist) => (
                      <MenuItem
                        value={dentist.id}
                      >{`${dentist.firstName} ${dentist.lastName}`}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText variant="standard">
                    {formik.touched.personnelId && formik.errors.personnelId}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </Stack>
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
          <Box marginTop={6}>
            <Stack direction="row" spacing={2}>
              <LargeButton fullWidth variant="gray" onClick={handleClose}>
                Back
              </LargeButton>
              <LargeButton
                fullWidth
                variant="primary"
                type="submit"
                loading={submitting}
              >
                Confirm
              </LargeButton>
            </Stack>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PatientDetailsDialog;
