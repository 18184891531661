import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { brandBlue } from "../../constants";
import defaultLogo from "../../images/evident_logo2.svg";
import { useSelector } from "react-redux";
import Facebook from "../../images/social-media/facebook.svg";
import Insta from "../../images/social-media/instagram.svg";
import Linkedin from "../../images/social-media/linkedin.svg";
import Youtube from "../../images/social-media/youtube.svg";
import Tiktok from "../../images/social-media/tiktok.svg";

const Layout = ({ children }) => {
  const loggedIn = Boolean(useSelector((state) => state.userInfo));
  const homeLink = loggedIn
    ? process.env.REACT_APP_EVIDENT_MAIN_LOG_IN
    : process.env.REACT_APP_EVIDENT_MAIN_URL;
  const aboutLink = loggedIn
    ? process.env.REACT_APP_EVIDENT_ABOUT_URL
    : process.env.REACT_APP_EVIDENT_MAIN_URL + "/about-us";

  const socials = [
    {
      image: Facebook,
      link: "https://www.facebook.com/evidentdigital",
    },
    {
      image: Insta,
      link: "https://www.instagram.com/evidentdigital/",
    },
    {
      image: Linkedin,
      link: "https://www.linkedin.com/company/evidentdigital/",
    },
    {
      image: Youtube,
      link: "https://www.youtube.com/@evidentdigital",
    },
    {
      image: Tiktok,
      link: "https://www.tiktok.com/@evidentdigital",
    },
  ];

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {children}
      <div style={{ paddingTop: "2rem" }}></div>
      <footer
        style={{
          boxSizing: "border-box",
          backgroundColor: "#101010",
          marginTop: "auto",
          display: "flex",
        }}
      >
        <Container maxWidth="lg" disableGutters>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              paddingY: {lg: 1},
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgb(88, 88, 88)",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ height: "45px" }}
            >
              <Box component="a" href={homeLink} sx={{ marginRight: "50px" }}>
                <img
                  src={defaultLogo}
                  alt="Evident"
                  style={{ maxHeight: "25px" }}
                />
              </Box>
              <Box
                component="a"
                href={homeLink}
                sx={{
                  lineHeight: "1.5",
                  textDecoration: "none",
                  color: "white",
                  "&: hover": {
                    color: brandBlue,
                  },
                }}
              >
                <Typography fontSize={{xs: "14px", lg: "16px"}}>Home</Typography>
              </Box>
              <Box
                component="a"
                href={aboutLink}
                sx={{
                  marginLeft: "40px",
                  lineHeight: "1.5",
                  textDecoration: "none",
                  color: "white",
                  "&: hover": {
                    color: brandBlue,
                  },
                }}
              >
                <Typography fontSize={{xs: "14px", lg: "16px"}}>About Us</Typography>
              </Box>
              <Box
                component="a"
                href="https://evidentdigital.com/wp-content/uploads/2024/09/Evident-License-and-Services-Agreement.pdf"
                sx={{
                  marginLeft: "40px",
                  lineHeight: "1.5",
                  textDecoration: "none",
                  color: "white",
                  "&: hover": {
                    color: brandBlue,
                  },
                }}
                target="_blank"
                rel="noreferrer"
              >
                <Typography fontSize={{xs: "14px", lg: "16px"}}>Evident License and Services Agreement</Typography>
              </Box>
              <Box
                component="a"
                href="https://evidentdigital.blob.core.windows.net/public/Evident_Privacy_policy.pdf"
                sx={{
                  marginLeft: "40px",
                  lineHeight: "1.5",
                  textDecoration: "none",
                  color: "white",
                  "&: hover": {
                    color: brandBlue,
                  },
                }}
                target="_blank"
                rel="noreferrer"
              >
                <Typography fontSize={{xs: "14px", lg: "16px"}}>Privacy Policy</Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              sx={{ height: "45px"} }
            >
              {socials.map((social) => (
                <a href={social.link} target="_blank" rel="noreferrer">
                  <img src={social.image} alt={social.link} />
                </a>
              ))}
            </Stack>
          </Box>
          <div
            style={{
              width: "100%",
              height: "46px",
              boxSIzing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#3EC1ED",
                margin: 0,
                fontSize: "16px",
                lineHeight: "0.75rem",
                display: "inline-block",
              }}
            >
              Copyright Evident 2023. All Rights Reserved.
            </p>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;
