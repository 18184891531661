import {
  Alert,
  Dialog,
  DialogContent,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import DragAndDropImage from "../../images/drag-and-drop.svg";
import { useDropzone } from "react-dropzone";
import LargeButton from "./button/largeButton";
import Check from "@mui/icons-material/Check";
import { brandBlue, brandColor } from "../../constants";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";

const UploadFileDialog = ({
  open,
  uploading,
  onClose,
  onUpload,
  additionalFields,
  title,
  error,
  resetError,
  isIos,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    ...(isIos && {
      accept: {
        "application/octet-stream": [
          ".stl",
          ".obj",
          ".ply",
          ".zip",
          ".ZIP",
          ".rar",
          ".RAR",
          ".7z",
          "7Z",
        ],
      },
    }),
    minSize: 1,
    onDropRejected: (files) => {
      const errors = [];
      files.forEach(
        (f) =>
          f.errors?.length > 0 &&
          errors.push({ name: f.file.name, error: f.errors[0].code })
      );
      let message = "";
      if (errors[0].error === "file-invalid-type") {
        message = errors[0].name + " is not a STL, PLY, OBJ, ZIP, RAR, or 7z file";
      } else if (errors[0].error === "file-too-small") {
        message = errors[0].name + " is empty";
      } else {
        message = "Something went wrong with your file";
      }
      dispatch(
        openSnackbar({
          title: "Upload failed",
          type: "error",
          body: message,
        })
      );
    },
    onDropAccepted: (files) => {
      if (resetError) {
        resetError();
      }
      setFiles(files);
    },
  });

  const handleClose = () => {
    if (uploading) return;
    onClose();
    setFiles([]);
  };

  const handleConfirm = async () => {
    try {
      await onUpload(files);
      setFiles([]);
    } catch (err) {
      console.log(error);
    }
  };

  const imagePaddingTop = 4;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="body"
      maxWidth="sm"
      PaperProps={{
        sx: {
          paddingY: 4,
          paddingX: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <DialogContent>
        <Stack alignItems="center" sx={{ marginBottom: 3 }}>
          <Box
            sx={{
              display: "inline-block",
              width: "80px",
              height: "80px",
              backgroundColor: "#EBF9FF",
              borderRadius: "100%",
            }}
          >
            <CloudUploadIcon
              sx={{
                display: "block",
                margin: "auto",
                height: "100%",
                color: "#d7d7d7",
                fontSize: 54,
              }}
            />
          </Box>
          <Typography
            component="h2"
            variant="h6"
            sx={{ marginTop: 2, fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <Typography>{/* No content */}</Typography>
        </Stack>
        {additionalFields}
        <Box sx={{ paddingTop: 2, paddingBottom: 4 }}>
          <Stack direction="row" justifyContent="space-between">
            <InputLabel
              id="file-label"
              sx={{
                marginBottom: 2,
                fontWeight: "bold",
                fontSize: "1.15em",
                color: "#2E3246",
              }}
            >
              Select Files:
            </InputLabel>
            {files.length > 0 && <Box>Number of files: {files.length}</Box>}
          </Stack>
          <Box
            sx={{
              height: "225px",
              padding: 2,
              backgroundColor: "#F3F9FE",
              border: "2px dashed " + brandBlue,
              borderColor: files.length > 0 ? brandColor : brandBlue,
              borderRadius: "27px",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {files.length > 0 ? (
              <Stack
                spacing={1}
                sx={{
                  width: "100%",
                  height: "100%",
                  margin: "auto",
                }}
              >
                {files.map((file, i) => (
                  <Stack
                    spacing={1}
                    key={file.path + file.lastModified + i}
                    direction="row"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "100%",
                        width: "30px",
                        height: "30px",
                        boxSizing: "border-box",
                      }}
                    >
                      <Check
                        color="success"
                        sx={{
                          display: "block",
                          margin: "auto",
                          height: "100%",
                        }}
                      />
                    </Box>
                    <Typography>{file.path}</Typography>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={DragAndDropImage}
                  sx={{ marginBottom: imagePaddingTop }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {files.length > 0 && (
          <Typography sx={{ marginY: 2, textAlign: "center" }}>
            Are you sure you want to continue with these files?
          </Typography>
        )}
        {error && (
          <Alert severity="error" sx={{ marginBottom: 4 }}>
            {error}
          </Alert>
        )}
        <Stack direction="row" spacing={2}>
          <LargeButton variant="gray" fullWidth onClick={handleClose}>
            Back
          </LargeButton>
          <LargeButton
            variant="primary"
            fullWidth
            loading={uploading}
            onClick={handleConfirm}
          >
            Confirm
          </LargeButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFileDialog;
