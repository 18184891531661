import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadLargeButton = ({
  path,
  fileName,
  label,
  children,
  variant = "outlined",
  fullWidth = false,
  isLoading,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const variants = {
    outlined: {
      color: "#2E3246",
      background: "none",
      border: "2px solid #2E3246",
    },
    secondary: {
      bgColor: "#2E3246",
      color: "#F8F8F8",
    },
    primary: {
      background: "linear-gradient(270deg, #11C984 0%, #32BE60 100%)",
      color: "#F8F8F8",
    },
    gray: {
      bgColor: "#777777",
      color: "#F8F8F8",
    },
    red: {
      bgColor: "#ff0000",
      color: "#fff",
    },
  };

  const style = variants[variant];
  console.log(style);

  const handleClickDownload = async () => {
    setLoading(true);
    let url = await path();

    const link = document.createElement("a");

    console.log(fileName);
    link.href = url;
    link.setAttribute("download", fileName);
    link.target = "_blank";
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
    console.log(link);
    document.body.removeChild(link);

    setLoading(false);
  };

  const getLabel = () => {
    if (label === null || label === undefined) return "Download";

    return label;
  };

  return (
    <Box
      target="_blank"
      component="button"
      disabled={loading}
      onClick={handleClickDownload}
      sx={{
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "bold",
        color: style.color,
        paddingX: 6,
        paddingY: loading ? 1 : 2,
        opacity: loading ? 0.4 : 1,
        cursor: loading ? "not-allowed" : "pointer",
        background: style.background,
        backgroundColor: style.bgColor,
        border: style.border || "2px solid transparent",
        borderRadius: "30px",
        "&:hover": {
          background: style.background,
        },
        width: fullWidth ? "100%" : undefined,
      }}
      {...props}
    >
      {loading ? (
        <CircularProgress size={30} sx={{ color: "white" }} />
      ) : (
        <Typography fontWeight="500">{children}</Typography>
      )}
    </Box>
  );
};

export default DownloadLargeButton;
