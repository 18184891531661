import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import CaseService from "../../services/case.service";
import StyledButton from "../common/button/largeButton";
import RejectDialog from "./rejectDialog";

const RequestRedesignButton = ({ caseDetails, onRedesign }) => {
  const [open, setOpen] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);

  const handleRequestRedesign = async (notes) => {
    setOpen(false);

    let commentModel = {
      CaseId: caseDetails.id,
      CommentAttachments: [],
      CommentedBy: "",
      Message: notes,
      PersonnelId: userInfo.Id,
      SendFromOutsideHub: false,
      SendMessageNotification: false,
      SendToHubOnly: false,
    };

    await CaseService.requestredesign(
      convertNightguardProducts(),
      commentModel
    ).then((res) => {
      onRedesign();
    });
  };

  const convertNightguardProducts = () => {
    let caseModel = caseDetails;

    var treatmentList = [];

    caseModel.treatment.forEach((x) => {
      if (
        x == "Flat Plane Occlusal Nightguard with Platform" ||
        x == "Standard Nightguard (no platform)"
      ) {
        treatmentList.push("Nightguard");
      } else {
        treatmentList.push(x);
      }
    });

    caseModel.treatment = treatmentList;

    caseModel.caseTreatments.forEach((x) => {
      if (
        x.treatmentType == "Flat Plane Occlusal Nightguard with Platform" ||
        x.treatmentType == "Standard Nightguard (no platform)"
      ) {
        x.treatmentType = "Nightguard";
      }
    });

    return caseModel;
  };

  return (
    <>
      <RejectDialog
        title={"Redesign Request"}
        placeholder={"Provide notes for the Redesign Request (REQUIRED)"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={handleRequestRedesign}
      />

      <StyledButton
        variant="orange"
        onClick={() => {
          setOpen(true);
        }}
      >
        Request Redesign
      </StyledButton>
    </>
  );
};

export default RequestRedesignButton;
