import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AdminService from "../../services/admin.service";
import { useSelector } from "react-redux";
import { brandColor } from "../../constants";
import { set } from "date-fns";
import { removeParameterFromUrl } from "../../helpers/removeParameterFromUrl";

const kois_domain = "KOIS";
const design_domain = "EvidentDesign";

function CardDetails({ isSuccessful }) {
  const [paymentError, setPaymentError] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [expiryMonth, setExpiryMonth] = React.useState("");
  const [expiryYear, setExpiryYear] = React.useState("");
  const [ccv, setCcv] = React.useState("");
  const [paymentDetailsFromDB, setPaymentDetailsFromDB] = React.useState(null);
  const [hasPaymentDetails, setHasPaymentDetails] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const userInfo = useSelector((state) => state.userInfo) || {};
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const luhn_checksum = (code) => {
    var len = code.length;
    var parity = len % 2;
    var sum = 0;
    for (var i = len - 1; i >= 0; i--) {
      var d = parseInt(code.charAt(i));
      if (i % 2 === parity) {
        d *= 2;
      }
      if (d > 9) {
        d -= 9;
      }
      sum += d;
    }
    return sum % 10;
  };

  const allowOnlyNumber = (value, field) => {
    setValue(field, value.replace(/[^0-9]/g, ""));
  };

  const confirmSubmission = (values) => {
    const searchParams = new URLSearchParams(window.location.search);
    setAccountName(values.accountName);
    setCardNumber(values.cardNumber);
    setExpiryMonth(values.expiryMonth);
    setExpiryYear(values.expiryYear);
    setCcv(values.ccv);
    if (luhn_checksum(values.cardNumber) === 0) {
      setLoading(true);
      let paymentDetailsModel = {
        id: userInfo.Id,
        name: userInfo.FirstName + " " + userInfo.LastName,
        email: userInfo.Email,
        cardHolderName: values.accountName,
        creditCardNumber: values.cardNumber,
        expirationMonth: Number(values.expiryMonth),
        expirationYear: Number(values.expiryYear),
        cvvNumber: values.ccv,
        identifier: searchParams.get('identifier')
      };
      if (hasPaymentDetails) {
        AdminService.updatePaymentDetails(paymentDetailsModel, design_domain)
          .then((response) => response.json())
          .then((json) => {
            setLoading(false);
            if (json.error === null) {
              setPaymentError("Successfully updated payment details.");
              setHasPaymentDetails(true);
              isSuccessful(true);
              window.location.refresh();
            } else {
              setLoading(false);
              setPaymentError(json.error.message);
            }
          });
      } else {
        AdminService.addPaymentDetails(paymentDetailsModel, design_domain)
          .then((response) => response.json())
          .then((json) => {
            if (json.error === null) {
              setLoading(false);
              setPaymentError("Successfully added card.");
              setHasPaymentDetails(true);
              isSuccessful(true);
              window.location.refresh();
            } else {
              setLoading(false);

              var errorMsg = JSON.parse(json.error.message);

              if (errorMsg.Error.DisplayMessage === null) {
                setPaymentError(errorMsg);
              } else {
                setPaymentError(errorMsg.Error.DisplayMessage);
              }
            }
          });
      }
    } else {
      setPaymentError("Invalid credit card number");
    }
  };

  React.useEffect(() => {
    setLoading(true);
    let model = {
      domain: design_domain,
      id: userInfo.Id,
      email: userInfo.Email,
    };

    AdminService.getPaymentDetails(model)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);

        if (json.error === null) {
          if (json.data && json.data.length > 0) {
            let result = JSON.parse(json.data[0].attributes);
            setPaymentDetailsFromDB(JSON.parse(json.data[0].attributes));
            const d = new Date();
            const year = d.getFullYear();
            const cardExpired =
              Number.parseInt(result.ExpirationYear) &&
              year > Number.parseInt(result.ExpirationYear);

            if (cardExpired) {
              setPaymentError(
                "We noticed that your credit card in your profile has expired,\nkindly update below.\n\nThank you."
              );
            }
            setHasPaymentDetails(true);

            setValue("accountName", result.Name);

            setValue("cardNumber", "**** **** **** " + result.Number);

            setValue("expiryMonth", result.ExpirationMonth);

            setValue("expiryYear", result.ExpirationYear);
            setValue("ccv", result.Cvv);
          }
        }
      });
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress color="success" />
      ) : (
        <Box
          sx={{
            background: '"rgba(255, 255, 255, 0.5)"',
            backdropFilter: " blur(20px)",
            borderRadius: "16px",
            width: "100%",

            paddingBottom: "45px",
          }}
        >
          {paymentError !== "" ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: "red",
                paddingBottom: "45px",
                whiteSpace: "pre-line",
              }}
            >
              <b>{paymentError}</b>
            </Box>
          ) : (
            ""
          )}
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#37465D",
                }}
              >
                Card Number
              </Box>
              <Controller
                name="cardNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Card number is required.",
                  },
                  minLength: {
                    value: 15,
                    message: "Card number must at least be 15 numbers.",
                  },
                  maxLength: {
                    value: 16,
                    message: "Card number cannot exceed be 16 numbers.",
                  },
                }}
                defaultValue={cardNumber}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    onChange={(text) =>
                      allowOnlyNumber(text.target.value, "cardNumber")
                    }
                    placeholder="Card Number"
                    sx={{ width: "100%", marginTop: "5px" }}
                  />
                )}
              />
              <Box sx={{ color: "red" }}>
                {errors.cardNumber?.message && errors.cardNumber?.message}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#37465D",
                }}
              >
                Account Name
              </Box>
              <Controller
                name="accountName"
                defaultValue={accountName}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="accountName"
                    variant="outlined"
                    placeholder="Account Name"
                    sx={{ width: "100%", marginTop: "5px" }}
                  />
                )}
              />
              <Box sx={{ color: "red" }}>
                {errors.accountName && "Account Name is required"}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#37465D",
                  marginTop: "20px",
                }}
              >
                Expiry Date
              </Box>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Controller
                    name="expiryMonth"
                    defaultValue={expiryMonth}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Card number is required.",
                      },
                      min: { value: 1, message: "Invalid month." },
                      max: { value: 12, message: "Invalid month." },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        variant="outlined"
                        placeholder="Expiry Month"
                        sx={{ width: "100%", marginTop: "5px" }}
                      />
                    )}
                  />
                  <Box sx={{ color: "red" }}>
                    {errors.expiryMonth?.message && errors.expiryMonth?.message}
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Controller
                    name="expiryYear"
                    defaultValue={expiryYear}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        variant="outlined"
                        placeholder="Expiry Year"
                        sx={{ width: "100%", marginTop: "5px" }}
                      />
                    )}
                  />
                  <Box sx={{ color: "red" }}>
                    {errors.expiryYear && "Year is required"}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#37465D",
                  marginTop: "20px",
                }}
              >
                CVV
              </Box>
              <Controller
                name="ccv"
                defaultValue={ccv}
                control={control}
                rules={{
                  required: { value: true, message: "CVV number is required." },
                  minLength: {
                    value: 3,
                    message: "CVV number must be at least be 3 numbers.",
                  },
                  maxLength: {
                    value: 4,
                    message: "CVV cannot be more than 4 numbers.",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="password"
                    onChange={(text) =>
                      allowOnlyNumber(text.target.value, "ccv")
                    }
                    variant="outlined"
                    placeholder="CVV"
                    sx={{ width: "100%", marginTop: "5px" }}
                  />
                )}
              />
              <Box sx={{ color: "red" }}>
                {errors.ccv?.message && errors.ccv?.message}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ paddingTop: "25px" }}>
            {hasPaymentDetails ? (
              <Button
                onClick={handleSubmit(confirmSubmission)}
                sx={{
                  textTransform: "none",
                  background: brandColor,
                  borderRadius: "5px",
                  width: "250px",
                  height: "60px",
                  color: "#fff",
                  "&:hover": { background: brandColor },
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleSubmit(confirmSubmission)}
                sx={{
                  textTransform: "none",
                  background: brandColor,
                  borderRadius: "5px",
                  width: "250px",
                  height: "60px",
                  color: "#fff",
                  "&:hover": { background: brandColor },
                }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default CardDetails;
