import {
  CircularProgress,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import LargeButton from "../common/button/largeButton";
import ContentContainer from "../common/contentContainer";
import usePatientFiles from "../../hooks/usePatientFiles";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import { Stack } from "@mui/material";
import DownloadButton from "../common/button/downloadButton";
import DownloadFileButton from "../common/button/downloadFileButton";
import NoFilesAlert from "./noFilesAlert";
import UploadFileDialog from "../common/uploadFileDialog";
import useUpload from "../../hooks/useUpload";

const type = "digitalPrescription";

const DigitalPrescription = ({ patientId }) => {
  const {
    files,
    loading,
    pages,
    currentPage,
    handleChangePage,
    handleAddFiles,
  } = usePatientFiles({
    patientId,
    type: type,
    take: 20,
  });
  const { uploading, upload } = useUpload({ patientId, type });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const headers = ["Photo Set", "Date Created", " "];
  const rows = files.map((file) => [
    file.fileName,
    moment(file.uploadedDate).format("LLL"),
    <DownloadFileButton path={file.path} fileName={file.fileName} />,
  ]);

  const handleClickPagination = (event, value) => {
    handleChangePage(value);
  };

  const handleUpload = async (files) => {
    const data = await upload(files);
    handleAddFiles(data);
    setIsDialogOpen(false);
  }

  return (
    <ContentContainer>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 4 }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", marginBottom: 1.25 }}
          >
            Digital Prescription
          </Typography>
          <Typography
            variant="body2"
            sx={{
              width: "90%",
              marginBottom: 3,
            }}
          >
            {/* No content */}
          </Typography>
          <LargeButton variant="primary" onClick={() => setIsDialogOpen(true)}>
            Upload Files
          </LargeButton>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
            Download Fillable Lab Communication Forms:
          </Typography>
          <Stack alignItems="flex-start">
          <DownloadButton
              path={() => "https://evidentdigital.blob.core.windows.net/public/LabCommunicationForm_Appliance(Fillable).pdf"}
              fileName="LabCommunicationForm_Applicance(Fillable).pdf"
              label="Appliance Form"
              variant="text"
            />
            <DownloadButton
              path={() => "https://evidentdigital.blob.core.windows.net/public/LabCommunicationForm_Fixed(Fillable).pdf"}
              fileName="LabCommunicationForm_Fixed(Fillable).pdf"
              label="Crown, Bridge, Teeth, Or Implants"
              variant="text"
            />
            <DownloadButton
              path={() => "https://evidentdigital.blob.core.windows.net/public/LabCommunicationForm_Removable(Fillable).pdf"}
              fileName="LabCommunicationForm_Removable(Fillable).pdf"
              label="Removable with or without implants"
              variant="text"
            />
          </Stack>
        </Box>
      </Stack>
      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
          marginBottom: 5,
        }}
      />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          <React.Fragment>
            <DataTable headers={headers}>
              {rows.map((row, i) => (
                <TableRow key={i} row={row} />
              ))}
            </DataTable>
            <Pagination
              count={pages}
              page={currentPage}
              onChange={handleClickPagination}
              sx={{ marginY: 2 }}
            />
          </React.Fragment>
        ) : (
          <NoFilesAlert />
        ))}
      <UploadFileDialog
        title="Upload Digital Prescription"
        open={isDialogOpen}
        uploading={uploading}
        onClose={() => setIsDialogOpen(false)}
        onUpload={handleUpload}
      />
    </ContentContainer>
  );
};

export default DigitalPrescription;
