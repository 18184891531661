
const _apiUrl = process.env.REACT_APP_ADMIN_API_END_POINT.split('=')[1];

const AdminHubService = {
    getProductPricing: function (model) {
        let data = {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
            }
        };

        return fetch(_apiUrl + 'customer/getcustomerproductprices', data);
    },
    getInvoices:  function (model) {        
        let data = {
          method: "POST",
          body: JSON.stringify(["KOIS","EvidentDesign"]),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        };
    
        
          return fetch(
          _apiUrl +
            "invoice/getinvoiceslist?" +
            `customerId=${model.userId}` +            
            `&skip=${model.skip}` +
            `&take=${model.take}` +
            `&invoiceNumber=${model.invoiceNumber}` +
            `&fromDate=${model.fromDate}` +
            `&endDate=${model.endDate}`,
          data,{mode: 'no-cors'}
        );
       
        
      },
      getCaseSendingStatus: function (model) {
        let data = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
            }
        };

        return fetch(_apiUrl + 'invoice/getdesigncasesendingstatus?casesAmount=0', data);
    },
};

export default AdminHubService;