import {
  Autocomplete,
  ButtonBase,
  CircularProgress,
  FormHelperText,
  FormLabel,
  MenuItem,
  Checkbox,
  Stack,
  TextField,
  Typography,
  Grid,
  Portal,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { Box } from "@mui/system";
import {
  DatePicker,
  // DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment-timezone";
// import { TimePicker as TimePickerAnt } from "antd";
// import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { brandColor } from "../../../constants";
import AdminService from "../../../services/admin.service";
import PatientService from "../../../services/patient.service";
import DialogContainer from "../../common/dialogContainer";
import LoadingModal from "../../common/modal/loadingModal";
import PatientDetailsDialog from "../../patient/patientDetailsDialog";
import { updatePatient, updatePatientId, updateProductToAdd } from "../../../redux/actions";

const Field = ({ label, children }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <FormLabel
        sx={{
          color: "#000",
          fontSize: "18px",
          fontWeight: 500,
          marginBottom: "12px",
        }}
      >
        {label}
      </FormLabel>
      {children}
    </Stack>
  );
};

const Button = ({ children, sx, ...props }) => (
  <ButtonBase
    sx={{
      marginTop: 2,
      paddingY: 1.875,
      paddingX: 8,
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "19px",
      color: "white",
      background: brandColor,
      borderRadius: "100px",
      ...sx,
    }}
    type="button"
    {...props}
  >
    <Typography fontWeight="600" fontSize="15px" lineHeight="19px">
      {children}
    </Typography>
  </ButtonBase>
);

const PrebookDialog = ({ open, products, onClose, onOrder }) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState("");

  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState(null);
  const [practicePatients, setPracticePatients] = useState([]);
  const [practices, setPracticeList] = useState([]);
  const [practice, setPractice] = useState("");
  const [dentists, setDentists] = useState([]);
  const [dentist, setDentist] = useState("");

  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingSlots, setFetchingSlots] = useState(false);
  const [slots, setSlots] = useState([]);
  const [setupCall, setSetupCall] = useState(true);
  const [setupInquiry, setSetupInquiry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingNewPatient,setIsAddingNewPatient] = useState(false);

  const userInfo = useSelector((state) => state.userInfo);
  const currentPatient = useSelector((state) => state.patient);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setFetchingData(true);
      const practicesResponse = await AdminService.getPractices(userInfo.Id);
      const practices = await practicesResponse.json();
      setPracticeList(practices.practices);

        // set default practice
        let defaultPractice = practices.practices.find(
          (x) => x.isPrimary === true
        );
        if (
          defaultPractice !== null &&
          defaultPractice !== undefined &&
          defaultPractice !== "undefined"
        ) {
          setPractice(currentPatient && currentPatient.PracticeId ? currentPatient.PracticeId : defaultPractice.id);
        }
      

      // set dentists
      if (!userInfo.Roles.includes("Dentist")) {
        // create practice dentists dictionary/object
        const practiceDentists = {};
        userInfo.PracticeWithPersonnel.forEach((pp) => {
          // if practice already in object append to array, else initialize it
          const value = practiceDentists[pp.practiceId];
          practiceDentists[pp.practiceId] = value
            ? [...value, pp.personnelId]
            : [pp.personnelId];
        });

        /// get dentist details
        const dentistsPromises = practices.practices.map(async (practice) => {
          const response = await AdminService.getDentistsByPracticeid(
            practice.id
          );
          const data = await response.json();
          return data;
        });

        await Promise.all(dentistsPromises).then((values) => {
          const dentists = {};
          practices.practices.forEach(
            (practice, i) =>
              // filter dentist where the dentist should be in practiceDentists object
              // since dentistsPromises return all dentists in a practice
              (dentists[practice.id] = values[i].filter((dentist) =>
                practiceDentists[practice.id].includes(dentist.id)
              ))
          );
          setDentists(dentists);
        });
      }

      const getPatients = async () => {
        const pId = userInfo.PracticeWithPersonnel.map((x) => x.personnelId);
        console.log("psp", userInfo);
        const patientsResponse = await PatientService.getPatientList(pId);
        const allPatients = await patientsResponse.json();

        setPatients(allPatients);
        
      };

      await getPatients();
      setFetchingData(false);
    };

    getData();
  }, [userInfo]);

  useEffect(()=>{
    if(currentPatient && currentPatient.Id){
      const preSelectedPatient = patients.filter(x => x.id == currentPatient.Id);

      if(preSelectedPatient.length){
        setPatient(preSelectedPatient[0]); 
        dispatch(updatePatient(null));
        dispatch(updatePatientId(null));
        dispatch(updateProductToAdd(null));
      }
    }    
  },[patients,currentPatient]);

  useEffect(() => {
    if (practice && patients.length > 0) {
      setPracticePatients(() => {
        const patientsPractice = patients.filter(
          (patient) => patient.practiceId === practice
        );

        console.log(
          "PSP",
          patientsPractice.sort((a, b) => a.lastName.localeCompare(b.lastName))
        );
        if (!userInfo.Roles.includes("Dentist")) {
          return patientsPractice
            .filter((patient) => patient.personnelId === dentist)
            .sort((a, b) => a.lastName.localeCompare(b.lastName));
        } else {
          return patientsPractice.sort((a, b) =>
            a.lastName.localeCompare(b.lastName)
          );
        }
      });
    }
  }, [practice, dentist, patients, userInfo]);

  useEffect(() => {
    // reset values
    if (open) return;
    setDate(null);
    setProduct(null);
    setTime(null);
    setError("");
    setDentist("");
    setPatient(null);
    setSlots([]);
    setSetupCall(false);
    setSetupInquiry(false);
  }, [open]);

  const handleProceed = async () => {
    try {
      setIsLoading(true);
      if (!patient) {
        setError("Patient is required");
        return;
      } else if (!practice) {
        setError("Date is required");
        return;
      } else if (!userInfo.Roles.includes("Dentist") && !dentist) {
        setError("Dentist is required");
        return;
      } else if (!date) {
        setError("Date is required");
        return;
      } else if (!product) {
        setError("Product is required");
        return;
      } else if (!time) {
        setError("Time is required");
        return;
      }

      const timeObj = new Date(`01/01/1970 ${time}`);
      const dateObj = new Date(date);
      dateObj.setHours(timeObj.getHours());
      dateObj.setMinutes(timeObj.getMinutes());
      dateObj.setSeconds(0);

      await onOrder(
        patient,
        dateObj,
        setupCall,
        setupInquiry,
        products.find((p) => p.description === product)
      );
      dispatch(updatePatient(null));
      dispatch(updatePatientId(null));
      dispatch(updateProductToAdd(null));
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const textFieldStyle = {
    bgcolor: "#fff",
    boxShadow: "2px 2px 40px rgba(0, 0, 0, 0.07)",
    width: "100%",
    outline: "none",
    borderRadius: "10px",
    borderColor: "transparent",
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& > fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        borderColor: "#1976d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1976d",
      },
    },
  };

  const handleDateChange = async (date) => {
    setDate(date);
    setTime(null);
    setSlots([]);

    if (!date || isNaN(date)) {
      return;
    }
    const selectedDate = new Date(date);
    const today = new Date();

    const timeArray = [];

    let startDate = new Date(selectedDate);
    const endDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      17,
      30,
      0
    );

    // console.log("sws", startDate, endDate);
    // // Calculate the difference between the two dates in milliseconds
    const timeDiff = Math.abs(startDate.getTime() - today.getTime());

    // // Convert the time difference to hours
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    // // If the selected date is not today, start from 12:00 am
    if (hoursDiff > 24) {
      startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        8,
        0,
        0
      );
    } else {
      const minutes = startDate.getMinutes();
      if (minutes >= 30) {
        startDate.setMinutes(0);
        startDate.setHours(startDate.getHours() + 1);
      } else {
        startDate.setMinutes(30);
      }
    }

    while (startDate <= endDate) {
      const formattedTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      timeArray.push(formattedTime);
      startDate.setMinutes(startDate.getMinutes() + 30);
    }

    setSlots(timeArray);
  };

  const handleDentistChange = (e) => {
    setDentist(e.target.value);
    setPatient("");
  };

  const handlePracticeChange = (e) => {
    setPractice(e.target.value);
    setPatient("");
    setDentist("");
  };

  const handleCloseNewPatient = () => {
    setIsAddingNewPatient(false);
  };

  const handleNewPatientSuccess = (patientId,form) => {
    form.id = patientId;
    setPracticePatients(prev => {
      return [...prev,form];
    })

    setPatient(form);
    setIsAddingNewPatient(false);
  };

  const completeValues =
    Boolean(practice) &&
    Boolean(patient) &&
    Boolean(date) &&
    Boolean(time) &&
    Boolean(product);

  const tomorrowMoment = moment().add(24, "hours");

  return (
    <DialogContainer
      open={open}
      onClose={() => onClose(product)}
      maxWidth="md"
      bg="#fafafa"
    >
      <LoadingModal open={fetchingData} message="Loading patients" />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "35px",
          boxSizing: "border-box",
        }}
      >
        <Box width="100%">
          <Typography
            component="h2"
            sx={{ fontSize: "35px", fontWeight: "700" }}
          >
            Pre-book a Same-Day Case
          </Typography>
          <Typography sx={{ color: "#666666" }}>
            Schedule your case now to align with your patient appointment
          </Typography>
        </Box>

        <Stack spacing={3.75} marginTop={3} sx={{ width: "100%" }}>
          <Field label="Select Treatment">
            <Autocomplete
              value={product}
              onChange={(_, newValue) => {
                setError("");
                setProduct(newValue);
              }}
              options={products.map((p) => p.description)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error !== ""}
                  sx={textFieldStyle}
                  placeholder="Select Treatment"
                />
              )}
            />
          </Field>
          <Field label="Practice">
            <TextField
              id="dental-practice"
              select
              variant="outlined"
              value={practice}
              onChange={handlePracticeChange}
              error={error !== ""}
              sx={textFieldStyle}
              placeholder="Practice"
              field
            >
              {practices.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Field>
          {!userInfo.Roles.includes("Dentist") && (
            <Field label="Dentist">
              <TextField
                id="dentist"
                select
                variant="outlined"
                value={dentist}
                onChange={handleDentistChange}
                disabled={!practice || practice === ""}
                error={error !== ""}
                sx={textFieldStyle}
                placeholder="Dentist"
              >
                {Boolean(dentists[practice]) ? (
                  dentists[practice].map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.firstName} ${option.lastName}`}
                    </MenuItem>
                  ))
                ) : (
                  <div></div>
                )}
              </TextField>
            </Field>
          )}
          <Grid container>
            <Grid item xs="10">
              <Field label="Patient Name">
                <Autocomplete
                  id="patient"
                  onChange={(event, value) => setPatient(value)}
                  getOptionLabel={(option) =>
                    option ? `${option.firstName} ${option.lastName}` : ""
                  }
                  options={practicePatients}
                  value={patient}
                  disabled={
                    !userInfo.Roles.includes("Dentist") &&
                    (!Boolean(dentist) || dentist === "")
                  }
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      key={option.id}
                    >
                      {option.firstName} {option.lastName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Patient Name"
                      error={error !== ""}
                      sx={textFieldStyle}
                    />
                  )}
                />
              </Field>
            </Grid>
            <Grid item xs="2" sx={{display:"flex",alignItems:"end"}}>
              <Button sx={{borderRadius: '5px', width: '24px', marginLeft: '5px', paddingX: '60px'}} onClick={()=>setIsAddingNewPatient(true)} ><Typography sx={{display:"flex"}}><AddIcon /> Add</Typography></Button>
            </Grid>
          </Grid>

          
          {/* <TimePickerAnt
            style={{
              padding: "15px 14px",
            }}
            className="test"
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
            onChange={(time, timeString) => {
              setTime(time);
            }}
            defaultOpenValue={dayjs("00:00", "h:mm")}
            format={"h:mm a"}
            use12Hours
            size="large"
            minuteStep={15}
          /> */}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
              <FormLabel
                sx={{
                  color: "#000",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Patient Scan to be Uploaded On
              </FormLabel>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Field label="Date">
                  <DatePicker
                    value={date}
                    onChange={handleDateChange}
                    minDate={new Date(tomorrowMoment)}
                    minutesStep={5}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => e.preventDefault()}
                        {...params}
                        error={error !== ""}
                        sx={textFieldStyle}
                        placeholder="Date"
                      />
                    )}
                  />
                </Field>
                <Field
                  label={
                    <Box component="span">
                      Local Time
                      {fetchingSlots && (
                        <CircularProgress size="18px" sx={{ marginLeft: 1 }} />
                      )}
                    </Box>
                  }
                >
                  <Autocomplete
                    value={time}
                    onChange={(_, newValue) => {
                      console.log(newValue);
                      setError("");
                      setTime(newValue);
                    }}
                    options={slots}
                    disabled={fetchingSlots}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={error !== ""}
                        sx={textFieldStyle}
                        placeholder="Select Time"
                      />
                    )}
                  />
                  <FormHelperText>
                    {/* {date === null && "Select date to view available timeslots"} */}
                    {Boolean(date) &&
                      !fetchingSlots &&
                      slots.length === 0 &&
                      "No timeslots available for selected day"}
                  </FormHelperText>
                </Field>
              </Stack>
              <Typography
                sx={{ marginTop: 1, fontSize: "14px", color: "#6c6c6c" }}
              >
                <strong>* Please note</strong> that if you miss your scan upload
                time by more than 10 minutes, we are unable to guarantee that a
                designer will be available.
              </Typography>
            </Box>
          </LocalizationProvider>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center">
              <Checkbox
                id="setup-call"
                label="Schedule a call to review treatment plan prior to case submission"
                inputProps={{
                  "aria-label":
                    "Schedule a call to review treatment plan prior to case submission",
                }}
                checked={setupCall}
                sx={{ paddingLeft: 0 }}
                onChange={(e) => setSetupCall(e.target.checked)}
              />
              <Box
                component="label"
                htmlFor="setup-call"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "31px",
                  color: "#000",
                }}
              >
                Schedule a call to review treatment plan prior to case
                submission
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{ marginTop: 1, fontSize: "14px", color: "#6c6c6c" }}
              >
                <strong>* Please Note:</strong> We charge a non-refundable $99
                pre-booking fee
              </Typography>
            </Stack>
            {/* <Stack direction="row" alignItems="center">
              <Checkbox
                id="setup-inquiry"
                label="Schedule a call for general inquiries"
                inputProps={{
                  "aria-label": "Schedule a call for general inquiries",
                }}
                checked={setupInquiry}
                sx={{ paddingLeft: 0 }}
                onChange={(e) => setSetupInquiry(e.target.checked)}
              />
              <Box
                component="label"
                htmlFor="setup-inquiry"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "31px",
                  color: "#000",
                }}
              >
                Schedule a call for general inquiries
              </Box>
            </Stack> */}
          </Stack>
          {/* {error && <Alert severity="error">{error}</Alert>} */}
        </Stack>

        <Stack direction="row" alignItems="center" marginTop={3.375}>
          {isLoading ? (
            <CircularProgress color="success" />
          ) : (
            <>
              <Button
                sx={{
                  backgroundColor: "#AFAFAF",
                  marginRight: 2.75,
                }}
                onClick={() => {
                  onClose(product);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  ...(!completeValues && { filter: "brightness(90%)" }),
                }}
                onClick={handleProceed}
                disabled={!completeValues}
              >
                Submit
              </Button>
            </>
          )}
        </Stack>
      </Box>
      <Portal>
        <PatientDetailsDialog 
          open={isAddingNewPatient}
          isNewPatient={true}
          onClose={handleCloseNewPatient}
          onSuccess={handleNewPatientSuccess}
          practiceId={practice}
          practiceName={
            practices.find((practice) => practice.id === practice)
              ?.name || null
          }
          userId={userInfo && userInfo.Id}

        />
      </Portal>
    </DialogContainer>
  );
};

export default PrebookDialog;
