import {
  CircularProgress,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import ContentContainer from "../common/contentContainer";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import ExpandingTableRow from "../common/table/expandingTableRow";

import PatientService from "../../services/patient.service";
import DownloadFileButton from "../common/button/downloadFileButton";
import usePaginationValues from "../../hooks/usePaginationValues";
import { useSelector } from "react-redux";
import NoFilesAlert from "./noFilesAlert";

const CompletedDesigns = ({ patientId, personnelId }) => {
  const count = 10;
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const [total, setTotal] = useState(0);
  const { currentPage, skip, pagesCount, handleChangePage } =
    usePaginationValues(count, total);

  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    setLoading(true);
    const getDesigns = async () => {
      const response = await PatientService.getPatientCompletedDesigns({
        patientId,
        skip,
        personnelId: personnelId,
        take: count,
      });
      const data = await response.json();
      const { totalCount, caseList } = data;
      setCases(caseList);
      setTotal(totalCount);
      setLoading(false);
    };

    getDesigns();
  }, [skip, patientId, userInfo.Id]);

  const headers = ["Case Number", "Product Type", "Last Updated"];
  const rows = useMemo(
    () =>
      cases.map((caseField, i) => {
        return [
          caseField.caseRefNo,
          <Box>
            {caseField.treatment.map((t) => (
              <Box>{t}</Box>
            ))}
          </Box>,
          moment(caseField.updatedAt).format("LLL"),
        ];
      }),
    [cases]
  );
  const expandContents = useMemo(
    () =>
      cases.map((caseField) => {
        const data = {
          headers: ["Filename", "Date Uploaded", ""],
          rows: [],
        };

        data.rows = caseField.files.map((file) => {
          return [
            file.fileName,
            moment(file.uploadedDate).format("LLL"),
            <DownloadFileButton
              path={file.filePath}
              fileName={file.fileName}
            />,
          ];
        });

        const attachments = [];
        caseField.caseComments.forEach((comment) => {
          const commentHasAttachments = comment.commentAttachments.length > 0;
          if (commentHasAttachments) {
            comment.commentAttachments.forEach((attachment) => {
              const isExternal = attachment.externalPath !== "";
              const path = isExternal
                ? attachment.externalPath
                : attachment.path;

              attachments.push([
                attachment.fileName,
                moment(comment.commentedAt).format("LLL"),
                <DownloadFileButton
                  path={path}
                  fileName={attachment.fileName}
                  isExternal={isExternal}
                />,
              ]);
            });
          }
        });

        // comments is ordered latest -> oldest but it needs to be oldest -> latest
        attachments.reverse();
        data.rows.push(...attachments);

        if (!data.rows.length > 0) {
          return null;
        }

        return (
          <DataTable
            headers={["Filename", "Date Uploaded", ""]}
            sx={{ paddingX: 4 }}
          >
            {data.rows.map((row, i) => (
              <TableRow key={i} row={row} shouldUseDivider />
            ))}
          </DataTable>
        );
      }),
    [cases]
  );

  const handleClickPagination = (event, value) => {
    handleChangePage(value);
  };

  return (
    <ContentContainer>
      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: 1.25 }}
        >
          Completed Designs
        </Typography>
        <Typography
          variant="body2"
          sx={{
            width: "90%",
            marginBottom: 3,
          }}
        >          
        </Typography>
      </Box>
      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
          marginBottom: 5,
        }}
      />

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          <React.Fragment>
            <DataTable headers={headers}>
              {rows.map((row, i) => (
                <ExpandingTableRow
                  key={i}
                  row={row}
                  expandContent={expandContents[i]}
                />
              ))}
            </DataTable>
            <Pagination
              count={pagesCount}
              page={currentPage}
              onChange={handleClickPagination}
              sx={{ marginY: 2 }}
            />
          </React.Fragment>
        ) : (
          <NoFilesAlert />
        ))}
    </ContentContainer>
  );
};

export default CompletedDesigns;
