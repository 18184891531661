import { Container, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LargeButton from "../../components/common/button/largeButton";
import ContentContainer from "../../components/common/contentContainer";
import TextInput from "../../components/common/textInput";
import { forgotPasswordSchema } from "../../types/forgotPasswordSchema";
import AccountService from "../../services/account.service";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";
import { brandColor } from "../../constants";

const defaultValues = {
  newPassword: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: defaultValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => handlePasswordReset(values),
  });
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const code = searchParams.get("code");
  const email = searchParams.get("email");

  const handlePasswordReset = async ({ newPassword }) => {
    setUploading(true);
    try {
      const response = await AccountService.resetPassword({
        code: code,
        email,
        newPassword,
      });

      const data = await response.json();

      if (data.statusCode !== 10) {
        throw new Error(data.error.message);
      }

      navigate("/");
      setUploading(false);
      dispatch(
        openSnackbar({
          title: "Success",
          body: "Password changed successfully",
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#101010", height: 46 }} />
      <Container maxWidth="md">
        <ContentContainer sx={{ marginTop: 6, padding: 12 }}>
          <Box sx={{ textAlign: "center" }}>
            <Box
              component="h1"
              sx={{ fontSize: "28px", lineHeight: "41px", color: "#000" }}
            >
              Reset your password
            </Box>
            <Box
              sx={{
                fontWeight: "300",
                fontSize: "18px",
                marginBottom: "48px",
                color: "#585656",
              }}
            >
              Choose a new password (minimum 8 characters)
            </Box>
          </Box>

          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextInput
                type="password"
                label="New password"
                placeholder="Enter new password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                id="newPassword"
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <TextInput
                type="password"
                label="Confirm password"
                placeholder="Confirm new password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                id="confirmPassword"
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ marginTop: 4 }}
            >
              <LargeButton variant="gray" type="button" onClick={handleCancel}>
                Cancel
              </LargeButton>
              <LargeButton variant="primary" type="submit" loading={uploading}>
                Reset Password
              </LargeButton>
            </Stack>
          </form>
        </ContentContainer>
      </Container>
    </>
  );
};

export default ResetPassword;
