import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import StyledFormLabel from "./styledFormLabel";

// for react-hook-form
const InputText = ({ label, placeholder, errors, register, type = "text", ...props }) => {
  return (
    <FormControl variant="standard" error={errors && Boolean(errors.message)}>
      <Box
        sx={{
          color: "#37465D",
          fontWeight: "600",
          textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
          fontSize: "14px",
          lineHeight: "17px",
          marginBottom: "5px",
        }}
      >
        {label}
      </Box>
      <OutlinedInput
        size="small"
        type={type}
        placeholder={placeholder}
        {...register}
        {...props}
      />
      <FormHelperText variant="standard">{errors && errors.message}</FormHelperText>
    </FormControl>
  );
};

export default InputText;
