import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { brandBlue } from '../../../../constants'

const AboutTreatmentButton = ({product,onClickAboutTreatment}) => {
  return (
    <Button
        disableRipple
        sx={{
            width: "100%",
            marginTop: 3,
            paddingY: 2,
            color: brandBlue,
            borderRadius: "30px",
            backgroundColor: "white",
            borderColor: brandBlue,
        }}
        variant="outlined"
        onClick={()=>onClickAboutTreatment(true,product)}
    >
        <Typography
            fontWeight="600"
            fontSize="15px"
            lineHeight="19px"
        >
            About Treatment
        </Typography>
    </Button>
  )
}

export default AboutTreatmentButton