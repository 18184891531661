import React from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import caseCreatedIcon from "../../../images/case_created_icon.svg";

function CaseCreatedModal({ isOpen, handleClose, initUser }) {
  const closeDialog = () => {
    handleClose(false);
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth sx={{ textAlign: "center" }}>
      <Box sx={{ textAlign: "right", marginRight: "25px", marginTop: "35px" }}>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <img src={caseCreatedIcon} />
      </Box>

      <h1 style={{ marginTop: 0, marginBottom: "60px" }}>
        Thank you. <br /> Your case is now on its way!
      </h1>
    </Dialog>
  );
}

export default connect(null, null)(CaseCreatedModal);
