import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import logo from "../../images/evident_logo2.svg";
import AdminService from "../../services/admin.service";
import { Link } from "react-router-dom";

const LoginBranding = () => {
  const [brand, setBrand] = useState();

  const setBrandLogo = () => {
    let siteSettings = JSON.parse(localStorage.getItem("siteSettings"));
    console.log(siteSettings);

    let siteLogo = siteSettings.find((x) => x.name === "BrandLogo44");
    console.log(siteLogo);
    if (siteLogo !== null && siteLogo !== undefined) {
      setBrand(
        <Grid container spacing={1} sx={{ marginTop: 0 }}>
          <Grid
            item
            xs={6}
            sx={{
              padding: 0,
              ".MuiGrid-item": {
                padding: 0,
              },
            }}
          >
            <Link to="/">
              <Box component="img" src={logo} alt="Evident logo" />
            </Link>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              textAlign: "right",
              padding: 0,
              ".MuiGrid-item": {
                padding: 0,
              },
            }}
          >
            <Box
              component="img"
              sx={{ width: "auto", height: "51px" }}
              src={siteLogo.value}
              alt=""
            />
          </Grid>
        </Grid>
      );
    } else {
      setBrand(
        <Grid container spacing={1} sx={{ marginTop: 0 }}>
          <Grid
            item
            xs={6}
            sx={{
              padding: 0,
              ".MuiGrid-item": {
                padding: 0,
              },
            }}
          >
            <Link to="/">
              <Box component="img" src={logo} alt="Evident logo" />
            </Link>
          </Grid>
        </Grid>
      );
    }
  };
  useEffect(() => {
    console.log(localStorage.getItem("siteSettings"));
    if (
      localStorage.getItem("siteSettings") === null ||
      localStorage.getItem("siteSettings") === "" ||
      localStorage.getItem("siteSettings").length === 0 ||
      localStorage.getItem("siteSettings") === "[]"
    ) {
      console.log("site settings does not exist");
      AdminService.getAppSettings()
        .then((response) => response.json())
        .then((json) => {
          localStorage.setItem("siteSettings", JSON.stringify(json));
          setBrandLogo();
        });
    } else {
      setBrandLogo();
    }
  }, []);

  return <React.Fragment>{brand}</React.Fragment>;
};

export default LoginBranding;
