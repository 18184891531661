import {
  Chip,
  chipClasses,
  CircularProgress,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import LargeButton from "../common/button/largeButton";
import ContentContainer from "../common/contentContainer";
import usePatientFiles from "../../hooks/usePatientFiles";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import { Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DownloadFileButton from "../common/button/downloadFileButton";
import UploadFileDialog from "../common/uploadFileDialog";
import NoFilesAlert from "./noFilesAlert";
import useUpload from "../../hooks/useUpload";
import CaseNumberListDialog from "./casenumberListDialog";
import { brandBlue, brandColor, brandGray } from "../../constants";
import { blue, grey } from "@mui/material/colors";
import { LightbulbCircleOutlined } from "@mui/icons-material";

const CBCTImages = ({ patientId }) => {
  const type = "cbctImages";
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { uploading, upload } = useUpload({ patientId, type });
  const {
    files,
    loading,
    pages,
    currentPage,
    handleChangePage,
    handleAddFiles,
  } = usePatientFiles({
    patientId,
    type: type,
    take: 20,
  });
  const [isCaseNumberDialogOpen, setIsCaseNumberDialogOpen] = useState(false);
  const [filesToSend, setFilesToSend] = useState();

  const headers = ["Filename", "Date Created", " "];
  const rows = files.map((file) => [
    file.fileName,
    moment(file.uploadedDate).format("LLL"),
    <DownloadFileButton path={file.path} fileName={file.fileName} />,
    <LargeButton
      variant="primary"
      onClick={() => {
        setIsCaseNumberDialogOpen(true);
        setFilesToSend(file);
      }}
    >
      Send to Evident Design
    </LargeButton>,
  ]);

  const handleClickPagination = (event, value) => {
    handleChangePage(value);
  };

  const handleUpload = async (files) => {
    const data = await upload(files);
    handleAddFiles(data);
    setIsDialogOpen(false);
  };

  const handleSendToEvidentDesign = (caseNumber) => {
    setIsCaseNumberDialogOpen(false);
  };
  return (
    <ContentContainer>
      <Box sx={{ marginBottom: 4 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ marginBottom: 1.25 }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
            CBCT Images
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          sx={{
            width: "90%",
            marginBottom: 0.5,
          }}
        >
          CBCT datasets are captured in DICOM format and identified as *.dcm. To
          upload, select the file directory containing the CBCT files and drop
          it into the area below.
        </Typography>
        <Box sx={{ display: "inline-block" }}>
          <Stack alignItems="flex-start">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                marginBottom: 4,
                padding: "0.675rem 2.75rem",
                backgroundColor: grey[700],
                borderRadius: "10px",
              }}
            >
              <InfoIcon fontSize="small" sx={{ color: brandColor }} />
              <Typography
                sx={{ fontSize: "1rem", lineHeight: "1rem", color: "white" }}
              >
                <span>
                  <b>DICOM files </b>
                </span>
                need to be zipped
              </Typography>
            </Stack>

            <LargeButton
              variant="primary"
              onClick={() => setIsDialogOpen(true)}
            >
              Upload Zipped Files
            </LargeButton>
          </Stack>
        </Box>
      </Box>
      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
          marginBottom: 5,
        }}
      />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          <React.Fragment>
            <DataTable headers={headers}>
              {rows.map((row, i) => (
                <TableRow key={i} row={row} />
              ))}
            </DataTable>
            <Pagination
              count={pages}
              page={currentPage}
              onChange={handleClickPagination}
              sx={{ marginY: 2 }}
            />
          </React.Fragment>
        ) : (
          <NoFilesAlert />
        ))}
      <UploadFileDialog
        title="Upload CBCT Images"
        open={isDialogOpen}
        uploading={uploading}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onUpload={handleUpload}
      />
      <CaseNumberListDialog
        open={isCaseNumberDialogOpen}
        onClose={() => {
          setIsCaseNumberDialogOpen(false);
        }}
        onSubmit={handleSendToEvidentDesign}
        patientId={patientId}
        filesToSend={filesToSend}
        isZip={true}
      />
    </ContentContainer>
  );
};

export default CBCTImages;
