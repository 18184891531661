import * as yup from "yup";

const patientDetailsSchema = yup.object({
  firstName: yup
    .string("Enter a valid first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter a valid last name")
    .required("Last name is required"),
  mobile: yup.string("Enter a valid mobile").nullable(),
  patientCode: yup.string("Enter a valid patient code").nullable(),
  email: yup.string().email("Enter a valid email").nullable(),
  dateOfBirth: yup
    .date()
    .typeError("Date of birth must be a valid date")
    .nullable(),
  isNewPatient: yup.boolean().nullable(),
  isDentist: yup.boolean().nullable(),
  personnelId: yup
    .string("Select dentist")
    .when(["isNewPatient", "isDentist"], {
      is: (isNewPatient, isDentist) => isNewPatient && isDentist === false,
      then: yup.string().required("Dentist is required"),
    })
});

export { patientDetailsSchema };