import React, { useEffect, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import {
  AccumulativeShadows,
  Environment,
  OrbitControls,
  PerspectiveCamera,
  RandomizedLight,
} from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";
import { ErrorBoundary } from "react-error-boundary";
import { Alert } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const STL = ({ link }) => {
  const ref = useRef();
  const stl = useLoader(STLLoader, link);

  console.log(stl);
  if (!stl) return null;

  // compute bounding box for the STL geometry
  stl.computeBoundingBox();

  // get the center of the bounding box
  const center = stl.boundingBox.getCenter(new THREE.Vector3());

  // compute for zoom
  const size = stl.boundingBox.getSize(new THREE.Vector3());
  const maxDim = Math.max(size.x, size.y, size.z);
  const fov = 50 * (Math.PI / 180);
  let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));
  cameraZ *= 1.25; // zoom out

  console.log(size);

  return (
    <>
      <PerspectiveCamera
        makeDefault
        aspect={1.777777777}
        position={[0, 0, cameraZ]}
        near={1}
        far={300}
        fov={50}
      />
      <mesh
        ref={ref}
        position={[-center.x, -center.y, -center.z]}
        scale={[1, 1, 1]}
      >
        <primitive object={stl} />
        <meshPhongMaterial specular={0x111111} color="#CD8658" shininess={0} />
      </mesh>
    </>
  );
};

const STLPreview = ({ link }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorLoadingSTL}>
      <div style={{ width: "100%", height: "480px", borderRadius: "20px" }}>
        <Canvas style={{ borderRadius: "inherit" }}>
                  <color attach="background" args={[blueGrey[50]]} />
          <hemisphereLight skyColor={0x443333} groundColor={0x111122} />
          <directionalLight position={[-30, -1, -1]} intensity={0.7} />
          <directionalLight position={[30, 1, -1]} intensity={0.7} />
          <directionalLight position={[1, 30, 1]} intensity={0.3} />
          <directionalLight position={[1, -30, 1]} intensity={0.3} />
          <directionalLight position={[1, 1, -30]} intensity={0.7} />
          <directionalLight position={[1, 1, 30]} intensity={0.3} />
          <STL link={link} />
          <OrbitControls autoRotate autoRotateSpeed={0.5} />

        </Canvas>
      </div>
    </ErrorBoundary>
  );
};

const ErrorLoadingSTL = ({ error, resetErrorBoundary }) => {
  return (
    <Alert severity="warning" sx={{ marginY: 2 }}>
      Something went wrong on the STL preview. Please download the file to view it.
    </Alert>
  );
};

export default STLPreview;
