import { Box } from "@mui/material";
import React from "react";
import { brandLight } from "../../constants";

const ContentContainer = ({ small = false, children, sx, ...props }) => {
  return (
    <Box
      sx={{
        bgcolor: brandLight,
        borderRadius: "20px",
        paddingX: small ? 3 : 6,
        paddingY: small ? 2 : 5,
        boxShadow: "0px 5px 10px rgba(138, 138, 138, 0.03)",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ContentContainer;
