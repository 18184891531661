import {
  CircularProgress,
  Container,
  dividerClasses,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import StyledButton from "../../components/common/button/largeButton";
import ContentContainer from "../../components/common/contentContainer";
import { apiUrl, brandColor } from "../../constants";
import { getAuthorizedFetchOptions } from "../../helpers/getAuthorizedFetchOptions";
import { getFetchOptions } from "../../helpers/getFetchOptions";
import defaultLogo from "../../images/evident_logo2.svg";
import { openSnackbar } from "../../redux/actions";

const ConfirmUser = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(userInfo);

  console.log(user);
  useEffect(() => {
    let mounted = true;

    const getUserDetails = async () => {
      const param = searchParams.get("param");
      const pos = searchParams.get("pos");
      const invitedUserId = window.atob(param);
      const position = window.atob(pos);
      const params = new URLSearchParams({ invitedUserId });

      const response = await fetch(
        apiUrl + "locker/getinviteduserdata?" + params.toString(),
        getAuthorizedFetchOptions("GET")
      );
      const data = await response.json();
      if (mounted) {
        setUser(data);
        setLoading(false);
      }
    };

    getUserDetails();

    return () => {
      mounted = false;
    };
  }, [searchParams]);

  const handleContinue = async () => {
    const params = {
      email: user.email,
    };
    const searchParams = new URLSearchParams(params);

    setSubmitting(true);
    const response = await fetch(
      apiUrl + "account/checkemailregistration?" + searchParams.toString(),
      getAuthorizedFetchOptions("GET")
    );
    const data = await response.json();

    if (data) {
      fetch(
        apiUrl + "locker/addtopractice",
        getFetchOptions("POST", {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          personnelId: user.personnelId,
          practiceId: user.practiceId,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          fetch(
            apiUrl + "locker/settoonboarded",
            getFetchOptions("POST", {
              email: user.email,
              personnelId: user.personnelId,
              position: user.position,
              practiceId: user.practiceId,
            })
          )
            .then((response) => response.text())
            .then((json) => {
              setSubmitting(false);
              dispatch(
                openSnackbar({ title: "Success", body: "Accepted invite" })
              );
              navigate("/");
            });
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err);
        });
    } else {
      setSubmitting(false);
      navigate("/signup", {
        state: { user },
      });
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#101010", height: 46 }} />
      {submitting && <LinearProgress />}
      <Stack alignItems="center" my={6} spacing={4}>
        <img
          src={defaultLogo}
          sx={{ width: "195px", height: "51px" }}
          alt="Evident Logo"
        />
        <Stack width="100%" alignItems="center" spacing={2}>
          <Typography variant="h4" component="h1">
            Accept Invitation
          </Typography>
          <Container maxWidth="md">
            <ContentContainer>
              {loading && (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
              {!loading && (
                <>
                  <Stack justifyContent="center" direction="row" spacing={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Name</Typography>
                      <Typography variant="h6">Email</Typography>
                      <Typography variant="h6">Invited By</Typography>
                      <Typography variant="h6">Practice Name</Typography>
                      <Typography variant="h6">Position</Typography>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        fontWeight="normal"
                      >{`${user.firstName} ${user.lastName}`}</Typography>
                      <Typography variant="h6" fontWeight="normal">
                        {user.email}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="normal"
                      >{`${user.personnelFirstName} ${user.personnelLastName}`}</Typography>
                      <Typography variant="h6" fontWeight="normal">
                        {user.practiceName}
                      </Typography>
                      <Typography variant="h6" fontWeight="normal">
                        {user.position}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack alignItems="center" marginTop={4}>
                    <StyledButton
                      variant={submitting ? "gray" : "primary"}
                      type="button"
                      onClick={handleContinue}
                      disabled={submitting}
                    >
                      Continue
                    </StyledButton>
                  </Stack>
                </>
              )}
            </ContentContainer>
          </Container>
        </Stack>
      </Stack>
    </>
  );
};

export default ConfirmUser;
