import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
// import 'swiper/css/pagination';
import ProductCard from '../../common/productCard/productCard';
import { Grid, IconButton } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { Pagination } from 'swiper/modules';

import './SwiperStyles.css';

const CustomNavigationButtons = () => {
  const swiper = useSwiper();
  const iconSize = 25;

  return <Grid item className="custom-navigation-buttons">
      <IconButton className='nav-buttons'>
        <ArrowLeftIcon style={{height:iconSize,width:iconSize,transition:'none'}} onClick={()=>swiper.slidePrev()} />
      </IconButton>
      <IconButton className='nav-buttons'>
        <ArrowRightIcon style={{height:iconSize,width:iconSize,transition:'none'}} onClick={()=>swiper.slideNext()} />
      </IconButton>
  </Grid>
}
const ProductUpsellCarousel = ({upsellProducts, selectedTreatment,onClickAboutTreatment,onClickOrderNow,patient}) => {
  const [slidesToView, setSlidesToView] = useState(window.innerWidth < 768 ? 1 : 3);

  useEffect(() => {
    const handleResize = () => {
      setSlidesToView(window.innerWidth < 768 ? 1 : 3);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const badge = {
    visible: false,
    label: 'BEST',
    backgroundColor: '#F8385A'
  }


  return (
    <Grid item xs={12} className="swiper-container">
      <Swiper
        modules={[Pagination]}
        pagination={{clickable:true}}
        className='swiper-wrapper'
        slidesPerView={slidesToView}
      >
        {upsellProducts.length > slidesToView && <CustomNavigationButtons  />}
        {upsellProducts.map(product=><SwiperSlide key={product.id}>
          <ProductCard patient={patient} onClickOrderNow={onClickOrderNow} onClickAboutTreatment={onClickAboutTreatment} badge={badge} product={product} selectedTreatment={selectedTreatment} />
        </SwiperSlide>)}
      </Swiper>
    </Grid>
  )
}

export default ProductUpsellCarousel