import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
const ProductBadge = ({badge}) => {
  return (
    <Stack sx={{display: 'flex', flexDirection: 'row-reverse'}}>
        <Box sx={{backgroundColor: badge.backgroundColor}} className='product-top-right-badge'><span>{badge.label}</span></Box>
    </Stack>
  )
}

export default ProductBadge