import React from "react";
import {
  TableRow as TableRowMUI,
} from "@mui/material";
import TableCell from "./tableCell";

const TableRow = ({ row, onClick, disableLastCell, shouldShowSpacing = true, shouldUseDivider = false }) => {
  const shouldDisable = (i) => disableLastCell && i + 1 === row.length;

  const rowStyles = {
    height: "100%",
    "td:first-of-type": {
      ">div:first-of-type": {
        borderTopLeftRadius: "1em",
        borderBottomLeftRadius: shouldShowSpacing ? "1em" : undefined,
        paddingTop: '30px'
      },
    },
    "td:nth-of-type(2)": {
      ">div:first-of-type": {
        paddingTop: '30px'
      },
    },
    "td:nth-of-type(3)": {
      ">div:first-of-type": {
        paddingTop: '30px'
      },
    },
    "td:nth-of-type(4)": {
      ">div:first-of-type": {
        paddingTop: '30px'
      },
    },
    "td:nth-of-type(5)": {
      ">div:first-of-type": {
        paddingTop: '30px'
      },
    },
    "td:nth-of-type(7)": {
      ">div:first-of-type": {
        paddingTop: '30px'
      },
    },
    "td:nth-of-type(8)": {
      ">div:first-of-type": {
        paddingTop: '20px'
      },
    },
    "td:nth-of-type(9)": {
      ">div:first-of-type": {
        paddingTop: '20px'
      },
    },
    "td:last-child": {
      ">div:first-of-type": {
        paddingTop: '20px',
        borderTopRightRadius: "1em",
        borderBottomRightRadius: shouldShowSpacing ? "1em" : undefined,
      },
    },
    "&:last-child": {
      td: {
        borderBottom: shouldUseDivider ? 0 : undefined
      }
    },
    "tr": {
      paddingBottom: '30px'
    }
  };

  return (
    <>
      <TableRowMUI sx={rowStyles}>
        {row.map((data, i) => (
          <TableCell
            index={i}
            key={i}
            component="td"
            onClick={!shouldDisable(i) ? onClick : undefined}
            shouldShowMargin={shouldShowSpacing}
            shouldUseDivider={shouldUseDivider}
          >
            {data}
          </TableCell>
        ))}
      </TableRowMUI>
    </>
  );
};

export default TableRow;
