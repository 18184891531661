import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { brandColor } from "../../../constants";

function AddToOrderButton({ onAdd }) {
  const handleClick = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to add this to your order?"
    );
    if (!isConfirmed) {
      return;
    }
    onAdd()
  };

  return (
    <ButtonBase
      disableRipple
      sx={{
        width: "100%",
        marginTop: 2,
        paddingY: 2,
        color: "#fff",
        borderRadius: "30px",
        backgroundColor: brandColor,
        transition: "all 250ms ease",
        ":hover": { filter: "brightness(90%)" },
      }}
      onClick={handleClick}
    >
      <Typography fontWeight="600" fontSize="15px" lineHeight="19px">
        Add to Order
      </Typography>
    </ButtonBase>
  );
}

export default AddToOrderButton;
