import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { brandBlue } from "../../../constants";

const DownloadButton = ({ path, variant, fileName, label }) => {
  const [loading, setLoading] = useState(false);

  const handleClickDownload = async () => {
    setLoading(true);
    let url = await path();

    const link = document.createElement("a");

    console.log(fileName);
    link.href = url;
    link.setAttribute("download", fileName);
    link.target = "_blank";
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
    console.log(link);
    document.body.removeChild(link);

    setLoading(false);
  };

  const getLabel = () => {
    if (label === null || label === undefined) return "Download";

    return label;
  };

  return (
    <Button
      target="_blank"
      onClick={handleClickDownload}
      color="brandBlue"
      variant={variant || "contained"}
      startIcon={
        loading ? <CircularProgress size={18} /> : <FileDownloadIcon />
      }
      disabled={loading}
      sx={{ minWidth: "110px" }}
    >
      {loading ? "Loading" : getLabel()}
    </Button>
  );
};

export default DownloadButton;
