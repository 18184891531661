import React, {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Portal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { brandColor, brandColorHover, brandColorActive } from "../constants";
import AdminHubService from "../services/adminHub.service";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


const PaymentDetailsDialogContext = createContext(null);  

export const PaymentDetailsDialogContextProvider = ({ children }) => {
    const [isAccountLocked,setIsAccountLocked] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo) || {};

    const pathName = location.pathname;

    useEffect(()=>{
        if(Object.keys(userInfo).length == 0){
            setIsAccountLocked(false);
        } else {
            AdminHubService.getCaseSendingStatus().then(response=>{
                return response.json()
            }).then(data=>{
                setIsAccountLocked(data.data.access === "blocked");
            })
        }
    },[userInfo,pathName]);
    
    const redirectToPaymentDetails = () => {
        navigate('/paymentDetails')
    }
    return (
        <PaymentDetailsDialogContext.Provider value={{isAccountLocked,pathName}} >
            {children}
            <Portal>
                <Dialog
                    maxWidth="xs"
                    fullWidth
                    paddingX={50}
                    paddingY={30}
                    open={isAccountLocked && pathName !== '/paymentDetails'}
                >
                    <DialogTitle>
                        <Box display="flex" justifyContent="center" justifyItems="center">
                            <ErrorOutlineIcon color="warning" style={{ fontSize: 100 }} />
                        </Box>
                        <Typography variant="h4" textAlign="center">Account Overdue!</Typography>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" textAlign="center">Account Locked!</Typography>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', paddingBottom: 5 }} >
                        <Button 
                            variant="contained" 
                            sx={{
                                backgroundColor: brandColor,
                                color: "white",
                                fontSize: "15px",
                                lineHeight: "1.2em",
                                fontWeight: "400",
                                paddingX: 2.5,
                                paddingY: 1,
                                borderRadius: "25px",
                                ":hover": {
                                    backgroundColor: brandColorHover,
                                },
                                ":active": {
                                    backgroundColor: brandColorActive,
                                }
                            }}
                            onClick={redirectToPaymentDetails}
                            >
                                Make Payment
                        </Button>
                    </DialogActions>
                </Dialog>
            </Portal>
        </PaymentDetailsDialogContext.Provider>
    );
};

export const usePaymentDetailsDialogContext = () => useContext(PaymentDetailsDialogContext);
