import React from "react";
import { TableCell as Cell, tableCellClasses } from "@mui/material";
import styled from "@emotion/styled";
import { blueGrey, grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { brandLight } from "../../../constants";

const TableCell = ({
  children,
  onClick,
  shouldShowMargin,
  shouldUseDivider,
  component,
  ...props
}) => {
  const StyledTableCell = styled(Cell)(() => ({
    height: "inherit",
    border: 0,
    borderBottom:
      component === "td" &&
      (shouldUseDivider || !shouldShowMargin) &&
      "1px dashed #C4C4C4",
    fontSize: "16px",
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    cursor: onClick ? "pointer" : undefined,
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontWeight: "500",
      paddingBottom: shouldShowMargin && !shouldUseDivider ? "0.5em" : "1",
      color: grey[900],
    },
  }));

  return (
    <StyledTableCell onClick={onClick} component={component} {...props}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          backgroundColor: component === "td" ? brandLight : undefined,
          boxSizing: "border-box",
          paddingX: 1.5,
          paddingY: 1,
          borderTopLeftRadius: 0,
        }}
      >
        {children}
      </Box>
    </StyledTableCell>
  );
};

export default TableCell;
