import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, brandColor, brandGradient } from "../../constants";
import { getAuthorizedFetchOptions } from "../../helpers/getAuthorizedFetchOptions";
import { openSnackbar } from "../../redux/actions";
import { changePasswordSchema } from "../../types/changePasswordSchema";
import DialogContainer from "../common/dialogContainer";
import InputText from "../common/inputText";

const ChangePasswordModal = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(changePasswordSchema),
  });

  const handleChangePassword = async (values) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        apiUrl + "account/changepassword",
        getAuthorizedFetchOptions("POST", {
          email: userInfo.Email,
          newPassword: values.newPassword,
          oldPassword: values.currentPassword,
        })
      );
      const data = await response.json();

      if (data.statusCode !== 10) {
        throw data.error;
      }

      handleClose();
      dispatch(
        openSnackbar({
          title: "Success",
          type: "success",
          body: "Password changed successfully",
        })
      );
    } catch (err) {
      if (err.code === 101) {
        setError("currentPassword", {
          type: "Custom",
          message: "Incorrect current password",
        });
      } else {
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
    reset({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <DialogContainer open={open} onClose={handleClose} loading={isLoading}>
      <Box sx={{ textAlign: "center" }}>
        <Typography component="h2" variant="h6">
          Change Password
        </Typography>
        <Typography variant="body2" mt={0.5}>
          Choose a new password. We'll send you an email to validate the change.
        </Typography>
      </Box>

      <Stack
        component="form"
        onSubmit={handleSubmit(handleChangePassword)}
        sx={{ marginTop: 4 }}
        spacing={1}
      >
        <InputText
          type="password"
          label="Current Password"
          errors={errors.currentPassword}
          register={register("currentPassword")}
        />

        <Stack spacing={1} sx={{ paddingTop: 2 }}>
          <InputText
            type="password"
            label="New Password"
            errors={errors.newPassword}
            register={register("newPassword")}
          />
          <InputText
            type="password"
            label="Confirm Password"
            errors={errors.confirmPassword}
            register={register("confirmPassword")}
          />
        </Stack>

        <Stack direction="row" spacing={2} sx={{ paddingTop: 4 }}>
          <Button type="button" fullWidth onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              background: brandColor,
              "&:hover": { background: brandColor },
            }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </DialogContainer>
  );
};

export default ChangePasswordModal;
