import React from "react";
import { Box } from "@mui/system";
import {
  Divider,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { DateTimePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { brandBlack, brandBlue, brandGray, brandLight } from "../../constants";
import { useSelector } from "react-redux";

function PatientDetails(props) {
  const {
    treatment,
    patientDetailsIcon,
    onSelectLastName,
    onSelectPractice,
    patientsList,
    practiceList,
    lastName,
    patientId,
    firstName,
    birthday,
    practiceId,
    email,
    returnDate,
    isSameDayDentistry,
    isPrepOrFinalRestoration,
    setLastName,
    setPatientId,
    setFirstName,
    setBirthday,
    setEmail,
    setReturnDate,
    setIsSameDayDentistry,
    setIsPrepOrFinalRestoration,
    dentists,
    onSelectDentist,
    personnelId,
    roles,
    disableChange,
  } = props;

  const userInfo = useSelector((state) => state.userInfo);
  const [pId, setPID] = React.useState(personnelId);
  const [pracId, setPracId] = React.useState(practiceId);
  const [selectedTreatment, setSelectedTreatment] = React.useState(treatment);
  const [isHideSameDayDentistry, setIsHideSameDayDentistry] =
    React.useState(false);
  const [isHidePrepFinal, setIsHidePrepFinal] = React.useState(false);

  React.useEffect(() => {
    setPID(personnelId);
  }, [personnelId]);

  React.useEffect(() => {
    setPracId(practiceId);
  }, [practiceId]);

  React.useEffect(() => {
    const treatmentsWithHideSDD = ["Aligners"];
    const treatmentsWithHidePrepFinal = [
      "All on X Implants",
      "Evident Smile Package",
    ];

    setIsHideSameDayDentistry(true);
    setIsHidePrepFinal(true);
    // if (
    //   treatmentsWithHideSDD.find((x) => x === selectedTreatment[0].description)
    // ) {
    //   console.log("Hide the same day edit");
    //   setIsHideSameDayDentistry(true);
    //   setIsHidePrepFinal(true);
    // } else if (
    //   treatmentsWithHidePrepFinal.find(
    //     (x) => x === selectedTreatment[0].description
    //   )
    // ) {
    //   console.log("Hide the Prep final");
    //   setIsHidePrepFinal(true);
    // } else {
    //   setIsHidePrepFinal(false);
    //   setIsHidePrepFinal(false);
    // }
  }, []);

  const isDisabled =
    (!userInfo.Roles.includes("Dentist") &&
      (personnelId === "" || !personnelId)) ||
    disableChange;

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "start", height: "57px" }}>
        <Box>
          <Divider
            sx={{
              border: "1px dashed #969696",
              width: "64px",
              height: "0px",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontSize: "32px",
            lineHeight: "31px",
            position: "relative",
            top: "-28px",
            boxSizing: "border-box",
          }}
        >
          <img src={patientDetailsIcon} style={{ width: "20px" }} />
        </Box>
        <Box id="patient-details" sx={{ position: "relative", top: "-28px", marginLeft: "20px" }}>
          <Box
            sx={{
              fontSize: "22px",
              lineHeight: "31px",
              fontWeight: "500",
              color: brandBlack,
            }}
          >
            Patient Details
          </Box>
          <Box
            sx={{
              marginTop: 0.5,
              fontSize: "16px",
              lineHeight: "16px",
              color: brandGray,
            }}
          >
            Fill your patient's basic information or select from existing
            patient
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: brandLight,
          backdropFilter: " blur(20px)",
          borderRadius: "16px",
          marginLeft: "64px",
          // width: "90%",
          paddingTop: "37px",
          paddingLeft: "57px",
          paddingRight: "57px",
          paddingBottom: "37px",
          marginBottom: "40px",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "20px" }}>
                <Box
                  sx={{
                    color: "#37465D",
                    fontWeight: "600",
                    textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                    fontSize: "16px",
                    lineHeight: "19px",
                    marginBottom: "5px",
                  }}
                >
                  Practice
                </Box>
                <TextField
                  id="dental-practice"
                  select
                  variant="outlined"
                  value={pracId}
                  onChange={(e) => onSelectPractice(e.target.value)}
                  disabled={disableChange}
                  sx={{
                    width: "100%",
                    border: "0.5px solid #DFDFDF",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  {practiceList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {!userInfo.Roles.includes("Dentist") && (
                <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "20px" }}>
                  <Box
                    sx={{
                      color: "#37465D",
                      fontWeight: "600",
                      textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                      fontSize: "16px",
                      lineHeight: "19px",
                      marginBottom: "5px",
                    }}
                  >
                    Dentist
                  </Box>
                  <TextField
                    id="dentist"
                    select
                    variant="outlined"
                    value={pId || ""}
                    onChange={(e) => onSelectDentist(e.target.value)}
                    disabled={!practiceId || practiceId === "" || disableChange}
                    sx={{
                      width: "100%",
                      border: "0.5px solid #DFDFDF",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  >
                    {dentists.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option.firstName} ${option.lastName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    color: "#37465D",
                    fontWeight: "600",
                    textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                    fontSize: "16px",
                    lineHeight: "19px",
                    marginBottom: "5px",
                  }}
                >
                  Email Address (Optional)
                </Box>
                <TextField
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isDisabled || patientId !== ""}
                  variant="outlined"
                  placeholder="Patient Email..."
                  sx={{
                    width: "100%",
                    border: "0.5px solid #DFDFDF",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "20px" }}>
            <Box
              sx={{
                color: "#37465D",
                fontWeight: "600",
                textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                fontSize: "16px",
                lineHeight: "19px",
                marginBottom: "5px",
              }}
            >
              Last Name
            </Box>
            <Autocomplete
              id="last-name"
              freeSolo
              onChange={(event, value) => onSelectLastName(value)}
              getOptionLabel={(option) => option.lastName}
              options={patientsList}
              inputValue={lastName}
              onInputChange={(event, newInputValue) => {
                setLastName(newInputValue);
                setPatientId("");
                setFirstName("");
                setBirthday(null);
                setEmail("");
                // setReturnDate("");
              }}
              disabled={isDisabled}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  key={option.id}
                >
                  {option.lastName}, {option.firstName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type in Patient Last Name"
                  label=""
                  sx={{
                    width: "100%",
                    border: "0.5px solid #DFDFDF",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "20px" }}>
            <Box
              sx={{
                color: "#37465D",
                fontWeight: "600",
                textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                fontSize: "16px",
                lineHeight: "19px",
                marginBottom: "5px",
              }}
            >
              First Name
            </Box>
            <TextField
              id="first-name"
              variant="outlined"
              disabled={isDisabled || patientId !== ""}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Type in Patient First Name..."
              sx={{
                width: "100%",
                border: "0.5px solid #DFDFDF",
                background: "#fff",
                borderRadius: "10px",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "20px" }}>
            <Box
              sx={{
                color: "#37465D",
                fontWeight: "600",
                textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                fontSize: "16px",
                lineHeight: "19px",
                marginBottom: "5px",
              }}
            >
              Birthday (Optional)
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                disabled={isDisabled || patientId !== ""}
                value={birthday}
                onChange={(newValue) => {
                  setBirthday(newValue);
                }}
                sx={{ border: "0.5px solid #DFDFDF !important" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "100%",
                      border: "0.5px solid #DFDFDF !important",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        {/* sx={{ display: "none" }} */}
        <Grid container spacing={1}>
          {!isHideSameDayDentistry && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Same Day Dentistry
              </Box>

              <Grid container spacing={1}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      minWidth: "130px",
                      maxWidth: "200px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      margin: "10px",
                      height: "50px",
                      background: isSameDayDentistry ? brandBlue : "#FBFBFB",
                      color: isSameDayDentistry ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setIsSameDayDentistry(true)}
                  >
                    Yes
                  </Box>
                  <Box
                    sx={{
                      minWidth: "130px",
                      maxWidth: "200px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      margin: "10px",
                      height: "50px",
                      background: !isSameDayDentistry ? brandBlue : "#FBFBFB",
                      color: !isSameDayDentistry ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setIsSameDayDentistry(false)}
                  >
                    No
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {/* {!isHidePrepFinal && !isSameDayDentistry && (
            <Grid item sm={6} md={4}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Is This Prep Or Final Restoration
              </Box>

              <Grid container spacing={1}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      minWidth: "130px",
                      maxWidth: "200px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      margin: "10px",
                      height: "50px",
                      background: isPrepOrFinalRestoration
                        ? brandBlue
                        : "#FBFBFB",
                      color: isPrepOrFinalRestoration ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setIsPrepOrFinalRestoration(true)}
                  >
                    Prep
                  </Box>
                  <Box
                    sx={{
                      minWidth: "130px",
                      maxWidth: "200px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      margin: "10px",
                      height: "50px",
                      background: !isPrepOrFinalRestoration
                        ? brandBlue
                        : "#FBFBFB",
                      color: !isPrepOrFinalRestoration ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setIsPrepOrFinalRestoration(false)}
                  >
                    Final Restoration
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )} */}
          {/* <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                color: "#37465D",
                fontWeight: "600",
                textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                fontSize: "16px",
                lineHeight: "19px",
                marginBottom: "5px",
              }}
            >
              Appointment Date
            </Box>

            <Grid container spacing={1}>
              <Grid item sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    value={returnDate}
                    onChange={(newValue) => {
                      setReturnDate(newValue);
                    }}
                    minDate={moment(moment()).toDate()}
                    sx={{ border: "0.5px solid #DFDFDF !important" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: "100%",
                          border: "0.5px solid #DFDFDF !important",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label=""
                    value={returnDate}
                    onChange={(newValue) => {
                      setReturnDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "100%",
                          border: "0.5px solid #DFDFDF !important",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label=""
                value={returnDate}
                onChange={(newValue) => {
                  setReturnDate(newValue);
                }}
                minDateTime={new Date()}
                sx={{ border: "0.5px solid #DFDFDF !important" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "100%",
                      border: "0.5px solid #DFDFDF !important",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
            </LocalizationProvider> */}
        </Grid>
      </Box>
    </div>
  );
}

export default PatientDetails;
