import { Box, TextField, CircularProgress, Dialog, Typography, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import RequirementBoxCount from "../RequirementBoxCount"
import ActionButton from "../button/actionButton";
import React, { useState } from "react";
import CaseService from "../../../services/case.service"

var model = {};

function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date.toISOString();
}



const AlignerFabModal = ({ open, dataCase, dataFiles, infoUser, dataPractices }) => {
	
	const [disabled, setDisabled] = useState(false);

	const handleClick = (dataCase, dataFiles, infoUser, dataPractices) => {
		setDisabled(true);
		var dateNow = new Date().toISOString();
		var dateNowFive = addHours(new Date(), 12);
		//add model data
		model.PersonnelId = dataCase.personnelId;
		model.PatientId = dataCase.patientId;
		model.PracticeId = infoUser.PracticeWithPersonnel[0].practiceId;
		
		model.ServiceProviders = [] 
		model.ServiceProviders.push({
			  LabId: "00000000-0000-0000-0000-000000000000",
			  Type: "Design"
		});
		model.OutputTypeId = "ec0f1b62-2f11-4f0f-748b-08d6b658bf50";
		model.LabId = "00000000-0000-0000-0000-000000000000";
		var comment = {};
		var requirement1 = {};
		var requirement2 = {};
		var productDeetsList = [];
		var productsVar = {};
		
		
		requirement1.reqName = "What is the patient''s main complaint?";
		requirement1.reqValue = "aligner fabrication only";
		
		requirement2.reqName = "The STL files I want to have are";
		requirement2.reqValue = " ";
		productDeetsList.push(requirement1);
		productDeetsList.push(requirement2);
		
		comment.outputType = "Aligner Fabrication";
		productsVar.productName = "Aligner Fabrication Only";
		let strNotes = "Continued from case number: " + dataCase.caseRefNo + "\n Additional Notes: " + textInput;
		productsVar.productNotes = strNotes
		productsVar.productDetails = [];
		productsVar.productDetails.push(productDeetsList);
		
		comment.products = strNotes;
		// comment.products.push(productsVar);
		
		var strComment = JSON.stringify(comment);
		
		model.Comment = strComment;
		model.OrderSummaryPdfUrl = process.env.REACT_APP_BLOB_URL;
		
		let strName = infoUser.FirstName + " " + infoUser.LastName;
		var varOrderSummary = {}
		varOrderSummary.dentistName = strName;
		var addtlFiles = []
		
		
		//push each final design file
		dataFiles.files.forEach(pushToAddtlFiles);
		function pushToAddtlFiles(item){
			
			addtlFiles.push({
				"fileType": 0,
				"fileName": item.fileName
			});
		}
		
		
		varOrderSummary.orderAdditionalFiles = addtlFiles;
		varOrderSummary.orderDate = dateNow;
		let strPatientName = dataCase.patientFirstName + " " + dataCase.patientLastName;
		varOrderSummary.patientName = strPatientName;
		varOrderSummary.practiceName = dataPractices[0].name;
		varOrderSummary.sendingPhysicalImpression = false;
		varOrderSummary.orderReturnDate = "";
		
		let orderProds = [];
		let orderProdsDetails = {};
		orderProdsDetails.description = "Aligners Fabrication Only"
		orderProdsDetails.hasToothChart = false;
		orderProdsDetails.logoUrl = null;
		orderProdsDetails.productCategory = "Orthodontic Treatments & Appliances!";
		orderProdsDetails.toothChartUrl = "";
		orderProdsDetails.withImpression = false;
			let orderProdsDetailsReqs = [];
			orderProdsDetailsReqs.push({
				"description": requirement1.reqName,
				"value": requirement1.reqValue
			});
			orderProdsDetailsReqs.push({
				"description": requirement2.reqName,
				"value": requirement2.reqValue
			});
		orderProdsDetails.requirements = orderProdsDetailsReqs;
		orderProdsDetails.notes = strNotes;
		orderProds.push(orderProdsDetails);
		varOrderSummary.orderProducts = orderProds;
		varOrderSummary.orderSupportingFiles = [];
		
		
		model.OrderSummary = varOrderSummary;
		model.ReturnDate = dateNowFive;
		model.CreatedBy = model.PersonnelId;
		model.CaseTreatments = [];
		model.CaseTreatments.push("Aligner Fabrication Only");
		model.CaseTreatmentModels = [];
		model.CaseTreatmentModels.push({
			"caseType": "Aligner Fabrication Only",
			"caseTypeParent": "Aligners",
			"consumerApp": "EvidentDesign",
			"customerId": model.PersonnelId,
			"productId": 1215,
			"units": 1,
			"version": "V3",
			"multiplier": 1
		});
		model.AdditionalDocuments = [];
		model.CasePatientFiles = [];
		model.PatientFilesRecords = []
		//push each final design file
		dataFiles.files.forEach(pushToPatientFilesRecords);
		function pushToPatientFilesRecords(item){
			
			model.PatientFilesRecords.push({
				"PatientFileId": item.patientFileId,
				"PatientFileExtensionId": "00000000-0000-0000-0000-000000000000"
			});
		}
		
		model.IsSameDayDentistry = false;
		model.IsPrepOrFinalRestoration = false;
		model.IsRush = false;
		model.IsPrebook = false;
		model.isUsingGraphyWorkflow = false;
		

		
		CaseService.submitCase(model)
			.then((response) => response.json());
			
		//close modal
		setTimeout(function(){
			setIsOpen(false);
			window.location.reload();
		 }, 5000);
	};

		
	const [isOpen, setIsOpen] = useState(true);
	
	const handleHide = () => {
		setIsOpen(false);
		window.location.reload(false);
	}
	var requirementsCount = 1;
	
	const [textInput, setTextInput] = useState('');
	
	const handleTextInputChange = (event) => {
		setTextInput(event.target.value);
	};
	
	return (
	  <>
		<Dialog
		  open={isOpen}
		  onClose={handleHide}
		  maxWidth="sm"
		  fullWidth
		  sx={{ textAlign: "center", justifyContent: "center" }}
		>
		<Box sx={{ marginLeft: "10px", marginTop: "10px" }}>
		<RequirementBoxCount
                  count={requirementsCount++}
                  description={"RX notes:"}
				  
		/>
		</Box>
		<Box sx={{ textAlign: "left", marginBottom: "45px" }}>
			<TextField sx={{ width: "97%", marginLeft:"10px" }}
					label="RX Notes:"
					value={textInput}
					onChange={handleTextInputChange}

			/>
		</Box>
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Box sx={{ textAlign:"left", marginBottom: "10px", marginLeft: "10px" }}>
				<ActionButton 
					style={{ color: "#ffffff",
							backgroundColor: "#7f7f7f"

					}}
					onClick={handleHide}
				>
					Cancel
				</ActionButton>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box sx={{ textAlign:"right", marginBottom: "10px", marginRight: "10px" }}>
				<ActionButton 
					color="brand"
					disabled={disabled}
					onClick = {() => handleClick(dataCase, dataFiles, infoUser, dataPractices) }
				>
					Continue with Fabrication
				</ActionButton>
				</Box>
			</Grid>
		</Grid>
		</Dialog>
	  </>
      
  );
};

export default AlignerFabModal;
