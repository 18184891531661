import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DownloadFileLargeButton from "../common/button/downloadFileLargeButton";
import PreviewFileButton from "./previewFileButton";

const FilePreviewDownload = ({ file, isLoading }) => {
  const fileTypesForPreview = ["stl", "html", "pdf", "jpg", "png"];
  return (
    <Box>
      <Typography
        sx={{
          textAlign: "left",
          marginBottom: 1,
          fontWeight: 600,
          color: "white",
        }}
      >
        {file.fileName}
      </Typography>
      <Stack direction="row" spacing={2}>
        {fileTypesForPreview.includes(file.type) && (
          <PreviewFileButton file={file} />
        )}
        <DownloadFileLargeButton
          isLoading={isLoading}
          path={file.path}
          fileName={file.fileName}
          variant="secondary"
        >
          Download
        </DownloadFileLargeButton>
      </Stack>
    </Box>
  );
};

export default FilePreviewDownload;
