import React from "react";
import { IconButton, Dialog, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import artisanLogo from "../../../images/artisanLogo.png";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const ArtisanInfoModal = ({ isOpen, handleClose }) => {
  const closeDialog = () => {
    handleClose();
  }; 

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      sx={{ textAlign: "center" , marginBottom:"15px"}}
      PaperProps={{ style: { padding: "15px" } }}
    >
      <Box sx={{ textAlign: "right", marginRight: "15px", marginTop: "15px" }}>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <img src={artisanLogo} style={{ width: "115px" }} />
      </Box>
      <DialogTitle id="alert-dialog-title"><h2>{"Artisan Dental Laboratory"}</h2></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"  sx={{ textAlign: "left" , fontSize: "14px",}}>
        <Box>  
                  Artisan Dental Laboratory is a family owned, full-service
                  dental laboratory in Portland, Oregon. Since being founded in
                  1973, we have earned a reputation for providing the dental
                  community with high quality dental prosthetics. Since 2018, we
                  have applied our vast knowledge of denture fabrication to
                  multiple additive manufacturing systems for digital dentures.
                  Today, our denture team prefers TrueDent which utilizes
                  Stratasys polyjet printing technology and proprietary resin
                  to fabricate the industry's only polychromatic monolithic 3D
                  printed permanent dentures. 
                  {<br />}
                  {<br />}
                
                  Artisan Dental Laboratory {<br />}
                  2532 SE Hawthorne Blvd.{<br />}
                  Portland, OR 97214-3927{<br />}
                  Ph: 1-800-222-6721{<br />}
                  Email: customerservice@artisandental.com{<br />}
                  Website: https://artisandental.com/{<br />}
               
              </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ArtisanInfoModal;
