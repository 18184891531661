import React from "react";
import { connect, useSelector } from "react-redux";
import { Formik } from "formik";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Link, IconButton, Button } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { initUser } from "../../redux/actions/index";
import LinearProgress from "@mui/material/LinearProgress";
import ForgotPassword from "./forgotPassword";
import { useLogin } from "../../hooks/useLogin";
import CustomModal from "../common/modal/customModal";
import { brandBlue, brandColor } from "../../constants";

function mapDispatchToProps(dispatch) {
  return {
    initUser: (user) => dispatch(initUser(user)),
  };
}

function LoginModal({ isOpen, handleClose, initUser }) {
    const { loggingIn, login } = useLogin();
    const userInfo = useSelector((state) => state.userInfo) || {};
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const closeDialog = (close) => {
    handleClose(close);
  };
   
     

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleCloseAdvisory = async (isOpen) => {
    try {
      setOpen(isOpen);
      await login(email, password);
      handleClose(true);
    } catch (err) {
      console.log(err);
      setErrorMessage("Log in failed. Please try again.");
    }
  };
  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth sx={{ textAlign: "center" }}>
      {loggingIn && (
        <Box sx={{ width: "100%", paddingTop: "1px" }}>
          <LinearProgress color="success" />
        </Box>
      )}
      <Box sx={{ textAlign: "right", marginRight: "25px", marginTop: "35px" }}>
        <IconButton onClick={() => closeDialog(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      {!showForgotPassword ? (
        <>
          <Box sx={{ fontSize: "28px", lineHeight: "41px", color: "#000" }}>
            <b>Login</b> to have a full access
          </Box>
          <Box
            sx={{
              fontWeight: "300",
              fontSize: "18px",
              lineHeight: "26px",
              marginBottom: "48px",
              color: "#585656",
            }}
          >
            Track and manage your orders. Learn more about the product you
            purchased.
          </Box>
          {errorMessage !== "" && (
            <Box
              sx={{
                textAlign: "center",
                fontSize: "20px",
                color: "red",
                marginBottom: "20px",
              }}
            >
              {errorMessage}
            </Box>
          )}
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Emaill address is required.";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Password is required.";
              }

              return errors;
            }}
            onSubmit={async (values) => {
              try {
                setEmail(values.email);
                setPassword(values.password);

                 await login(values.email, values.password);
                 handleClose(true);

                /*setOpen(true);*/
              } catch (err) {
                console.log(err);
                setErrorMessage("Log in failed. Please try again.");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ width: "85%", margin: "0 auto" }}>
                  <Box sx={{ marginBottom: "15px" }}>
                    <TextField
                      sx={{ width: "100%", marginBottom: "15px" }}
                      id="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label="Email Address"
                      variant="outlined"
                    />
                    <Box sx={{ color: "red" }}>
                      {errors.email && touched.email && errors.email}
                    </Box>
                  </Box>
                  <Box sx={{ marginBottom: "15px" }}>
                    <TextField
                      sx={{ width: "100%", marginBottom: "15px" }}
                      id="password"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      label="Password"
                      variant="outlined"
                    />

                    <Box sx={{ color: "red" }}>
                      {errors.password && touched.password && errors.password}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      textAlign: "right",
                      width: "100%",
                      margin: "0 auto",
                      marginBottom: "48px",
                      fontSize: "18px",
                      lineHeight: "26px",
                      textDecoration: "underline",
                      color: brandBlue,
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setShowForgotPassword(!showForgotPassword)}
                    >
                      Forgot Password
                    </span>
                  </Box>

                  <Box
                    sx={{
                      fontSize: "18px",
                      lineHeight: "26px",
                      fontWeight: "300",
                      color: "#000",
                      marginBottom: "15px",
                    }}
                  >
                    No Account yet?{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => closeDialog("signup")}
                    >
                      Create Account
                    </span>
                  </Box>

                  <Button
                    disabled={loggingIn}
                    sx={{
                      color: "#fff",
                      marginBottom: "15px",
                      background: brandColor,
                      borderRadius: "29px",
                      width: "481px",
                      height: "57px",
                      "&:hover": { background: brandColor },
                    }}
                    type="submit"
                  >
                    Login
                  </Button>

                  <Box sx={{ textAlign: "center", marginBottom: "41px" }}>
                    By signing in with evident, you agree to our{" "}
                    <Link
                      href="/docs/Evident_-_HIPAA_BAA.pdf"
                      target="blank"
                      underline="hover"
                    >
                      Business Associate Agreement
                    </Link>{" "}
                    and our{" "}
                    <Link
                      href="/docs/Evident_-_HUB_T&Cs.pdf"
                      target="blank"
                      underline="hover"
                    >
                      Terms of Use.
                    </Link>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <ForgotPassword />
          <Box sx={{ textAlign: "center", marginBottom: "41px" }}>
            Go back to{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setShowForgotPassword(!showForgotPassword)}
            >
              Login
            </span>
          </Box>
        </>
      )}

      <CustomModal
        open={open}
        setOpen={handleCloseAdvisory}
        title={"Advisory"}
        content={`Starting Monday, February 13, 2023, we will be introducing charges for prebooked and rush design cases.

Prebooked case: You can now schedule your case in advance to align with your patient appointment. $50/case

Rush cases: Need your case back quickly? 2-5 hour turnaround time. $5/unit

Contact us for more information at 1 (877) 909 7770 or support@evidentlabs.com     
`}
      />
    </Dialog>
  );
}

export default connect(null, mapDispatchToProps)(LoginModal);
