import { Box } from "@mui/material";
import React from "react";

const PatientMenuItem = ({ children, ...props }) => {
  return (
    <Box
      backgroundColor="#F5F6F9"
      fontSize="16px"
      textAlign="center"
      paddingY={1.5}
      sx={{
        fontWeight: "500",
        border: 0,
        cursor: "pointer",
        color: "inherit",
        textDecoration: "none",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default PatientMenuItem;
