import React, { useState, useEffect, useSelector } from "react";
import { connect, useDispatch } from "react-redux";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrorForward from "@mui/icons-material/ArrowForward";
import { Controller, useForm } from "react-hook-form";
import { initUser } from "../../redux/actions";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { Button, ButtonBase, Typography, Link, Stack } from "@mui/material";
import { brandBlue, brandColor, brandGray } from "../../constants";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CardDetails from "../../components/paymentDetails/cardDetails";
import AdminService from "../../services/admin.service";
import { ArrowForward } from "@mui/icons-material";

function BillingOptionModal({
  isOpen,
  handleClose,
  onSelectBillingOption,
  handleCancel,
  initUserInfo,
}) {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const kois_domain = "KOIS";
  const design_domain = "EvidentDesign";
  //const userInfo = useSelector((state) => state.userInfo) || {};
  const [isCloseModal, setIsCloseModal] = useState(false);
  const [hasPaymentDetails, setHasPaymentDetails] = useState(true);
  const [cardNumber, setCardNumber] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [expiryMonth, setExpiryMonth] = React.useState("");
  const [expiryYear, setExpiryYear] = React.useState("");
  const [ccv, setCcv] = React.useState("");
  const [paymentError, setPaymentError] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedBillingOption, setSelectedBillingOption] =
    React.useState(false);
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [successfulMesage, setSuccessfulMesage] = React.useState("");
  const [isSuccessPrepay, setIsSuccessPrepay] = React.useState(false);

  const closeDialog = () => {
    handleClose(true);
  };
  const handleBackButton = () => {
    setHasPaymentDetails(true);
  };
  const handleForwardButton = () => {
    setHasPaymentDetails(false);
  };
  const handleUpdateCardDetails = (success) => {
    console.log("  ResultMessage(paymentError", success);
  };
  const updateInitUserInfo = (billOption) => {
    let userInfo = {
      Id: initUserInfo.id,
      LoginId: initUserInfo.LoginId,
      FirstName: initUserInfo.FirstName,
      LastName: initUserInfo.LastName,
      Roles: initUserInfo.Roles,
      Email: initUserInfo.Email,
      PersonnelAttributes: initUserInfo.PersonnelAttributes,
      PracticeWithPersonnel: initUserInfo.PracticeWithPersonnel,
      BillingOption: billOption,
    };
    dispatch(initUser(userInfo));
  };

  const onClickButtonBillingOption = async (selectedBillOption) => {
    setLoading(true);
    setHasError(false);

    let model = {
      domain: design_domain,
      id: initUserInfo.Id,
      email: initUserInfo.Email,
    };
    await AdminService.getPaymentDetails(model)
      .then((response) => response.json())
      .then((json) => {
        if (json.error === null) {
          if (json.data && json.data.length > 0) {
            let result = JSON.parse(json.data[0].attributes);
            setHasPaymentDetails(true);
          }
        }
      });
    //setHasPaymentDetails(false);
    let result = "";
    let resultJSON = "";

    if (hasPaymentDetails) {
      let billingOptionModel = {
        PersonnelId: initUserInfo.Id,
        BillingOption: selectedBillOption,
        Email: initUserInfo.Email,
        AccountName: initUserInfo.FirstName + " " + initUserInfo.LastName,
        Token: localStorage.getItem("auth_token"),
        IsOverdue: false,
      };
      await AdminService.updatePersonnelBillingOption(billingOptionModel)
        .then((response) => response.json())
        .then((c) => {
          if (c.statusCode == 10) {
            result = c.statusCode;
            setHasError(false);
            onSelectBillingOption(selectedBillOption);
            if (selectedBillOption == "Prepay") {
              setIsSuccessPrepay(true);
            } else {
              setSuccessfulMesage(
                "You now have access to our standard pricing."
              );
            }

            setIsSuccess(true);
            //closeDialog();
            // handleClose(true);
          } else {
            // error
            result = c.error.message;
            setHasError(true);
            let errMsg = "Error: " + c.error.message;
            setPaymentError(errMsg);
            setIsSuccess(false);
            setSuccessfulMesage("");
          }
        });
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        sx={{ textAlign: "center" }}
      >
        {/* Payment Details */}

        {hasPaymentDetails === false ? (
          <>
            <Box
              sx={{
                width: "500px",
                padding: "20px",
                margin: "auto",
                fontWeight: "700",
                fontSize: "21px",
                lineHeight: "21px",
                color: "#2E3246",
              }}
            >
              <Typography>
                <b>Payment Information</b>{" "}
              </Typography>
              <br></br>
              <CardDetails isSuccessful={handleUpdateCardDetails} />
              <>
                <>
                  <Typography
                    sx={{
                      color: "red",
                      paddingBottom: "10px",
                      fontSize: "1rem",
                    }}
                  ></Typography>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <IconButton onClick={handleBackButton}>
                      <ArrowBackIcon
                        fontSize="medium"
                        sx={{ color: "black" }}
                      />
                    </IconButton>
                    <Typography sx={{ fontSize: "1rem" }} paddingBottom={0.5}>
                      Back To Billing Option
                    </Typography>
                  </Stack>
                </>
              </>
            </Box>
          </>
        ) : (
          <>
            {loading ? (
              <Box sx={{ height: "200px", padding: "20px" }}>
                <center>
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginY: 4,
                      marginBottom: "20px",
                    }}
                  >
                    Processing. Please wait...
                  </Typography>
                  <br></br>
                  <CircularProgress color="success" />
                </center>
              </Box>
            ) : (
              <>
                {isSuccess ? (
                  <Box sx={{ height: "250px", padding: "20px" }}>
                    <center>
                      {isSuccessPrepay ? (
                        <>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "21px",
                              lineHeight: "18px",
                              color: "brandColor",
                              marginTop: "30px",
                            }}
                          >
                            {" "}
                            Payment Successful
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "18px",
                              color: "#2E3246",
                              marginTop: "30px",
                            }}
                          >
                            You are now subscribed to the prepay plan
                            ($199/month).
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "14px",
                              lineHeight: "18px",
                              color: "#2E3246",
                              marginTop: "30px",
                            }}
                          >
                            {" "}
                            If you have any questions about the prices, you can
                            view the price list
                            <Link
                              sx={{}}
                              href={process.env.REACT_APP_EVIDENT_PRICELIST_URL}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {" "}
                              here
                            </Link>
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "18px",
                              color: "#2E3246",
                              marginTop: "30px",
                            }}
                          >
                            {successfulMesage}
                            <br></br>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                fontSize: "14px",
                                lineHeight: "18px",
                                color: "#2E3246",
                                marginTop: "30px",
                              }}
                            >
                              {" "}
                              If you have any questions about the prices, you
                              can view the price list
                              <Link
                                sx={{}}
                                href={
                                  process.env.REACT_APP_EVIDENT_PRICELIST_URL
                                }
                                rel="noreferrer"
                                target="_blank"
                              >
                                {" "}
                                here
                              </Link>
                            </Typography>
                          </Typography>
                        </>
                      )}
                    </center>
                    <br></br>

                    <ButtonBase
                      sx={{
                        width: "15%",
                        marginTop: 1,
                        paddingY: 2,
                        fontSize: "15px",
                        fontWeight: 600,
                        color: "white",
                        background: brandColor,
                        borderRadius: "30px",
                        boxShadow: "0px 2px 17px rgba(42, 193, 106, 0.37)",
                        marginBottom: "10px",
                      }}
                      onClick={() => closeDialog()}
                    >
                      Ok
                    </ButtonBase>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        fontWeight: "700",
                        fontSize: "21px",
                        lineHeight: "21px",
                        color: "#2E3246",
                        marginTop: "30px",
                      }}
                    >
                      Please select a billing option to proceed
                    </Box>
                    <Box
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#2E3246",
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          marginBottom: "0px",
                        }}
                      >
                        Refresh your memory on your different billing options{" "}
                        <Link
                          sx={{}}
                          href={process.env.REACT_APP_EVIDENT_PRICELIST_URL}
                          rel="noreferrer"
                          target="_blank"
                        >
                          here
                        </Link>{" "}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        marginTop: "7px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <ButtonBase
                        sx={{
                          width: "55%",
                          marginTop: 1,
                          paddingY: 2,
                          fontSize: "15px",
                          fontWeight: 600,
                          color: "white",
                          background: brandColor,
                          borderRadius: "30px",
                          boxShadow: "0px 2px 17px rgba(42, 193, 106, 0.37)",
                          marginBottom: "20px",
                        }}
                        onClick={() => onClickButtonBillingOption("Prepay")}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                width: "100%",
                                fontSize: "15px",
                                fontWeight: 600,
                                color: "white",
                              }}
                            >
                              <center>Prepay</center>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "white",
                              }}
                            >
                              <center>($199/month - charged monthly) </center>
                            </Box>
                          </Grid>
                        </Grid>
                      </ButtonBase>
                      <ButtonBase
                        sx={{
                          width: "55%",
                          marginTop: 1,
                          paddingY: 2,
                          fontSize: "15px",
                          fontWeight: 600,
                          marginBottom: 2,
                          color: "white",
                          background: brandColor,
                          borderRadius: "30px",
                          boxShadow: "0px 2px 17px rgba(42, 193, 106, 0.37)",
                        }}
                        onClick={() => onClickButtonBillingOption("Standard")}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                width: "100%",
                                fontSize: "15px",
                                fontWeight: 600,
                                color: "white",
                              }}
                            >
                              Standard
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "white",
                              }}
                            >
                              (per unit charge)
                            </Box>
                          </Grid>
                        </Grid>
                      </ButtonBase>
                      {hasError ? (
                        <>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ color: "red", paddingBottom: "5px" }}
                          >
                            {paymentError}
                          </Box>
                          <br></br>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                            sx={{ marginLeft: "auto", marginRight: "0" }}
                          >
                            <Typography
                              sx={{ fontSize: "1rem" }}
                              paddingBottom={0.5}
                            >
                              {" "}
                              Update Payment Information
                            </Typography>
                            <IconButton onClick={handleForwardButton}>
                              <ArrowForward
                                fontSize="medium"
                                sx={{ color: "black" }}
                              />
                            </IconButton>
                          </Stack>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </>
                )}{" "}
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

export default connect(null, null)(BillingOptionModal);
