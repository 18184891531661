import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../../components/header/header";
import { brandLight } from "../../constants";

const Videos = () => {
  const videoList = [
    {
      id: 1,
      src: "https://www.youtube.com/embed/6LwX8cQ46SY",
      title: "How to order an All On X",
    },
    {
      id: 2,
      src: "https://www.youtube.com/embed/vrm7a2v3vVk",
      title: "How to order a Denture",
    },
    {
      id: 3,
      src: "https://www.youtube.com/embed/bKDMzWHvkeM",
      title: "How to order a Diagnostic Waxup",
    },
    {
      id: 4,
      src: "https://www.youtube.com/embed/T-YxpL89KR4",
      title: "How to Create a Patient Profile",
    },
    {
      id: 5,
      src: "https://www.youtube.com/embed/YgcHwugZvik",
      title:
        "How to track your cases (Messages, Download Design, Approving Cases, Adding Files)",
    },
    {
      id: 6,
      src: "https://www.youtube.com/embed/8SubVovV7Jc",
      title: "How to send back a case",
    },
    {
      id: 7,
      src: "https://www.youtube.com/embed/mF1wx1HOkBE",
      title: "How to invite your Lab",
    },
    {
      id: 8,
      src: "https://www.youtube.com/embed/m68ZPun7Naw",
      title: "How to invite your Assistant/Office Manager",
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <Container>
        <Box
          sx={{
            minWidth: { xs: "100%", md: "464px", marginTop: "20px" },
            marginBottom: 3.75,
          }}
        >
          <Typography variant="h4" component="h1">
            Using Evident Hub For Dentists
          </Typography>
        </Box>

        <Box>
          <Grid container spacing={3.75} alignItems="stretch">
            {videoList.map((video) => (
              <Grid key={video.id} item lg={4} md={6} xs={12}>
                <Stack
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingY: 3,
                    paddingX: 2,
                    backgroundColor: brandLight,
                    borderRadius: "24px",
                    boxSizing: "border-box",
                    boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        maxHeight: "334px",
                        borderRadius: "22px",
                      }}
                    >
                      <CardMedia
                        allowFullScreen
                        sx={{ height: "300px", borderRadius: "20px" }}
                        component="iframe"
                        src={video.src}
                      />
                    </Box>
                    <Stack>
                      <Typography
                        sx={{
                          marginTop: 3,
                          fontSize: "1.125rem",
                          fontWeight: 700,
                          color: "#000",
                        }}
                      >
                        {video.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Videos;
