import {
    Dialog,
    DialogContent,
    InputLabel,
    Select,
    Stack,
    TextField,
    Typography,
    SelectChangeEvent,
    MenuItem,
    FormControl,
    IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';




const prebookConfirmationDialog = ({
    open,
    onClose,
}) => {


    const handleClose = () => {

        onClose();
    };
    const stepsList = [
        "1. Login to your Account",
        "2. Click the Case Management tab at the top of your screen",
        "3. Click on Prebooked cases to filter your cases",
        "4. Choose the case you scheduled",
        "5. Click on Start order and fill our your RX",
    ];

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    paddingY: 4,
                    paddingX: 4,
                    borderRadius: "14px",
                },
            }}
            fullWidth
        >
            <Box sx={{ textAlign: 'right', height:"5px" }}>
                <IconButton onClick={() => onClose(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>

                <Box width="100%" marginTop="-10px">

                    <Typography
                        
                        component="h2"
                        sx={{ fontSize: "25px", fontWeight: "700" }}
                    >
                        Congratulations! Your case has been scheduled with our team
                    </Typography>
                    <Stack spacing={1.75} marginTop={3} sx={{ width: "100%" }}>
                        <Typography sx={{ fontSize: "18px" }}>
                            At your scheduled time to upload your scans, please follow these easy steps:
                        </Typography>
                        <Stack>
                            {stepsList.map(steps => {
                                return (
                                    <Typography sx={{ color: "black", fontSize: "14px" }}>{steps}</Typography>
                                );
                            })}

                        </Stack>
                    </Stack>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default prebookConfirmationDialog;
