import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ProductDescription = ({description,productDescription}) => {
  return (
    <Stack>
        <Typography
            sx={{
                marginTop: 3,
                fontSize: "1.125rem",
                fontWeight: 700,
                color: "#000",
            }}
            >
            {description}
            </Typography>
            <Divider
            sx={{
                width: "22px",
                marginY: "12px",
                border: "1px solid #000",
            }}
            />
            <Typography
            sx={{
                fontSize: "0.938rem",
                fontWeight: 400,
                minHeight: 100
            }}
            >
            {productDescription}
        </Typography>
    </Stack>
  )
};

export default ProductDescription;