export const removeParameterFromUrl = (url,parameter) => {
    if (typeof parameter === "undefined" || parameter === null || parameter === "") {
        throw new Error("Parameter is required.");
    }

    // Remove the parameter and any leftover crud
    url = url.replace(new RegExp("\\b" + parameter + "=[^&;]+[&;]?", "gi"), "");
    url = url.replace(/[&;]$/, ""); // Remove trailing '&' or ';'

    console.log(url,'url');
    return url;
}