import { CircularProgress, Dialog, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";

const LoadingModal = ({ open, message }) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ textAlign: "center", justifyContent: "center" }}
    >
      <div alignItem="center" style={{padding: "4rem"}}>
        <CircularProgress color="info" size="100px" />
        <h1>{message}</h1>
      </div>
    </Dialog>
  );
  // return (
  //   <Dialog fullWidth maxWidth="sm" open={open}>
  //     <Box sx={{ paddingX: 10, paddingY: 10, margin: "0 auto" }}>
  //       <CircularProgress sx={{display: "block", margin: "0 auto" }}/>
  //       <Typography sx={{ marginTop: 4, color: grey[600] }}>
  //         {message}
  //       </Typography>
  //     </Box>
  //   </Dialog>
  // )
};

export default LoadingModal;
