export const formatCaseStatus = (status) => {
  if (status === "Sent") {
    return "Orders Received";
  } else if (status === "In Progress" || status === "Shipped") {
    return "Case In Progress";
  } else if (status === "For Approval") {
    return "Case For Dentist Approval";
  } else if (status === "For Completion") {
    return "Finalizing Design";
  } else if (status === "Completed" || status === "Received") {
      return "Completed";
  } else if (status === "Cancelled") {
      return "Cancelled";
  } else {
    return "None"
  }
};
