import Box from '@mui/material/Box';
import AboutTreatmentButton from './ctaButtons/aboutTreatmentButton';
import OrderNowButton from './ctaButtons/orderNowButton';

const CtaButtons = ({product,onClickAboutTreatment,selectedTreatment,onClickOrderNow,patient}) => {
  return (
    <Box>
        {(product.id !== process.env.REACT_APP_FILE_NESTING_ID && product.id !== 'prebooking') && <AboutTreatmentButton product={product} onClickAboutTreatment={onClickAboutTreatment} />}
        <OrderNowButton product={product} selectedTreatment={selectedTreatment} onClickOrderNow={onClickOrderNow} patient={patient} />
    </Box>
  )
};

export default CtaButtons;