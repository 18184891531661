import React from "react";
import  {  
  useRef,
  useState,
} from 'react';

export const useHubspotChat = (portalId) => {
    const [ hasLoaded, setHasLoaded ] = React.useState(false);
    const [ activeConversation, setActiveConversation ] = React.useState(false);
    
    
    React.useEffect(() => {
      console.log('hey')
      
      // Add event listener.
      window.hsConversationsOnReady = [() => {
        setHasLoaded(true);
      }];
     
      // Create script component.
      let script = document.createElement('script');
      script.src=`//js.hs-scripts.com/${portalId}.js`;
      script.async = true;
      
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
        window.hsConversationsOnReady = [];
      }
      
    }, []);
    
  
    
    return {
      hasLoaded, 
      activeConversation,
      };
  }