import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import LargeButton from "../common/button/largeButton";
import ContentContainer from "../common/contentContainer";
import usePatientFiles from "../../hooks/usePatientFiles";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import ExpandingTableRow from "../common/table/expandingTableRow";

import DownloadFileButton from "../common/button/downloadFileButton";
import NoFilesAlert from "./noFilesAlert";
import useUpload from "../../hooks/useUpload";
import UploadFileDialog from "../common/uploadFileDialog";
import PatientService from "../../services/patient.service";
import CaseNumberListDialog from "./casenumberListDialog";

const type = "intraoralScans";
const scanSetTypes = [
  { value: 0, name: "Pre Scan" },
  { value: 1, name: "Mock Up Scan" },
  { value: 2, name: "Post Scan" },
  { value: 3, name: "Other" },
];

const IntraoralScans = ({ patientId }) => {
  const [scanSetType, setScanSetType] = useState(0);
  const [scanSetName, setScanSetName] = useState("");
  const [uploadingSet, setUploadingSet] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { uploading, upload } = useUpload({ patientId, type });
  const {
    files,
    loading,
    pages,
    currentPage,
    handleChangePage,
    handleAddFiles,
  } = usePatientFiles({
    patientId,
    type: type,
    shouldGetExtensions: true,
    take: 10,
  });
  const [isCaseNumberDialogOpen, setIsCaseNumberDialogOpen] = useState(false);
  const [filesToSend, setFilesToSend] = useState();
  const [error, setError] = useState();

  const headers = ["Scan Set", "Date Created", ""];
  const rows = files.map((file) => [
    file.setName,
    moment(file.uploadedDate).format("LLL"),
    <LargeButton
      variant="primary"
      onClick={() => {
        setIsCaseNumberDialogOpen(true);
        setFilesToSend(file);
      }}
    >
      Send to Evident Design
    </LargeButton>,
  ]);

  const expandContents = files.map((file) => {
    const data = {
      headers: ["Filename", ""],
    };

    data.rows = file.fileExtensions.map((fileInExtension) => {
      return [
        fileInExtension.fileName,
        <DownloadFileButton
          path={fileInExtension.path}
          fileName={fileInExtension.fileName}
        />,
      ];
    });
    return (
      <DataTable headers={data.headers} sx={{ paddingX: 2 }}>
        {data.rows.map((row, i) => (
          <TableRow key={i} row={row} shouldUseDivider />
        ))}
      </DataTable>
    );
  });

  const handleClickPagination = (event, value) => {
    handleChangePage(value);
  };

  const handleUpload = async (files) => {
    if (files.length === 0) {
      const message = "No files selected";
      setError(message)
      throw new Error(message);
    } else if (scanSetType === 3 && scanSetName === "") {
      const message = "Scan set name is required";
      setError(message);
      throw new Error(message);
    }

    const data = await upload(files, {
      FileSetType: scanSetType,
    });

    setUploadingSet(true);

    const setData = await PatientService.uploadFileSet(
      {
        Description: scanSetName,
        FileName: scanSetName,
        FileSetType: scanSetType,
        ExtensionIds: data.map((d) => d.id),
        PatientId: patientId,
      },
      type
    );

    // method expects an array but setData is an object
    handleAddFiles([setData]);
    setIsDialogOpen(false);
    setUploadingSet(false);
  };

  const handleSelectChange = (event) => {
    setScanSetType(event.target.value);
    if (event.target.value !== 3 && scanSetName !== "") setScanSetName("");
  };

  const handleSendToEvidentDesign = (caseNumber) => {
    setIsCaseNumberDialogOpen(false);
  };

  return (
    <ContentContainer>
      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: 1.25 }}
        >
          Intraoral Scans
        </Typography>
        <Typography
          variant="body2"
          sx={{
            width: "90%",
            marginBottom: 3,
          }}
        >
          Digital scans may be exported from an intraoral scanner into STL or
          other formats. If needed, ask Evident for assistance. To upload,
          select Upload Scans and drag and drop the file/s into one of the
          predefined area.
        </Typography>
        <LargeButton variant="primary" onClick={() => setIsDialogOpen(true)}>
          Upload Scans
        </LargeButton>
      </Box>
      <Divider
        sx={{
          border: 0,
          borderBottom: "1px dashed #6C6C6C",
          marginBottom: 5,
        }}
      />

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          <React.Fragment>
            <DataTable headers={headers}>
              {rows.map((row, i) => (
                <ExpandingTableRow
                  key={row}
                  row={row}
                  expandContent={expandContents[i]}
                />
              ))}
            </DataTable>
            <Pagination
              count={pages}
              page={currentPage}
              onChange={handleClickPagination}
              sx={{ marginY: 2 }}
            />
          </React.Fragment>
        ) : (
          <NoFilesAlert />
        ))}
      <UploadFileDialog
        additionalFields={
          <Box>
            <FormControl fullWidth>
              <InputLabel id="scan-set-select-label">Scan Set Type</InputLabel>
              <Select
                labelId="scan-set-select-label"
                id="simple-select"
                value={scanSetType}
                label="Scan Set Type"
                onChange={handleSelectChange}
              >
                {scanSetTypes.map((v) => (
                  <MenuItem key={v.value} name={v.name} value={v.value}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {scanSetType === 3 && (
              <TextField
                label="Name of scan set"
                fullWidth
                sx={{ marginTop: 2 }}
                value={scanSetName}
                onChange={(e) => setScanSetName(e.target.value)}
              />
            )}
          </Box>
        }
        title="Upload Intraoral Scans"
        open={isDialogOpen}
        uploading={uploading || uploadingSet}
        onClose={() => {
          setIsDialogOpen(false);
          setError("")
        }}
        onUpload={handleUpload}
        error={error}
        resetError={() => setError("")}
        isIos
      />

      <CaseNumberListDialog
        open={isCaseNumberDialogOpen}
        onClose={() => {
          setIsCaseNumberDialogOpen(false);
        }}
        onSubmit={handleSendToEvidentDesign}
        patientId={patientId}
        filesToSend={filesToSend}
      />
    </ContentContainer>
  );
};

export default IntraoralScans;
