import * as yup from "yup";

export const inviteLabSchema = yup.object({
  showDentists: yup.boolean(),
  practiceId: yup
    .string("Enter a valid practiceId")
    .required("Practice is required"),
  dentist: yup.object().when("showDentists", {
    is: true,
    then: yup
      .object()
      .typeError("Choose a valid dentist")
      .required("Dentist is required"),
  }),
  labCode: yup.string().nullable(),
  labName: yup.string("Enter a valid string").required("Lab name is required"),
  firstName: yup.string("Enter a valid string").nullable(),
  lastName: yup.string("Enter a valid string").nullable(),
  email: yup
    .string("Enter a valid string")
    .email("Must be a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter a valid string")
    .min(5, "Phone number must be 5 characters or longer")
    .required("Phone number is required"),
});
