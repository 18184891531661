import React from "react";
import { Table, TableRow, TableBody, TableHead } from "@mui/material";
import TableCell from "./tableCell";

const DataTable = ({ headers, children, fixed = false, sx }) => {
  return (
    <Table
      size="small"
      sx={{
        height: "100%",
        borderCollapse: "separate",
        backgroundColor: "",
        tableLayout: fixed ? "fixed" : undefined,
        ...sx,
      }}
    >
      {headers && headers.length > 0 && (
        <TableHead>
          <TableRow sx={{ height: "100%" }}>
            {headers.map((header, i) => (
              <TableCell key={i} component="th">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>{children}</TableBody>
    </Table>
  );
};

export default DataTable;
