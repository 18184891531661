import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const ConfirmIfNewScanModal = ({closeDialog,isOpen,confirmNewOrder}) => {
    const buttonStyle = {
        marginX: '5px', 
        color: "#64B457",
        borderColor: "#64B457"
    }

    const modalStyle = {
        borderRadius: '12px',
        backgroundColor: "#FFF",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
    };
      
  return (
    <Modal
        onClose={closeDialog}
        open={isOpen}
    >
        <Box style={modalStyle}>
            <Box sx={{ textAlign: "right", marginRight: "5px", marginTop: "5px" }}>
                <IconButton sx={{color: "#858585"}} onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box paddingX={5} paddingY={3}>
                <Typography>Would you like to continue with the current patient scan or upload a new scan?</Typography>
            </Box>
            <Box marginY={2} display="flex" justifyContent="center" marginBottom={5}>
                <Box>
                    <Button sx={{
                        ...buttonStyle,
                        "&:hover": {
                            backgroundColor: "#65B559",
                            color: "#FFF",
                            borderColor: "#39A929"
                        }
                    }} variant='outlined' onClick={()=>confirmNewOrder(false)}>Upload New Scans</Button>
                    <Button sx={{
                        ...buttonStyle,
                        backgroundColor: "#65B559", 
                        color: "#FFF",
                        "&:hover": {
                            backgroundColor: "#39A929"
                        }
                    }} variant='contained' onClick={()=>confirmNewOrder(true)}>Continue</Button>
                </Box>
            </Box>
        </Box>
    </Modal>
  )
}

export default ConfirmIfNewScanModal