import React, { useState, useEffect } from 'react';
import ProductDetailModal from './productDetailModal';
import  './productDetailModal.css';
import { Grid } from '@mui/material';

// Utility function to extract the iframe element from HTML content
const extractIframe = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const iframe = doc.querySelector('iframe');
  return iframe ? iframe.outerHTML : null;
};

const HtmlContentChecker = ({ htmlContent }) => {
  const [iframeContent, setIframeContent] = useState(null);

  useEffect(() => {
    setIframeContent(extractIframe(htmlContent));
  }, [htmlContent]);

  return (
    <div>
      {iframeContent ? (
        <div>
         {/* Render the HTML content with the iframe  */}
         <Grid className='main-container' container spacing={2}>
            <Grid item md={5} xs={12}>
              <div className="video-hldr" dangerouslySetInnerHTML={{ __html: iframeContent }} />
            </Grid>
            <Grid item md={7} xs={12}>
            <div className="prdct_dtl" dangerouslySetInnerHTML={{ __html: htmlContent.replace(iframeContent, '') }} />
            </Grid>   
         </Grid>
          
          
        </div>
      ) : (
        
        <div className="prdct_dtl" dangerouslySetInnerHTML={{ __html: htmlContent.replace(iframeContent, '') }} />
        //  Render the HTML content without the iframe 
      )}
     
   
    </div>
  );
};

export default HtmlContentChecker;
