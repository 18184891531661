import { toPng } from "html-to-image";

const universal = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
];
const fdi = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37, 36,
  35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
];
const dentalNotationSystem = "Universal";

const ToothChartService = {
  convertToothNumberNotation: function (
    number,
    previousNotation,
    currentNotation
  ) {
    switch (currentNotation) {
      case "Universal":
        if (previousNotation == "FDI") {
          var index = fdi.findIndex((o) => o == number);
          number = universal[index];
        }
        break;
      case "FDI":
        if (previousNotation == "Universal") {
          var index = universal.findIndex((o) => o == number);
          number = fdi[index];
        }
        break;
    }

    return number;
  },
  getDentalNotationSystem: function () {
    var dentalNotation;
    if (
      window.localStorage.getItem("storedDentalNotation") === null ||
      !window.localStorage.getItem("storedDentalNotation")
    ) {
      dentalNotation = dentalNotationSystem;
    } else {
      dentalNotation = dentalNotation = window.localStorage.getItem(
        "storedDentalNotation"
      );
    }
    return dentalNotation;
  },
  setDentalNotationSystem: function (notion) {
    window.localStorage.setItem("storedDentalNotation", notion);
  },
  loadToothNumbers: function (numbers, treatmentId) {
    numbers.forEach((number) => {
      let tooth = document.getElementById("Tooth" + number + "-" + treatmentId);

      if (tooth !== null) {
        if (number < 17) {
          tooth.classList.add("selected-tooth-maxillary");
          tooth.setAttribute("style", "fill: rgba(76, 186, 83, 0.6);");
        } else {
          tooth.classList.add("selected-tooth-mandibular");
          tooth.setAttribute("style", "fill: rgba(23, 143, 254, 0.6);");
        }
      }
    });
  },
  archesCount: function (numbers){
    var notation = this.getDentalNotationSystem();
    var arches = 1;
    var upper = 0;
    var lower = 0;
    numbers.forEach((number) => {
      if(notation === "Universal"){
        if(number < 17)
            upper = upper + 1;
        else
          lower = lower + 1;
      }else if(notation === "FDI"){
        if(number < 29)
            upper = upper + 1;
        else
          lower = lower + 1;
      }
    });
    if(upper > 0 && lower > 0)
      arches = 2;

    return arches;
  },
  removeSelectedTooth: function (number, treatmentId) {
    var a = this.getDentalNotationSystem();
    if (a === "FDI") {
      console.log("number", number);
      number = ToothChartService.convertToothNumberNotation(
        parseInt(number),
        "FDI",
        "Universal"
      );
      console.log("numberafterconvert", number);
    }
    let tooth = document.getElementById("Tooth" + number + "-" + treatmentId);

    if (tooth !== null) {
      if (number < 17) {
        tooth.classList.remove("selected-tooth-maxillary");
        tooth.setAttribute("style", "fill: #FFFFFF;");
      } else {
        tooth.classList.remove("selected-tooth-mandibular");
        tooth.setAttribute("style", "fill: #FFFFFF;");
      }
    }
  },
  setToothColorToSelected: function (number, treatmentId) {
    var a = this.getDentalNotationSystem();
    if (a === "FDI") {
      number = ToothChartService.convertToothNumberNotation(
        parseInt(number),
        "FDI",
        "Universal"
      );
      console.log("numberafterconvert", number);
    }
    let tooth = document.getElementById("Tooth" + number + "-" + treatmentId);

    if (tooth !== null) {
      if (number < 17) {
        tooth.classList.remove("selected-tooth-maxillary");
        tooth.setAttribute("style", "fill: gray;");
      } else {
        tooth.classList.remove("selected-tooth-mandibular");
        tooth.setAttribute("style", "fill: gray;");
      }
    }
  },
  convertToothChartToPng: function (treatmentId) {
    let getToothChart = document.getElementById("tooth-chart-" + treatmentId);

    if (getToothChart !== null) {
      return toPng(getToothChart);
    } else {
      return null;
    }
  },
};

export default ToothChartService;
