import Box from "@mui/material/Box";

const ProductImage = ({imageLink}) => {
  return (
    <Box
        sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        maxHeight: "334px",
        borderRadius: "22px",
        }}
    >
        <Box
        component="img"
        src={imageLink}
        sx={{
            objectFit: "contain",
            maxWidth: "100%",
            borderRadius: "22px",
        }}
        />
    </Box>
  )
};

export default ProductImage;