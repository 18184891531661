import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import MenuItem from "./menuItem";

const MenuButton = ({ icon, title, onClick }) => {
  return (
    <MenuItem component={ButtonBase} onClick={onClick}>
      {icon}
      <Typography as="span" sx={{ marginLeft: 1, fontWeight: "400" }}>
        {title}
      </Typography>
    </MenuItem>
  );
};

export default MenuButton;
