import React from "react";
import Dropzone from "react-dropzone";
import {
  Box,
  Grid,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  Checkbox,
} from "@mui/material";

import ToothChart from "../../../common/toothChart/toothChart";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import setFilePhoto from "../../../../images/set_file_photo.svg";
import DragAndDropImage from "../../../../images/drag-and-drop.svg";
import caseCreatedIcon from "../../../../images/case_created_icon.svg";

import AggressiveStyle from "../../../../images/toothStyles/aggressiveStyle.png";
import DominantStyle from "../../../../images/toothStyles/dominantStyle.png";
import EnhancedStyle from "../../../../images/toothStyles/enhancedStyle.png";
import FocusedStyle from "../../../../images/toothStyles/focusedStyle.png";
import FunctionalStyle from "../../../../images/toothStyles/functionalStyle.png";
import HollywoodStyle from "../../../../images/toothStyles/hollywoodStyle.png";
import MatureStyle from "../../../../images/toothStyles/matureStyle.png";
import NaturalStyle from "../../../../images/toothStyles/naturalStyle.png";
import OvalStyle from "../../../../images/toothStyles/ovalStyle.png";
import SoftenedStyle from "../../../../images/toothStyles/softenedStyle.png";
import VigorousStyle from "../../../../images/toothStyles/vigorousStyle.png";
import YouthfulStyle from "../../../../images/toothStyles/youthfulStyle.png";
import AdditionalFiles from "../../../../images/additionalFiles.svg";

import A1Shade from "../../../../images/Shade/A1-Shade.png";
import BleachShade from "../../../../images/Shade/bleach-shade.png";
import D2Shade from "../../../../images/Shade/d2-shade.png";

import ToothService from "../../../../services/toothChart.service";
import FileUploaderService from "../../../../services/fileUploader.service";
import PatientService from "../../../../services/patient.service";

import SelectToothRestorationModal from "../common/selectToothRestorationModal/selectToothRestorationModal";

function EvidentSmilePackage({
  treatment,
  patientId,
  handleTreatmentUpdate,
  patientFiles,
  notion
}) {
  const optionsForFinalDesign = [
    "Diagnostic Wax-up",
    "Trial Smile (to try in the patient's mouth)",
    "Prepped shell temps with virtual prep or a prepped IOS scan",
    "Final restorations",
    "File with the current scan with the design file to show the areas of reduction",
    "Every other tooth model (for direct composite bonding)",
  ];

  const [treatmentDetails, setTreatmentDetails] = React.useState(treatment);
  const [selectToothRestorationOpen, setSelectToothRestorationOpen] =
    React.useState(false);
  const [selectedTooth, setSelectedTooth] = React.useState(0);
  const [typesOfRestoration, setTypesOfRestoration] = React.useState([
    "Veneers",
    "Crowns",
    "Pontic",
  ]);
  const [requiredFiles, setRequiredFiles] = React.useState([
    {
      name: "IOS Scan (STL Files) (Upper, Lower and Bite)",
      image: setFilePhoto,
      files: [],
      numberOfFilesUploading: 0,
    },
    {
      name: "Clinical Photos (jpg/png)",
      image: setFilePhoto,
      files: [],
      numberOfFilesUploading: 0,
    },
    {
      name: "Additional Files",
      image: AdditionalFiles,
      files: [],
      numberOfFilesUploading: 0,
    },
  ]);

  const toothStyles = [
    {
      name: "Aggressive Style",
      image: AggressiveStyle,
    },
    {
      name: "Dominant Style",
      image: DominantStyle,
    },
    {
      name: "Oval Style",
      image: OvalStyle,
    },
    {
      name: "Softened Style",
      image: SoftenedStyle,
    },
    {
      name: "Enhanced Style",
      image: EnhancedStyle,
    },
    {
      name: "Vigorous Style",
      image: VigorousStyle,
    },
    {
      name: "Youthful Style",
      image: YouthfulStyle,
    },
    {
      name: "Functional Style",
      image: FunctionalStyle,
    },
    {
      name: "Hollywood Style",
      image: HollywoodStyle,
    },
    {
      name: "Focused Style",
      image: FocusedStyle,
    },
    {
      name: "Mature Style",
      image: MatureStyle,
    },
    {
      name: "Natural Style",
      image: NaturalStyle,
    },
  ];

  React.useEffect(() => {
    let requiredFilesCopy = Object.assign([], requiredFiles);

    requiredFilesCopy[0].files = requiredFilesCopy[0].files.splice();

    setRequiredFiles(requiredFilesCopy);

    if (patientFiles) {
      let ios = patientFiles.find((x) => x.fileType === "Intraoral Scan");

      if (ios) {
        ios.files.forEach((y) => {
          PatientService.getPatientFileExtension(
            y.patientFileId,
            y.patientId,
            y.fileType
          )
            .then((response1) => response1.json())
            .then((json1) => {
              json1.forEach((pFile) => {
                let linkToPatient = {
                  PatientId: patientId,
                  FileId: pFile.fileId,
                  Filename: pFile.fileName,
                };

                PatientService.uploadPatientFile(linkToPatient)
                  .then((response) => response.json())
                  .then((json) => {
                    let newFile = {
                      idx: 0,
                      fileName: pFile.fileName,
                      fileId: pFile.fileId,
                      patientFileId: json.id,
                      uploadedDate: pFile.uploadedDate,
                    };

                    treatmentDetails.files =
                      treatmentDetails.files.concat(newFile);

                    setTreatmentDetails(treatmentDetails);
                    if (handleTreatmentUpdate) {
                      handleTreatmentUpdate(treatmentDetails);
                    }

                    let requiredFilesCopy = Object.assign([], requiredFiles);

                    requiredFilesCopy[0].files.push(newFile);

                    setRequiredFiles(requiredFilesCopy);
                  });
              });
            });
        });
      }

      let cp = patientFiles.find((x) => x.fileType === "Clinical Photography");
      //Clinical Photography
      if (cp) {
        cp.files.forEach((y) => {
          PatientService.getPatientFileExtension(
            y.patientFileId,
            y.patientId,
            y.fileType
          )
            .then((response1) => response1.json())
            .then((json1) => {
              json1.forEach((pFile) => {
                let linkToPatient = {
                  PatientId: patientId,
                  FileId: pFile.fileId,
                  Filename: pFile.fileName,
                };

                PatientService.uploadPatientFile(linkToPatient)
                  .then((response) => response.json())
                  .then((json) => {
                    let newFile = {
                      idx: 1,
                      fileName: pFile.fileName,
                      fileId: pFile.fileId,
                      patientFileId: json.id,
                    };

                    treatmentDetails.files =
                      treatmentDetails.files.concat(newFile);

                    setTreatmentDetails(treatmentDetails);
                    if (handleTreatmentUpdate) {
                      handleTreatmentUpdate(treatmentDetails);
                    }

                    let requiredFilesCopy = Object.assign([], requiredFiles);

                    requiredFilesCopy[1].files.push(newFile);

                    setRequiredFiles(requiredFilesCopy);
                  });
              });
            });
        });
      }
    }
  }, [patientFiles]);

  React.useEffect(() => {
    let requirements = [
      {
        description: "Tooth Chart",
        value: null,
      },
      {
        description: "Type of Restoration (Multiple)",
        value: null,
      },
      {
        description: "Misaligned Teeth Correction",
        value: null,
      },
      {
        description: "Gingival Contouring",
        value: null,
        needGingivalGuide: null,
      },
      {
        description: "Vertical Dimension for Occlusion",
        value: null,
      },
      {
        description: "Max Preferred Length of Centrals",
        value: null,
      },
      {
        description: "Shade (Case Presentation Purposes)",
        value: null,
      },
      {
        description: "Smile Type",
        value: null,
      },
      {
        description: "Select up to 4 files to be included in the package",
        value: [],
      },
      {
        description: "Model Files ($15 per additional file)",
        value: [],
      },
    ];

    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    treatmentDetailsCopy.requirements = requirements;

    setTreatmentDetails(treatmentDetailsCopy);

    handleTreatmentUpdate(treatmentDetailsCopy);

    if (treatment.files.length > 0) {
      let requiredFilesCopy = [
        {
          name: "IOS Scan (STL Files) (Upper, Lower and Bite)",
          image: setFilePhoto,
          files: [],
        },
        {
          name: "Clinical Photos (jpg/png)",
          image: setFilePhoto,
          files: [],
        },
        {
          name: "Additional Files",
          image: AdditionalFiles,
          files: [],
        },
      ];

      treatment.files.forEach((x) => {
        requiredFilesCopy[x.idx].files.push(x);
      });

      setRequiredFiles(requiredFilesCopy);
    }

    if (treatment.toothNumbers.length > 0) {
      let numbers = [];

      treatment.toothNumbers.forEach((x) => {
        numbers.push(x.tooth);
      });

      ToothService.loadToothNumbers(numbers, treatment.id);
    }
  }, []);

  const handleSelectRestorationClose = (selected) => {
    setSelectToothRestorationOpen(false);
    if (selected !== null) {
      treatmentDetails.toothNumbers.forEach((tooth) => {
        if (tooth.restorationType == "") {
          tooth.restorationType = selected;
        }

        setTreatmentDetails(treatmentDetails);

        if (handleTreatmentUpdate) {
          handleTreatmentUpdate(treatmentDetails);
        }
      });
    }
  };

  const handleSelectTooth = (number) => {
    setSelectedTooth(number);
    let newTooth = {
      tooth: number,
      restorationType: "",
    };
    treatmentDetails.toothNumbers =
      treatmentDetails.toothNumbers.concat(newTooth);
    setTreatmentDetails(treatmentDetails);
  };

  const removeTooth = (number) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
    treatmentDetailsCopy.toothNumbers =
      treatmentDetailsCopy.toothNumbers.filter((x) => x.tooth !== number);

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }

    ToothService.removeSelectedTooth(number, treatment.id);
  };

  const handleRemoveTeeth = (number) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
    treatmentDetailsCopy.toothNumbers =
      treatmentDetailsCopy.toothNumbers.filter((x) => x.tooth !== number);

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleRequirementSelectValue = (
    reqDesc,
    value,
    measurementToZero = false
  ) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      getRequirement.value = value;

      if (measurementToZero) {
        if (typeof getRequirement.measurement !== "undefined") {
          getRequirement.measurement = -1;
        }
      }
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleRequirementSelectMeasurement = (reqDesc, value) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      getRequirement.measurement = value;
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleRequirementsSelectList = (reqDesc, value, add) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      if (add === false) {
        getRequirement.value = getRequirement.value.filter((x) => x !== value);
      } else {
        getRequirement.value.push(value);
      }
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleChangeAdditionalNotes = (value) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    treatmentDetailsCopy.additionalNotes = value;

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const onFileChange = (files, idx) => {
    console.log("files", files);
    if (files.length > 0) {
      let requiredFilesCopy = Object.assign([], requiredFiles);
      requiredFilesCopy[idx].numberOfFilesUploading =
        requiredFilesCopy[idx].numberOfFilesUploading + files.length;
      setRequiredFiles(requiredFilesCopy);

      for (var i = 0; i < files.length; i++) {
        let x = files[i];

        let generateFileId = FileUploaderService.generateGuid();

        let blobName =
          "patient/" +
          "00000000-0000-0000-0000-000000000000" +
          "/" +
          generateFileId;

        if (patientId !== "") {
          blobName = "patient/" + patientId + "/" + generateFileId;
        }

        console.log("fileId", blobName);

        let fileObj = {
          idx: idx,
          treatmentId: treatment.id,
          fileId: generateFileId,
          fileName: x.name,
          patientFileId: "",
          patientExtensionId: "",
        };

        FileUploaderService.uploadFile(
          x,
          blobName,
          fileUploadCallback,
          fileObj
        );
      }
    }

    // let documentId = "file-upload-" + treatment.id;

    // document.getElementById(documentId).value = "";
  };

  const fileUploadCallback = (progress, fileObj) => {
    if (progress === 100) {
      if (patientId !== "") {
        let linkToPatient = {
          PatientId: patientId,
          FileId: fileObj.fileId,
          Filename: fileObj.fileName,
        };

        PatientService.uploadPatientFile(linkToPatient)
          .then((response) => response.json())
          .then((json) => {
            let newFile = {
              idx: fileObj.idx,
              fileName: fileObj.fileName,
              fileId: fileObj.fileId,
              patientFileId: json.id,
            };

            treatmentDetails.files = treatmentDetails.files.concat(newFile);

            setTreatmentDetails(treatmentDetails);

            if (handleTreatmentUpdate) {
              handleTreatmentUpdate(treatmentDetails);
            }

            let requiredFilesCopy = Object.assign([], requiredFiles);

            requiredFilesCopy[fileObj.idx].files.push(newFile);
            requiredFilesCopy[fileObj.idx].numberOfFilesUploading =
              requiredFilesCopy[fileObj.idx].numberOfFilesUploading - 1;
            setRequiredFiles(requiredFilesCopy);
          });
      } else {
        let newFile = {
          idx: fileObj.idx,
          fileName: fileObj.fileName,
          fileId: fileObj.fileId,
          patientFileId: "00000000-0000-0000-0000-000000000000",
        };

        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

        treatmentDetailsCopy.files = treatmentDetailsCopy.files.concat(newFile);

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
          handleTreatmentUpdate(treatmentDetailsCopy);
        }

        let requiredFilesCopy = Object.assign([], requiredFiles);

        requiredFilesCopy[fileObj.idx].files.push(newFile);
        requiredFilesCopy[fileObj.idx].numberOfFilesUploading =
          requiredFilesCopy[fileObj.idx].numberOfFilesUploading - 1;
        setRequiredFiles(requiredFilesCopy);
      }
    }
  };

  const handleRequirementSelectNeedGingival = (
    reqDesc,
    value,
    measurementToZero = false
  ) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      getRequirement.needGingivalGuide = value;

      if (measurementToZero) {
        if (typeof getRequirement.measurement !== "undefined") {
          getRequirement.measurement = -1;
        }
      }
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const removeFile = (requireIdx, fileIdx) => {
    let requiredFilesCopy = Object.assign([], requiredFiles);

    let fileCopy = Object.assign(
      {},
      requiredFilesCopy[requireIdx].files[fileIdx]
    );

    requiredFilesCopy[requireIdx].files.splice(fileIdx, 1);

    setRequiredFiles(requiredFilesCopy);

    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    treatmentDetailsCopy.files = treatmentDetailsCopy.files.filter(
      (x) => x.fileId !== fileCopy.fileId
    );

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  return (
    <>
      <SelectToothRestorationModal
        isOpen={selectToothRestorationOpen}
        withGingivalContouring={false}
        typesOfRestoration={typesOfRestoration}
        handleClose={handleSelectRestorationClose}
      />
      {treatmentDetails.requirements
        .filter((x) => x.description !== "Type of Restoration (Multiple)")
        .map((req, idx) => (
          <>
            <Box sx={{ marginTop: "45px", marginBottom: "45px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#5B5B5B",
                    color: "#fff",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  {idx + 1}
                </Box>
                <Box
                  sx={{
                    fontSize: "21px",
                    fontWeight: "500",
                    lineHeight: "31px",
                    color: "#27374F",
                  }}
                >
                  {req.description}
                </Box>
              </Box>

              {req.description === "Tooth Chart" ? (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        background: "rgba(0,0,0,0.08)",
                        borderRadius: "21px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "75%" }}>
                        <ToothChart
                          toothChartId={treatment.id}
                          selectedChartType={notion}
                          handleSelectTooth={handleSelectTooth}
                          handleRemoveTeeth={handleRemoveTeeth}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        background: "#fff",
                        minHeight: "100%",
                        borderRadius: "16px 19px 19px 19px",
                        overflowY: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#000",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeigth: "21px",
                          paddingTop: "40px",
                          textAlign: "center",
                        }}
                      >
                        Your selected tooth will be displayed here:
                      </Box>
                      <Box
                        sx={{
                          lineHeigth: "21px",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "none",
                            background:
                              "linear-gradient(270deg, #11C984 0%, #32BE60 100%)",
                            boxShadow: "0px 7px 7px rgba(0, 0, 0, 0.05)",
                            borderRadius: "5px",
                            width: "250px",
                            height: "60px",
                            color: "#fff",
                          }}
                          onClick={() => setSelectToothRestorationOpen(true)}
                        >
                          Choose a treatment plan for the selected tooth.
                        </Button>
                      </Box>

                      {treatmentDetails.toothNumbers.length === 0 ? (
                        <Box
                          sx={{
                            display: "flex",
                            background:
                              "linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FDFDFD",
                            borderRadius: "6px",
                            width: "75%",
                            margin: "0 auto",
                            paddingTop: "22px",
                            paddingBottom: "22px",
                            paddingLeft: "20px",
                            marginTop: "30px",
                          }}
                        >
                          Select a tooth first.
                        </Box>
                      ) : (
                        <>
                          {treatmentDetails.toothNumbers.map((tooth, idx) => (
                            <Box
                              sx={{
                                display: "flex",
                                width: "75%",
                                margin: "0 auto",
                                borderRadius: "6px",
                                marginTop: "30px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "65%",
                                  alignItems: "center",
                                  paddingTop: "22px",
                                  paddingBottom: "22px",
                                  paddingLeft: "20px",
                                  background:
                                    "linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FDFDFD",
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                }}
                              >
                                <Box sx={{ flexGrow: 1 }}>
                                  #{tooth.tooth} Tooth
                                </Box>
                                <Box sx={{ marginRight: "15px" }}>
                                  <IconButton
                                    onClick={() => removeTooth(tooth.tooth)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "35%",
                                  paddingTop: "22px",
                                  paddingBottom: "22px",
                                  paddingLeft: "20px",
                                  background: "#0587E4",
                                  borderTopRightRadius: "6px",
                                  borderBottomRightRadius: "6px",
                                  color: "#fff",
                                }}
                              >
                                {tooth.restorationType.indexOf("-") >= 0
                                  ? tooth.restorationType.split("-")[0]
                                  : tooth.restorationType}
                              </Box>
                            </Box>
                          ))}
                        </>
                      )}
                      
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              {req.description === "Misaligned Teeth Correction" ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "230px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "15px",
                      height: "55px",
                      background:
                        req.value === "As Much as Possible"
                          ? "#0587E4"
                          : "#FBFBFB",
                      color:
                        req.value === "As Much as Possible" ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(
                        "Misaligned Teeth Correction",
                        "As Much as Possible"
                      )
                    }
                  >
                    As Much as Possible
                  </Box>
                  <Box
                    sx={{
                      width: "230px",
                      height: "55px",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "60px",
                      cursor: "pointer",
                      background:
                        req.value !== null &&
                        req.value !== "As Much as Possible"
                          ? "#0587E4"
                          : "#FBFBFB",
                      color:
                        req.value !== null &&
                        req.value !== "As Much as Possible"
                          ? "#fff"
                          : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(
                        "Misaligned Teeth Correction",
                        ""
                      )
                    }
                  >
                    Other
                  </Box>

                  {req.value !== null && req.value !== "As Much as Possible" ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        <TextField
                          size={"medium"}
                          value={req.value}
                          onChange={(e) => {
                            handleRequirementSelectValue(
                              "Misaligned Teeth Correction",
                              e.target.value
                            );
                          }}
                          sx={{ backgroundColor: "#fff", width: "250px" }}
                          placeholder="Please specify"
                        />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}

              {req.description === "Gingival Contouring" ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "230px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "15px",
                      height: "55px",
                      background: req.value === "Yes" ? "#0587E4" : "#FBFBFB",
                      color: req.value === "Yes" ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue("Gingival Contouring", "Yes")
                    }
                  >
                    Yes
                  </Box>
                  <Box
                    sx={{
                      width: "230px",
                      height: "55px",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "60px",
                      cursor: "pointer",
                      background:
                        req.value !== null && req.value !== "Yes"
                          ? "#0587E4"
                          : "#FBFBFB",
                      color:
                        req.value !== null && req.value !== "Yes"
                          ? "#fff"
                          : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue("Gingival Contouring", "No")
                    }
                  >
                    No
                  </Box>

                  {req.value !== null && req.value !== "No" ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ marginBottom: "10px" }}>
                        Do you need a Gingival Reduction Guide? (Additional
                        $170)
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            width: "100px",
                            cursor: "pointer",
                            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                            marginRight: "15px",
                            height: "55px",
                            background:
                              req.needGingivalGuide === true
                                ? "#0587E4"
                                : "#FBFBFB",
                            color:
                              req.needGingivalGuide === true ? "#fff" : "#000",
                            borderRadius: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleRequirementSelectNeedGingival(
                              "Gingival Contouring",
                              true
                            )
                          }
                        >
                          Yes
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "55px",
                            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                            marginRight: "60px",
                            cursor: "pointer",
                            background:
                              req.needGingivalGuide !== null &&
                              req.needGingivalGuide !== true
                                ? "#0587E4"
                                : "#FBFBFB",
                            color:
                              req.needGingivalGuide !== null &&
                              req.needGingivalGuide !== true
                                ? "#fff"
                                : "#000",
                            borderRadius: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleRequirementSelectNeedGingival(
                              "Gingival Contouring",
                              false
                            )
                          }
                        >
                          No
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}

              {req.description === "Vertical Dimension for Occlusion" ? (
                <>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        width: "194px",
                        height: "65px",
                        display: "flex",
                        border:
                          req.value === "Keep Existing"
                            ? "2px solid #32BE60"
                            : "2px solid #000000",
                        cursor: "pointer",
                        marginRight: "10px",
                        borderRadius: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(196, 196, 196, 0.5)",
                      }}
                      onClick={() =>
                        handleRequirementSelectValue(
                          "Vertical Dimension for Occlusion",
                          "Keep Existing",
                          true
                        )
                      }
                    >
                      Keep Existing
                    </Box>
                    <Box
                      sx={{
                        width: "194px",
                        height: "65px",
                        display: "flex",
                        border:
                          req.value === "Open Bite"
                            ? "2px solid #32BE60"
                            : "2px solid #000000",
                        cursor: "pointer",
                        marginRight: "10px",
                        borderRadius: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(196, 196, 196, 0.5)",
                      }}
                      onClick={() =>
                        handleRequirementSelectValue(
                          "Vertical Dimension for Occlusion",
                          "Open Bite"
                        )
                      }
                    >
                      Open Bite
                    </Box>
                    <Box
                      sx={{
                        width: "194px",
                        height: "65px   ",
                        display: "flex",
                        border:
                          req.value === "Make Ideal"
                            ? "2px solid #32BE60"
                            : "2px solid #000000",
                        cursor: "pointer",
                        marginRight: "10px",
                        borderRadius: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(196, 196, 196, 0.5)",
                      }}
                      onClick={() =>
                        handleRequirementSelectValue(
                          "Vertical Dimension for Occlusion",
                          "Make Ideal",
                          true
                        )
                      }
                    >
                      Make Ideal
                    </Box>
                  </Box>

                  {req.value === "Open Bite" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                      }}
                    >
                      <Box sx={{ marginBottom: "10px" }}>
                        Desired Measurement
                      </Box>
                      <Box>
                        <TextField
                          size={"medium"}
                          value={req.measurement}
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            handleRequirementSelectMeasurement(
                              "Vertical Dimension for Occlusion",
                              e.target.value
                            );
                          }}
                          sx={{ backgroundColor: "#fff", width: "250px" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mm</InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {req.description === "Snap on Temp Needed" ? (
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      width: "230px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "15px",
                      height: "60px",
                      background: req.value === true ? "#0587E4" : "#FBFBFB",
                      color: req.value === true ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue("Snap on Temp Needed", true)
                    }
                  >
                    Yes
                  </Box>
                  <Box
                    sx={{
                      width: "230px",
                      height: "60px",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      background: req.value === false ? "#0587E4" : "#FBFBFB",
                      color: req.value === false ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue("Snap on Temp Needed", false)
                    }
                  >
                    No
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {req.description === "Max Preferred Length of Centrals" ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "230px",
                      cursor: "pointer",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "15px",
                      height: "60px",
                      background: req.value === "Ideal" ? "#0587E4" : "#FBFBFB",
                      color: req.value === "Ideal" ? "#fff" : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(
                        "Max Preferred Length of Centrals",
                        "Ideal"
                      )
                    }
                  >
                    Ideal
                  </Box>
                  <Box
                    sx={{
                      width: "230px",
                      height: "60px",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginRight: "60px",
                      cursor: "pointer",
                      background:
                        req.value !== null && req.value !== "Ideal"
                          ? "#0587E4"
                          : "#FBFBFB",
                      color:
                        req.value !== null && req.value !== "Ideal"
                          ? "#fff"
                          : "#000",
                      borderRadius: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(
                        "Max Preferred Length of Centrals",
                        ""
                      )
                    }
                  >
                    Others
                  </Box>

                  {req.value !== null && req.value !== "Ideal" ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ marginBottom: "10px" }}>
                        Desired Length in mm
                      </Box>
                      <Box>
                        <TextField
                          size={"medium"}
                          type="number"
                          value={req.value}
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            handleRequirementSelectValue(
                              "Max Preferred Length of Centrals",
                              e.target.value
                            );
                          }}
                          sx={{ backgroundColor: "#fff", width: "250px" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mm</InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}

              {req.description === "Shade (Case Presentation Purposes)" ? (
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Box
                    sx={{
                      width: "180px",
                      height: "70px",
                      marginBottom: "10px",
                      display: "flex",
                      border:
                        req.value === "Natural"
                          ? "2px solid #32BE60"
                          : "2px solid #000000",
                      cursor: "pointer",
                      marginRight: "10px",
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundImage: `url(${A1Shade})`,
                      backgroundSize: "cover",
                      backgroundSize: "100% 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(req.description, "Natural")
                    }
                  ></Box>
                  <Box
                    sx={{
                      width: "180px",
                      height: "70px",
                      marginBottom: "10px",
                      display: "flex",
                      border:
                        req.value === "Bleach"
                          ? "2px solid #32BE60"
                          : "2px solid #000000",
                      cursor: "pointer",
                      marginRight: "10px",
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundImage: `url(${BleachShade})`,
                      backgroundSize: "cover",
                      backgroundSize: "100% 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(req.description, "Bleach")
                    }
                  ></Box>
                  <Box
                    sx={{
                      width: "180px",
                      height: "70px",
                      marginBottom: "10px",
                      display: "flex",
                      border:
                        req.value === "D2"
                          ? "2px solid #32BE60"
                          : "2px solid #000000",
                      cursor: "pointer",
                      marginRight: "10px",
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundImage: `url(${D2Shade})`,
                      backgroundSize: "cover",
                      backgroundSize: "100% 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() =>
                      handleRequirementSelectValue(req.description, "D2")
                    }
                  ></Box>
                </Box>
              ) : (
                ""
              )}

              {req.description ===
              "Select up to 4 files to be included in the package" ? (
                <Box sx={{}}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        checked={req.value.indexOf("Diagnostic Wax Up") >= 0}
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Diagnostic Wax Up",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>Diagnostic Wax Up</Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        disabled={
                          req.value.indexOf(
                            "Virtually prepped shell temps (with a 0.6 mm virtual prep)"
                          ) >= 0
                        }
                        checked={
                          req.value.indexOf(
                            "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)"
                          ) >= 0
                        }
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      Trial Smile (One-piece veneer style to try in over the
                      patient's existing teeth)
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        disabled={
                          req.value.indexOf(
                            "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)"
                          ) >= 0
                        }
                        checked={
                          req.value.indexOf(
                            "Virtually prepped shell temps (with a 0.6 mm virtual prep)"
                          ) >= 0
                        }
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Virtually prepped shell temps (with a 0.6 mm virtual prep)",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      Virtually prepped shell temps (with a 0.6 mm virtual prep)
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        checked={
                          req.value.indexOf(
                            "Prepped shell temps (chairside prep and IOS scan"
                          ) >= 0
                        }
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Prepped shell temps (chairside prep and IOS scan",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>Prepped shell temps (chairside prep and IOS scan)</Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        checked={req.value.indexOf("Final restorations") >= 0}
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Final restorations",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>Final restorations</Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {req.description === "Model Files ($15 per additional file)" ? (
                <Box sx={{}}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        checked={
                          req.value.indexOf(
                            "Printable model file with the current scan and the reduced design file to show the amount of reduction"
                          ) >= 0
                        }
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Printable model file with the current scan and the reduced design file to show the amount of reduction",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      Printable model file with the current scan and the reduced
                      design file to show the amount of reduction
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "15px" }}>
                      <Checkbox
                        checked={
                          req.value.indexOf(
                            "Every other tooth model (for direct composite bonding)"
                          ) >= 0
                        }
                        onChange={(e) => {
                          handleRequirementsSelectList(
                            req.description,
                            "Every other tooth model (for direct composite bonding)",
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      Every other tooth model (for direct composite bonding)
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {req.description === "Smile Type" ? (
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {toothStyles.map((sty, idx) => (
                    <Box
                      sx={{
                        width: "168px",
                        marginTop: "10px",
                        height: "66px",
                        display: "flex",
                        border:
                          req.value === sty.name
                            ? "2px solid #32BE60"
                            : "2px solid #000000",
                        cursor: "pointer",
                        marginRight: "10px",
                        borderRadius: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage: `url(${sty.image})`,
                        backgroundSize: "cover",
                        backgroundSize: "100% 100%",
                        backgroundRepeat: "no-repeat",
                      }}
                      onClick={() =>
                        handleRequirementSelectValue(
                          "Smile Type",
                          sty.name,
                          true
                        )
                      }
                    ></Box>
                  ))}
                </Box>
              ) : (
                ""
              )}
            </Box>

            {/* <Divider sx={{marginTop:'60px', marginBottom: '60px'}}/> */}
          </>
        ))}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#5B5B5B",
              color: "#fff",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
            }}
          >
            {treatmentDetails.requirements.length}
          </Box>
          <Box
            sx={{
              fontSize: "21px",
              fontWeight: "500",
              lineHeight: "31px",
              color: "#27374F",
              flexGrow: 1,
            }}
          >
            Required Files
          </Box>
        </Box>

        {requiredFiles.map((file, idx) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "42px",
              marginBottom: "10px",
              background: "rgba(234, 234, 234, 1)",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ marginRight: "18px" }}>
              <img src={file.image} />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Box
                sx={{
                  color: "#322F2F",
                  fontWEight: "500",
                  fontSize: "21px",
                  lineHeight: "21px",
                }}
              >
                {file.name}
              </Box>
            </Box>

            <Box
              sx={{
                fontSize: "21px",
                fontWeight: "500",
                lineHeight: "31px",
                color: "#27374F",
              }}
            >
              <Dropzone onDropAccepted={(files) => onFileChange(files, idx)}>
                {({ getRootProps, getInputProps }) => (
                  <Box
                    sx={{
                      marginTop: "10px",
                      background: "#ECF2F9",
                      borderRadius: "12px",
                      width: "600px",
                      height: "175px",
                      textAlign: "center",
                      overflowY: "auto",
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {file.files.length === 0 ? (
                      <img
                        src={DragAndDropImage}
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      <>
                        {file.files.map((file, fileIdx) => (
                          <Box sx={{ display: "flex", marginTop: "20px" }}>
                            <Box
                              sx={{
                                xs: 1,
                                marginRight: "10px",
                                marginLeft: "25px",
                              }}
                            >
                              <img
                                src={caseCreatedIcon}
                                style={{ width: "45px", height: "45px" }}
                              />
                            </Box>
                            <Box
                              sx={{
                                xs: 3,
                                flex: "wrap",
                                fontSize: "16px",
                                lineHeight: "21px",
                                fontWeight: "400",
                                flexGrow: 1,
                                textAlign: "left",
                              }}
                            >
                              {file.fileName}
                            </Box>
                            <Box
                              sx={{
                                xs: 3,
                                fontSize: "16px",
                                lineHeight: "21px",
                                fontWeight: "400",
                                flexGrow: 1,
                                textAlign: "left",
                              }}
                            >
                              {moment(file.uploadedDate).format("lll")}
                            </Box>
                            <Box
                              sx={{
                                xs: 3,
                                flexGrow: 1,
                                fontSize: "16px",
                                lineHeight: "21px",
                                fontWeight: "400",
                                color: "#4A90E2",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginRight: "15px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeFile(idx, fileIdx);
                              }}
                            >
                              Remove File
                            </Box>
                          </Box>
                        ))}
                      </>
                    )}
                  </Box>
                )}
              </Dropzone>

              {file.numberOfFilesUploading > 0 ? (
                <Box
                  sx={{ width: "100%", margin: "10px", textAlign: "center" }}
                >
                  <LinearProgress color="success" />
                  Uploading File
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        ))}
      </Box>

      {/* <Divider sx={{marginTop:'60px', marginBottom: '60px'}}/> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#5B5B5B",
              color: "#fff",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
            }}
          >
            {treatmentDetails.requirements.length + 1}
          </Box>
          <Box
            sx={{
              fontSize: "21px",
              fontWeight: "500",
              lineHeight: "31px",
              color: "#27374F",
            }}
          >
            Additional Notes
          </Box>
        </Box>

        <Box>
          <TextField
            id="additional-notes"
            multiline
            InputProps={{
              style: { height: "220px", alignItems: "start" },
            }}
            sx={{ width: "100%", background: "#fff", marginTop: "5px" }}
            value={treatmentDetails.additionalNotes}
            onChange={(e) => handleChangeAdditionalNotes(e.target.value)}
          />
        </Box>
      </Box>
    </>
  );
}

export default EvidentSmilePackage;
