import React, { useState } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import ProceedToFinalDialog from "./proceedToFinalDialog";
import { brandOrange } from "../../constants";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";
import CaseService from "../../services/case.service";
import ActionButton from "../common/button/actionButton";

const ProceedToFinalButton = ({ caseDetails }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setOpen(false);
  };

  return (
    <>
      <ProceedToFinalDialog
        open={open}
        onClose={() => setOpen(false)}
        caseId={caseDetails.id}
        onSubmit={handleSubmit}
      />

      <ActionButton
        color="brandOrange"
        onClick={() => setOpen(true)}
      >
        Proceed to Final Step
      </ActionButton>
    </>
  );
};

export default ProceedToFinalButton;
