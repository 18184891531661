import { Link, Stack } from "@mui/material";
import React from "react";

const PdfPreview = ({ link }) => {
  return (
    <>
      <embed src={link} width="100%" height="480px" />
      <Stack alignItems="center" marginY={2}>
        <Link href={link} target="_blank" rel="noreferrer">
          Open in a new tab
        </Link>
      </Stack>
    </>
  );
};

export default PdfPreview;
