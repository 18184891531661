import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";

// for formik
const TextInput = ({ label, placeholder, name, value, onChange, id, error, helperText, type = "text" }) => {
  return (
    <FormControl variant="standard" error={error} fullWidth>
      <Typography
        component="label"
        htmlFor={id}
        sx={{ fontWeight: "bold", color: "#37465D" }}
      >
        {label}
      </Typography>
      <OutlinedInput
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        sx={{ marginTop: 1 }}
        type={type}
      />
      <FormHelperText>{ helperText }</FormHelperText>
    </FormControl>
  );
};

export default TextInput;
