import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';


const ProceedToOrderDialog = ({isOpen,onClose,treatmentDescriptions}) => {
    console.log('treatment desc',treatmentDescriptions);
    const StyledDialogTitle = styled(DialogTitle)({
        padding: '10px',
        paddingTop: '40px'
    });
    
    const StyledDialogContent = styled(DialogContent)({
        padding: '20px',
        paddingLeft: '50px',
        paddingRight: '50px',
    });
    
    const StyledDialogActions = styled(DialogActions)({
        padding: '10px',
    });

  return (
    <Dialog
        open={isOpen}
        onClose={()=>onClose(false)}
        sx={{padding: '30px'}}
        aria-labelledby="proceed-order-dialog-title"
        aria-describedby="proceed-order-dialog-description"
    >
        <StyledDialogTitle id="proceed-order-dialog-title" textAlign="center">Proceed to Order?</StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>onClose(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
            <CloseIcon />
        </IconButton>
        <StyledDialogContent id="proceed-order-dialog-description">
            <Typography>You have selected the following treatments</Typography>
            <List>
                {treatmentDescriptions && treatmentDescriptions.map(t=><ListItem key={t}>&#x2022; {t}</ListItem>)}
            </List>
        </StyledDialogContent>
        <StyledDialogActions>
            <Button sx={{color:"#A9ACB0"}} onClick={()=>onClose(false)}>Cancel</Button>
            <Button sx={{color:"#65B559"}} onClick={()=>onClose(true)} autoFocus>
                Proceed
            </Button>
        </StyledDialogActions>
    </Dialog>
  )
}

export default ProceedToOrderDialog