import React from "react";
import PatientService from "../../../services/patient.service";
import DownloadButton from "./downloadButton";
import { useSelector } from "react-redux";
import AdminService from "../../../services/admin.service";
import NoAccessPrepayModal from "../../../components/account/noAccessModal";



const DownloadFileButton = ({ path, variant, fileName, label, isExternal = false }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openNoAccessModal, setOpenNoAccessModal] =    React.useState(false); 
  const [continueDownload, setContinueDownload] =    React.useState(false); 
  const [isDentistRole, setIsDentistRole] =    React.useState(false); 
  const [dentistByPractice, setDentistByPractice] = React.useState("");
  const [checkStatusResult, setCheckStatusResult] =    React.useState(""); 

  const handleNoAccessModalClose = (confirm) => {
    setOpenNoAccessModal(false); 
  }

const blobDownload = async () =>{
  const blobPath = await PatientService.getPatientFilePath(path, isExternal, fileName);
  return blobPath;

}

  const getPath = async () => { 
    let locBillingOption = localStorage.getItem("locBillingOption"); 
    let userID =userInfo.Id;
    let isDentist=userInfo.Roles.includes("Dentist"); 
        
  setIsDentistRole(isDentist);


  if(isDentist == false) //for om/assts
  {
    let practiceID =localStorage.getItem("locSelectedPractice");
    let dentistID =localStorage.getItem("locSelectedDentistID");
     

   // get dentist details  
   await AdminService.getDentistsByPracticeid(practiceID)
   .then((response) => response.json())
   .then((json) => {     
    //console.log("new userid  response",json );   
       const personnel = json.find(
         (j) => j.id === dentistID
       ); 
       setDentistByPractice(personnel); 
       locBillingOption = personnel.billingOption;  
       userID=dentistID;   
        
   });
  }
    

 

    if(locBillingOption === "Prepay")
    {    
        
    return AdminService.checkPrepayStatus(userID)
      .then((response) => response.json())
      .then((json) => {
        if (json.error === null) 
        { 
          if (json.data !== null) 
          {  
            setCheckStatusResult(json.data.access);
            if(json.data.access == "blockedPrepay")
            {  
              console.log("do not allow to download case");
               setOpenNoAccessModal(true);
                setContinueDownload(false);
                // do not allow to download case                   
                setErrorMessage(json.data.message); 
                setContinueDownload(false); 
                window.stop();
                return;
            }
            else if(json.data.access == "promptPrepay")
            {
              //allow to download case  but show prompt message       
              console.log("allow to download case  but show prompt message  ");
               setOpenNoAccessModal(true);  
               setErrorMessage(json.data.message); 
               setContinueDownload(true); 
               const blobPath =   PatientService.getPatientFilePath(path, isExternal, fileName);
               window.stop();
               return blobPath;  
            }
            else
            {
              setContinueDownload(true);  
              const blobPath =   PatientService.getPatientFilePath(path, isExternal, fileName);
              return blobPath;            
            }
            
          }
          else { console.log("block1");  }         
           
        } else { console.log("block2");  }        
       });

    }
    else // FOR STANDARD - Allow download
    {  
     setContinueDownload(true); 
     const blobPath =  PatientService.getPatientFilePath(path, isExternal, fileName);
     return blobPath;
    } 

  };

  return (
    <>
      <NoAccessPrepayModal 
        isOpen={openNoAccessModal} 
        msg={errorMessage} 
        handleClose={handleNoAccessModalClose} 
        isDentist={isDentistRole}
      />
      
    <DownloadButton variant={variant} path={getPath} fileName={fileName} label={label || undefined}/>
      </>
  );
};

export default DownloadFileButton;
