import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { brandColor, brandGradient } from "../../constants";
import { newUserSchema } from "../../types/newUserSchema";
import DialogContainer from "../common/dialogContainer";
import InputText from "../common/inputText";
import StyledFormLabel from "../common/styledFormLabel";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  practiceObj: null,
  positionObj: null,
  position: "",
};

const NewUserDialog = ({
  open,
  onClose,
  submitting,
  isLoading,
  isSubmitting,
  onUserAdd,
  dialogDetails: { practices, positions },
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(newUserSchema),
  });

  useEffect(() => {
    reset(initialValues);
  }, [open]);

  return (
    <DialogContainer open={open} onClose={onClose} loading={isSubmitting}>
      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <Stack component="form" onSubmit={handleSubmit(onUserAdd)}>
          <Box sx={{ textAlign: "center" }}>
            <Typography component="h2" variant="h6">
              Add New User
            </Typography>
            <Typography variant="body2" mt={0.5}>
              Invite your office manager and/or assistant to sign up for the
              Evident Kois Hub. Invited user will be receiving an email
              invitation to set up their account.
            </Typography>
          </Box>

          <Stack sx={{ width: "100%", marginTop: 4 }} spacing={1}>
            <InputText
              label="First Name"
              register={register("firstName")}
              errors={errors.firstName}
              disabled={submitting}
            />

            <InputText
              label="Last Name"
              register={register("lastName")}
              errors={errors.lastName}
              disabled={submitting}
            />

            <InputText
              label="Email"
              type="email"
              register={register("email")}
              errors={errors.email}
              disabled={submitting}
            />

            <Box>
              <StyledFormLabel size="sm">Practice</StyledFormLabel>
              <Controller
                control={control}
                name="practiceObj"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Autocomplete
                    autoHighlight
                    disablePortal
                    disabled={submitting}
                    options={practices}
                    onChange={(_, option) => onChange(option)}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={Boolean(error)}
                        FormHelperTextProps={{ variant: "standard" }}
                        helperText={error && error.message}
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                  />
                )}
              />
            </Box>

            <Box>
              <StyledFormLabel size="sm">Position</StyledFormLabel>
              <Controller
                control={control}
                name="positionObj"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Autocomplete
                    autoHighlight
                    disablePortal
                    disabled={submitting}
                    options={positions}
                    getOptionLabel={(option) => option.description || option}
                    onChange={(_, option) => onChange(option)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={Boolean(error)}
                        FormHelperTextProps={{ variant: "standard" }}
                        helperText={error && error.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ paddingTop: 4 }}>
            <Button type="button" fullWidth onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                background: brandColor,
                "&:hover": { background: brandColor },
              }}
            >
              Invite
            </Button>
          </Stack>
        </Stack>
      )}
    </DialogContainer>
  );
};

export default NewUserDialog;
