import {
  Button,
  CircularProgress,
  Divider,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import ContentContainer from "../common/contentContainer";
import moment from "moment";
import DataTable from "../common/table/dataTable";
import TableRow from "../common/table/tableRow";
import DownloadFileButton from "../common/button/downloadFileButton";
import CaseService from "../../services/case.service";
import StyledButton from "../common/button/largeButton";
import RejectDialog from "./rejectDialog";
import RequestRedesignButton from "./requestRedesignButton";
import PreviewFileButton from "./previewFileButton";
import { FileDownload, Visibility } from "@mui/icons-material";
import DownloadAllFilesDialog from "./downloadAllFilesDialog";
import NoAccessPrepayModal from "../../components/account/noAccessModal";
import AdminService from "../../services/admin.service";
import { useSelector } from "react-redux";

const CaseFiles = ({ caseId, onStatusChange, caseRefNo }) => {
  const [fileDownloads, setFileDownloads] = React.useState([]);
  const [fetched, setFetched] = useState(false);
  const [fileByCase, setFileByCase] = React.useState([]);
  const [update, setUpdate] = useState(false);
  const [caseData, setCaseData] = React.useState(null);
  const [isLoadingBackdrop, setisLoadingBackdrop] = useState(false);
  const [downloadAllOpen, setDownloadAllOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openNoAccessModal, setOpenNoAccessModal] =    React.useState(false); 
  const [isDentistRole, setIsDentistRole] =    React.useState(false); 
  const [dentistByPractice, setDentistByPractice] = React.useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const [checkStatusResult, setCheckStatusResult] =    React.useState(""); 

  const headers = ["Files from Evident Design Center", "", " ", ""];
  
  const handleNoAccessModalClose = (confirm) => {
    setOpenNoAccessModal(false);
    if(checkStatusResult =="promptPrepay")
    {
      setDownloadAllOpen(true);      // allow to process
    }
  }

  const rows = useMemo(() => {
    return fileDownloads.map((file) => {
      
    


      file.type = file.fileName.split(".").pop();
      file.path = file.filePath;
      const fileTypesForPreview = ["stl", "html", "pdf", "jpg", "png"];
      const showPreview = fileTypesForPreview.includes(file.type);
      return [
        file.treatment,
        file.fileName,
        moment(file.uploadedDate).format("LLL"),
        <Stack direction="row" alignItems="space-between" spacing={2}>
          {showPreview ? (
            <PreviewFileButton file={file}>
              <Button
                variant="contained"
                color="brandBlue"
                startIcon={<Visibility />}
                sx={{ minWidth: "110px" }}
              >
                Preview
              </Button>
            </PreviewFileButton>
          ) : (
            ""
          )}
        </Stack>,
        <DownloadFileButton path={file.filePath} fileName={file.fileName} />,
      ];
    });
  }, [fileDownloads]);

  const headers2 = ["Files From Dentist", "", " "];
  const rows2 = fileByCase.map((file) => [
    file.fileName,
    moment(file.uploadedDate).format("LLL"),
    <DownloadFileButton path={file.containerName} fileName={file.fileName} />,
  ]);

  React.useEffect(() => {
    if (fetched) return;
    if (fileDownloads.length > 0 && !update) return;
    // CaseService.getCaseFileDownloads(caseId);
    setisLoadingBackdrop(true);

    const getCase = async () => {
      const response = await CaseService.getCaseById(caseId);
      const data = await response.json();

      let task = [];
      data.caseTreatments.forEach((ct) => {
        ct?.serviceProviders.forEach((sp) => {
          if (sp.lastModified) {
            sp.treatmentType = ct.treatmentType;
            task.push(sp);
          }
        });
      });

      task.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.lastModified) - new Date(a.lastModified);
      });

      if (task.length < 1) {
        var tempLatestTask =
          data.caseTreatments && data.caseTreatments.length > 0
            ? data.caseTreatments[0].serviceProviders[0]
            : null;

        if (tempLatestTask) {
          tempLatestTask.status = "Sent";
          data.latestTask = tempLatestTask;
        }
      } else {
        data.latestTask = task[0];
      }
      setCaseData(data);
    };

    const GetFiles = async () => {
      const fileDownloads = await CaseService.getallcasefiledownloads(caseId);
      const fileDownloadsData = await fileDownloads.json();
      console.log(fileDownloadsData);
      setFileDownloads(fileDownloadsData.files);
      return fileDownloadsData.files;

      // const filesByCase = await CaseService.getFilesByCase(caseId);
      // const filesByCaseData = await filesByCase.json();
      // setFileByCase(filesByCaseData);
    };

    Promise.all([getCase(), GetFiles()]).then(([caseData, files]) => {
      setUpdate(false);
      setisLoadingBackdrop(false);
      setFetched(true);
    });
  }, [caseId, update, fileDownloads]);

  const handleDownloadAllClick =async () => {
    console.log("handleDownloadAllClick");

    let locBillingOption = localStorage.getItem("locBillingOption"); 
    let userID =userInfo.Id;
    let isDentist=userInfo.Roles.includes("Dentist"); 
       
  console.log("old userid  isDentistRole",userID );  
  setIsDentistRole(isDentist);

  

      if(isDentist == false) //for om/assts
      {
        let practiceID =localStorage.getItem("locSelectedPractice");
        let dentistID =localStorage.getItem("locSelectedDentistID");
        
      console.log("localStorage",dentistID, " ",practiceID );  

      // get dentist details  
      await  AdminService.getDentistsByPracticeid(practiceID)
      .then((response) => response.json())
      .then((json) => {     
         console.log("new userid  response",json );   
          const personnel = json.find(
            (j) => j.id === dentistID
          ); 
          setDentistByPractice(personnel); 
          locBillingOption = personnel.billingOption;  
          userID=dentistID;   
          
      console.log("new userid  isDentistRole",userID," locBillingOption " , locBillingOption );  
      });

      console.log("localStorage",dentistID, " ",locBillingOption );  
      }
        

      console.log("localStorage locBillingOption",  " ",locBillingOption ); 
      if(locBillingOption === "Prepay")
      {
        AdminService.checkPrepayStatus(userID)
      .then((response) => response.json())
      .then((json) => {
        if (json.error === null) 
        { 
          if (json.data !== null) 
          { 
            setCheckStatusResult(json.data.access);
            if(json.data.access != "allowed")
            {  
              console.log("do not allow to download case");
               setOpenNoAccessModal(true); 
                // do not allow to download case                   
                setErrorMessage(json.data.message);
                  
            }
            else
            { 
              console.log("setDownloadAllOpen");  
              setDownloadAllOpen(true);        
            }
            
          }
          else 
          { 
            setDownloadAllOpen(true);
          }         
           
        } 
        else 
        { 
          setDownloadAllOpen(true);
        }        
       });
       }
      else // standard
      {

        setDownloadAllOpen(true);
      }
  };
  console.log(caseId);

  return (
    <>
      <NoAccessPrepayModal 
        isOpen={openNoAccessModal} 
        msg={errorMessage} 
        handleClose={handleNoAccessModalClose} 
        isDentist={isDentistRole}
      />

      {downloadAllOpen && (
        <DownloadAllFilesDialog
          files={fileDownloads}
          open={downloadAllOpen}
          onClose={() => setDownloadAllOpen(false)}
          caseRefNo={caseRefNo}
        />
      )}
      <ContentContainer sx={{ margin: "10px", bgcolor: "white" }}>
        {isLoadingBackdrop && (
          <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
            <CircularProgress color="success" />
          </Box>
        )}

        {!isLoadingBackdrop &&
          (rows.length > 0 ? (
            <React.Fragment>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ marginBottom: 4 }}
              >
                {rows.length > 1 && (
                  <Button
                    onClick={handleDownloadAllClick}
                    variant="contained"
                    color="brandBlue"
                    startIcon={<FileDownload />}
                  >
                    Download All
                  </Button>
                )}
              </Stack>
              <DataTable headers={headers}>
                {rows.map((row, i) => (
                  <TableRow key={i} row={row} />
                ))}
              </DataTable>
              <Pagination
                // count={pages}
                // page={currentPage}
                // onChange={handleClickPagination}
                sx={{ marginY: 2 }}
              />
              <Stack direction="row" justifyContent="flex-end">
                {caseData &&
                  (caseData.latestTask.status === "Completed" ||
                    caseData.latestTask.status === "Received") &&
                  caseData.latestTask.serviceProviderId ===
                    "00000000-0000-0000-0000-000000000000" &&
                  caseData.treatment.find(
                    (x) =>
                      x === "Evident Smile Package" ||
                      x === "All on X Implants" ||
                      x === "All on Four Standard"
                  ) === undefined && (
                    <RequestRedesignButton
                      onRedesign={() => {
                        setUpdate(true);
                        setisLoadingBackdrop(true);
                        setFetched(false);
                        onStatusChange("Case In Progress");
                      }}
                      caseDetails={caseData}
                    />
                  )}
              </Stack>
            </React.Fragment>
          ) : (
            <Typography>No Files Available</Typography>
          ))}
      </ContentContainer>
    </>
  );
};

export default CaseFiles;
