import ButtonBase from "@mui/material/ButtonBase"
import Typography from "@mui/material/Typography"
import { brandColor, brandColorHover } from '../../../../constants'
import { X } from "@mui/icons-material"

const OrderNowButton = ({product,selectedTreatment,onClickOrderNow,patient}) => {
    return (
        <ButtonBase
            disableRipple
            sx={{
            width: "100%",
            marginTop: 2,
            paddingY: 2,
            color: "#fff",
            borderRadius: "30px",
            backgroundColor: brandColor,
            
            transition: "all 250ms ease",
            ":hover": {
                ...(!Boolean(
                selectedTreatment.find(
                    (x) =>
                    x.description === product.description
                )
                )
                ? {
                    backgroundColor: brandColorHover,
                    }
                : { filter: "brightness(90%)" }),
            },
            }}
            onClick={ () => {onClickOrderNow(product,patient)} }
           
        >
            <Typography
            fontWeight="600"
            fontSize="15px"
            lineHeight="19px"
            >
            Order Now
            </Typography>
        </ButtonBase>
    )
}

export default OrderNowButton