import { Box } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material"

export default function RequirementBoxCount(props) {
  const { count, description, requirement,treatment} = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
      }}
    >
      <Box
        id={'description-'+count}
        sx={{
          display: "flex",
          backgroundColor: "#5B5B5B",
          color: "#fff",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center", 
          marginRight: "12px",
        }}
      >
        {count}
      </Box>
      <Box
        sx={{
          fontSize: "21px",
          fontWeight: "500",
          lineHeight: "31px",
          color: "#27374F",
        }}
          >
            {description === 'Tooth Chart' ? 'Add Treatment' :description}
      </Box>
    </Box>
  );
}
