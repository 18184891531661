import { ButtonBase } from "@mui/material";
import React from "react";
import { brandColor, brandColorHover } from "../../../constants";

const Button = ({ active = true, sx, disabled, children, ...props }) => {
  return (
    <ButtonBase
      disableRipple
      sx={{
        paddingY: "15px",
        paddingX: "25px",
        color: "#fff",
        fontSize: "0.938rem",
        borderRadius: "30px",
        backgroundColor: brandColor,
        ...(!active && {
          color: brandColor,
          backgroundColor: "none",
          border: `1px solid ${brandColor}`,
        }),
        transition: "all 250ms ease",
        filter: disabled ? "brightness(0.9)" : undefined,
        ":hover": {
          ...(active
            ? {
                backgroundColor: brandColorHover,
              }
            : { filter: "brightness(90%)" }),
        },
        ...(sx)
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};

export { Button };
