import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Popover, Stack } from "@mui/material";
import { useRef } from "react";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import PatientMenuItem from "./patientMenuItem";
import { Search } from "@mui/icons-material";

const PatientMenu = ({ patient }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const id = open ? "simple-popper" : undefined;

  return (
    <Box>
      <IconButton
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        ref={anchorEl}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl.current}
        elevation={24}
        PaperProps={{ sx: { borderRadius: "17px", backgroundColor: "white" } }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack>
          <Box
            width="250px"
            display="flex"
            justifyContent="center"
            fontSize="16px"
            fontWeight="bold"
            paddingX={3}
            paddingY={3}
            boxSizing="border-box"
          >
            <Box>Select An Option:</Box>
          </Box>
          <Stack spacing={1} sx={{ paddingBottom: 3 }}>
            {[
              {
                title: "Add New Treatment",
                path: "/?patientId=" + patient.patientId,
              },
              {
                title: "Track Cases",
                path:
                  "/cases?search=" + `${patient.firstName} ${patient.lastName}`,
                state: null,
              },
            ].map((item, i) => (
              <PatientMenuItem
                key={i}
                component={RouterLink}
                to={item.path}
                state={item.state}
              >
                {item.title}
              </PatientMenuItem>
            ))}
            <PatientMenuItem component={RouterLink} to={patient.patientId}>
              See Patient Details
            </PatientMenuItem>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
};

export default PatientMenu;
