import { CircularProgress, Skeleton, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import PatientService from "../../services/patient.service";

const PhotographyThumbnail = ({ fileName, path }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const images = ["jpg", "png", "apng", "avif", "gif", "jpeg", "jfif", "pjpeg", "pjp", "webp"];
    const fileType = fileName.split(".").pop().toLowerCase();
    console.log("psp", fileType);

    const fetchThumbnail = async () => {
      const blobPath = await PatientService.getPatientFilePath(
        path,
        false,
        fileName
      );
      const response = await fetch(blobPath);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      console.log(url);
      setFileUrl(url);
      setLoading(false);
    };

    if (images.includes(fileType)) {
      console.log(fileType);
      setLoading(true);
      setFileUrl(null);
      fetchThumbnail();
    }
  }, [fileName, path]);

  console.log("File url", fileUrl);
  return (
    <Stack>
      <Box>{fileName}</Box>
        {loading && !fileUrl && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              marginY: "1rem",
              width: "250px",
              height: "250px",
              backgroundColor: grey[50],
            }}
          >
            <CircularProgress sx={{ marginRight: 1 }} />
          </Stack>
        )}
        {fileUrl && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              marginY: "1rem",
              width: "250px",
              height: "250px",
              backgroundColor: grey[50],
            }}
          >
            <img
              src={fileUrl}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              alt={"thumbnail of " + fileName}
            />
          </Stack>
        )}
    </Stack>
  );
};

export default PhotographyThumbnail;
