import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  List,
  FormControlLabel,
  ListItem,
  Checkbox,
  TextField,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  FormGroup,
} from "@mui/material";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import LargeButton from "../components/common/button/largeButton";
import {
  Info,
  CampaignOutlined,
  ColorLens,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import Header from "../components/header/header";
import StyledButton from "../components/common/button/largeButton";
import ProfileDetails from "../components/profile/profile";
import {
  brandBlack,
  brandBlue,
  brandGray,
  brandLight,
  brandColor,
} from "../constants";

function mapStateToProps(state) {
  return { userInfo: state.userInfo };
}

function createData(product, cost, turnaroundTime) {
  return { product, cost, turnaroundTime };
}

function FabricationDetails({
  userInfo,
  buttonCancel,
  selectedTreatment,
  patientDetails,
  handleNextStep,
  componentsVisible,
  fabricationDetails,
}) {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const pricingRows = [
    createData("Full Countour Zirconia Crown", "$65", "2 Days"),
    createData("Lithium Disilicate", "$65", "2 Days"),
    createData("Splints", "$90", "2 Days"),
    createData("Quad Model", "$7.50", "2 Days"),
    createData("Full Model", "$15.00", "2 Days"),
  ];

  //const [fabricationData, setFabricationData] = useState(fabricationDetails);
  const [toothShade, setToothShade] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [skipApproval, setSkipApproval] = useState(false);

  const [isSelected, setIsSelected] = useState(false);
  const handleCardClick = () => {
    setIsSelected(!isSelected);
  };

  console.log("fabrication details: ", fabricationDetails);
  console.log("selected treatment", selectedTreatment);

  const handleCancelClick = () => {
    buttonCancel();
  };

  const confirmProceed = (values) => {
    if (!isSelected) {
      alert("Please select a lab before proceeding.");
      return;
    }

    setToothShade(values.toothShade);
    setNameUser(values.nameUser);
    setMobile(values.mobile);
    setCity(values.city);
    setState(values.state);
    setZipCode(values.zipCode);
    setShippingAddress(values.shippingAddress);
    setSkipApproval(values.skipApproval);

    let shippingDetails = {
      shade: values.toothShade,
      name: values.nameUser,
      mobile: values.mobile,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      shippingAddress: values.shippingAddress,
      skipApproval: values.skipApproval,
    };

    if (!values.understand) {
      return;
    }

    console.log(selectedTreatment);
    handleNextStep(
      1,
      selectedTreatment,
      patientDetails,
      null,
      componentsVisible,
      fabricationDetails,
      shippingDetails
    );
  };

  const skipPage = () => {
    setToothShade("");
    setNameUser("");
    setMobile("");
    setCity("");
    setState("");
    setZipCode("");
    setShippingAddress("");
    setSkipApproval("");

    let shippingDetails = {
      shade: "",
      name: "",
      mobile: "",
      city: "",
      state: "",
      zipCode: "",
      shippingAddress: "",
      skipApproval: "",
    };

    handleNextStep(
      1,
      selectedTreatment,
      patientDetails,
      null,
      componentsVisible,
      ""
      //shippingDetails
    );
  };

  const cancelSubmission = () => {
    handleNextStep(-1, selectedTreatment, patientDetails);
    handleCancelClick();
  };

  const cardList = [
    {
      id: 1,
      src: "https://preprodkoisstorage.blob.core.windows.net/koispublic/FabLogo%2Fderbylogo.png",
      title: "Derby",
    },
    //   {
    //     id: 2,
    //     src: "https://preprodkoisstorage.blob.core.windows.net/koispublic/FabLogo%2Fderbylogo.png",
    //     title: "Extra",
    //   },
    //   {
    //     id: 3,
    //     src: "https://preprodkoisstorage.blob.core.windows.net/koispublic/FabLogo%2Fderbylogo.png",
    //     title: "Extra1",
    //   },
    //   {
    //     id: 4,
    //     src: "https://preprodkoisstorage.blob.core.windows.net/koispublic/FabLogo%2Fderbylogo.png",
    //     title: "Extra2",
    //   },
  ];

  const shades = ["A1", "B2", "A35", "C3"];

  return (
    <React.Fragment>
      <Container
        maxWidth="xl"
        width="100%"
        sx={{
          margin: "0 auto",
          paddingY: 5,
          paddingX: { xs: 3, sm: 4, md: 21 }, // Responsive padding
        }}
      >
        <Box marginY={5} maxWidth="1366px" height="134px" width="100%">
          <Stack direction="column">
            <Typography
              sx={{
                lineHeight: "43.57px",
                fontWeight: "600",
                fontSize: "35px",
              }}
            >
              Introducing Evident Preferred Fabrication Partners
            </Typography>

            <Typography
              sx={{
                marginTop: "12px",
                fontSize: "18px",
                lineHeight: "21.78px",
                color: "#6E6E6E",
                fontWeight: "400",
              }}
            >
              Get this case expertly fabricated by one of our trusted partners
              to enjoy an end-to-end connected workflow
            </Typography>

            <Typography
              component="a"
              href="#"
              sx={{
                marginTop: "32px",
                fontSize: "16px",
                lineHeight: "24px",
                textDecoration: "none",
                color: "#64B457",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={skipPage}
            >
              Skip This Step
            </Typography>
          </Stack>
        </Box>

        <Stack direction="row" spacing={3} sx={{ width: "100%" }}>
          <Card
            sx={{
              maxWidth: "318px",
              width: "100%",
              height: "257px",
              marginTop: "75px",
              border: isSelected
                ? "2px solid #64B457"
                : "2px solid transparent",
            }}
          >
            <CardActionArea
              sx={{
                height: "100%",
              }}
              onClick={handleCardClick}
              disableRipple
              disableFocusRipple
            >
              <Box
                sx={{
                  poaition: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70%",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    maxWidth: "181px",
                    height: "69px",
                    position: "absolute",
                    top: "50px",
                  }}
                  image="https://preprodkoisstorage.blob.core.windows.net/koispublic/FabLogo%2Fderbylogo.png"
                />
              </Box>

              <Divider sx={{ width: "100%" }} />

              {/* Bottom section with content */}
              <CardContent
                sx={{
                  height: "30%",
                  ml: "5px",
                  justifyContent: "center",
                }}
              >
                {/* <Stack direction="row" spacing={1}> */}
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    mt: "10px",
                  }}
                >
                  {/* Derby Dental Lab */}
                  {fabricationDetails[0].labName}
                </Typography>
                {/* <Info
                    sx={{
                      color: "#AFAFB8",
                      width: "20px",
                      height: "20px",
                    }}
                  /> */}
                {/* </Stack> */}

                {/* <Stack
                  direction="row"
                  spacing={1}
                  alignItems="end"
                  marginTop="30px"
                >
                  <Typography
                    marginTop="100px"
                    sx={{
                      fontSize: "36px",
                      lineHeight: "36px",
                      fontWeight: "600",
                      color: "#64B457",
                    }}
                  >
                    ${fabricationDetails[0].totalAmount}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "26px",
                      fontWeight: "400",
                      color: "#757575",
                    }}
                  >
                    USD
                  </Typography>
                </Stack> */}

                {/* <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "18.2px",
                    fontWeight: "400",
                    color: "#A8A8A8",
                    marginTop: "10px",
                  }}
                >
                  {fabricationDetails[0].taTinHrs} Hour TAT
                </Typography> */}
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            sx={{
              maxWidth: "318px",
              width: "100%",
              height: "257px",
              marginTop: "75px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              height="85.84px"
              sx={{
                maxWidth: "122px",
                margin: "0 auto",
                paddingTop: "10px",
              }}
              image="https://preprodkoisstorage.blob.core.windows.net/koispublic/FabLogo%2FMoreFabPartner.png"
            />

            <CardContent>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  color: "#D9D9D9",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                MORE PARTNERS COMING SOON
              </Typography>
            </CardContent>
          </Card>
        </Stack>

        {isSelected && ( // Conditionally render this portion
          <Box>
            <Box
              marginY={5}
              width="100%"
              maxWidth="1366px"
              sx={{
                border: "1px solid",
                borderColor: "#D1D1D1",
                borderRadius: "16px",
                padding: "42px 42px 60px 42px",
              }}
            >
              <Typography
                sx={{
                  lineHeight: "36px",
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#111111",
                }}
              >
                {fabricationDetails[0].labName}
              </Typography>

              <Stack marginTop="25px">
                <Stack direction="row" spacing={5}>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#777777",
                    }}
                  >
                    Lab Type
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#111111",
                    }}
                  >
                    {fabricationDetails[0].labType}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={5} marginTop="24px">
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#777777",
                    }}
                  >
                    Service Area
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#333333",
                    }}
                  >
                    {fabricationDetails[0].serviceArea}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={5} marginTop="24px">
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#777777",
                    }}
                  >
                    Bio
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#333333",
                    }}
                  >
                    {fabricationDetails[0].bio}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={5} marginTop="24px">
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#777777",
                    }}
                  >
                    Address
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#333333",
                    }}
                  >
                    {fabricationDetails[0].address}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={5} marginTop="24px">
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#777777",
                    }}
                  >
                    Contact
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#333333",
                    }}
                  >
                    {fabricationDetails[0].contact}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={5} marginTop="24px">
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#777777",
                    }}
                  >
                    Pricing Chart
                  </Typography>

                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      border: "none",
                    }}
                  >
                    <Table
                      size="small"
                      aria-label="pricing chart"
                      sx={{
                        border: "none",
                        "& td, & th": {
                          border: "none",
                          padding: "12px",
                        },
                        "& tr": {
                          border: "none",
                        },
                        "& thead th": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "24px",
                              color: "#333333",
                              width: "70%",
                            }}
                          >
                            Product
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "24px",
                              color: "#333333",
                              width: "15%",
                            }}
                          >
                            Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "24px",
                              color: "#333333",
                              width: "15%",
                            }}
                          >
                            Turnaround Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pricingRows.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              backgroundColor:
                                index % 2 !== 0 ? "white" : "#FAFAFA",
                            }}
                          >
                            <TableCell
                              sx={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#333333",
                              }}
                            >
                              {row.product}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#333333",
                              }}
                            >
                              {row.cost}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#333333",
                              }}
                            >
                              {row.turnaroundTime}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>

              <Divider
                sx={{
                  maxWidth: "1366px",
                  width: "100%",
                  borderBottomWidth: "1px",
                  borderColor: "##00000033",
                  marginTop: "70px",
                }}
              />

              {!(selectedTreatment.length === 1 &&
                selectedTreatment[0].description ===
                  "Standard Nightguard") && (
                  <Box marginTop="70px">
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "26px",
                        color: "#000000",
                      }}
                    >
                      Please choose tooth shade.
                    </Typography>

                    <Typography
                      sx={{
                        mt: "15px",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15.4px",
                        color: "#181826",
                        position: "relative",
                      }}
                    >
                      Shade
                      <span
                        style={{
                          color: "#E03232",
                          position: "absolute",
                          top: "-5px",
                          fontSize: "14px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Typography>

                    <Controller
                      name="toothShade"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Tooth Shade is required.",
                        },
                      }}
                      defaultValue={toothShade}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Select Tooth Shade"
                          variant="outlined"
                          sx={{
                            mt: "7px",
                            width: "363px",
                            height: "52px",
                            borderRadius: "6px",
                            borderWidth: "1px",
                            borderColor: "#E5E5EC",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#E5E5EC",
                              },
                              "&:hover fieldset": {
                                borderColor: "#64B457",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#64B457",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    <Box sx={{ color: "red", mt: "5px" }}>
                      {errors.toothShade?.message && errors.toothShade?.message}
                    </Box>
                  </Box>
                )}

              <Box marginTop="70px">
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "26px",
                    color: "#000000",
                  }}
                >
                  Please fill your shipping details.
                </Typography>

                <Box sx={{ flexGrow: 1 }} maxWidth="1017px" width="100%">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "15.4px",
                          color: "#181826",
                          position: "relative",
                        }}
                      >
                        Name
                        <span
                          style={{
                            color: "#E03232",
                            position: "absolute",
                            top: "-5px",
                            fontSize: "14px",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <Controller
                        name="nameUser"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required.",
                          },
                        }}
                        defaultValue={nameUser}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Name"
                            variant="outlined"
                            sx={{
                              mt: "7px",
                              width: "100%",
                              height: "52px",
                              borderRadius: "6px",
                              borderWidth: "1px",
                              borderColor: "#E5E5EC",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#E5E5EC",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#64B457",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#64B457",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Box sx={{ color: "red", mt: "5px" }}>
                        {errors.nameUser?.message && errors.nameUser?.message}
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "15.4px",
                          color: "#181826",
                          position: "relative",
                        }}
                      >
                        Mobile
                        <span
                          style={{
                            color: "#E03232",
                            position: "absolute",
                            top: "-5px",
                            fontSize: "14px",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Mobile is required.",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Mobile number must contain only digits.",
                          },
                        }}
                        defaultValue={mobile}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Mobile"
                            variant="outlined"
                            sx={{
                              mt: "7px",
                              width: "100%",
                              height: "52px",
                              borderRadius: "6px",
                              borderWidth: "1px",
                              borderColor: "#E5E5EC",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#E5E5EC",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#64B457",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#64B457",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Box sx={{ color: "red", mt: "5px" }}>
                        {errors.mobile?.message && errors.mobile?.message}
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "15.4px",
                          color: "#181826",
                          position: "relative",
                        }}
                      >
                        City
                        <span
                          style={{
                            color: "#E03232",
                            position: "absolute",
                            top: "-5px",
                            fontSize: "14px",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <Controller
                        name="city"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "City is required.",
                          },
                        }}
                        defaultValue={city}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="City"
                            variant="outlined"
                            sx={{
                              mt: "7px",
                              width: "100%",
                              height: "52px",
                              borderRadius: "6px",
                              borderWidth: "1px",
                              borderColor: "#E5E5EC",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#E5E5EC",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#64B457",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#64B457",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Box sx={{ color: "red", mt: "5px" }}>
                        {errors.city?.message && errors.city?.message}
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "15.4px",
                          color: "#181826",
                          position: "relative",
                        }}
                      >
                        State
                        <span
                          style={{
                            color: "#E03232",
                            position: "absolute",
                            top: "-5px",
                            fontSize: "14px",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <Controller
                        name="state"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "State is required.",
                          },
                        }}
                        defaultValue={state}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="State"
                            variant="outlined"
                            sx={{
                              mt: "7px",
                              width: "100%",
                              height: "52px",
                              borderRadius: "6px",
                              borderWidth: "1px",
                              borderColor: "#E5E5EC",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#E5E5EC",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#64B457",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#64B457",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Box sx={{ color: "red", mt: "5px" }}>
                        {errors.state?.message && errors.state?.message}
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "15.4px",
                          color: "#181826",
                          position: "relative",
                        }}
                      >
                        Zipcode
                        <span
                          style={{
                            color: "#E03232",
                            position: "absolute",
                            top: "-5px",
                            fontSize: "14px",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <Controller
                        name="zipCode"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Zip Code is required.",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Zip Code must be a number.",
                          },
                        }}
                        defaultValue={zipCode}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Zipcode"
                            variant="outlined"
                            sx={{
                              mt: "7px",
                              width: "100%",
                              height: "52px",
                              borderRadius: "6px",
                              borderWidth: "1px",
                              borderColor: "#E5E5EC",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#E5E5EC",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#64B457",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#64B457",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Box sx={{ color: "red", mt: "5px" }}>
                        {errors.zipCode?.message && errors.zipCode?.message}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "15.4px",
                          color: "#181826",
                          position: "relative",
                        }}
                      >
                        Shipping Address
                        <span
                          style={{
                            color: "#E03232",
                            position: "absolute",
                            top: "-5px",
                            fontSize: "14px",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <Controller
                        name="shippingAddress"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Shipping Address is required.",
                          },
                        }}
                        defaultValue={shippingAddress}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Suit #, Building, Street Name"
                            variant="outlined"
                            sx={{
                              mt: "7px",
                              width: "100%",
                              height: "52px",
                              borderRadius: "6px",
                              borderWidth: "1px",
                              borderColor: "#E5E5EC",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#E5E5EC",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#64B457",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#64B457",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <Box sx={{ color: "red", mt: "5px" }}>
                        {errors.shippingAddress?.message &&
                          errors.shippingAddress?.message}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Stack direction="row">
              <Controller
                name="understand"
                control={control}
                defaultValue={false}
                rules={{
                  required: {
                    value: true,
                    message:
                      "You must understand the conditions before proceeding.",
                  },
                }}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    disableRipple
                    disableFocusRipple
                    sx={{
                      top: "-10px",
                      "&.Mui-checked": {
                        color: "#64B457",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
              />

              <Typography
                sx={{
                  minWidth: "1250px",
                  maxWidth: "1366px",
                  width: "100%",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                }}
              >
                I understand that Evident is only billing me for designs; I will
                not be paying for fabrication services today. My fabrication
                partner will reach out separately to set up my account and
                billing details.
              </Typography>
            </Stack>

            <Stack direction="row" marginTop="10px">
              <Controller
                name="skipApproval"
                control={control}
                defaultValue={skipApproval}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    disableRipple
                    disableFocusRipple
                    sx={{
                      top: "-10px",
                      "&.Mui-checked": {
                        color: "#64B457",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
              />
              <Typography
                sx={{
                  minWidth: "100px",
                  maxWidth: "1366px",
                  width: "100%",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                }}
              >
                I want to skip the design approval process for this case.
                Evident can send it directly to the selected fabrication partner
                for production.
              </Typography>
            </Stack>

            <Box sx={{ color: "red", mt: "20px", ml: "10px" }}>
              {errors.understand?.message && errors.understand?.message}
            </Box>
          </Box>
        )}

        <Box marginTop="70px" width="100%">
          <Divider
            sx={{
              marginTop: "10px",
              width: "100%",
              //minWidth: "1200px",
              maxWidth: "1366px",
            }}
          />

          <Box sx={{ width: "100%", maxWidth: "1366px" }}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              marginTop="25px"
            >
              <Button
                sx={[
                  {
                    textTransform: "none",
                    background: brandGray,
                    borderRadius: "30px",
                    width: "165px",
                    height: "44px",
                    color: "#fff",
                    marginRight: "15px",
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                  { "&:hover": { background: brandGray } },
                ]}
                onClick={cancelSubmission}
                startIcon={<ArrowBackIosOutlined />}
              >
                Back
              </Button>
              <Button
                onClick={handleSubmit(confirmProceed)}
                endIcon={<ArrowForwardIosOutlined />}
                sx={{
                  textTransform: "none",
                  background: brandColor,
                  borderRadius: "30px",
                  width: "165px",
                  height: "44px",
                  color: "#fff",
                  fontSize: "16px",
                  lineHeight: "24px",
                  "&:hover": { background: brandColor },
                }}
              >
                Proceed
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default connect(mapStateToProps, null)(FabricationDetails);
