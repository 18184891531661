import React from "react";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const IframeDialog = ({ isOpen, handleClose, link }) => {
  return (
    <Dialog
      onClose={() => {
        handleClose(false);
      }}
      open={isOpen}
      maxWidth="md"
      fullWidth
    >
      <embed height="600px" src={link} />
    </Dialog>
  );
};

export default IframeDialog;
