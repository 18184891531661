import React from "react";
import { Box } from "@mui/system";
import { brandBlue } from "../../constants";

export default function ImagesListComponent(props) {
  const {
    onTiBase,
    smileMockup,
    req,
    itemList,
    larger,
    returnItemList,
    handleRequirementSelectValue,
  } = props;
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {itemList.map((sty, idx) => (
        <Box
          key={idx}
          sx={{
            width: "168px",
            marginTop: "10px",
            height: req.description === "Type of Implant Restoration" ? "150px" : larger ? "122px" : "66px",
            display: "flex",
            border:
              req.value === sty.name
                ? "2px solid " + brandBlue
                : "2px solid #e0e0e0",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            marginRight: "10px",
            borderRadius: "16px",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            paddingY: 0.2,
            paddingX: 0.5,
            padding: smileMockup ? 0 : undefined,
            flexDirection: "column",
            position: "relative",
          }}
          onClick={() =>
            returnItemList
              ? handleRequirementSelectValue(
                  req.description,
                  sty.name,
                  itemList
                )
              : handleRequirementSelectValue(req.description, sty.name, true)
          }
        >
          <Box
            component="img"
            src={sty.image}
            sx={{
              flex: 1,
              width: "100%",
              height: "100%",
              borderRadius: smileMockup ? "16px" : undefined,
            }}
          />
          {/* {smileMockup && (
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                position: "absolute",
                bottom: "4px"
              }}
            >
              {sty.name.split(" ")[0]}
            </Box>
          )} */}
        </Box>
      ))}
    </Box>
  );
}
