import { Dialog, DialogContent, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { brandColor } from "../../constants";

const DialogContainer = ({ open, onClose, loading, children, maxWidth, bg }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth || "xs"}
      scroll="body"
      PaperProps={{
        sx: {
          borderRadius: "16px",
          bgcolor: bg
        },
      }}
      fullWidth
    >
      {loading && (
        <LinearProgress height={14} borderRadius="16px" color="success" />
      )}
      <DialogContent>
        <Box sx={{ padding: 2 }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogContainer;
