import {
  Dialog,
  DialogContent,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
  SelectChangeEvent,
  MenuItem,
  FormControl,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import DragAndDropImage from "../../images/drag-and-drop.svg";
import { useDropzone } from "react-dropzone";
import Check from "@mui/icons-material/Check";
import LargeButton from "../../components/common/button/largeButton";
import PatientService from "../../services/patient.service";
import CaseService from "../../services/case.service";

const LabsDialog = ({
  open,
  onClose,
  onSubmit,
  dentistId,
  caseId,
  caseType 
}) => {
  const [lab, setLab] = useState("");
  const [labs, setLabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowLabWriteUp, setIsShowLabWriteUp] = useState(false);

  const handleClose = () => {
    setLab("");
    onClose();
  };

  const handleChange = (event) => {
    const labID = event.target.value;
    setLab(labID);

    const selectedLab = labs.find((x) => x.id == labID);

    setIsShowLabWriteUp(isArtisanLab(selectedLab.labCode));
  };

  const isArtisanLab = (id) => {
    if (id == process.env.REACT_APP_EVIDENT_ARTISAN_ID) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log(lab, caseId);
    let model = {
      caseId: caseId,
      labId: lab,
    };

    CaseService.sendtolabs(model).then((response) => {
      setLoading(false);
      setLab("");
      onSubmit();
    });
  };

  useEffect(() => {
    console.log(dentistId);
    const getLabs = async () => {
      let model = [
        {
          certifiedPartners: "",
          designCenterName: "Evident Design Center",
          displayName: "Fabricate",
          orderNumber: 2,
          serviceProvider: "Fabricate",
          showDesignCenter: false,
          showInvitedLabs: true,
        },
      ];

      if (caseType == "Truedent Denture" && process.env.REACT_APP_EVIDENT_ENABLETRUEDENT_NEWFORM === "TRUE") 
      {
        // if truedent- show Trudent lab code only
        const response = await CaseService.getserviceprovidersTruedent();
        await response.json().then((res) => { 
          setLabs(res[0].providers);
        }); 
      } 
      else 
      {
        const response = await CaseService.getserviceproviders(
          dentistId,
          model
        );
        await response.json().then((res) => { 
          setLabs(res[0].providers.filter((x) => x.invitationStatus === 1));
        });
      }
    };
    if (open === true) {
      getLabs();
    }
  }, [open]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            paddingY: 4,
            paddingX: 4,
            borderRadius: "16px",
          },
        }}
        fullWidth
        onBackdropClick="false"
      >
        <DialogContent>
          <Stack alignItems="center" sx={{ marginBottom: 3 }}>
            <Typography variant="h6" sx={{ marginTop: 2, fontWeight: "bold" }}>
              Send To Lab
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginTop: 2, fontWeight: "bold" }}
            >
              Please select the lab you wish to send your case for fabrication.
            </Typography>

            <FormControl fullWidth>
              <InputLabel id="case-select-label">Lab Name</InputLabel>
              <Select
                labelId="case-select-label"
                value={lab}
                label="Lab Name"
                onChange={handleChange}
              >
                <MenuItem key={0} value={0}>
                  {""}
                </MenuItem>
                {labs.map((row, i) => (
                  <MenuItem key={i} value={row.id}>
                    {row.labName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {isShowLabWriteUp ? (
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginTop: 2,
                    fontWeight: "light",
                    textAlign: "justify",
                    border: "solid 0px red",
                    fontSize: "12px",
                  }}
                >
                  {<br />}
                  {<br />}
                  Artisan Dental Laboratory is a family owned, full-service
                  dental laboratory in Portland, Oregon. Since being founded in
                  1973, we have earned a reputation for providing the dental
                  community with high quality dental prosthetics. Since 2018, we
                  have applied our vast knowledge of denture fabrication to
                  multiple additive manufacturing systems for digital dentures.
                  Today, our denture team prefers TrueDent which utilizes
                  Stratasys polyjet printing technology and proprietary resin
                  to fabricate the industry's only polychromatic monolithic 3D
                  printed permanent dentures.{<br />}
                  {<br />}
                  {<br />}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginTop: 2,
                    fontWeight: "light",
                    textAlign: "justify",
                    border: "solid 0px red",
                    fontSize: "11px",
                  }}
                >
                  Artisan Dental Laboratory {<br />}
                  2532 SE Hawthorne Blvd.{<br />}
                  Portland, OR 97214-3927{<br />}
                  Ph: 1-800-222-6721{<br />}
                  Email: customerservice@artisandental.com{<br />}
                  Website: https://artisandental.com/{<br />}
                </Typography>
              </Box>
            ) : (
              ""
            )}

            <Stack direction="row" spacing={2} sx={{ paddingTop: 2 }}>
              { (caseType == "Truedent Denture" && process.env.REACT_APP_EVIDENT_ENABLETRUEDENT_NEWFORM === "TRUE") ? (
                ""
              ) : (
                <LargeButton variant="gray" fullWidth onClick={handleClose}>
                  Back
                </LargeButton>
              )}
              <LargeButton
                loading={loading}
                variant="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Confirm
              </LargeButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LabsDialog;
