import React, { useState, useEffect } from "react";
import CaseService from "../../services/case.service";
import DialogContainer from "../common/dialogContainer";
import LoadingModal from "../common/modal/loadingModal";
import ResubmitDialog from "./resubmitDialog";
import { useSelector } from "react-redux";
import ProceedToFinalPrompt from "./proceedToFinalPrompt";

const ProceedToFinalDialog = ({ open, onClose, onSubmit, caseId }) => {
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [caseDetails, setCaseDetails] = useState({});
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const response = await CaseService.getCaseById(caseId);
      const data = await response.json();
      console.log(data);
      setLoading(false);
      setCaseDetails(data);
    };

    if (open === true) {
      fetchDetails();
    } else {
      setCaseDetails({});
    }
  }, [open, caseId]);

  const handleSubmit = async () => {
    setSubmitting(true);
    let model = {
      CaseId: caseId,
      PersonnelId: userInfo.Id,
      Message:
        "Uploaded new scans for the final restoration.",
      CommentedBy: "",
      CommentAttachments: [],
      SendMessageNotification: false,
      SendToHubOnly: false,
      SendFromOutsideHub: false,
      Flags: {
        Redesign: false,
        Rescan: false,
      },
      isAssistant: false, //vm.isAssistant === 1 ? true : false,
    };

    try {
      await CaseService.submitCaseComment(
        model,
        [],
        caseDetails.patientId,
        caseId
      );
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
    setOpenPrompt(true);
  };

  const handlePromptClick = () => {
    setOpenPrompt(false);
    onSubmit();
  }

  return (
    <>
      {open && isLoading && (
        <LoadingModal open={true} message="Getting case details" />
      )}
      {open && isSubmitting && (
        <LoadingModal open={true} message="Finishing up" />
      )}
      {open && openPrompt && (
        <ProceedToFinalPrompt open={true} onClick={handlePromptClick}/>
      )}
      {open && !isSubmitting && !isLoading && !openPrompt && (
        <ResubmitDialog
          open={true}
          onClose={onClose}
          patientId={caseDetails.patientId}
          caseId={caseId}
          onSubmit={handleSubmit}
          uploadType="approve"
          caseNumber={caseDetails.caseRefNo}
          message="Submit Files for Final Restoration"
        />
      )}
    </>
  );
};

export default ProceedToFinalDialog;
