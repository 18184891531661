import {
  IconButton,
  TableRow as TableRowMUI,
  TableCell as TableCellMUI,
  Collapse,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import TableRow from "./tableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { blueGrey } from "@mui/material/colors";

const ExpandingTableRow = ({ row, expandContent }) => {
  const [open, setOpen] = useState(false);

  const handleToggleExpand = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        row={[
          ...row,
          expandContent && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleToggleExpand}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ),
        ]}
        shouldShowSpacing={!open}
      />
      {open && (
        <TableRowMUI>
          <TableCellMUI
            sx={{
              padding: 0,
              paddingBottom: open ? 1 : undefined,
              borderBottom: 0,
              bgcolor: "inherit",
            }}
            colSpan={row.length + 1}
          >
            <Collapse in={open}>
              <Box sx={{ paddingY: 0, backgroundColor: "#fff" }}>
                {open && expandContent}
              </Box>
            </Collapse>
          </TableCellMUI>
        </TableRowMUI>
      )}
    </>
  );
};

export default ExpandingTableRow;
