import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { initUser, openLoading, closeLoading } from "../redux/actions";
import AccountService from "../services/account.service";
import AdminService from "../services/admin.service";
import LockerService from "../services/locker.service";
import ToothChartService from "../services/toothChart.service";
import useSegment from "./useSegment";
import { removeParameterFromUrl } from "../helpers/removeParameterFromUrl";

export const useLogin = () => {
  const [loggingIn, setLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const segment = useSegment();
      
    const setUserInfo = (loginResult, practiceAndPersonnel) => {

        let userInfo = {
            Id: loginResult.personnel.id,
            LoginId: loginResult.jwt.loginId,
            FirstName: loginResult.personnel.firstName,
            LastName: loginResult.personnel.lastName,
            Roles: loginResult.jwt.roles,
            Email: loginResult.personnel.email,
            PersonnelAttributes: loginResult.personnel.attributes,
            PracticeWithPersonnel: practiceAndPersonnel,
            BillingOption: loginResult.personnel.billingOption,
        };

        //console.log("userInfo BillingOption ", userInfo.BillingOption);
        localStorage.setItem('locBillingOption', userInfo.BillingOption);

        var myObject = JSON.parse(loginResult.personnel.attributes);
        if (myObject.DentalNotationUserSetting != null) {
            ToothChartService.setDentalNotationSystem(
                myObject.DentalNotationUserSetting
            );
        }
        dispatch(initUser(userInfo));
    };

  const login = async (email, password) => {
    setLoggingIn(true);
    const response = await AccountService.login(email, password);
    const data = await response.json();

    if (data.statusCode !== 10) {
      setLoggingIn(false);
      throw new Error(data.error.message);
    }

    let loginResult = data.data;

    localStorage.setItem("auth_token", loginResult.jwt.auth_token);
    var practiceAndPersonnel = null;
    await Promise.all(
      loginResult.jwt.roles.map(async (element) => {
        if (element !== "api_access") {
          if (element === "Office Manager" || element === "Assistant") {
            console.log("inside if");
            const lockerRes = await LockerService.getlockers(email);
            const json = await lockerRes.json();
            console.log("locker", json);
            practiceAndPersonnel = json;
            setUserInfo(loginResult, practiceAndPersonnel);
            setLoggingIn(false);
          } else {
            const adminRes = await AdminService.getPractices(
              loginResult.personnel.id
            );
            const json = await adminRes.json();
            practiceAndPersonnel = [];
            json.practices.forEach((prac) => {
              let x = {
                practiceId: prac.id,
                personnelId: loginResult.personnel.id,
              };
              practiceAndPersonnel.push(x);
            });
            setUserInfo(loginResult, practiceAndPersonnel);
            setLoggingIn(false);
          }
        }
      })
    );
  };

  const ssologin = async (otlid, sessionid) => {
    dispatch(openLoading());
    setLoggingIn(true);
    const response = await AccountService.ssologin(otlid, sessionid);
    const data = await response.json();
    console.log(data);
    if (data.statusCode !== 10) {
      dispatch(closeLoading());
      setLoggingIn(false);
      throw new Error(data.error.message);
    }

    let loginResult = data.data;
    console.log(loginResult);
    if (
      loginResult.personnel.practiceCount === null ||
      loginResult.personnel.practiceCount === 0
    ) {
      dispatch(closeLoading());
      console.log(loginResult);
      navigate(`/signupsso`, {
        state: {
          userBasicData: loginResult.personnel,
          token: loginResult.jwt.auth_token,
        },
      });
    } else {
      dispatch(closeLoading());
      console.log(loginResult);
      localStorage.setItem("auth_token", loginResult.jwt.auth_token);
      var practiceAndPersonnel = null;
      await Promise.all(
        loginResult.jwt.roles.map(async (element) => {
          console.log(element);
          if (element !== "api_access") {
            if (element === "Office Manager" || element === "Assistant") {
              console.log("inside if");
              const lockerRes = await LockerService.getlockers(
                loginResult.personnel.email
              );
              const json = await lockerRes.json();
              console.log("locker", json);
              practiceAndPersonnel = json;
              setUserInfo(loginResult, practiceAndPersonnel);
              setLoggingIn(false);
              segment.trigger({event: 'identify',email:loginResult.personnel.email});
              dispatch(closeLoading());
            } else {
              const adminRes = await AdminService.getPractices(
                loginResult.personnel.id
              );
              const json = await adminRes.json();
              practiceAndPersonnel = [];
              json.practices.forEach((prac) => {
                let x = {
                  practiceId: prac.id,
                  personnelId: loginResult.personnel.id,
                };
                practiceAndPersonnel.push(x);
              });
              setUserInfo(loginResult, practiceAndPersonnel);
              setLoggingIn(false);
              segment.trigger({event: 'identify',email:loginResult.personnel.email});
              dispatch(closeLoading());

              const searchParams = new URLSearchParams(window.location.search);
              const hasParams = searchParams.has('params');
              if(hasParams){
                const params = searchParams.get('params');
                if(params && params?.trim() !== '' && params !== 'null'){
                    const stringified = window.atob(params);
                    const paramsObject = JSON.parse(stringified);
                    if(paramsObject.paymentDetailsUpdateRequired){
                        navigate('/paymentDetails/?identifier=failedPayment');
                        removeParameterFromUrl(window.location.href,'params');
                    }
                }
              }
            }
          }
        })
      );
    }
  };

  return {
    loggingIn,
    login,
    ssologin,
  };
};
