import { Box, Button, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

const Tabs = ({ data, selected, onPracticeChange }) => {
  return (
    <Stack direction="row" spacing={2}>
      {data.map((d, i) => {
        const isSelected = d.id === selected;
        const bgColor = isSelected ? "#f4f5f6" : "#DEE1E2";

        // padding bottom should be 2, green indicator is background of parent div
        return (
          <Box
            key={i}
            sx={{
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
              paddingBottom: 0.3,
              backgroundImage: isSelected
                ? "linear-gradient(270deg, #11C984 0%, #32BE60 100%)"
                : null,
                backgroundColor: isSelected ? null : bgColor
            }}
            onClick={() => onPracticeChange(d.id)}
          >
            <Button
              disableRipple
              sx={{
                paddingX: 6,
                paddingTop: 2,
                paddingBottom: 1.7,
                color: isSelected ? "black" : grey[600],
                fontWeight: isSelected ? "bold" : "regular",
                fontSize: "16px",
                textTransform: "none",
                backgroundColor: bgColor,
                "&:hover": {
                  backgroundColor: bgColor,
                },
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              {d.name}
            </Button>
          </Box>
        );
      })}
    </Stack>
  );
};

export default Tabs;
