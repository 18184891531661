import { Box, Button, Skeleton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useMemo } from "react";
import { brandColor, brandGray, brandLightGreen } from "../../constants";
import { getRandomInt } from "../../helpers/getRandomInt";

const PracticeTabs = ({ practices, selected, onPracticeChange }) => {
  const skeletonPractice = useMemo(
    () => new Array(getRandomInt(1, 2)).fill(""),
    [practices]
  );

  return (
    <Stack direction="row" spacing={2}>
      {!practices.length > 0 && (
        <Box>
          <Stack direction="row" spacing={2}>
            {skeletonPractice.map(() => (
              <Skeleton
                variant="rounded"
                width={getRandomInt(150, 280) + "px"}
                sx={{
                  borderTopLeftRadius: "14px",
                  borderTopRightRadius: "14px",
                }}
                height="59px"
              />
            ))}
          </Stack>
        </Box>
      )}
      {practices.map((practice, i) => {
        const isSelected = practice.id === selected;
        const bgColor = isSelected ? brandLightGreen : "#fff";

        // padding bottom should be 2, green indicator is background of parent div
        return (
          <Box
            key={i}
            sx={{
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              paddingBottom: 0.3,
              backgroundColor: isSelected ? brandLightGreen : bgColor,
            }}
            onClick={() => onPracticeChange(practice.id)}
          >
            <Button
              disableRipple
              sx={{
                paddingX: 6,
                paddingTop: 2,
                paddingBottom: 1.7,
                color: isSelected ? "#fff" : brandGray,
                fontWeight: isSelected ? "bold" : "regular",
                fontSize: "16px",
                textTransform: "none",
                backgroundColor: bgColor,
                "&:hover": {
                  backgroundColor: bgColor,
                },
                borderTopLeftRadius: "14px",
                borderTopRightRadius: "14px",
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              {practice.name}
            </Button>
          </Box>
        );
      })}
    </Stack>
  );
};

export default PracticeTabs;
