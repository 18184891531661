import {
  Dialog,
  DialogContent,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import DragAndDropImage from "../../images/drag-and-drop.svg";
import { useDropzone } from "react-dropzone";
import Check from "@mui/icons-material/Check";
import LargeButton from "../../components/common/button/largeButton";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import moment from "moment";
const ApproveDialog = ({ open, onClose, onSubmit }) => {
  const [returnDate, setReturnDate] = React.useState("");

  const handleClose = () => {
    onClose();
    setReturnDate("");
  };

  const handleSubmit = async () => {
    if (returnDate === "" || returnDate === undefined) {
      // setValidateNote(true);
    } else {
      await onSubmit(returnDate);
      setReturnDate("");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          paddingY: 4,
          paddingX: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <DialogContent>
        <Stack alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ marginTop: 2, fontWeight: "bold" }}
          >
            Please Select Next Appointment Date
          </Typography>
          <Stack direction="row">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                value={returnDate}
                onChange={(newValue) => {
                  setReturnDate(newValue);
                }}
                minDate={moment(moment()).toDate()}
                sx={{ border: "0.5px solid #DFDFDF !important" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "100%",
                      border: "0.5px solid #DFDFDF !important",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label=""
                value={returnDate}
                onChange={(newValue) => {
                  setReturnDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "100%",
                      border: "0.5px solid #DFDFDF !important",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ paddingTop: 2 }}>
            <LargeButton variant="gray" fullWidth onClick={handleClose}>
              Back
            </LargeButton>
            <LargeButton variant="primary" fullWidth onClick={handleSubmit}>
              Confirm
            </LargeButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ApproveDialog;
