import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
function isLoadingModal({isOpen})
{
    return (
        <Dialog open={isOpen} maxWidth="sm" fullWidth sx={{textAlign: 'center', justifyContent: 'center'}}>
            <div alignItem="center" >
                  <CircularProgress color="info" size="100px"   />
                  <h1>Case Being Created</h1>
            </div>   
        </Dialog>
    )
}
export default connect(null, null)(isLoadingModal);