import {
  Autocomplete,
  Divider,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  Skeleton,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import AdminService from "../../services/admin.service";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/common/table/dataTable";
import TableRow from "../../components/common/table/tableRow";
import { brandBlue, brandColor, brandLight } from "../../constants";
import AddPracticeDialog from "../account/addPracticeDialog";
import { initUser, openSnackbar } from "../../redux/actions";
import StyledButton from "../common/button/largeButton";
import LoadingModal from "../common/modal/loadingModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const ProfileDetails = () => {
  const [practices, setPractices] = useState([]);
  const [isAddPracticeOpen, setAddPracticeOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [currentPractice, setCurrentPractice] = useState(null);

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const headers = [
    "Practice Name",
    "Practice Address",
    // "Practice Specialty",
    "Phone",
    "Actions",
    "Default Practice",
  ];

  const getPractices = async () => {
    const practicesResponse = await AdminService.getPractices(userInfo.Id);
    const { practices } = await practicesResponse.json();
    console.log("practices", practices);
    setPractices(practices);
  };
  useEffect(() => {
    if (!userInfo.Id) {
      navigate("/");
    }

    getPractices();
  }, [userInfo.Id, navigate]);

  const getSpecialty = (practice) => {
    let specialty = "";
    let specialties = JSON.parse(practice.specialty);

    if (specialties.familyDentistry) {
      specialty = "Family Dentistry,";
    }

    if (specialties.implants) {
      specialty = specialty + " Implants,";
    }

    if (specialties.orthodonticsAligners) {
      specialty = specialty + " Orthodontics and Aligners,";
    }

    if (specialties.pediatricDentistry) {
      specialty = specialty + " Pediatric Dentistry,";
    }

    if (specialties.prosthodontics) {
      specialty = specialty + " Prosthodontics,";
    }

    return specialty.substring(0, specialty.length - 1);
  };

  const setDefaultPractice = (practiceId) => {
    console.log(practiceId, userInfo);
    let param = { PersonnelId: userInfo.Id, PracticeId: practiceId };
    console.log(param);
    AdminService.updateprimarypracticepersonnel(param)
      .then((response) => response.json())
      .then((json) => {
        getPractices();
      });
  };

  const rows = useMemo(
    () =>
      practices.map((practice, i) => [
        practice.name,
        `${practice.address.line1}  ${practice.address.line2}  ${practice.address.city}  ${practice.address.state} ${practice.address.postal} ${practice.address.country}`,
        // getSpecialty(practice),
        practice.phone,
        userInfo.Roles.includes("Dentist") ? (
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              console.log(practice);
              setAddPracticeOpen(true);
              setCurrentPractice(practice);
            }}
            sx={{
              width: "170px",
              borderRadius: "30px",
              backgroundColor: brandBlue,
              "&:hover": {
                backgroundColor: brandBlue,
                color: "#fff",
              },
            }}
          >
            Edit Practice
          </Button>
        ) : null,
        practice.isPrimary ? (
          <IconButton>
            <Tooltip title="Default Practice">
              <CheckCircleIcon sx={{ color: brandColor }} />
            </Tooltip>
          </IconButton>
        ) : (
          userInfo.Roles.find((x) => x === "Dentist") && (
            <IconButton onClick={() => setDefaultPractice(practice.id)}>
              <Tooltip title="Set as Default practice">
                <CheckCircleOutlineIcon />
              </Tooltip>
            </IconButton>
          )
        ),
        // <PatientMenu patient={patient} />,
      ]),
    [practices, userInfo.Roles]
  );

  const handleEditPractice = async (practice) => {
    const {
      id,
      email,
      customerRefId,
      isNew,
      isPrimary,
      personnels,
      shouldRedirectUrl,
    } = currentPractice;

    const { name, address, equipment, phone, specialty } = practice;

    const practiceData = {
      id: userInfo.Id,
      practices: [
        {
          id,
          email,
          customerRefId,
          isNew,
          isPrimary,
          personnels,
          shouldRedirectUrl,
          name,
          address,
          phone,
          equipment: JSON.stringify(equipment),
          specialty: JSON.stringify(specialty),
        },
      ],
    };

    setSubmitting(true);
    try {
      const response = await AdminService.updateDentistDentalPractice(
        practiceData
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error();
      }
      dispatch(
        openSnackbar({
          title: "Success",
          body: "Practice edited",
        })
      );

      setPractices((prev) =>
        prev.map((practice) =>
          practice.id === data[0].id ? data[0] : practice
        )
      );
    } catch (err) {
      console.log(err);
      dispatch(
        openSnackbar({
          title: "Error",
          body: "Practice not added. Please try again.",
          type: "error",
        })
      );
    }

    setSubmitting(false);
    setCurrentPractice(null);
  };

  const handleAddPractice = async (practice) => {
    const practiceData = {
      id: userInfo.Id,
      practices: [
        {
          id: "00000000-0000-0000-0000-000000000000",
          email: userInfo.Email,
          name: practice.name,
          phone: practice.phone,
          isNew: true,
          customerRefId: 0,
          specialty: JSON.stringify(practice.specialty),
          address: practice.address,
          equipment: JSON.stringify(practice.equipment),
        },
      ],
    };

    setSubmitting(true);
    try {
      const response = await AdminService.updateDentistDentalPractice(
        practiceData
      );
      const data = await response.json();
      setPractices((p) => [...p, ...data]);
      const practiceId = data[0].id;
      if (!response.ok) {
        throw new Error();
      }
      dispatch(
        openSnackbar({
          title: "Success",
          body: "Practice added",
        })
      );
      // update userInfo to include added practice in PracticeWithPersonnel array
      dispatch(
        initUser({
          ...userInfo,
          PracticeWithPersonnel: [
            ...userInfo.PracticeWithPersonnel,
            {
              practiceId,
              personnelId: userInfo.Id,
            },
          ],
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        openSnackbar({
          title: "Error",
          body: "Practice not added. Please try again.",
          type: "error",
        })
      );
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <LoadingModal open={isSubmitting} message="Saving practice details" />
      {isAddPracticeOpen && (
        <AddPracticeDialog
          open={isAddPracticeOpen}
          onClose={() => {
            setAddPracticeOpen(false);
            setCurrentPractice(null);
          }}
          onAddPractice={handleAddPractice}
          onEditPractice={handleEditPractice}
          practice={currentPractice}
          edit={Boolean(currentPractice)}
        />
      )}
      <Container maxWidth="lg">
        <Box marginY={5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" component="h1">
              My Profile
            </Typography>
            {userInfo.Roles.includes("Dentist") && (
              <StyledButton
                variant="primary"
                onClick={() => {
                  setAddPracticeOpen(true);
                }}
              >
                Add New Practice
              </StyledButton>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            paddingY: 2,
            paddingX: 4,
            backgroundColor: brandLight,
            borderRadius: "20px",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#37465D",
                  }}
                >
                  Name: {userInfo.FirstName} {userInfo.LastName}
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#37465D",
                  }}
                >
                  Email: {userInfo.Email}
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#37465D",
                  }}
                >
                  Position: {userInfo.Roles[1]}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box>
              {rows.length > 0 && (
                <>
                  <Divider
                    sx={{
                      border: 0,
                      borderBottom: "1px dashed #6C6C6C",
                    }}
                  />
                  <DataTable headers={headers}>
                    {rows.map((row, i) => (
                      <TableRow key={i} row={row} />
                    ))}
                  </DataTable>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
export default ProfileDetails;
