import React from "react";
import PatientService from "../../../services/patient.service";
import DownloadLargeButton from "./downloadLargeButton";

const DownloadFileLargeButton = ({
  children,
  path,
  fileName,
  label,
  isExternal = false,
  variant = "outlined",
  fullWidth = false,
  isLoading,
}) => {
  const getPath = async () => {
    const blobPath = await PatientService.getPatientFilePath(path, isExternal, fileName);
    return blobPath;
  };

  return (
    <DownloadLargeButton
      isLoading={isLoading}
      children={children}
      path={getPath}
      fileName={fileName}
      label={label || undefined}
      variant={variant}
      fullWidth={fullWidth}
    />
  );
};

export default DownloadFileLargeButton;
