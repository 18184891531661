import React, { useMemo, useState } from "react";
import {
  Grid,
  Box,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Stack,
  OutlinedInput,
  FormHelperText,
  Chip,
  Typography,
} from "@mui/material";
import ToothChart from "../common/toothChart/toothChart";

import crownPic from "../../images/ModalToothSelection/crown.png";
import ponticPic from "../../images/ModalToothSelection/pontic.png";
import veneerPic from "../../images/ModalToothSelection/veneer.png";
import StyledFormLabel from "../common/styledFormLabel";
import FormOption from "../common/formOption";
import * as yup from "yup";
import { useFormik } from "formik";
import ToothChartService from "../../services/toothChart.service";
import { groupTeeth } from "../../helpers/groupTeeth";
import { brandBlue, brandColor, brandLight } from "../../constants";
import CancelIcon from '@mui/icons-material/Cancel';
import NotesSection from "./notesSection";

const initialValues = {
  teeth: [],
  selectedTreatment: "",
  gingivalContouring: false,
  widthAndLength: "",
  notes: "",
  restorationType:"",
  designMode:"",
  materialClass:"",
  material:"",
  device:"",
};

function ToothChartComponent(props) {
  const {
    treatment,
    selectedTeeth,
    toothChartId,
    notion,
    onSelectTeeth,
    onClearTeeth,
    onRemoveSelectedTooth,
    updateNotesHandler,
    isUpdatingNotes,
    onFinishedUpdatingNotes
  } = props;
 
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      ...initialValues,
    },
    validationSchema: yup.object({
      showImplant: yup.boolean(),
      
      teeth: yup
        .array()
        .min(1, "Select at least 1 tooth")
        .required("Teeth is required"),
        selectedTreatment: yup.string().test('isCEREC', 'Treatment plan is required', function (value) {
          if (treatment.description === "CEREC Crown & Bridge") { 
            return true;  
          } 
          return !!value;  
        }),  
      gingivalContouring: yup.boolean().required("Required"),
      widthAndLength: yup.number().when("showImplant", {
        is: true,
        then: yup
          .number()
          .positive()
          .typeError("Must be a number")
          .required("Required"),
      }),
      notes: yup.string("Notes must be a string").nullable(),
      material: yup.string().test('isCEREC', 'Material is required', function (value) {
        if (treatment.description !== "CEREC Crown & Bridge") { 
          return true;  
        } 
        return !!value;  
      }),    
      device: yup.string().test('isCEREC', 'Device is required', function (value) {
        if (treatment.description !== "CEREC Crown & Bridge") { 
          return true;  
        } 
        return !!value;  
      }),  
      
    }),
    onSubmit: (values) => handleSelectedTeethAdd(values),
    
  });


  const groupedTeeth = useMemo(
    () => groupTeeth(selectedTeeth),
    [selectedTeeth,isUpdatingNotes]
  );

  const treatmentPlans = [
    {
      name: "Crowns",
      image: crownPic,
    },
    {
      name: "Pontic",
      image: ponticPic,
    },
    {
      name: "Veneers",
      image: veneerPic,
    },
  ];

  const treatmentPlanswithInlayOnlay = [
    {
      name: "Crowns",
      image: crownPic,
    },
    {
      name: "Pontic",
      image: ponticPic,
    },
    {
      name: "Veneers",
      image: veneerPic,
    },
    {
      name: "Inlay/Onlay",
      image: crownPic,
    },
  ];
  const CERECRestorationType = [
    {
      name: "R1",
      value:"Restore 1" 
    },
    {
      name: "R2" ,
      value:"Restore 2" 
    },
    {
      name: "R3" ,
      value:"Restore 3" 
    },
  ];
  
  const CERECDesignMode = [
    {
      name: "D1",
      value:"Design 1" 
    },
    {
      name: "D2" ,
      value:"Design 2" 
    },
    {
      name: "D3" ,
      value:"Design 3" 
    },
  ];
  
  const CERECMaterialClass = [
    {
      name: "MC1",
      value:"MC1" 
    },
    {
      name: "MC2" ,
      value:"MC2" 
    },
    {
      name: "MC3" ,
      value:"MC3" 
    },
  ];

  const printingDevices = ["MC X5", "MC XL", "Primemill", "Primeprint", "5-Axis-0.6-Instrument", "Generic 3D Printer", "3 axes 0.001mm Cy"];
  
  const CERECMaterial = [
    {
      name: "M1",
      value:"M1 1" 
    },
    {
      name: "M2" ,
      value:"M2 2" 
    },
    {
      name: "M3" ,
      value:"M3 3" 
    },
  ];


  const handleRestorationTypeOnChange = (value) => {
    setFieldValue("restorationType", value);
  }; 
  const handleDesignModeOnChange = (value) => {
    setFieldValue("designMode", value);
  }; 
  const handleMaterialClassOnChange = (value) => {
    setFieldValue("materialClass", value);
  }; 
  const handleMaterialOnChange = (value) => {
    setFieldValue("material", value);
  };
  const handleDeviceOnChange = (value) => {
    setFieldValue("device", value);
  };




  const handleTreatmentPlanClick = (value) => {
    setFieldValue("selectedTreatment", value);
  };

  const handleToothAdd = (number) => {
    setFieldValue("teeth", [...values.teeth, parseInt(number)]);
  };

  const handleToothRemove = (number) => {
    // remove from selected teeth
    number = parseInt(number);
    if (!values.teeth.includes(number)) {
      return onRemoveSelectedTooth(number);
    }

    setFieldValue(
      "teeth",
      values.teeth.filter((tooth) => tooth !== number)
    );
  };

  const removeSelectedTeethClasses = (teeth) => {
    teeth.forEach((tooth) =>
      ToothChartService.setToothColorToSelected(tooth, toothChartId)
    );
  };

  const handleSelectedTeethAdd = (values) => {
    const data = values.teeth.map((tooth) => ({
      tooth,
      notes: values.notes,
      gingivalContouring: values.gingivalContouring,
      widthAndLength: values.widthAndLength,
      restorationType: treatment.description=="CEREC Crown & Bridge" ? values.restorationType : values.selectedTreatment ,
      designMode: treatment.description=="CEREC Crown & Bridge" ? values.designMode :"",
      materialClass:treatment.description=="CEREC Crown & Bridge" ? values.materialClass:"",
      material:treatment.description=="CEREC Crown & Bridge" ? values.material:"",
      device:treatment.description=="CEREC Crown & Bridge" ?  values.device:"",
    }));

    removeSelectedTeethClasses(values.teeth);
    resetForm();
    onSelectTeeth(data);
  };

  const onUpdateNotes = (teeth,notes) => {
    updateNotesHandler(teeth,notes);
  }

  const handleChangeNotation = () => {
    removeSelectedTeethClasses(values.teeth);
    setFieldValue("teeth", []);
  };

  return (
    <Grid container>
      <Grid item xs={6} sx={{background: brandLight, padding: '20px', paddingBottom: '10px'}}>
        {treatment.description !== "CEREC Crown & Bridge" && 
          (<>
            <Box>
              <Typography fontSize={16} fontWeight={600}>Choose Treatment</Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginTop: 1, width: "100%", height: "50px" }}
              >
                {treatment.description !== "Crown & Bridge" &&
                treatment.description !== "CEREC Crown & Bridge" &&
                  treatmentPlans.map((treatmentPlan) => (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontWeight: "500",
                        border:
                          values.selectedTreatment === treatmentPlan.name
                            ? "none"
                            : "1px solid rgb(224, 224, 224)",
                        color:
                          values.selectedTreatment === treatmentPlan.name
                            ? "white"
                            : "black",
                        background:
                          values.selectedTreatment === treatmentPlan.name
                            ? brandColor
                            : "white",
                        borderRadius: "18px",
                      }}
                      onClick={() =>
                        handleTreatmentPlanClick(treatmentPlan.name)
                      }
                    >
                      <Box>{treatmentPlan.name}</Box>
                    </Box>
                  ))}
                {treatment.description === "Crown & Bridge" &&
                  treatmentPlanswithInlayOnlay.map((treatmentPlan) => (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontWeight: "500",
                        color:
                          values.selectedTreatment === treatmentPlan.name
                            ? "white"
                            : "black",
                        border:
                          values.selectedTreatment === treatmentPlan.name
                            ? "none"
                            : "1px solid rgb(224, 224, 224)",
                        background:
                          values.selectedTreatment === treatmentPlan.name
                            ? brandColor
                            : "white",
                        borderRadius: "18px",
                      }}
                      onClick={() =>
                        handleTreatmentPlanClick(treatmentPlan.name)
                      }
                    >
                      <Box>{treatmentPlan.name}</Box>
                    </Box>
                  ))}
              </Stack>
              <FormHelperText error sx={{ marginTop: 1 }}>
                {touched.selectedTreatment && errors.selectedTreatment}
              </FormHelperText>
              
              </Box>
          </>)} 
        
        <Stack marginY={5}>
          <Typography fontSize={16} fontWeight={600}>Select Tooth</Typography>
          <Box
            sx={{
              borderRadius: "21px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <ToothChart
                toothChartId={toothChartId}
                selectedChartType={notion}
                handleSelectTooth={handleToothAdd}
                handleRemoveTeeth={handleToothRemove}
                onChangeNotation={handleChangeNotation}
              />
            </Box>
          </Box>
        </Stack>

        {
            treatment.description == "CEREC Crown & Bridge" && (<>
            <Box>
                <StyledFormLabel>Material</StyledFormLabel>
                <TextField
                    size={"medium"}
                    select 
                    value={values.material}
                    variant="outlined" 
                    sx={{
                      backgroundColor: "#fff",
                      width: "50%",
                      marginRight: "15px",
                    }}
                    onChange={(e) => handleMaterialOnChange(e.target.value) }
                  >
                    {CERECMaterial?.map((item, cIndex) => (
                      <MenuItem key={cIndex} value={item.value} sx={{whiteSpace: "unset",
                      wordBreak: "break-all"}}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
                <FormHelperText error sx={{ marginTop: 1 }}>
                    {touched.material && errors.material}
                  </FormHelperText>
            </Box>

            <Box>
                <StyledFormLabel>Device</StyledFormLabel>
                <TextField
                    size={"medium"} 
                    variant="outlined" 
                    value={values.device}
                    sx={{
                      backgroundColor: "#fff",
                      width: "50%",
                      marginRight: "15px",
                    }}
                    onChange={(e) => handleDeviceOnChange(e.target.value) }
                    select
                  > 
                  {printingDevices.map(p=><MenuItem key={p} value={p}>{p}</MenuItem>)}
                </TextField>
                <FormHelperText error sx={{ marginTop: 1 }}>
                    {touched.device && errors.device}
                  </FormHelperText>
            </Box>
            </>)
          }

          {treatment.description !== "Provisional Restorations" &&
            treatment.description !== "Evident Smile Package" &&
            treatment.description !== "Diagnostic Wax-Up" &&
            treatment.description !== "Crown & Bridge" &&
            treatment.description !== "Surgical Planning and Guide" &&
            treatment.description !== "Gingival Reduction Guide" &&
            treatment.description !== "Implant Crowns" &&
            treatment.description !== "CEREC Crown & Bridge" && (
              <Box>
                <StyledFormLabel>Gingival Contouring</StyledFormLabel>
                <Stack spacing={2} direction="row">
                  <FormOption
                    selected={values.gingivalContouring === true}
                    onClick={() => setFieldValue("gingivalContouring", true)}
                  >
                    Yes
                  </FormOption>
                  <FormOption
                    selected={values.gingivalContouring === false}
                    onClick={() => setFieldValue("gingivalContouring", false)}
                  >
                    No
                  </FormOption>
                </Stack>
                <FormHelperText error sx={{ marginTop: 1 }}>
                  {touched.gingivalContouring && errors.gingivalContouring}
                </FormHelperText>
              </Box>
          )}
        
        <Box>
          <StyledFormLabel>Notes</StyledFormLabel>
          <OutlinedInput
            sx={{backgroundColor: "#FFF"}}
            placeholder="Please leave your note"
            name="notes"
            value={values.notes}
            onChange={handleChange}
            minRows={2}
            maxRows={2}
            multiline
            fullWidth
          />
        </Box>

        <Box>
          <FormHelperText error sx={{ marginTop: 1, marginLeft: 2 }}>
            {touched.teeth && errors.teeth}
          </FormHelperText>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            color="brand"
            size="large"
            onClick={handleSubmit}
            sx={{ 
              fontWeight: 400,
              borderRadius: "6px", 
              marginTop: '15px', 
              backgroundColor: '#65B559',
              width: '100px', 
              '&:hover': {
                backgroundColor: '#39A929', // Background color on hover
              }, 
            }}
          >
            Add
          </Button>
        </Box>
        
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            height: "100%",
            boxSizing: "border-box",
            overflowY: "auto",
            padding: 4,
          }}
        >
          {selectedTeeth.length > 0 && (
            <Box sx={{ marginTop: 3 }}>
              <StyledFormLabel>Selected Teeth</StyledFormLabel>
              <Stack
                spacing={1.5}
                sx={{
                  marginTop: 1,
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  padding: 2,
                  overflow: "auto",
                }}
              >
                {groupedTeeth.map((t) => (
                  <Stack sx={{borderBottom: 'dashed 1px #BDBDBD', paddingBottom: '10px'}}>
                    <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                      <Box>
                        <Typography
                          sx={{ fontSize: "1em", fontWeight: "medium", display: "flex", alignItems: "center" }}
                        >
                          <Typography variant="caption" component="div" paddingRight={2}>
                            &#x2022;  
                          </Typography> 
                          {treatment.description !== "CEREC Crown & Bridge" && <Typography variant="body" component="div" minWidth={80}>
                            {`${t.restorationType}:`}
                          </Typography>}
                          <Typography variant="body" component="div">
                            {`Tooth ${t.teeth.join(", ")}`}
                          </Typography>
                          { treatment.description ==="CEREC Crown & Bridge" && 
                          (<>
                            , {t.material}, {t.device}
                          </>)} 
                        </Typography>
                        {t.gingivalContouring === true && (
                          <Chip size="small" label="Gingival Contouring" />
                        )}
                      </Box>
                      <IconButton
                        onClick={()=>{onClearTeeth(t.teeth)}}
                        sx={{color: "#AFAFB8"}}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Stack>
                    {t.widthAndLength > 0 && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        marginTop={0.5}
                        spacing={1}
                      >
                        <Typography sx={{ fontSize: "0.9em" }}>
                          {`Width and Length: ${t.widthAndLength}mm`}
                        </Typography>
                      </Stack>
                    )}
                    {t.notes && (
                      <NotesSection treatment={treatment} onUpdateNotes={onUpdateNotes} teeth={t.teeth} notes={t.notes} />
                    )}
                  </Stack>
                ))}
              </Stack>
            </Box>
          )}
          {/* <Stack spacing={2}> */}
          
         
          
            {/*             {isImplant && (
              <Box>
                <StyledFormLabel>Implant Width and Length</StyledFormLabel>
                <OutlinedInput
                  name="widthAndLength"
                  value={values.widthAndLength}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">mm</InputAdornment>
                  }
                />
                <FormHelperText error sx={{ marginTop: 1 }}>
                  {touched.selectedTreatment && errors.widthAndLength}
                </FormHelperText>
              </Box>
            )} */}

            

            
          {/* </Stack> */}
          
        </Box>
      </Grid>
    </Grid>
  );
}

export default ToothChartComponent;
