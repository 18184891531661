import {
  Dialog,
  DialogContent,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
  SelectChangeEvent,
  MenuItem,
  FormControl,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import DragAndDropImage from "../../images/drag-and-drop.svg";
import { useDropzone } from "react-dropzone";
import Check from "@mui/icons-material/Check";
import LargeButton from "../../components/common/button/largeButton";
import PatientService from "../../services/patient.service";
import CaseService from "../../services/case.service";

const CaseNumberListDialog = ({
  open,
  onClose,
  onSubmit,
  patientId,
  filesToSend,
  isZip = false,
}) => {
  const [caseNumbers, setCaseNumbers] = useState([]);
  const [caseNumber, setCaseNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setCaseNumber(0);
    onClose();
  };

  const handleSubmit = async () => {
    if (caseNumber === "" || caseNumber === undefined || caseNumber === 0) {
      // setValidateNote(true);
    } else {
      if (
        window.confirm(
          "Are you sure you want to attach the file to the existing case?"
        )
      ) {
        setLoading(true);
        if (isZip === true) {
          console.log(filesToSend);
          let files = [];

          let model = {
            CaseNumber: caseNumber,
            Files: files,
            IsSingleFileOnly: true,
            FileName: filesToSend.fileName,
            Path: filesToSend.path,
          };
          CaseService.sendfiletodesign(model).then((response) => {
            setLoading(false);
            setCaseNumber(0);
            onSubmit(caseNumber);
          });
        }
        if (isZip === false) {
          let files = [];

          filesToSend.fileExtensions.forEach((value) => {
            let f = { fileName: value.fileName, path: value.path };
            files.push(f);
          });

          let model = {
            caseNumber: caseNumber,
            files: files,
            isSingleFileOnly: false,
          };
          CaseService.sendfiletodesign(model).then((response) => {
            setLoading(false);
            setCaseNumber(0);
            onSubmit(caseNumber);
          });
        }
      }
    }
  };

  useEffect(() => {
    const getCaseNumbers = async () => {
      const response = await PatientService.getcasesofpatient(patientId);
      await response.json().then((res) => {
        console.log(res);
        setCaseNumbers(res);
      });
    };

    getCaseNumbers();
  }, []);

  const handleChange = (event) => {
    setCaseNumber(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          paddingY: 4,
          paddingX: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <DialogContent>
        <Stack alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography variant="h6" sx={{ marginTop: 2, fontWeight: "bold" }}>
            Send To Evident Design
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ marginTop: 2, fontWeight: "bold" }}
          >
            Please indicate the case you want to attach the file to
          </Typography>

          <FormControl fullWidth>
            <InputLabel id="case-select-label">Case Number</InputLabel>
            <Select
              labelId="case-select-label"
              value={caseNumber}
              label="Case Number"
              onChange={handleChange}
            >
              <MenuItem key={0} value={0}>
                {""}
              </MenuItem>
              {caseNumbers.map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction="row" spacing={2} sx={{ paddingTop: 2 }}>
            <LargeButton variant="gray" fullWidth onClick={handleClose}>
              Back
            </LargeButton>
            <LargeButton
              loading={loading}
              variant="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Confirm
            </LargeButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CaseNumberListDialog;
