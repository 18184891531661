import { systemErrorRetryPolicy } from "@azure/core-http";
import {
  Alert,
  Button,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PatientService from "../../services/patient.service";
import DialogContainer from "../common/dialogContainer";
import HtmlPreview from "./htmlPreview";
import ImagePreview from "./imagePreview";
import PdfPreview from "./pdfPreview";
import STLPreview from "./stlPreview";
import { grey } from "@mui/material/colors";
import { brandBlue, brandOrange } from "../../constants";

const PreviewFileButton = ({ file, buttonProps, children }) => {
  const [isOpen, open] = useState(false);
  const [blobLink, setBlobLink] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setBlobLink(null);
      setError("");
      return;
    }

    const getFile = async () => {
      try {
        const blobPath = await PatientService.getPatientFilePath(
          file.path,
          false
        );

        const blobResponse = await fetch(blobPath, {
          method: "GET",
        });
        const blob = await blobResponse.blob();

        if (!blobResponse.ok) {
          setError("Failed to get file");
        }
        const url = URL.createObjectURL(blob);
        setBlobLink(url);
      } catch (err) {
        console.log(err);
      }
    };

    getFile();
  }, [isOpen, file]);

  return (
    <>
      {children && <Box onClick={() => open(true)}>{children}</Box>}
      {!children && (
        <Box
          target="_blank"
          component="button"
          onClick={() => open(true)}
          sx={{
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#F8F8F8",
            paddingX: 6,
            background: brandBlue,
            backgroundColor: brandBlue,
            borderRadius: "30px",
            border: 0,
            "&:hover": {
              background: brandBlue,
            },
            cursor: "pointer",
          }}
          {...buttonProps}
        >
          <Typography fontWeight="500">Preview Design</Typography>
        </Box>
      )}
      {isOpen && (
        <DialogContainer
          open={isOpen}
          onClose={() => open(false)}
          maxWidth="md"
          loading={!blobLink}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography component="h2" variant="h6">
              File Preview
            </Typography>
            <Typography variant="body2" mt={0.5}>
              {blobLink
                ? file.fileName
                : "Downloading file for preview. This may take a while."}
            </Typography>
          </Box>

          {error && (
            <Alert severity="warning" sx={{ marginY: 2 }}>
              There was a problem downloading your file for the preview. Please
              download the file to view it.
            </Alert>
          )}
          {!error && (
            <Box marginY={2}>
              {/* file types pdf, html, stl, png, jpg */}
              {blobLink && file.type === "stl" && (
                <STLPreview link={blobLink} />
              )}
              {blobLink && file.type === "html" && (
                <HtmlPreview link={blobLink} />
              )}
              {blobLink && file.type === "pdf" && (
                <PdfPreview link={blobLink} />
              )}
              {blobLink &&
                ["png", "jpg"].some((type) => type === file.type) && (
                  <ImagePreview filename={file.fileName} link={blobLink} />
                )}

              {!blobLink && (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height="480px"
                  sx={{ borderRadius: "20px" }}
                />
              )}
            </Box>
          )}

          <Stack direction="row" alignItems="center">
            {blobLink && (
              <Link href={blobLink} download={file.fileName}>
                Save File
              </Link>
            )}
            <Button
              color="brand"
              variant="contained"
              onClick={() => open(false)}
              sx={{ display: "block", marginLeft: "auto" }}
            >
              Close
            </Button>
          </Stack>
        </DialogContainer>
      )}
    </>
  );
};

export default PreviewFileButton;
