import * as yup from "yup";

export const newUserSchema = yup.object({
  firstName: yup
    .string("Enter a valid string")
    .required("First name is required"),
  lastName: yup
    .string("Enter a valid string")
    .required("Last name is required"),
  email: yup
    .string("Enter a valid string")
    .email("Must be a valid email")
    .required("Email is required"),
  practiceObj: yup
    .object()
    .typeError("Choose a valid practice")
    .required("Practice is required"),
  positionObj: yup
    .object()
    .typeError("Choose a valid position")
    .required("Position is required"),
});
