import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'

const OrderDetails = ({selectedTreatments,confirmationDetails,patientDetails}) => {
    const [selectedTreatmentNames,setSelectedTreatmentNames] = useState("");

    useEffect(()=>{
        let treatmenNames = selectedTreatments.map(t=>t.description);
        setSelectedTreatmentNames(treatmenNames.join(', '));
    },[]);

    return (
        <Box borderLeft='solid thin #00000033' display='flex' flexDirection='column' alignItems='flex-start' alignContent='center' textAlign='left' marginTop={5} paddingTop={5} paddingLeft={10} >
            <Typography marginY={1} variant='body1' component='p'>Patient: {`${patientDetails.FirstName} ${patientDetails.LastName}`}</Typography>
            <Typography marginY={1} variant='body1' component='p'>Practice: {patientDetails.PracticeName}</Typography>
            <Typography marginY={1} variant='body1' component='p'>Treatment Name: {selectedTreatmentNames}</Typography>
            <Typography marginY={1} variant='body1' component='p'>Email: {patientDetails.Email ? patientDetails.Email : "N/A"}</Typography>
            <Typography marginY={1} variant='body1' component='p'>Case Number: {confirmationDetails.RefNo}</Typography>

            <Box marginTop={5}>
                <a
                    href={`/cases/${confirmationDetails.Id && confirmationDetails.Id}`}
                    rel="noopener noreferrer"
                    color="#64B457"
                >
                    <Typography style={{
                            textDecoration: 'underline',
                            textDecorationColor: '#64B457',  // Change this to your desired color
                            textDecorationThickness: '2px', // Optional: Change thickness of underline
                            textDecorationStyle: 'solid', // Optional: Change style of underline (dashed, solid, etc.)
                        }} 
                        color="#64B457"
                        >
                            Track Case Progress
                    </Typography>
                </a>
            </Box>
           
        </Box>
    )
}

export default OrderDetails