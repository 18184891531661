import React, { useState } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { brandColor, brandGray } from "../../constants";
import { ThumbDown, ThumbUp, ErrorOutline, Error } from "@mui/icons-material";
import CardDetails from "../../components/paymentDetails/cardDetails";
import { useSelector } from "react-redux";
import AdminService from "../../services/admin.service";

function NoAccessPrepayModal({
  isOpen,
  handleClose,
  initUser,
  msg,
  isDentist,
}) {
  const [payNow, setPayNow] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [messageToUser, setMessageToUser] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [paymentError, setPaymentError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function resetVariables() {
    setPayNow(false);
    setIsSuccess(false);
    setHasError(false);
    setLoading(false);
    setMessageToUser("");
    setPaymentError("");
  } 
  const closeDialog = (confirm) => {
    handleClose(confirm);
    setIsSuccess(false);
  };
  const closeDialogPayment = (confirm) => {
    if (isSuccess && hasError == false) {
      handleClose(confirm);
      resetVariables();
    } // retain on page
    else {
      setIsSuccess(false);
      setLoading(false);
      setPayNow(false);
    }
  };
  const handlePaynow = () => {
    setPayNow(true);
  };

  const handleUpdateCardDetails = async (success) => {
    console.log("  ResultMessage success", success);
    // add code here call update billing option
    setLoading(true);

    //set up parameter
    let result = "";
    let resultJSON = "";

    let model = {
      PersonnelId: userInfo.Id,
      BillingOption: "Prepay",
      Email: userInfo.Email,
      AccountName: userInfo.FirstName + " " + userInfo.LastName,
      Token: localStorage.getItem("auth_token"),
      IsOverdue: true,
    };

    await AdminService.updatePersonnelBillingOption(model)
      .then((response) => response.json())
      .then((c) => {
        // c.statusCode=20; -- test failed
        if (c.statusCode == 10) {
          // successful
          setHasError(false);
          setIsSuccess(true);
          setMessageToUser("Payment successful.");
        } else {
          // error
          setHasError(true);
          let errMsg = "Error: " + c.error.message; //"testing failed error message.";
          setMessageToUser("Payment failed.");
          setPaymentError(errMsg);
          setIsSuccess(true);
        }
      });

    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        sx={{ textAlign: "center" }}
      >
        {isSuccess ? (
          <>
            <Box sx={{ marginTop: "14px" }}>
              <Box
                sx={{
                  width: "120px",
                  height: "120px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100%",
                  border: `0px solid ${brandColor}`,
                  marginTop: "20px",
                }}
              >
                {hasError ? (
                  <Error sx={{ width: "85px", height: "85px", color: "red" }} />
                ) : (
                  <ThumbUp
                    sx={{ width: "85px", height: "85px", color: brandColor }}
                  />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                fontWeight: "700",
                fontSize: "21px",
                lineHeight: "21px",
                color: "black",
                marginTop: "20px",
              }}
            >
              {messageToUser}
            </Box>

            <Box
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "21px",
                color: "red",
                marginTop: "30px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {hasError ? <>{paymentError}</> : " "}
            </Box>

            <Box
              sx={{
                marginTop: "67px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                sx={[
                  {
                    textTransform: "none",
                    width: "200px",
                    height: "60px",
                    background: brandGray,
                    color: "#fff",
                    marginTop: "10px",
                    marginBottom: "49px",
                  },
                  { "&:hover": { background: brandGray } },
                ]}
                onClick={() => closeDialogPayment(false)}
              >
                Okay
              </Button>
            </Box>
          </>
        ) : (
          <>
            {loading ? (
              <>
                <Box
                  sx={{
                    marginTop: "20px",
                    padding: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <center>
                    <Box
                      sx={{
                        fontWeight: "700",
                        fontSize: "21px",
                        lineHeight: "21px",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {" "}
                      Processing payment. Please wait...
                    </Box>

                    <br></br>
                    <CircularProgress color="success" />
                  </center>
                </Box>
              </>
            ) : (
              <>
                {payNow ? (
                  <>
                    {" "}
                    <Box
                      sx={{
                        marginTop: "20px",
                        padding: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: "700",
                          fontSize: "21px",
                          lineHeight: "21px",
                          color: "black",
                          marginTop: "20px",
                        }}
                      >
                        Payment Information
                      </Box>
                      <br></br>
                      <CardDetails isSuccessful={handleUpdateCardDetails} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ marginTop: "14px" }}>
                      <Box
                        sx={{
                          width: "120px",
                          height: "120px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "100%",
                          border: `0px solid ${brandGray}`,
                          marginTop: "20px",
                        }}
                      >
                        <Error
                          sx={{ width: "85px", height: "85px", color: "red" }}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        fontWeight: "700",
                        fontSize: "21px",
                        lineHeight: "21px",
                        color: "red",
                        marginTop: "20px",
                      }}
                    >
                      Payment Overdue
                    </Box>

                    <Box
                      sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "black",
                        marginTop: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      {" "}
                      {msg}
                    </Box>

                    <Box
                      sx={{
                        marginTop: "67px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {isDentist ? (
                        <>
                          <Button
                            sx={{
                              textTransform: "none",
                              width: "300px",
                              height: "60px",
                              color: "#fff",
                              background: brandColor,
                              "&:hover": { background: brandColor },
                            }}
                            onClick={handlePaynow}
                          >
                            I want to pay now
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}

                      <Button
                        sx={[
                          {
                            textTransform: "none",
                            width: "300px",
                            height: "60px",
                            background: brandGray,
                            color: "#fff",
                            marginTop: "10px",
                            marginBottom: "49px",
                          },
                          { "&:hover": { background: brandGray } },
                        ]}
                        onClick={() => closeDialog(false)}
                      >
                        {isDentist ? <>I will pay later</> : <>Okay</>}
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

export default connect(null, null)(NoAccessPrepayModal);
