import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { CheckCircleIcon } from '@heroicons/react/outline'

const OrderSuccessMessage = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' alignContent='center' textAlign='center' marginTop={5} paddingY={5} >
        <Stack>
            <CheckCircleIcon color='#64B457' style={{height: 65, width: 65}} />
        </Stack>
        <Stack marginY={3}>
            <Typography variant='h4' component='h1' fontSize='24px' fontWeight='600'>Treatment Order Success!</Typography>
        </Stack>
        <Stack>
            <Typography variant='body1' component='p' fontSize='16px' width={350}>
                Your case order is successful. You will receive an email notification when your case is completed or requires approval
            </Typography>
        </Stack>
    </Box>
  )
}

export default OrderSuccessMessage