import { Box } from "@mui/system";
import React from "react";
import { brandBlue } from "../../constants";

const FormOption = ({ selected = false, onClick, children }) => {
  return (
    <Box
      sx={{
        minWidth: "130px",
        maxWidth: "200px",
        cursor: "pointer",
        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
        height: "50px",
        background: selected ? brandBlue : "#FBFBFB",
        color: selected ? "#fff" : "#000",
        borderRadius: "18px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default FormOption;
