import React, { useEffect, useState } from "react";
import {
  IconButton,
  Dialog,
  Box,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import truedentLogo from "../../../images/truedentLogo.png";
import j5Logo from "../../../images/J5Logo.png";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InfoOutlined, ArrowBack } from "@mui/icons-material";
const TruedentInfoModal = ({ isOpen, handleClose, Option, selectArtisan }) => {
  const closeDialog = () => {
    handleClose();
  };
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(0);

  const handleSelectArtisan = () => {
    selectArtisan();
  };

  useEffect(() => {
    setSelectedWorkFlow(0);
  }, []);

  useEffect(() => {
    setSelectedWorkFlow(0);
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      sx={{ textAlign: "center", marginBottom: "15px" }}
      PaperProps={{ style: { padding: "15px" } }}
    >
      
      <DialogTitle sx={{ fontWeight: "bold" , border:"solid 0px red",paddingTop:"0px", paddingBottom:"10px !important", height:"70px" }} id="alert-dialog-title"> 
        <h2 >{Option == 0 ? <>TrueDent™ Denture</> : <>Stratasys J5 DentaJet 3D
              Printer</>}</h2> 
        <IconButton onClick={closeDialog} sx={{marginTop:"-160px", marginLeft:"90%"}}>
          <CloseIcon />
        </IconButton> 
      </DialogTitle>
      <DialogContent>
        {Option === 0 ? (
          <>
            {selectedWorkFlow === 0 && (
              <>
                <Box>
                  <img src={truedentLogo} style={{ width: "90%" }} />
                </Box>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{
                    textAlign: "left",
                    fontSize: "14px",
                    marginTop: "20px",
                  }}
                >
                  <b>Stratasys TrueDent™</b> is a light-curable resin indicated for the
                  fabrication of dental appliances including removable full and
                  partial dentures, denture bases, denture teeth, bridges,
                  crowns, inlays, onlays, and veneers in dental laboratories.
                  The material is an alternative to traditional heat-curable and
                  auto polymerizing resins. Stratasys TrueDent™ is intended
                  exclusively for professional dental work.
                  <br /> To find out more about Statasys TrueDent™ click the
                  link {" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://www.stratasys.com/en/industries-and-applications/3d-printing-applications/dentures/?utm_campaign=dent-truede&utm_medium=referral&utm_source=evident"
                      );
                    }}
                  >
                    (here)
                  </Link>
                  <br /> <br />
                  <Grid container spacing={2} sx={{marginBottom:"30px"}}>
                    <Grid item xs={12}>
                      <b>Learn more about the TrueDent™ Workflows:</b>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                      {" "}
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedWorkFlow(1);
                        }}
                        startIcon={<InfoOutlined />}
                      >
                        TrueDent™ Immediate
                      </Button>{" "}
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedWorkFlow(2);
                        }}
                        startIcon={<InfoOutlined />}
                      >
                        TrueDent™ Copy{" "}
                      </Button>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedWorkFlow(3);
                        }}
                        startIcon={<InfoOutlined />}
                      >
                         TrueDent™ Reference{" "}
                      </Button>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedWorkFlow(4);
                        }}
                        startIcon={<InfoOutlined />}
                      >
                        TrueDent™ Duplicate{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </>
            )}

            {selectedWorkFlow === 1 && (
              <DialogContentText
                id="alert-dialog-description"
                sx={{ textAlign: "left", fontSize: "14px", marginTop: "20px" }}
              >
                <h2>
                  <b>TrueDent™ Immediate Workflow</b>
                </h2>
                <br />
                <Typography sx={{ textAlign: "justify" }}>
                  This workflow is used when terminal dentition will be
                  extracted for a complete prosthesis. Terminal dentition will
                  be used as a reference for incisal edge, midline and labial
                  position unless instructed otherwise.
                </Typography>
                <Typography sx={{ textAlign: "justify" }}>
                <br/> <br/> <b>Clinical:</b>
                  <ul>
                    <li>
                      Scan terminal dentition, soft tissue anatomy and bite scan
                      at desired relationship record.
                    </li>
                    <li>
                      Important clinical variables to understand and communicate
                      to design technician.
                    </li>
                    <ul>
                      <li>Are these traditional extractions?</li>
                      <li>
                        For traditional 1.5mm is reduced from gingival margin.
                      </li>
                      <li>Will any socket augmentation be done?</li>
                      <li>Will any bone grafting take place?</li>
                    </ul>
                    <li>Send photos of patient at rest and high smile</li>
                  </ul>
                </Typography>
              </DialogContentText>
            )}

            {selectedWorkFlow === 2 && (
              <DialogContentText
                id="alert-dialog-description"
                sx={{ textAlign: "left", fontSize: "14px", marginTop: "20px" }}
              >
                <h2>
                  <b>TrueDent™ Copy Workflow</b>
                </h2>
                <br />
                <Typography sx={{ textAlign: "justify" }}>
                  This workflow is used when dentist desires. a copy of existing
                  denture but changes to intaglio from wash impression (tissue
                  condition) or new posterior teeth due to significant wear.
                  Mould or shape and position of teeth will be copied unless
                  instructed otherwise. This workflow can also be used as a
                  traditional denture reline procedure.
                </Typography>
                <Typography sx={{ textAlign: "justify" }}>
                <br/> <br/> <b>Clinical:</b>
                  <ul>
                    <li>
                      Tissue condition denture to capture functional impression
                      then have patient wear for 24-48 hours.
                    </li>
                    <li>
                      {" "}
                      Scan intaglio cameo and bite scan at desired relationship
                      record.{" "}
                    </li>
                    <li>Send photos of patient at rest and high smile.</li>
                  </ul>
                </Typography>
              </DialogContentText>
            )}

            {selectedWorkFlow === 3 && (
              <DialogContentText
                id="alert-dialog-description"
                sx={{ textAlign: "left", fontSize: "14px", marginTop: "20px" }}
              >
                <h2>
                  <b>TrueDent™ Reference Workflow</b>
                </h2>
                <br />
                <Typography sx={{ textAlign: "justify" }}>
                  This workflow is used when a reference such as wax occlusion
                  rim, tooth set-up or existing denture is scanned for records
                  and tooth position.
                 
                  <br />A Reference workflow is used when a patient presents
                  edentulous without a denture or desires extensive changes
                  (esthetic and functional) to existing denture.
                </Typography>
                <Typography sx={{ textAlign: "justify" }}>
                <br/>   <b>Clinical:</b>
                  <ul>
                    <li>
                      If patient presents without dentures, then scan soft
                      tissue anatomy and have lab create a baseplate with
                      occlusion rim for relationship records and functional wash
                      impression. Scan wash impression (light body VPS) intaglio
                      surface, scan cameo and bite scan of occlusion rim at
                      desired relationship record
                    </li>
                    <li>
                      If patient presents with existing denture, determine
                      desires and expectations, then either tissue condition or
                      wash intaglio with VPS followed by scanning intaglio,
                      cameo and bite at desired relationship record.
                    </li>
                    <li>
                      Send photos of patient with existing dentures at rest and
                      high smile.
                    </li>
                  </ul>
                </Typography>
              </DialogContentText>
            )}

            {selectedWorkFlow === 4 && (
              <DialogContentText
                id="alert-dialog-description"
                sx={{ textAlign: "left", fontSize: "14px", marginTop: "20px" }}
              >
                <h2>
                  <b>TrueDent™ Duplicate Workflow</b>
                </h2>
                <br />
                <Typography sx={{ textAlign: "justify" }}>
                  The Duplicate workflow is used when clinician desires an exact
                  duplicate of existing Denture or dentures.
                  <br />
                  <br />
                  This workflow will not have any design changes to Intaglio or
                  cameo (teeth/occlusion) surfaces.
                  <br />
                  <br />
                  Ideal for the patient who does not have a duplicate denture
                  but desires to reproduce existing for a spare denture.
                </Typography>
                <Typography sx={{ textAlign: "justify" }}>
                <br/> <br/> <b>Clinical:</b>
                  <ul>
                    <li>
                      Scan cameo, intaglio, and bite scan in centric occlusion.
                    </li>
                    <li>
                      No modifications will be made with a duplicate denture
                      workflow.
                    </li>
                  </ul>
                </Typography>
              </DialogContentText>
            )}

            {selectedWorkFlow > 0 && (
              <Grid item xs={3} sx={{ textAlign: "left" }}>
               <br/> <br/> <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedWorkFlow(0);
                  }}
                  startIcon={<ArrowBack />}
                >
                  Back
                </Button>
              </Grid>
            )}
          </>
        ) 
        : (
          <>
            <Box>
              <img src={j5Logo} style={{ width: "95%", marginTop:"-20px" }} />
            </Box>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "left", fontSize: "14px", fontWeight: "400" }}
            > 
            <br/> 
              <b>TrueDent™</b> dentures can only be fabricated on a Stratasys J5 DentaJet 3D
              printer. If you do not have access to one, we can automatically send your 
              case to our fabrication partner, Artisan Dental Laboratory for a seamless workflow
              <br />
              <Box sx={{ textAlign: "center", padding: "10px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="brand"
                  sx={{ margin: "10px" }}
                  onClick={closeDialog}
                >
                  I have a J5 DentaJet Printer
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="brand"
                  sx={{ margin: "10px" }}
                  onClick={handleSelectArtisan}
                >
                  Automatically send to Artisan
                </Button>
              </Box>
              <br />
              <br />
              <br />
            </DialogContentText>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TruedentInfoModal;
