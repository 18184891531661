import { Box, Button } from "@mui/material";
import React from "react";

const ActionButton = ({ children, color = "brand", textProps, ...props }) => {
  return (
    <Button
      variant="contained"
      color={color}
      disableElevation
      sx={{
        minWidth: "140px",
        height: "38px",
        paddingY: 0.5,
        paddingX: 2,
        borderRadius: "30px",
        whiteSpace: "nowrap",
        fontWeight: "500",
        lineHeight: "14px",
      }}
      {...props}
    >
      <Box component="span" {...textProps}>
        {children}
      </Box>
    </Button>
  );
};

export default ActionButton;
