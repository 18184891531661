import React from "react";
import { Box } from "@mui/system";
import { Checkbox, MenuItem, TextField } from "@mui/material";

export default function SelectListComponent(props) {
  const { req, handleRequirementParentSelectValue } = props;

  React.useEffect(() => {
    console.log(req);
  }, []);

  return (
    <Box sx={{}}>
      <TextField
        autoFocus
        size={"medium"}
        select
        value={req.value === null ? "" : req.value}
        variant="outlined"
        onChange={(e) =>
          handleRequirementParentSelectValue(
            req.description,
            e.target.value,
            req.itemList
          )
        }
        sx={{
          backgroundColor: "#fff",
          width: "50%",
          marginRight: "15px",
        }}
      >
        {req?.itemList.map((item, cIndex) => (
          <MenuItem key={cIndex} value={item.item} sx={{whiteSpace: "unset",
          wordBreak: "break-all"}}>
            {item.item}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
