import {
  Dialog,
  DialogContent,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import DragAndDropImage from "../../images/drag-and-drop.svg";
import { useDropzone } from "react-dropzone";
import Check from "@mui/icons-material/Check";
import LargeButton from "../../components/common/button/largeButton";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";

const RejectDialog = ({
  open,
  onClose,
  onSubmit,
  title = "Design Rejected",
  placeholder = "Provide notes for Rejected Design (REQUIRED)",
}) => {
  const [note, setNote] = useState("");
  const [validateNote, setValidateNote] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    setNote();
  };

  const handleSubmit = async () => {
    if (note === "" || note === undefined) {
      setValidateNote(true);
    } else {
      await onSubmit(note);
      dispatch(
        openSnackbar({
          title: "Success",
          body: "Redesign request sent to designer.",
        })
      );
      setNote("");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          paddingY: 4,
          paddingX: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <DialogContent>
        <Stack alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography
            component="h2"
            variant="h6"
            sx={{ marginTop: 2, fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <Typography>{/* No content */}</Typography>

          <TextField
            error={validateNote}
            helperText={validateNote ? "Notes is Required" : ""}
            required
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
              setValidateNote(false);
            }}
            multiline
            rows={5}
            placeholder={placeholder}
            fullWidth
            sx={{ paddingTop: 2 }}
          />

          <Stack direction="row" spacing={2} sx={{ paddingTop: 2 }}>
            <LargeButton variant="gray" fullWidth onClick={handleClose}>
              Cancel
            </LargeButton>
            <LargeButton variant="primary" fullWidth onClick={handleSubmit}>
              Send to Designer
            </LargeButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RejectDialog;
