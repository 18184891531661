import React from "react";
import { connect } from "react-redux";
import Dialog from '@mui/material/Dialog';
import { initUser } from "../../redux/actions/index";
import { Formik } from "formik";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { IconButton, Button, MenuItem, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import LinearProgress from "@mui/material/LinearProgress";
import AccountService from "../../services/account.service";
import AdminService from "../../services/admin.service";
import { REGISTER } from "redux-persist";
import ToothChartService from "../../services/toothChart.service";
import Close from "@mui/icons-material/Close";

function mapDispatchToProps(dispatch) {
    return {
        initUser: user => dispatch(initUser(user))
    };
}

function SignUpModal({isOpen, handleClose, initUser}) {
    const [age, setAge] = React.useState('');
    const [isLogin, setIsLogin] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    
    const [position,setPosition] = React.useState([]);
    const learnAboutUsOptions = [
        "Google",
        "Facebook",
        "Instagram",
        "Linkedin",
        "Webinar",
        "Youtube",
        "Newsletter",
        "Blog",
        "Email",
        "Referral",
        "Kois Center",
        "Other"
    ];

    const countryList = [
        {
          "Code": "CA",
          "Name": "Canada"
        },
        {
          "Code": "US",
          "Name": "United States"
        },
        {
          "Code": "AU",
          "Name": "Australia"
        },
        {
          "Code": "UK",
          "Name": "United Kingdom"
        },
        {
          "Code": "FR",
          "Name": "France"
        },
        {
          "Code": "PH",
          "Name": "Philippines"
        },
        {
          "Code": "KR",
          "Name": "South Korea"
        },
        {
          "Code": "AF",
          "Name": "Afghanistan"
        },
        {
          "Code": "AX",
          "Name": "\u00c5land Islands"
        },
        {
          "Code": "AL",
          "Name": "Albania"
        },
        {
          "Code": "DZ",
          "Name": "Algeria"
        },
        {
          "Code": "AS",
          "Name": "American Samoa"
        },
        {
          "Code": "AD",
          "Name": "Andorra"
        },
        {
          "Code": "AO",
          "Name": "Angola"
        },
        {
          "Code": "AI",
          "Name": "Anguilla"
        },
        {
          "Code": "AQ",
          "Name": "Antarctica"
        },
        {
          "Code": "AG",
          "Name": "Antigua and Barbuda"
        },
        {
          "Code": "AR",
          "Name": "Argentina"
        },
        {
          "Code": "AM",
          "Name": "Armenia"
        },
        {
          "Code": "AW",
          "Name": "Aruba"
        },
        {
          "Code": "AT",
          "Name": "Austria"
        },
        {
          "Code": "AZ",
          "Name": "Azerbaijan"
        },
        {
          "Code": "BS",
          "Name": "Bahamas"
        },
        {
          "Code": "BH",
          "Name": "Bahrain"
        },
        {
          "Code": "BD",
          "Name": "Bangladesh"
        },
        {
          "Code": "BB",
          "Name": "Barbados"
        },
        {
          "Code": "BY",
          "Name": "Belarus"
        },
        {
          "Code": "BE",
          "Name": "Belgium"
        },
        {
          "Code": "BZ",
          "Name": "Belize"
        },
        {
          "Code": "BJ",
          "Name": "Benin"
        },
        {
          "Code": "BM",
          "Name": "Bermuda"
        },
        {
          "Code": "BT",
          "Name": "Bhutan"
        },
        {
          "Code": "BO",
          "Name": "Bolivia, Plurinational State of"
        },
        {
          "Code": "BQ",
          "Name": "Bonaire, Sint Eustatius and Saba"
        },
        {
          "Code": "BA",
          "Name": "Bosnia and Herzegovina"
        },
        {
          "Code": "BW",
          "Name": "Botswana"
        },
        {
          "Code": "BV",
          "Name": "Bouvet Island"
        },
        {
          "Code": "BR",
          "Name": "Brazil"
        },
        {
          "Code": "IO",
          "Name": "British Indian Ocean Territory"
        },
        {
          "Code": "BN",
          "Name": "Brunei Darussalam"
        },
        {
          "Code": "BG",
          "Name": "Bulgaria"
        },
        {
          "Code": "BF",
          "Name": "Burkina Faso"
        },
        {
          "Code": "BI",
          "Name": "Burundi"
        },
        {
          "Code": "KH",
          "Name": "Cambodia"
        },
        {
          "Code": "CM",
          "Name": "Cameroon"
        },
      
        {
          "Code": "CV",
          "Name": "Cape Verde"
        },
        {
          "Code": "KY",
          "Name": "Cayman Islands"
        },
        {
          "Code": "CF",
          "Name": "Central African Republic"
        },
        {
          "Code": "TD",
          "Name": "Chad"
        },
        {
          "Code": "CL",
          "Name": "Chile"
        },
        {
          "Code": "CN",
          "Name": "China"
        },
        {
          "Code": "CX",
          "Name": "Christmas Island"
        },
        {
          "Code": "CC",
          "Name": "Cocos (Keeling) Islands"
        },
        {
          "Code": "CO",
          "Name": "Colombia"
        },
        {
          "Code": "KM",
          "Name": "Comoros"
        },
        {
          "Code": "CG",
          "Name": "Congo"
        },
        {
          "Code": "CD",
          "Name": "Congo, the Democratic Republic of the"
        },
        {
          "Code": "CK",
          "Name": "Cook Islands"
        },
        {
          "Code": "CR",
          "Name": "Costa Rica"
        },
        {
          "Code": "CI",
          "Name": "C\u00f4te d'Ivoire"
        },
        {
          "Code": "HR",
          "Name": "Croatia"
        },
        {
          "Code": "CU",
          "Name": "Cuba"
        },
        {
          "Code": "CW",
          "Name": "Cura\u00e7ao"
        },
        {
          "Code": "CY",
          "Name": "Cyprus"
        },
        {
          "Code": "CZ",
          "Name": "Czech Republic"
        },
        {
          "Code": "DK",
          "Name": "Denmark"
        },
        {
          "Code": "DJ",
          "Name": "Djibouti"
        },
        {
          "Code": "DM",
          "Name": "Dominica"
        },
        {
          "Code": "DO",
          "Name": "Dominican Republic"
        },
        {
          "Code": "EC",
          "Name": "Ecuador"
        },
        {
          "Code": "EG",
          "Name": "Egypt"
        },
        {
          "Code": "SV",
          "Name": "El Salvador"
        },
        {
          "Code": "GQ",
          "Name": "Equatorial Guinea"
        },
        {
          "Code": "ER",
          "Name": "Eritrea"
        },
        {
          "Code": "EE",
          "Name": "Estonia"
        },
        {
          "Code": "ET",
          "Name": "Ethiopia"
        },
        {
          "Code": "FK",
          "Name": "Falkland Islands (Malvinas)"
        },
        {
          "Code": "FO",
          "Name": "Faroe Islands"
        },
        {
          "Code": "FJ",
          "Name": "Fiji"
        },
        {
          "Code": "FI",
          "Name": "Finland"
        },
        {
          "Code": "GF",
          "Name": "French Guiana"
        },
        {
          "Code": "PF",
          "Name": "French Polynesia"
        },
        {
          "Code": "TF",
          "Name": "French Southern Territories"
        },
        {
          "Code": "GA",
          "Name": "Gabon"
        },
        {
          "Code": "GM",
          "Name": "Gambia"
        },
        {
          "Code": "GE",
          "Name": "Georgia"
        },
        {
          "Code": "DE",
          "Name": "Germany"
        },
        {
          "Code": "GH",
          "Name": "Ghana"
        },
        {
          "Code": "GI",
          "Name": "Gibraltar"
        },
        {
          "Code": "GR",
          "Name": "Greece"
        },
        {
          "Code": "GL",
          "Name": "Greenland"
        },
        {
          "Code": "GD",
          "Name": "Grenada"
        },
        {
          "Code": "GP",
          "Name": "Guadeloupe"
        },
        {
          "Code": "GU",
          "Name": "Guam"
        },
        {
          "Code": "GT",
          "Name": "Guatemala"
        },
        {
          "Code": "GG",
          "Name": "Guernsey"
        },
        {
          "Code": "GN",
          "Name": "Guinea"
        },
        {
          "Code": "GW",
          "Name": "Guinea-Bissau"
        },
        {
          "Code": "GY",
          "Name": "Guyana"
        },
        {
          "Code": "HT",
          "Name": "Haiti"
        },
        {
          "Code": "HM",
          "Name": "Heard Island and McDonald Islands"
        },
        {
          "Code": "VA",
          "Name": "Holy See (Vatican City State)"
        },
        {
          "Code": "HN",
          "Name": "Honduras"
        },
        {
          "Code": "HK",
          "Name": "Hong Kong"
        },
        {
          "Code": "HU",
          "Name": "Hungary"
        },
        {
          "Code": "IS",
          "Name": "Iceland"
        },
        {
          "Code": "IN",
          "Name": "India"
        },
        {
          "Code": "ID",
          "Name": "Indonesia"
        },
        {
          "Code": "IR",
          "Name": "Iran, Islamic Republic of"
        },
        {
          "Code": "IQ",
          "Name": "Iraq"
        },
        {
          "Code": "IE",
          "Name": "Ireland"
        },
        {
          "Code": "IM",
          "Name": "Isle of Man"
        },
        {
          "Code": "IL",
          "Name": "Israel"
        },
        {
          "Code": "IT",
          "Name": "Italy"
        },
        {
          "Code": "JM",
          "Name": "Jamaica"
        },
        {
          "Code": "JP",
          "Name": "Japan"
        },
        {
          "Code": "JE",
          "Name": "Jersey"
        },
        {
          "Code": "JO",
          "Name": "Jordan"
        },
        {
          "Code": "KZ",
          "Name": "Kazakhstan"
        },
        {
          "Code": "KE",
          "Name": "Kenya"
        },
        {
          "Code": "KI",
          "Name": "Kiribati"
        },
        {
          "Code": "KP",
          "Name": "Korea, Democratic People's Republic of"
        },
        {
          "Code": "KW",
          "Name": "Kuwait"
        },
        {
          "Code": "KG",
          "Name": "Kyrgyzstan"
        },
        {
          "Code": "LA",
          "Name": "Lao People's Democratic Republic"
        },
        {
          "Code": "LV",
          "Name": "Latvia"
        },
        {
          "Code": "LB",
          "Name": "Lebanon"
        },
        {
          "Code": "LS",
          "Name": "Lesotho"
        },
        {
          "Code": "LR",
          "Name": "Liberia"
        },
        {
          "Code": "LY",
          "Name": "Libya"
        },
        {
          "Code": "LI",
          "Name": "Liechtenstein"
        },
        {
          "Code": "LT",
          "Name": "Lithuania"
        },
        {
          "Code": "LU",
          "Name": "Luxembourg"
        },
        {
          "Code": "MO",
          "Name": "Macao"
        },
        {
          "Code": "MK",
          "Name": "Macedonia, the Former Yugoslav Republic of"
        },
        {
          "Code": "MG",
          "Name": "Madagascar"
        },
        {
          "Code": "MW",
          "Name": "Malawi"
        },
        {
          "Code": "MY",
          "Name": "Malaysia"
        },
        {
          "Code": "MV",
          "Name": "Maldives"
        },
        {
          "Code": "ML",
          "Name": "Mali"
        },
        {
          "Code": "MT",
          "Name": "Malta"
        },
        {
          "Code": "MH",
          "Name": "Marshall Islands"
        },
        {
          "Code": "MQ",
          "Name": "Martinique"
        },
        {
          "Code": "MR",
          "Name": "Mauritania"
        },
        {
          "Code": "MU",
          "Name": "Mauritius"
        },
        {
          "Code": "YT",
          "Name": "Mayotte"
        },
        {
          "Code": "MX",
          "Name": "Mexico"
        },
        {
          "Code": "FM",
          "Name": "Micronesia, Federated States of"
        },
        {
          "Code": "MD",
          "Name": "Moldova, Republic of"
        },
        {
          "Code": "MC",
          "Name": "Monaco"
        },
        {
          "Code": "MN",
          "Name": "Mongolia"
        },
        {
          "Code": "ME",
          "Name": "Montenegro"
        },
        {
          "Code": "MS",
          "Name": "Montserrat"
        },
        {
          "Code": "MA",
          "Name": "Morocco"
        },
        {
          "Code": "MZ",
          "Name": "Mozambique"
        },
        {
          "Code": "MM",
          "Name": "Myanmar"
        },
        {
          "Code": "NA",
          "Name": "Namibia"
        },
        {
          "Code": "NR",
          "Name": "Nauru"
        },
        {
          "Code": "NP",
          "Name": "Nepal"
        },
        {
          "Code": "NL",
          "Name": "Netherlands"
        },
        {
          "Code": "NC",
          "Name": "New Caledonia"
        },
        {
          "Code": "NZ",
          "Name": "New Zealand"
        },
        {
          "Code": "NI",
          "Name": "Nicaragua"
        },
        {
          "Code": "NE",
          "Name": "Niger"
        },
        {
          "Code": "NG",
          "Name": "Nigeria"
        },
        {
          "Code": "NU",
          "Name": "Niue"
        },
        {
          "Code": "NF",
          "Name": "Norfolk Island"
        },
        {
          "Code": "MP",
          "Name": "Northern Mariana Islands"
        },
        {
          "Code": "NO",
          "Name": "Norway"
        },
        {
          "Code": "OM",
          "Name": "Oman"
        },
        {
          "Code": "PK",
          "Name": "Pakistan"
        },
        {
          "Code": "PW",
          "Name": "Palau"
        },
        {
          "Code": "PS",
          "Name": "Palestine, State of"
        },
        {
          "Code": "PA",
          "Name": "Panama"
        },
        {
          "Code": "PG",
          "Name": "Papua New Guinea"
        },
        {
          "Code": "PY",
          "Name": "Paraguay"
        },
        {
          "Code": "PE",
          "Name": "Peru"
        },
        {
          "Code": "PN",
          "Name": "Pitcairn"
        },
        {
          "Code": "PL",
          "Name": "Poland"
        },
        {
          "Code": "PT",
          "Name": "Portugal"
        },
        {
          "Code": "PR",
          "Name": "Puerto Rico"
        },
        {
          "Code": "QA",
          "Name": "Qatar"
        },
        {
          "Code": "RE",
          "Name": "R\u00e9union"
        },
        {
          "Code": "RO",
          "Name": "Romania"
        },
        {
          "Code": "RU",
          "Name": "Russian Federation"
        },
        {
          "Code": "RW",
          "Name": "Rwanda"
        },
        {
          "Code": "BL",
          "Name": "Saint Barth\u00e9lemy"
        },
        {
          "Code": "SH",
          "Name": "Saint Helena, Ascension and Tristan da Cunha"
        },
        {
          "Code": "KN",
          "Name": "Saint Kitts and Nevis"
        },
        {
          "Code": "LC",
          "Name": "Saint Lucia"
        },
        {
          "Code": "MF",
          "Name": "Saint Martin (French part)"
        },
        {
          "Code": "PM",
          "Name": "Saint Pierre and Miquelon"
        },
        {
          "Code": "VC",
          "Name": "Saint Vincent and the Grenadines"
        },
        {
          "Code": "WS",
          "Name": "Samoa"
        },
        {
          "Code": "SM",
          "Name": "San Marino"
        },
        {
          "Code": "ST",
          "Name": "Sao Tome and Principe"
        },
        {
          "Code": "SA",
          "Name": "Saudi Arabia"
        },
        {
          "Code": "SN",
          "Name": "Senegal"
        },
        {
          "Code": "RS",
          "Name": "Serbia"
        },
        {
          "Code": "SC",
          "Name": "Seychelles"
        },
        {
          "Code": "SL",
          "Name": "Sierra Leone"
        },
        {
          "Code": "SG",
          "Name": "Singapore"
        },
        {
          "Code": "SX",
          "Name": "Sint Maarten (Dutch part)"
        },
        {
          "Code": "SK",
          "Name": "Slovakia"
        },
        {
          "Code": "SI",
          "Name": "Slovenia"
        },
        {
          "Code": "SB",
          "Name": "Solomon Islands"
        },
        {
          "Code": "SO",
          "Name": "Somalia"
        },
        {
          "Code": "ZA",
          "Name": "South Africa"
        },
        {
          "Code": "GS",
          "Name": "South Georgia and the South Sandwich Islands"
        },
        {
          "Code": "SS",
          "Name": "South Sudan"
        },
        {
          "Code": "ES",
          "Name": "Spain"
        },
        {
          "Code": "LK",
          "Name": "Sri Lanka"
        },
        {
          "Code": "SD",
          "Name": "Sudan"
        },
        {
          "Code": "SR",
          "Name": "Suriname"
        },
        {
          "Code": "SJ",
          "Name": "Svalbard and Jan Mayen"
        },
        {
          "Code": "SZ",
          "Name": "Swaziland"
        },
        {
          "Code": "SE",
          "Name": "Sweden"
        },
        {
          "Code": "CH",
          "Name": "Switzerland"
        },
        {
          "Code": "SY",
          "Name": "Syrian Arab Republic"
        },
        {
          "Code": "TW",
          "Name": "Taiwan, Province of China"
        },
        {
          "Code": "TJ",
          "Name": "Tajikistan"
        },
        {
          "Code": "TZ",
          "Name": "Tanzania, United Republic of"
        },
        {
          "Code": "TH",
          "Name": "Thailand"
        },
        {
          "Code": "TL",
          "Name": "Timor-Leste"
        },
        {
          "Code": "TG",
          "Name": "Togo"
        },
        {
          "Code": "TK",
          "Name": "Tokelau"
        },
        {
          "Code": "TO",
          "Name": "Tonga"
        },
        {
          "Code": "TT",
          "Name": "Trinidad and Tobago"
        },
        {
          "Code": "TN",
          "Name": "Tunisia"
        },
        {
          "Code": "TR",
          "Name": "Turkey"
        },
        {
          "Code": "TM",
          "Name": "Turkmenistan"
        },
        {
          "Code": "TC",
          "Name": "Turks and Caicos Islands"
        },
        {
          "Code": "TV",
          "Name": "Tuvalu"
        },
        {
          "Code": "UG",
          "Name": "Uganda"
        },
        {
          "Code": "UA",
          "Name": "Ukraine"
        },
        {
          "Code": "AE",
          "Name": "United Arab Emirates"
        },
        {
          "Code": "UM",
          "Name": "United States Minor Outlying Islands"
        },
        {
          "Code": "UY",
          "Name": "Uruguay"
        },
        {
          "Code": "UZ",
          "Name": "Uzbekistan"
        },
        {
          "Code": "VU",
          "Name": "Vanuatu"
        },
        {
          "Code": "VE",
          "Name": "Venezuela, Bolivarian Republic of"
        },
        {
          "Code": "VN",
          "Name": "Viet Nam"
        },
        {
          "Code": "VG",
          "Name": "Virgin Islands, British"
        },
        {
          "Code": "VI",
          "Name": "Virgin Islands, U.S."
        },
        {
          "Code": "WF",
          "Name": "Wallis and Futuna"
        },
        {
          "Code": "EH",
          "Name": "Western Sahara"
        },
        {
          "Code": "YE",
          "Name": "Yemen"
        },
        {
          "Code": "ZM",
          "Name": "Zambia"
        },
        {
          "Code": "ZW",
          "Name": "Zimbabwe"
        }
      ];

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    
    const listRoles = ['Dentist', 'Dental Hygienist', 'Practice Administrator'];
    const iosVendors = ["Medit", "3DISC Heron", "3Shape TRIOS", "iTero", "Sirona CEREC", "Carestream", "3M", "VeriPulse", "Other"];
    const cbctVendors = ["Carestream", "Planmeca", "Kavo - iCAT", "Sirona", "Vatech", "Acteon", "NewTom", "Other"];
    const printerVendors = ["Carbon", "SprintRay", "Formlabs", "Asiga", "EnvisionTec", "VeriEko", "Ackuretta", "Other"];
    const chairsideMillVendor = ["Dentsply Sirona", "VHF", "Amman Girrbach", "CORiTEC", "Asiga", "Planmeca", "Other"];

    const specialtyList =["Family Dentistry", "Pediatric Dentistry", "Orthodontics Aligners", "Implants", "Prostodontics"]
    const equipmentList =["Intra Oral Scanner", "CBT Scanner", "3D Printer", "Chairside Mill"]
    
    const [accountModel, setAccountModel] = React.useState({
        "password": "",
        "learnAboutUs": "",
        "learnAboutUsOthers": "",
        "personnel": {
            "FirstName": "",
            "Email": "",
            "LastName": "",
            "PositionId": "",
            "Role": "",
            "UtmSource": "",
            "UtmMedium": "",
            "UtmCampaign": ""
        },
        "practices": [{
            "Email": "",
            "Name": "",
            "Phone": "",
            "Specialty": {
                "familyDentistry": false,
                "pediatricDentistry": false,
                "orthodonticsAligners": false,
                "implants": false,
                "prosthodontics": false
            },
            "Address": {
                "Line1": "",
                "Line2": "",
                "City": "",
                "State": "",
                "Country": "",
                "Postal": ""
            },
            "Equipment": {
                "hasIos": false,
                "hasCbct": false,
                "hasPrinter": false,
                "hasChairsideMill": false,
                "Ios": "",
                "Cbct": "",
                "Printer": "",
                "ChairsideMill": ""
            }
        }],
        "PersonnelInviteId": "00000000-0000-0000-0000-000000000000"
        
    })

    const {practices} = accountModel


    const closeDialog = (close) => {
        handleClose(close);
    }

    React.useEffect(() => {
      AdminService.getPosition()
      .then(response => response.json())
      .then(json => { 
        console.log("jsonPosition",json);
        setPosition(json);
        console.log("position",position);
      })
    },[])

    return (
        <Dialog open={isOpen} maxWidth="md" fullWidth sx={{textAlign: 'center'}}>

            {isLogin? (
                <Box sx={{ width: "100%", paddingTop:"1px" }}>
                        <LinearProgress color="success" />
                </Box>
            ) : (
                ""
                )}
            <Box sx={{textAlign:'right', marginRight: '25px', marginTop: '35px'}}>
                <IconButton onClick={()=>closeDialog(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{fontSize: "28px", lineHeight: '41px', color: '#000'}}><b>Sign-up</b> to have a full access</Box>
            <Box sx={{fontWeight: '300', fontSize: '18px', lineHeight: '26px', marginBottom: '48px', color: '#585656'}}>Track and manage your orders. Learn more about the product you purchased.</Box>
            {errorMessage !== "" ? (
                <Box sx={{ textAlign: "center", fontSize: "20px", color: "red", marginBottom: "20px" }}>
                    {errorMessage}                    
                </Box>
                ) : (
                ""
                )}
            <Formik
                initialValues={{
                  email: "", 
                  password: "", 
                  firstName: "", 
                  lastName:"", 
                  role:"", 
                  IntraOralScanner:false, 
                  CBTScanner:false, 
                  '3DPrinter':false, 
                  ChairsideMill:false ,
                  practiceName: ""
                }}
                validate={values => {
                    const errors = {};
                    console.log("values",values);
                    if (!values.firstName) {
                        errors.firstName = "First name is required."
                    }

                    if (!values.lastName) {
                        errors.lastName = "Last name is required."
                    }

                    if (!values.role) {
                        errors.role = "Role is required."
                    }

                    if (!values.email) {
                        errors.email = 'Emaill address is required.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    
                    if (!values.password) {
                        errors.password = "Password is required."
                    }

                    if(values.password !== values.confirmPassword)
                    {
                        errors.confirmPassword = "Password must match."
                    }
                    
                    if(!values.practiceName)
                    {
                        errors.practiceName = "Practice Name is required."
                    }
                    if(!values.addressLine1)
                    {
                        errors.addressLine1 = "Address line 1 is required"
                    }

                    if(!values.city)
                    {
                        errors.city = "City is required."
                    }

                    if (!values.learnAboutUs) {
                        errors.learnAboutUs = "How did you hear about us is required."
                    }

                    if(!values.country)
                    {
                        errors.country = "Country is required."
                    }

                    if(!values.state)
                    {
                        errors.state = "State/Province is required."
                    }

                    if(!values.phone)
                    {
                        errors.phone = "Phone is required."
                    }

                    

                    console.log("errors",errors);
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setIsLogin(true);
                    setErrorMessage("");
                    AccountService.validateEmail(values.email)
                    .then(response => response.json())
                    .then(json => {                        
                        if(json.error === null)
                        {
                            var positionId = position.find(x=> x.description === values.role);
                            console.log("values.role",values.role);
                            console.log("positionId",positionId);
                            if(positionId)
                            {
                              accountModel.personnel.PositionId = positionId.id;
                            }
                            else
                            {
                             accountModel.personnel.PositionId = position.find(x=> x.description === "Dentist").id;
                            }
                            
                            accountModel.password = values.password;
                            accountModel.learnAboutUs = values.learnAboutUs;
                            accountModel.personnel.Email = values.email;
                            accountModel.personnel.FirstName = values.firstName;
                            accountModel.personnel.LastName = values.lastName;
                            accountModel.personnel.Role = values.role;
                            
                            accountModel.practices[0].Email = values.email;
                            accountModel.practices[0].Name = values.practiceName;
                            accountModel.practices[0].Phone = values.phone;                            
                            accountModel.practices[0].Address.Line1 = values.addressLine1;
                            accountModel.practices[0].Address.City = values.city;
                            accountModel.practices[0].Address.Country = values.country;
                            accountModel.practices[0].Address.Line2 = values.addressLine2;
                            accountModel.practices[0].Address.Postal = values.postal;
                            accountModel.practices[0].Address.State = values.state;
                            accountModel.practices[0].Specialty = JSON.stringify(accountModel.practices[0].Specialty);
                            accountModel.practices[0].Equipment = JSON.stringify(accountModel.practices[0].Equipment);
                            AccountService.register(accountModel)
                            .then(response => response.json())
                            .then(json => {                   
                                AccountService.login(values.email, values.password)
                                .then(response => response.json())
                                .then(json => {
                                    console.log("json",json);
                                    let loginResult = json.data;
                                    
                                    localStorage.setItem("auth_token", loginResult.jwt.auth_token);
                                    
                                    let userInfo = {
                                        Id: loginResult.personnel.id,
                                        LoginId: loginResult.jwt.loginId,
                                        FirstName: loginResult.personnel.firstName,
                                        LastName: loginResult.personnel.lastName,
                                        Roles: loginResult.jwt.roles,
                                        Email: loginResult.personnel.email,
                                        PersonnelAttributes: loginResult.personnel.attributes
                                    };
                                    // var myObject = JSON.parse(loginResult.personnel.attributes);                                  
                                    // if(myObject.DentalNotationUserSetting != null)
                                    // {
                                    //     ToothChartService.setDentalNotationSystem(myObject.DentalNotationUserSetting);
                                    // }
                                    initUser(userInfo);
                                    setIsLogin(false);
                                    closeDialog(true);

                                })
                                .catch(err =>
                                    {
                                        console.log("err",err);
                                        setErrorMessage("Login failed. Please try again.");                                
                                        setIsLogin(false);
                                    }
                                )
                                setIsLogin(false);
                            })
                            .catch(err =>
                                {
                                    //setErrorMessage = "E-mail already exists.";
                                    console.log("err",err);
                                    setIsLogin(false);
                                }
                            )
                            
                        }
                        else
                        {
                            setErrorMessage("There is already an account associated with the email address");
                            setIsLogin(false);
                            console.log("errorMessage");
                        }
                        
                        
                    })
                    .catch(err =>
                        {
                            setErrorMessage("E-mail already exists.");
                            console.log("err",err);
                            setIsLogin(false);
                        }
                    )
                

                }}
                  >
                
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{width: '85%', margin:'0 auto'}}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="firstName"                                     
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        label="First name" 
                                        variant="outlined" />

                                    <Box sx={{color: 'red'}}>
                                    {errors.firstName && touched.firstName && errors.firstName}
                                    </Box>
                                    </Box>  
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="lastName"                                     
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        label="Last name" 
                                        variant="outlined" />

                                    <Box sx={{color: 'red'}}>
                                    {errors.lastName && touched.lastName && errors.lastName}
                                    </Box>                           
                                    </Box>  
                                </Grid>
                            </Grid>
                            

                            
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="email" 
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        label="Email Address" 
                                        variant="outlined" />

                                    <Box sx={{color: 'red'}}>
                                    {errors.email && touched.email && errors.email}
                                    </Box>                           
                                    </Box> 
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        select
                                        id="role"                                     
                                        name="role"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.role}
                                        label="Role" 
                                        variant="outlined">
                                        {listRoles.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Box sx={{color: 'red'}}>
                                    {errors.role && touched.role && errors.role}
                                    </Box>                           
                                    </Box> 
                                </Grid>
                            </Grid>
                             
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="password" 
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        label="Password" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.password && touched.password && errors.password}
                                    </Box>                                
                                    </Box>  
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="confirmPassword" 
                                        type="password"
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        label="Confirm Password" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword} 
                                    </Box>                                
                                    </Box> 
                                </Grid>
                            </Grid>
                                  
                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        select
                                        id="learnAboutUs"                                     
                                        name="learnAboutUs"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.learnAboutUs}
                                        label="How did you hear about us?" 
                                        variant="outlined">
                                        {learnAboutUsOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Box sx={{color: 'red'}}>
                                    {errors.learnAboutUs && touched.learnAboutUs && errors.learnAboutUs}
                                    </Box>                           
                            </Box> 
                             
                             {/* TODO: Multiple Practice// Add Practice */}
                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="practiceName"
                                        name="practiceName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.practiceName}
                                        label="Practice Name" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.practiceName && touched.practiceName && errors.practiceName}
                                    </Box>                                
                            </Box>  

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="addressLine1"                                         
                                        name="addressLine1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressLine1}
                                        label="Practice address line 1" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.addressLine1 && touched.addressLine1 && errors.addressLine1}
                                    </Box>                                
                            </Box>  

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="addressLine2"                                         
                                        name="addressLine2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressLine2}
                                        label="Practice address line 2 (optional)" 
                                        variant="outlined" />
                                                                                        
                            </Box>  

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="city"                                         
                                        name="city"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city}
                                        label="City" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.city && touched.city && errors.city}
                                    </Box>                                
                            </Box>  

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        select
                                        id="country"                                     
                                        name="country"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.country}
                                        label="Country" 
                                        variant="outlined">
                                        {countryList.map((option) => (
                                            <MenuItem key={option.Code} value={option.Code}>
                                                {option.Name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Box sx={{color: 'red'}}>
                                    {errors.country && touched.country && errors.country}
                                    </Box>                           
                            </Box> 

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="state"                                         
                                        name="state"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.state}
                                        label="State/Province" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.state && touched.state && errors.state }
                                    </Box>                                
                            </Box>  

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="postal"                                         
                                        name="postal"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.postal}
                                        label="Zip/Postal" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.postal && touched.postal && errors.postal }
                                    </Box>                                
                            </Box>  

                            <Box sx={{marginBottom: '15px'}}>
                                    <TextField 
                                        sx={{width: '100%', marginBottom: '15px'}}
                                        id="phone"                                         
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        label="Phone Number" 
                                        variant="outlined" />
                                    
                                    <Box sx={{color: 'red'}}>
                                        {errors.phone && touched.phone && errors.phone }
                                    </Box>                                
                            </Box>  

                            {/* <Grid container spacing={1}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <Box sx={{fontWeight: '300', fontSize: '18px', lineHeight: '26px', marginBottom: '48px', color: '#585656'}}>Select all fields that apply to your practice.</Box>
                                    { specialtyList.map((spec) => (
                                            <FormGroup>
                                                <FormControlLabel sx={{fontWeight: '300', fontSize: '18px', lineHeight: '26px', color: '#585656', textAlign:'center'}} key={spec._idx} control={<Checkbox />} label={spec} />
                                            </FormGroup>
                                    ))}
                                                
                                    </Box>  
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Box sx={{marginBottom: '15px'}}>
                                    <Box sx={{fontWeight: '300', fontSize: '18px', lineHeight: '26px', marginBottom: '48px', color: '#585656'}}>Select primary equipment that your practice owns.</Box>
                                    { equipmentList.map((equipment) => (
                                        <Grid container spacing={2} sx={{padding: '16px 0'}} >
                                            <Grid item xs={12} md={6}>
                                            <FormGroup>
                                                <FormControlLabel sx={{fontWeight: '300', fontSize: '18px', lineHeight: '26px', color: '#585656', textAlign:'center'}} key={equipment._idx} control={<Checkbox onChange={handleChange} id={equipment._idx} name={equipment.replace(/\s/g, '')}/>} label={equipment} />
                                            </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {values.IntraOralScanner && equipment === "Intra Oral Scanner" && 
                                                <TextField 
                                                    sx={{width: '100%'}}
                                                    select
                                                    id="ios"                                     
                                                    name="ios"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} 
                                                    value={values.ios}
                                                    label="Intra Oral Scanner" 
                                                    variant="outlined">
                                                    {iosVendors.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> 
                                                }
                                                {values.CBTScanner && equipment === 'CBT Scanner' && 
                                                <TextField 
                                                    sx={{width: '100%'}}
                                                    select
                                                    id="cbct"                                     
                                                    name="cbct"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cbct}
                                                    label="CBT Scanner" 
                                                    variant="outlined">
                                                    {cbctVendors.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> 
                                                }
                                                {values["3DPrinter"] && equipment === '3D Printer' && 
                                                <TextField 
                                                    sx={{width: '100%'}}
                                                    select
                                                    id="printer"                                     
                                                    name="printer"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cbct}
                                                    label="3D Printer" 
                                                    variant="outlined">
                                                    {printerVendors.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> 
                                                }
                                                {values.ChairsideMill && equipment === 'Chairside Mill' && 
                                                <TextField 
                                                    sx={{width: '100%'}}
                                                    select
                                                    id="chairsideMill"                                     
                                                    name="chairsideMill"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cbct}
                                                    label="Chairside Mill" 
                                                    variant="outlined">
                                                    {chairsideMillVendor.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> 
                                                }
                                            </Grid>
                                        </Grid>
                                        
                                    ))}
                                    </Box>  
                                </Grid>
                            </Grid> */}
                            



                            <Button disabled={isLogin} sx={{color: '#fff', marginBottom: '15px', background: ' linear-gradient(90.3deg, #11C984 0.26%, #32BE60 100%, #21713B 100%)', boxShadow: '0px 2px 17px rgba(42, 193, 106, 0.37)', borderRadius: '29px', width: '481px', height: '57px' }} type="submit">
                                Sign-up
                            </Button>

                          
                        
                        </Box>
                        
                    </form>
                )}

            </Formik>
        </Dialog>
    );
}

export default connect(null, mapDispatchToProps)(SignUpModal);