import { Box, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { apiUrl } from "../../constants";
import { getAuthorizedFetchOptions } from "../../helpers/getAuthorizedFetchOptions";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";

const ResendLabInvite = ({ id }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendInvite = async () => {
    setIsLoading(true);
    const response = await fetch(
      apiUrl + "lab/resendlabinvite",
      getAuthorizedFetchOptions("POST", {
        id,
        personnelFirstName: userInfo.firstName,
        personnelLastName: userInfo.lastName,
      })
    );
    const data = await response.json();

    if (data.statusCode !== 10) {
      return console.log(data.error);
    }
    setIsLoading(false);
    dispatch(openSnackbar({title: "Success", body: "Invitation successfully sent"}));
  };

  return (
    <Button size="small" variant="contained" onClick={handleSendInvite}>
      {isLoading && <CircularProgress size={19} sx={{color: "white" }}/>}
      {!isLoading && "Resend Lab Invite"}
    </Button>
  );
};

export default ResendLabInvite;
