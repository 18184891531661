import { AnalyticsBrowser } from '@segment/analytics-next'

const useSegment = () => {
    const trigger = (payload) => {
        if(!process.env.REACT_APP_SEGMENT_WRITE_KEY){
            return;
        }
        const analytics = new AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY })
        if(payload.event === 'page'){
            analytics.page();
        } else if(payload.event === 'identify'){
            analytics.identify(payload.email);
            console.log('identified: ', payload.email)
        } else {
            analytics.track(payload.event,payload.properties)
        }
    }

    return { trigger };
}

export default useSegment;