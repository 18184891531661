import { Stack, Container, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { brandLight } from "../../constants";

const PageLayout = ({ banner, header, children }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ borderRadius: "24px" }}>
        {/* Banner */}
        <Box width="100%" position="relative">
          <Stack
            width="100%"
            maxHeight="209px"
            bgcolor="#000"
            justifyContent="center"
            alignItems="center"
          >
            <a href={banner.link} target="_blank" rel="noreferrer">
              <Box
                component="img"
                src={banner.image}
                alt="patients-banner"
                sx={{
                  display: { xs: "none", md: "inherit" },
                  maxWidth: "100%",
                }}
              />
            </a>
          </Stack>

          <Container
            maxWidth="xl"
            disableGutters={useMediaQuery(theme.breakpoints.down("xl"))}
          >
            <Box paddingTop={4} paddingX={{xs: 3, xl: 0}}>
              <Typography
                variant="h1"
                component="h2"
                display="inline"
                sx={{
                  fontSize: "40px",
                  color: "#000"
                }}
                fontWeight={700}
              >
                {header}
              </Typography>
            </Box>
          </Container>
        </Box>

        <Container
          maxWidth="xl"
        >
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 10,
              paddingX: 6,
              paddingY: 4,
              bgcolor: brandLight,
              borderRadius: "20px",
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PageLayout;
