import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

const SelectInput = ({
  label,
  name,
  items,
  control,
  disabled,
  fullWidth = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth={fullWidth} error={Boolean(error)} size="small">
          {/* <Box
            component="label"
            htmlFor={name + "select-input"}
            id={name + "select-label"}
            sx={{
              color: "#37465D",
              fontWeight: "600",
              textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
              fontSize: "14px",
              lineHeight: "17px",
              marginBottom: "5px",
            }}
          >
            {label}
          </Box> */}
          <InputLabel>{label}</InputLabel>
          <Select
            sx={{
              fontSize: "15px",
              height: "45px",
              backgroundColor: "#ffffff",
            }}
            labelId={name + "-select-label"}
            id={name + "-select-input"}
            displayEmpty
            value={value}
            onChange={onChange}
            disabled={disabled}
          >
            {items.map((item) => (
              <MenuItem name={item.name} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText variant="standard">
            {error && error.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectInput;
