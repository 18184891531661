import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from "../reducer/index";
import storage from 'redux-persist/lib/storage' 

const persistConfig = { 
    key: 'cloudroot',
    storage, 
}

const persistedReducer = persistReducer(persistConfig, rootReducer) 

const store = createStore(persistedReducer,  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const  persistor = persistStore(store); 

export {store, persistor}