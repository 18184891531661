import * as yup from "yup";

export const forgotPasswordSchema = yup.object({
  newPassword: yup
    .string("Enter a valid password")
    .min(8, "New password must be 8 characters or longer")
    .matches(
      /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/,
      "Password must have 1 number, 1 uppercase letter, and 1 lowercase letter"
    )
    .required("New password is required"),
  confirmPassword: yup
    .string("Enter a valid password")
    .oneOf([yup.ref("newPassword"), null], "Passwords don't match")
    .required("Confirm password is required"),
});
