import {
  Dialog,
  DialogContent,
  Stack,
  Checkbox
} from "@mui/material"; 
import React from "react"; 
import { useState } from "react"; 
import LargeButton from "../../components/common/button/largeButton";
 
const ApproveWithFabricateDialog = ({ open, onClose, onSubmit }) => {
  const [isFabricate, setIsFabricate] = useState(true); 
  const handleClose = () => {
    onClose(); 
  };

  const handleFabricateChange = () => {
    setIsFabricate(!isFabricate);
  };
  const handleSubmit = async () => {
      await onSubmit(isFabricate);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          paddingY: 4,
          paddingX: 4,
          borderRadius: "16px",
        },
      }}
      fullWidth
    >
      <DialogContent>
              <Stack alignItems="center" sx={{ marginBottom: 3, fontWeight: "bold", fontSize: "18px" }}>
            <Stack direction="row" alignItems="center">
                <Checkbox
                    id="fabricate" 
                    checked={isFabricate}
                    onChange={handleFabricateChange}
                />
                Continue with Fabrication?
            </Stack>

          <Stack direction="row" spacing={2} sx={{ paddingTop: 4 }}>
            <LargeButton variant="gray" fullWidth onClick={handleClose}>
              Back
            </LargeButton>
            <LargeButton variant="primary" fullWidth onClick={handleSubmit}>
              Confirm
            </LargeButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ApproveWithFabricateDialog;
