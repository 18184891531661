import React, { useRef, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CaseService from "../../services/case.service";
import moment from "moment";
import DownloadFileButton from "../common/button/downloadFileButton";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from "@microsoft/signalr";
import { Check } from "@mui/icons-material";
import {
  brandBlue,
  brandColor,
  brandColorActive,
  brandGray,
} from "../../constants";

const CaseChat = ({ caseId, patientId }) => {
  const [connection, setConnection] = useState(null);

  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      setFiles(files);
    },
  });

  const latestChat = useRef(null);

  latestChat.current = chats;

  const userInfo = useSelector((state) => state.userInfo);

  const messagesEndRef = useRef(null);

  const [ticking, setTicking] = useState(true),
    [count, setCount] = useState(0);

  React.useEffect(() => {
    // CaseService.getChats(caseId, patientId);

    getChats();
  }, []);

  const getChats = async () => {
    const response = await CaseService.getChats(caseId, patientId);
    const data = await response.json();

    let comments = [];

    let refIds = [];

    data?.caseComments.forEach((cc) => {
      comments.push(cc);
      if (cc.isRead === false) {
        refIds.push(cc.id);
      }
    });

    if (refIds.length > 0) {
      await CaseService.updatenotifications(userInfo.Id, refIds);
    }

    comments.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.commentedAt) - new Date(b.commentedAt);
    });

    data.caseComments = comments;
    setChats(comments);

    scrollToBottom();
  };

  React.useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");

          // connection.on("ReceiveMessage", (message) => {
          //   const updatedChat = [...latestChat.current];
          //   updatedChat.push(message);

          //   setChat(updatedChat);
          // });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  React.useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const classes = {
    table: {
      minWidth: 650,
    },
    chatSection: {
      width: "100%",
      height: "100%",
      padding: "30px",
    },
    headBG: {
      backgroundColor: "#d9d9d9",
    },
    borderRight500: {
      borderRight: "1px solid #d9d9d9",
    },
    messageArea: {
      height: "70vh",
      overflowY: "auto",
    },
  };

  const getNameInitials = (name) => {
    let str = name;

    let initials = "";

    if (str) {
      var res = str.split(" ");

      res.forEach((r) => {
        initials = initials.concat(r[0]);
      });
    }

    return initials;
  };

  const handleMessageOnChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message !== "") {
      setLoading(true);

      const sendMessage = async () => {
        let model = {
          CaseId: caseId,
          PersonnelId: userInfo.Id,
          Message: message,
          CommentedBy: "",
          CommentAttachments: [],
          SendMessageNotification: false,
          SendToHubOnly: false,
          SendFromOutsideHub: false,
          Flags: {
            Redesign: false,
            Rescan: false,
          },
          isAssistant: false, //vm.isAssistant === 1 ? true : false,
        };

        setMessage("");
        setFiles([]);
        console.log(files);
        const response = await CaseService.submitCaseComment(
          model,
          files,
          patientId,
          caseId
        );
        const data = await response.json();

        let newChat = Object.assign([], chats);
        newChat.push(data);
        setChats(newChat);

        scrollToBottom();
        setLoading(false);
      };
      sendMessage();
    }
  };
  return (
    <div style={{ padding: "40px" }}>
      <Grid container component={Paper} sx={classes.chatSection}>
        <Grid item xs={12}>
          <List sx={classes.messageArea}>
            {chats.map((chat, index) =>
              chat.commentById === "00000000-0000-0000-0000-000000000000" ? (
                <ListItem key={index}>
                  <Grid container>
                    <Grid item xs={12} align="left">
                      <Stack direction="row">
                        <Tooltip
                          sx={{
                            width: "5%",
                          }}
                          title={chat.commentedBy}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              backgroundColor: brandGray,
                              color: "#fff",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "15px",
                            }}
                          >
                            {getNameInitials(chat.commentedBy)}
                          </Box>
                        </Tooltip>
                        <Stack sx={{ width: "60%" }}>
                          <div
                            style={{
                              background: "#e6e6e6",
                              padding: "10px",
                              borderRadius: "20px",
                            }}
                          >
                            {chat?.flags?.rescan && (
                              <Box
                                component="span"
                                fontWeight="200"
                                color={brandGray}
                                marginRight={1}
                              >
                                Rescan Request Comment
                              </Box>
                            )}
                            {chat?.flags?.redesign && (
                              <Box
                                component="span"
                                fontWeight="200"
                                color={brandGray}
                                marginRight={1}
                              >
                                Redesign Request Comment
                              </Box>
                            )}
                            <Typography
                              whiteSpace="pre-wrap"
                              sx={{ marginTop: "5px", lineHeight: "1" }}
                            >
                              {chat.message}
                            </Typography>

                            {chat.commentAttachments.length > 0 && (
                              <div style={{ marginTop: "10px" }}>
                                <Box
                                  component="span"
                                  fontWeight="200"
                                  color={brandGray}
                                  marginRight={1}
                                >
                                  Attachment:
                                </Box>
                                <Stack>
                                  {chat.commentAttachments.map((ca, idx) => (
                                    <DownloadFileButton
                                      variant="text"
                                      key={idx + ca.fileName}
                                      path={ca.externalPath}
                                      fileName={ca.fileName}
                                      label={ca.fileName}
                                      isExternal
                                    />
                                  ))}
                                </Stack>
                              </div>
                            )}
                          </div>

                          <ListItemText
                            align="left"
                            secondary={
                              "Sent at " +
                              moment(chat.commentedAt).format(
                                "MM/DD/YYYY hh:mm A"
                              )
                            }
                          ></ListItemText>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
              ) : (
                <ListItem key={index}>
                  <Grid container>
                    <Grid item xs={12} align="right">
                      <div dir="rtl">
                        <Stack direction="row">
                          <Tooltip
                            sx={{
                              width: "5%",
                            }}
                            title={chat.commentedBy}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                backgroundColor: brandGray,
                                color: "#fff",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "15px",
                              }}
                            >
                              {getNameInitials(chat.commentedBy)}
                            </Box>
                          </Tooltip>
                          <Stack sx={{ width: "60%" }}>
                            <div
                              style={{
                                background: "#e6e6e6",
                                padding: "10px",
                                borderRadius: "20px",
                                textAlign: "left",
                              }}
                            >
                              {chat?.flags?.rescan && (
                                <Box
                                  component="span"
                                  fontWeight="200"
                                  color={brandGray}
                                  marginRight={1}
                                >
                                  Rescan Request Comment
                                </Box>
                              )}
                              {chat?.flags?.redesign && (
                                <Box
                                  component="span"
                                  fontWeight="200"
                                  color={brandGray}
                                  marginRight={1}
                                >
                                  Redesign Request Comment
                                </Box>
                              )}
                              <Typography
                                whiteSpace="pre-wrap"
                                sx={{ marginTop: "5px", lineHeight: "1" }}
                              >
                                {chat.message}
                              </Typography>

                              {chat.commentAttachments.length > 0 && (
                                <div style={{ marginTop: "10px" }}>
                                  <Box
                                    component="span"
                                    fontWeight="200"
                                    color={brandColor}
                                    marginRight={1}
                                  >
                                    :Attachment
                                  </Box>
                                  <Stack>
                                    {chat.commentAttachments.map((ca, idx) => (
                                      <DownloadFileButton
                                        variant="text"
                                        key={idx}
                                        path={ca.path}
                                        fileName={ca.fileName}
                                        label={ca.fileName}
                                      />
                                    ))}
                                  </Stack>
                                </div>
                              )}
                            </div>

                            <ListItemText
                              align="right"
                              secondary={
                                "Sent at " +
                                moment(chat.commentedAt).format(
                                  "MM/DD/YYYY hh:mm A"
                                )
                              }
                            ></ListItemText>
                          </Stack>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            )}
            <div ref={messagesEndRef} />
          </List>
        </Grid>

        <Divider />
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <TextField
              value={message}
              multiline
              onChange={handleMessageOnChange}
              id="outlined-basic-email"
              label="Write your comment here"
              fullWidth
            />
            <Fab
              color="primary"
              sx={{ background: brandBlue }}
              aria-label="add"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <AttachmentIcon />
            </Fab>
            {loading ? (
              <Fab
                color="primary"
                sx={{ background: brandBlue }}
                aria-label="add"
              >
                <CircularProgress size={30} sx={{ color: "white" }} />
              </Fab>
            ) : (
              <Fab
                color="primary"
                sx={{ background: brandBlue }}
                aria-label="add"
                onClick={handleSendMessage}
              >
                <SendIcon />
              </Fab>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          {files.length > 0 && (
            <Stack
              spacing={1}
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                margin: "auto",
              }}
            >
              <Typography>Attachments:</Typography>
              {files.map((file, i) => (
                <Stack
                  spacing={1}
                  key={file.path + file.lastModified + i}
                  direction="row"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "100%",
                      width: "30px",
                      height: "30px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Check
                      color="success"
                      sx={{
                        display: "block",
                        margin: "auto",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Typography>{file.path}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CaseChat;
