import { Box } from "@mui/material";
import React from "react";

const StyledFormLabel = ({ size = "md", sx, children }) => {
  let fontSize;

  switch(size) {
    case "sm":
      fontSize = "14px";
      break;
    case "md":
      fontSize = "16px";
      break;
    default:
      fontSize = "16px";
  }

  return (
    <Box
      sx={{
        color: "#37465D",
        fontWeight: "600",
        textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
        fontSize: fontSize,
        lineHeight: "19px",
        marginBottom: "5px",
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
export default StyledFormLabel;
