import React, { useEffect, useState } from "react";
import { Box, Grid, Link } from "@mui/material";
import logo from "../../images/evident_logo2.svg";
import AdminService from "../../services/admin.service";
import { Link as RouterLink } from "react-router-dom";

const HeaderBranding = () => {
  const [brand, setBrand] = useState();

  const setBrandLogo = () => {
    let siteSettings = JSON.parse(localStorage.getItem("siteSettings"));
    console.log(siteSettings);

    let siteLogo = siteSettings.find((x) => x.name === "BrandLogo44");
    console.log(siteLogo);
    if (siteLogo !== null && siteLogo !== undefined) {
      setBrand(
        <Link
          component={RouterLink}
          to="/"
          sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "center",
            height: "32px",
            mr: 2,
          }}
        >
          <img src={siteLogo.value} alt="Evident" />
        </Link>
      );
    }
  };
  useEffect(() => {
    if (
      localStorage.getItem("siteSettings") === null ||
      localStorage.getItem("siteSettings") === "" ||
      localStorage.getItem("siteSettings").length === 0 ||
      localStorage.getItem("siteSettings") === "[]"
    ) {
      AdminService.getAppSettings()
        .then((response) => response.json())
        .then((json) => {
          localStorage.setItem("siteSettings", JSON.stringify(json));
          setBrandLogo();
        });
    } else {
      setBrandLogo();
    }
  }, []);

  return <React.Fragment>{brand}</React.Fragment>;
};

export default HeaderBranding;
