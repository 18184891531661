import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { brandColor } from "../../constants";
import { openSnackbar } from "../../redux/actions";
import AdminService from "../../services/admin.service";
import DialogContainer from "../common/dialogContainer";

const NotificationSettingsModal = ({ open, onClose }) => {
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const userInfo = useSelector((state) => state.userInfo);
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      sendEmail: false,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) return;
    if (!userInfo) return;
    const fetchNotifications = async () => {
      const personnelId = userInfo.Id;
      try {
        const response = await AdminService.getEmailNotifications(personnelId);
        const responseData = await response.json();
        const { data } = responseData;
        setValue("sendEmail", data.shouldSendConfirmationEmail);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    setLoading(true);
    fetchNotifications();
  }, [setValue, userInfo, open]);

  const handleNotificationSet = async ({ sendEmail }) => {
    const personnelId = userInfo.Id;
    const notificationData = {
      PersonnelId: personnelId,
      ShouldNotSendConfirmationEmail: !sendEmail,
    };

    setSubmitting(true);
    try {
      const response = await AdminService.toggleEmailNotifications(
        notificationData
      );
      if (!response.ok) {
        throw new Error();
      }
      dispatch(
        openSnackbar({
          title: "Success",
          body: "Email notifications updated",
        })
      );
    } catch (err) {
      dispatch(
        openSnackbar({
          title: "Error",
          body: "Something went wrong. Please try again later",
          type: "error",
        })
      );
    }
    onClose();
    setSubmitting(false);
  };

  return (
    <DialogContainer open={open} onClose={onClose} loading={isSubmitting}>
      <Box component="form" onSubmit={handleSubmit(handleNotificationSet)}>
        {isLoading && (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {!isLoading && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <Typography component="h2" variant="h6">
                Email Notification Settings
              </Typography>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Controller
                name="sendEmail"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="Do you want to recieve notifications for completed case/s?"
                    sx={{
                      fontWeight: "300",
                      fontSize: "18px",
                      lineHeight: "26px",
                      color: "#585656",
                    }}
                    control={
                      <Checkbox {...field} checked={field[`value`] ?? false} />
                    }
                  />
                )}
              />
            </Box>
            <Stack direction="row" spacing={2} sx={{ paddingTop: 4 }}>
              <Button type="button" fullWidth onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  background: brandColor,
                  "&:hover": { background: brandColor },
                }}
              >
                Save Settings
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </DialogContainer>
  );
};

export default NotificationSettingsModal;
