import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import DragAndDropImage from "../../../images/drag-and-drop.svg";
import DiagnosticWaxUp from "../../../images/diagnostic-wax-up.svg";
import caseCreatedIcon from "../../../images/case_created_icon.svg";
import prefGuideIcon from "../../../images/prefguide.svg";
import patientDetailsIcon from "../../../images/patient_details.svg";
import treatmentDetailsIcon from "../../../images/treatment_details.svg";

import PatientService from "../../../services/patient.service";
import AdminService from "../../../services/admin.service";
import FileUploaderService from "../../../services/fileUploader.service";
import ToothChartService from "../../../services/toothChart.service";

import RequiredFilesModal from "./requiredFilesModal/requiredFilesModal";
import ProductDetailModal from "./productDetailModal/productDetailModal";

import EvidentSmilePackage from "./treatmentTypes/evidentSmilePackage";
import CrownAndBridge from "./treatmentTypes/crownAndBridge";
import DiagnosticWaxUpForm from "./treatmentTypes/diagnosticWaxUp";

import { Link } from "@mui/material";
import { getDate } from "date-fns";
import { brandGray } from "../../../constants";

function mapStateToProps(state) {
  return { userInfo: state.userInfo };
}

function InputTreatmentDetails({
  selectedTreatment,
  patientDetails,
  handleNextStep,
  userInfo,
}) {
  const preferenceGuideRef = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [treatmentsSelected, setTreatmentsSelected] = React.useState([]);
  const [patientsList, setPatientsList] = React.useState([]);
  const [practiceList, setPracticeList] = React.useState([]);

  const [patientId, setPatientId] = React.useState(patientDetails?.Id);
  const [lastName, setLastName] = React.useState(patientDetails?.LastName);
  const [firstName, setFirstName] = React.useState(patientDetails?.FirstName);
  const [middleName, setMiddleName] = React.useState("");
  const [birthday, setBirthday] = React.useState(
    patientDetails?.DateofBirth !== null || patientDetails?.DateofBirth !== ""
      ? patientDetails?.DateOfBirth
      : null
  );
  const [email, setEmail] = React.useState(patientDetails?.Email);
  const [practiceId, setPracticeId] = React.useState(
    patientDetails?.PracticeId
  );
  const [requiredFilesOpen, setRequiredFilesOpen] = React.useState(false);
  const [returnDate, setReturnDate] = React.useState("");

  const [productDetailModalOpen, setProductDetailModalOpen] =
    React.useState(false);
  const [productDetail, setProductDetail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [mappedFiles, setMappedFiles] = React.useState("");
  const open = Boolean(anchorEl);
  const [notion, setNotion] = React.useState("");
  React.useEffect(() => {
    setNotion(ToothChartService.getDentalNotationSystem());
    PatientService.getPatientList(userInfo.Id)
      .then((response) => response.json())
      .then((json) => {
        setPatientsList(json);
      });

    AdminService.getPractices(userInfo.Id)
      .then((response) => response.json())
      .then((json) => {
        setPracticeList(json.practices);
      });

    AdminService.getProductProfiles()
      .then((response) => response.json())
      .then((json) => {
        json.forEach((x) => {
          if (
            x.treatmentCategoryId === selectedTreatment[0].treatmentCategoryId
          ) {
            x.products.forEach((y) => {
              if (y.treatmentTypeId === selectedTreatment[0].id) {
                setProductDetail(y);
              }
            });
          }
        });
      });
  }, []);

  React.useEffect(() => {
    selectedTreatment.forEach((treatm) => {
      if (treatm.description === "Evident Smile Package") {
        treatm.productSubDescription =
          "https://evidentdigital.com/smile-package";
      } else if (treatm.description === "Diagnostic Wax-Up") {
        treatm.productSubDescription =
          "https://evidentdigital.com/diagnostic-wax-up";
      } else {
        treatm.productSubDescription = "";
      }

      console.log(treatm);
    });

    setTreatmentsSelected(selectedTreatment);
  }, [selectedTreatment]);

  const cancelSubmission = () => {
    let patientDetailsObject = {
      Id: "",
      FirstName: "",
      LastName: "",
      Email: "",
      DateOfBirth: "",
      PracticeId: "",
      ReturnDate: "",
    };
    handleNextStep(-1, [], patientDetailsObject);
  };

  const proceed = () => {
    setErrorMessage("");

    if (
      firstName === "" ||
      lastName === "" ||
      practiceId === null ||
      practiceId === "" ||
      returnDate === null ||
      returnDate === ""
    ) {
      setErrorMessage("Please fill out patient details!.");
      return;
    }

    let treatmentsSelectedCopy = Object.assign([], treatmentsSelected);

    let validRequirements = true;

    let requirementsCopy = treatmentsSelectedCopy[0].requirements;
    requirementsCopy.forEach((x) => {
      if (
        x.description !== "Tooth Chart" &&
        x.description !== "Type of Restoration (Multiple)" &&
        (x.value === null || (Array.isArray(x.value) && x.value.length === 0))
      ) {
        validRequirements = false;
      } else {
        if (x.description == "Tooth Chart") {
          if (treatmentsSelectedCopy[0].toothNumbers == null) {
            validRequirements = false;
          } else {
            treatmentsSelectedCopy[0].toothNumbers.forEach((element) => {
              if (typeof element.restorationType !== "undefined") {
                if (element.restorationType === "") {
                  validRequirements = false;
                  setErrorMessage(
                    "Please fill out restoration type for selected tooth."
                  );
                  return;
                }
              }
            });
          }
        }
      }
    });

    if (validRequirements === false) {
      setErrorMessage("Please fill out all the details of the case.");
      return;
    }

    if (treatmentsSelectedCopy[0].files.length === 0) {
      setErrorMessage("Please submit required files before proceeding.");
      return;
    }

    ToothChartService.convertToothChartToPng(treatmentsSelectedCopy[0].id).then(
      (response) => {
        treatmentsSelectedCopy[0].toothChartImage = response;

        setTreatmentsSelected(treatmentsSelectedCopy);

        let patientDetailsObject = {
          Id: patientId,
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          DateOfBirth: birthday,
          PracticeId: practiceId,
          ReturnDate: returnDate,
        };
        handleNextStep(1, treatmentsSelectedCopy, patientDetailsObject);
      }
    );
  };

  const onSelectLastName = (value) => {
    if (value !== null) {
      setPatientId(value.id);
      setFirstName(value.firstName);
      setLastName(value.lastName);
      if (value.dateOfBirth !== null) {
        setBirthday(new Date(value.dateOfBirth));
      }
      setEmail(value.email);
      setPracticeId(value.practiceId);

      PatientService.getAllMappedFiles(value.id)
        .then((response) => response.json())
        .then((json) => {
          setMappedFiles(json);
        });
    } else {
      setPatientId("");
      setFirstName("");
      setLastName("");
      setBirthday("");
      setEmail("");
      setPracticeId("");
      setReturnDate("");
      setMappedFiles("");
    }
  };

  const handleTreatmentUpdate = (treatmentToUpdate) => {
    let treatmentsSelectedCopy = Object.assign([], treatmentsSelected);
    treatmentsSelectedCopy.files = Object.assign([], treatmentToUpdate.files);

    let getTreatmentToUpdate = treatmentsSelectedCopy.findIndex(
      (x) => x.id === treatmentToUpdate.id
    );

    if (getTreatmentToUpdate >= 0) {
      treatmentsSelectedCopy[getTreatmentToUpdate] = Object.assign(
        {},
        treatmentToUpdate
      );
      setTreatmentsSelected(treatmentsSelectedCopy);
    }
  };

  const handleProductDetailModalClose = (confirm) => {
    setProductDetailModalOpen(confirm);
  };

  return (
    <div style={{ paddingBottom: "100px" }}>
      <Box sx={{ textAlign: "center", marginTop: "45px" }}>
        <Box
          sx={{
            color: "#0A0909",
            fontSize: "48px",
            fontWeight: "300",
            lineHeight: "70px",
          }}
        >
          Treatment Details
        </Box>
        <Box
          sx={{
            color: "#0A0909",
            fontSize: "18px",
            fontWeight: "300",
            lineHeight: "26px",
          }}
        >
          The first step of ordering a treatment process includes choosing a
          treatment for your patient.
        </Box>
        <Box
          sx={{
            color: "#0A0909",
            fontSize: "18px",
            fontWeight: "300",
            lineHeight: "26px",
          }}
        >
          After this you will have to add patient information and provide
          treatment details.
        </Box>
      </Box>

      <Divider
        sx={{
          border: "0.4px dashed #6C6C6C",
          marginTop: "30px",
          marginBottom: "60px",
        }}
      />

      <Box
        sx={{
          width: "90%",
          margin: "0 auto",
          height: "100%",
          borderLeft: "1px dashed " + brandGray,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "start", height: "57px" }}>
          <Box>
            <Divider
              sx={{
                border: "1px dashed " + brandGray,
                width: "64px",
                height: "0px",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "57px",
              height: "57px",
              borderRadius: "5px",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontSize: "32px",
              lineHeight: "31px",
              position: "relative",
              top: "-28px",
            }}
          >
            <img src={patientDetailsIcon} style={{ width: "32px" }} />
          </Box>
          <Box sx={{ position: "relative", top: "-28px", marginLeft: "20px" }}>
            <Box
              sx={{ fontSize: "28px", lineHeight: "31px", fontWeight: "400" }}
            >
              Patient Details
            </Box>
            <Box
              sx={{
                marginTop: "7px",
                fontSize: "16px",
                lineHeight: "16px",
                color: brandGray,
              }}
            >
              Fill your patient's basic information or select from existing
              patient
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.5)",
            backdropFilter: " blur(20px)",
            borderRadius: "16px",
            marginLeft: "64px",
            width: "90%",
            paddingTop: "45px",
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingBottom: "45px",
            marginBottom: "60px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={4} sx={{ marginBottom: "20px" }}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Last Name
              </Box>
              <Autocomplete
                id="last-name"
                freeSolo
                onChange={(event, value) => onSelectLastName(value)}
                getOptionLabel={(option) => option.lastName}
                options={patientsList}
                inputValue={lastName}
                onInputChange={(event, newInputValue) => {
                  setLastName(newInputValue);
                  setPatientId("");
                  setFirstName("");
                  setBirthday("");
                  setEmail("");
                  setReturnDate("");
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    key={option.id}
                    {...props}
                  >
                    {option.lastName}, {option.firstName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Type in Patient Last Name"
                    label=""
                    sx={{
                      width: "100%",
                      border: "0.5px solid #DFDFDF",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} sx={{ marginBottom: "20px" }}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                First Name
              </Box>
              <TextField
                id="first-name"
                variant="outlined"
                disabled={patientId !== ""}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Type in Patient First Name..."
                sx={{
                  width: "100%",
                  border: "0.5px solid #DFDFDF",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item sm={4} sx={{ marginBottom: "20px" }}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Middle Name (Optional)
              </Box>
              <TextField
                id="middle-name"
                value={middleName}
                disabled={patientId !== ""}
                onChange={(e) => setMiddleName(e.target.value)}
                variant="outlined"
                placeholder="Type in Patient Middle Name..."
                sx={{
                  width: "100%",
                  border: "0.5px solid #DFDFDF",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Birthday (Optional)
              </Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  disabled={patientId !== ""}
                  value={birthday}
                  onChange={(newValue) => {
                    setBirthday(newValue);
                  }}
                  sx={{ border: "0.5px solid #DFDFDF !important" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "100%",
                        border: "0.5px solid #DFDFDF !important",
                        background: "#fff",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={4}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Practice
              </Box>
              <TextField
                id="dental-practice"
                select
                variant="outlined"
                value={practiceId}
                disabled={patientId !== ""}
                onChange={(e) => setPracticeId(e.target.value)}
                sx={{
                  width: "100%",
                  border: "0.5px solid #DFDFDF",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              >
                {practiceList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={4}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Email Address (Optional)
              </Box>
              <TextField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={patientId !== ""}
                variant="outlined"
                placeholder="Patient Email..."
                sx={{
                  width: "100%",
                  border: "0.5px solid #DFDFDF",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <Box
                sx={{
                  color: "#37465D",
                  fontWeight: "600",
                  textShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                  fontSize: "16px",
                  lineHeight: "19px",
                  marginBottom: "5px",
                }}
              >
                Appointment Date
              </Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label=""
                  value={returnDate}
                  onChange={(newValue) => {
                    setReturnDate(newValue);
                  }}
                  minDateTime={new Date()}
                  sx={{ border: "0.5px solid #DFDFDF !important" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "100%",
                        border: "0.5px solid #DFDFDF !important",
                        background: "#fff",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>

        {treatmentsSelected.map((treatment, index) => (
          <>
            <Box sx={{ display: "flex", alignItems: "start", height: "57px" }}>
              <Box>
                <Divider
                  sx={{
                    border: "1px dashed #969696",
                    width: "64px",
                    height: "0px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "57px",
                  height: "57px",
                  borderRadius: "5px",
                  background: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  fontSize: "32px",
                  lineHeight: "31px",
                  position: "relative",
                  top: "-28px",
                }}
              >
                <img src={treatmentDetailsIcon} style={{ width: "32px" }} />
              </Box>
              <Box
                sx={{ position: "relative", top: "-28px", marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    fontSize: "28px",
                    lineHeight: "31px",
                    fontWeight: "400",
                  }}
                >
                  Treatment Details {index + 1}
                </Box>
                <Box
                  sx={{
                    marginTop: "7px",
                    fontSize: "16px",
                    lineHeight: "16px",
                    color: brandGray,
                  }}
                >
                  Type in Details for your selected treatment
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                marginLeft: "64px",
                paddingTop: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "45px",
              }}
            >
              <Box
                sx={{
                  background: "rgba(250, 250, 250, 0.8)",
                  borderRadius: "20px",
                  boxShadow: "0px 5px 10px rgba(138, 138, 138, 0.03)",
                  minHeight: "500px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "38px",
                    backgroundColor: "#414141",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    padding: "30px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "114px",
                      marginRight: "20px",
                      height: "120px",
                      background: "#fff",
                      boxShadow: "inset 0px 12px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "12px",
                      backgroundImage: `url(${treatment.imageLink})`,
                      backgroundSize: "cover",
                    }}
                  ></Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        fontSize: "24px",
                        fontWeigth: "400",
                        lineHeight: "21px",
                        color: "#fff",
                        marginBottom: "3px",
                      }}
                    >
                      {treatment.description}
                    </Box>
                    <Box
                      sx={{
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "#C0C0C0",
                        marginBottom: "24px",
                      }}
                    >
                      <Link
                        target="_blank"
                        href={treatment.productSubDescription}
                      >
                        {treatment.productSubDescription}
                      </Link>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        width: "240px",
                        height: "50px",
                        background: "#4A90E2",
                        borderRadius: "5px",
                        color: "#fff",
                        fontSize: "16px",
                        lineHeight: "19px",
                        textTransform: "none",
                        textShadow: "0px 4px 6px rgba(255, 255, 255, 0.25)",
                        marginRight: "12px",
                      }}
                    >
                      Change Treatment
                    </Button>
                    <Button
                      sx={{
                        width: "292px",
                        height: "50px",
                        background: "none",
                        border: "1px solid #fff",
                        borderRadius: "5px",
                        color: "#fff",
                        fontSize: "16px",
                        lineHeight: "19px",
                        textTransform: "none",
                        textShadow: "0px 4px 6px rgba(255, 255, 255, 0.25)",
                        marginRight: "12px",
                      }}
                      onClick={() => handleProductDetailModalClose(true)}
                    >
                      About This Treatment
                    </Button>
                  </Box>
                </Box>

                <Box sx={{ padding: "30px", paddingTop: "15px" }}>
                  {treatment.description === "Evident Smile Package" ? (
                    <EvidentSmilePackage
                      treatment={treatment}
                      patientId={patientId}
                      handleTreatmentUpdate={handleTreatmentUpdate}
                      patientFiles={mappedFiles}
                      notion={notion}
                    />
                  ) : (
                    ""
                  )}

                  {treatment.description === "Crown & Bridge" ? (
                    <CrownAndBridge
                      treatment={treatment}
                      patientId={patientId}
                      handleTreatmentUpdate={handleTreatmentUpdate}
                      patientFiles={mappedFiles}
                      notion={notion}
                    />
                  ) : (
                    ""
                  )}

                  {treatment.description === "Diagnostic Wax-Up" ? (
                    <DiagnosticWaxUpForm
                      treatment={treatment}
                      patientId={patientId}
                      handleTreatmentUpdate={handleTreatmentUpdate}
                      patientFiles={mappedFiles}
                      notion={notion}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ))}

        {errorMessage !== "" ? (
          <Box sx={{ textAlign: "center", fontSize: "20px", color: "red" }}>
            {errorMessage}
          </Box>
        ) : (
          ""
        )}

        <Box sx={{ display: "flex", alignItems: "end", width: "100%" }}>
          <Box>
            <Divider
              sx={{
                border: "1px dashed #969696",
                width: "64px",
                height: "0px",
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              spacing={1}
              sx={{ position: "relative", top: "28px" }}
            >
              <Grid item sm={6}>
                <Button
                  sx={[
                    {
                      textTransform: "none",
                      background: "#414141",
                      boxShadow: "0px 7px 7px rgba(0, 0, 0, 0.05)",
                      borderRadius: "5px",
                      width: "250px",
                      height: "60px",
                      color: "#fff !important",
                    },
                    { "&:hover": { background: "#414141" } },
                  ]}
                  disabled={true}
                >
                  Add More Treatment
                </Button>
              </Grid>
              <Grid item sm={6} sx={{ textAlign: "right" }}>
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    sx={[
                      {
                        textTransform: "none",
                        background: "#CA2F35",
                        boxShadow: "0px 7px 7px rgba(0, 0, 0, 0.05)",
                        borderRadius: "5px",
                        width: "250px",
                        height: "60px",
                        color: "#fff",
                        marginRight: "15px",
                      },
                      { "&:hover": { background: "#CA2F35" } },
                    ]}
                    onClick={cancelSubmission}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      background:
                        "linear-gradient(270deg, #11C984 0%, #32BE60 100%)",
                      boxShadow: "0px 7px 7px rgba(0, 0, 0, 0.05)",
                      borderRadius: "5px",
                      width: "250px",
                      height: "60px",
                      color: "#fff",
                    }}
                    onClick={proceed}
                  >
                    Proceed
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ProductDetailModal
          isOpen={productDetailModalOpen}
          handleCloseDialog={handleProductDetailModalClose}
          treatmentDetailToDisplay={treatmentsSelected}
          productDetail={productDetail}
        />
      </Box>
    </div>
  );
}

export default connect(mapStateToProps, null)(InputTreatmentDetails);
