import { Container, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ForgotPasswordComponent from "../../components/account/forgotPassword";
import ContentContainer from "../../components/common/contentContainer";
import { brandBlack, brandColor } from "../../constants";
import logo from "../../images/evident_logo2.svg";
import { Link as RouterLink } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#101010", height: 46 }} />
      <Container maxWidth="sm">
        <Box sx={{ paddingY: 10 }}>
          <Box
            component="img"
            src={logo}
            alt="Evident logo"
            sx={{ display: "block", margin: "0 auto" }}
          />
          <ContentContainer sx={{ marginTop: 3 }}>
            <ForgotPasswordComponent />
            <Typography
              sx={{ marginTop: 2, color: brandBlack, textAlign: "center" }}
            >
              Go back to{" "}
              <Link component={RouterLink} to="/login" underline="hover">
                Login
              </Link>
            </Typography>
          </ContentContainer>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
