import {

    Container,
    Box
  } from "@mui/material";
  import React, { useEffect } from "react";
  import Header from "../../components/header/header";  
  const SessionExpire = () => {

    useEffect(()=> {
        const logout = () => {
            window.localStorage.clear();
            const dt = new Date();
            let cTime = dt.getTime();
            localStorage.setItem("checkSessionCount", cTime);
            console.log(localStorage.getItem("checkSessionCount"));
            window.location.href = process.env.REACT_APP_WEB_REDIRECT;
        }
        console.log("session expired redirect");
        logout();
    }, [])

    return (
      <React.Fragment>
        <Header  />
        <Container maxWidth="xl">
          <Box marginY={4}>
          </Box>
        </Container>
      </React.Fragment>
    );
  
}
  export default SessionExpire;
  