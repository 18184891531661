import { useState } from "react";
import { useEffect } from "react";
import PatientService from "../services/patient.service";
import usePaginationValues from "./usePaginationValues";

const usePatientFiles = ({
  patientId,
  type,
  shouldGetExtensions = false,
  take = 20,
}) => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [total, setTotal] = useState(0);
  const { currentPage, skip, pagesCount, handleChangePage } =
    usePaginationValues(take, total);

  useEffect(() => {
    let ignore = false;
    const getFiles = async () => {
      try {
        const response = await PatientService.getPatientFiles({
          patientId,
          type,
          skip,
          take,
        });
        const data = await response.json();
        let files = data.patientFileList;

        if (shouldGetExtensions) {
          const fileExtensionPromises = files.map((file) => {
            return (async () => {
              try {
                const response = await PatientService.getPatientFileExtension(
                  file.id,
                  patientId,
                  file.fileType
                );
                const data = await response.json();
                return data;
              } catch (err) {
                console.log(err);
              }
            })();
          });

          const extensions = await Promise.all(fileExtensionPromises);

          files = files.map((file, i) => ({
            ...file,
            fileExtensions: extensions[i],
          }));
        }

        if (!ignore) {
          setFiles(files);
          setTotal(data.totalCount);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    setLoading(true);
    setFiles([]);
    getFiles();

    return () => {
      ignore = true;
    }
  }, [skip, patientId, shouldGetExtensions, take, type]);

  const handleAddFiles = (files) => {
    if (currentPage === 1) {
      setFiles(f => [...files, ...f]);
    }
  }

  return {
    files,
    loading,
    pages: pagesCount,
    currentPage,
    handleChangePage,
    handleAddFiles
  };
};

export default usePatientFiles;
